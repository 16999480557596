import {
    Box,
    Button as ButtonCore,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Select,
    Switch,
    TextField,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
    AssignmentIndOutlined as AssigneesIcon,
    AssignmentOutlined as TasksIcon,
    Close as CloseIcon,
    DeleteOutline as DeleteIcon,
    HomeOutlined as HomeIcon,
    Subject as DescriptionIcon,
    SupervisedUserCircleOutlined as SupervisedIcon,
    ThumbsUpDownOutlined as ApprovalIcon,
} from '@material-ui/icons'
import React from 'react'
import MuiAlert from '@mui/material/Alert'

import { convertListToObject, convertObjectToList, isMobileDevice } from 'code/Helper'

import { Tasks } from 'objects/Tasks'
import { Subtasks } from 'objects/Tasks/Subtasks'

import { withStyles } from '@material-ui/core/styles'
import FormSection from 'components/Forms/FormSection'
import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import SelectAutocomplete from 'components/SelectAutocomplete'
import ErrorTypography from 'components/Typography/ErrorTypography'
import Widget from 'components/Widget'
import { Button, Typography } from 'components/Wrappers'
import app from 'firebase/app'

import { getYMDFromDate } from 'code/TimeAgo'
import { days, times } from 'pages/calendar/upcoming-events/mock'

import moment from 'moment-timezone'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import useMediaQuery from '@material-ui/core/useMediaQuery'

const GappedLabel = withStyles(theme => ({
    root: {
        justifyContent: 'space-between',
        marginLeft: 0,
    },
}))(FormControlLabel)


/**
 * Updates or creates a task
 */
export default function UpdateTask(props) {
    const theme = useTheme()

    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const taskId = props.match.params.id
    const isNew = taskId === 'new'

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const [isLoading, setIsLoading] = React.useState(false)
    const [hasGrabbed, setHasGrabbed] = React.useState(isNew ? true : false)
    const [deleteDialog, setDeleteDialog] = React.useState({ open: false })

    const [date, setDate] = React.useState(getDefaultStart())

    function getDefaultStart() {
        let data = {}
        let curDate = moment()
        let hours = curDate.hours() + 1

        data.sdow = 0
        data.edow = 0

        if (hours >= 22) {
            hours = 9
            curDate.add(1, 'days')
            data.edow = 1
        }

        let day = getYMDFromDate(curDate)
        data.sd = day
        data.ed = day
        data.st = hours * 60
        data.et = (hours + 1) * 60

        return data
    }

    if (!hasGrabbed && !isLoading) {
        setIsLoading(true)
        grabTask()
    }

    const [task, setTask] = React.useState(Tasks.getNew())

    const updateTask = () => {
        setIsLoading(true)

        let data = new Tasks(task).getRaw()

        data.author = user.getAuthor()
        data.date = date

        const db = app.firestore()

        let taskRef

        if (isNew) {
            taskRef = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('tasks')
                .doc()
            data.cd = new Date()
        } else {
            taskRef = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('tasks')
                .doc(taskId)
        }

        db.runTransaction(function(transaction) {
            return transaction.get(taskRef).then(function(taskDoc) {
                let addedAssigned = []
                let addedSupervised = []
                if (!taskDoc.exists) {
                    if (!isNew) {
                        alert('Task no longer exists!')
                        throw new Error('Document does not exist!')
                    }

                    addedAssigned = data.assigneesArr ? data.assigneesArr : []
                    addedSupervised = data.supervisorsArr ? data.supervisorsArr : []

                    transaction.set(taskRef, data)
                } else {
                    let curData = taskDoc.data()
                    //t mergedNewsletter = mergeNewsletters(data, toBeMergedNewsletter)
                    let oldAssigned = curData.assigneesArr ? curData.assigneesArr : []
                    let oldSupervised = curData.supervisorsArr ? curData.supervisorsArr : []

                    let newAssigned = data.assigneesArr ? data.assigneesArr : []
                    let newSupervised = data.supervisorsArr ? data.supervisorsArr : []

                    addedAssigned = newAssigned.filter(uid => oldAssigned.indexOf(uid) === -1)
                    addedSupervised = newSupervised.filter(uid => oldSupervised.indexOf(uid) === -1)

                    transaction.update(taskRef, mergeTasks(data, curData))
                }

                if (data.notifications === true && (addedAssigned.length > 0 || addedSupervised.length > 0)) {
                    let submission = {
                        chapter: user.getChapter(),
                        taskID: taskRef.id,
                        type: 'ADD',
                        assigned: addedAssigned,
                        supervised: addedSupervised,
                    }

                    let functionsCall = app.functions().httpsCallable('notifyTask')
                    functionsCall(submission)
                }
            })
        })

        taskRef
            .set(data)
            .then(function() {
                setIsLoading(false)

                if (isNew || props.history.length === 0) {
                    props.history.replace('/app/applications/tasks/' + taskRef.id)
                } else {
                    props.history.goBack()
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function mergeTasks(curTask, newData) {
        let newTask = { ...curTask }

        if (newData.weeks) {
            newTask.weeks = newData.weeks
        }

        let taskKeys = Object.keys(newTask.subtasks)

        for (let i = 0; i < taskKeys.length; i++) {
            let curKey = taskKeys[i]
            if (newData.subtasks[curKey]) {
                newTask.subtasks[curKey].status = newData.subtasks[curKey].status
                newTask.subtasks[curKey].lu = newData.subtasks[curKey].lu
            }
        }

        return newTask
    }

    function deleteTask() {
        const db = app.firestore()

        setDeleteDialog({ ...deleteDialog, loading: true })

        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('tasks')
            .doc(taskId)

        ref.delete()
            .then(function(doc) {
                if (props.history.length <= 1) {
                    props.history.replace('/app/applications/tasks/')
                } else {
                    props.history.go(-2)
                }
                setDeleteDialog({ ...deleteDialog, loading: false })
            })
            .catch(function(error) {
                setDeleteDialog({ ...deleteDialog, loading: false, error: error.message })
            })
    }

    function grabTask() {
        const db = app.firestore()
        let taskRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('tasks')
            .doc(taskId)

        taskRef
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    data.subtasks = convertObjectToList(data.subtasks)

                    setTask(new Tasks(data))
                    setDate(data.date)
                    setHasGrabbed(true)
                    setIsLoading(false)
                } else {
                    window.alert('Tasks does not exist')
                }
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error)
            })
    }

    function getPossibleEditors() {
        let mems = chapter.members
        let memIds = Object.keys(mems)
        let finishedMems = []
        for (let i = 0; i < memIds.length; i++) {
            let mem = mems[memIds[i]]
            let newMem = {}
            newMem.id = memIds[i]
            newMem.name = mem.first + ' ' + mem.last
            newMem.first = mem.first
            newMem.last = mem.last
            finishedMems.push(newMem)
        }

        let finalMems = finishedMems.sort(function(a, b) {
            let val = a.last.localeCompare(b.last)
            if (val !== 0) {
                return val
            }

            return a.first.localeCompare(b.first)
        })

        return finalMems
    }

    function getNavTitles() {
        let titles = [
            {
                name: 'My House',
                link: '/app/dashboard/',
                icon: <HomeIcon />,
            },
            {
                name: 'Tasks',
                link: '/app/applications/tasks/',
                icon: <TasksIcon />,
                iconMobileOnly: true,
            },
        ]

        if (isNew) {
            titles.push({ name: 'Create' })
        } else {
            titles.push({
                name: 'Update',
            })
        }

        return titles
    }

    const dividerStyle = isMobileDevice() ? { marginLeft: -16, marginRight: -16 } : { marginLeft: -24, marginRight: -24 }

    const renderEditor = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    label={task.name ? '' : 'Name'}
                    type="text"
                    InputLabelProps={{ shrink: false }}
                    value={task.name}
                    onChange={e => {
                        setTask({ ...task, name: e.target.value })
                    }}
                    inputProps={{
                        maxLength: 64,
                    }}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Type" showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                <Select
                                    native
                                    aria-label="Type"
                                    value={task.type}
                                    onChange={e => {
                                        setTask({ ...task, type: parseInt(e.target.value) })
                                    }}
                                    disabled={!isNew}
                                >
                                    <option value={Tasks.TYPE_UNTIMED}>Untimed</option>
                                    {/*<MenuItem value={Tasks.TYPE_SINGLE}>Timed</MenuItem>*/}
                                    <option value={Tasks.TYPE_WEEKLY}>Recurring Timed (Weekly)</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            {task.type !== Tasks.TYPE_UNTIMED && (
                <>
                    <Grid item xs={12}>
                        <Divider style={dividerStyle} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSection label="Timing" showHeader={true}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: atLeastSmall ? 'row' : 'column',
                                        }}
                                    >
                                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box>
                                                <Select
                                                    native
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={date.sdow !== undefined ? date.sdow : 0}
                                                    onChange={event => {
                                                        let val = parseInt(event.target.value)

                                                        if ((date.st >= date.et && val === date.edow) || val > date.edow) {
                                                            let st = date.st ? date.st : 540 // 9 am
                                                            let newEDOW = val
                                                            if (newEDOW === 6 && date.st >= 1365) {
                                                                st = 1365
                                                            }

                                                            let val2 = st + 60

                                                            if (val2 >= 1440) {
                                                                val2 -= 1440

                                                                setDate({
                                                                    ...date,
                                                                    sdow: val,
                                                                    et: val2,
                                                                    edow: newEDOW + 1,
                                                                })
                                                            } else {
                                                                setDate({ ...date, st: st, sdow: val, et: val2, edow: newEDOW })
                                                            }
                                                        } else {
                                                            setDate({ ...date, sdow: val })
                                                        }
                                                    }}
                                                    style={{ marginTop: 4, marginBottom: 4 }}
                                                >
                                                    {days.map(days => (
                                                        <option value={days.val}>{days.str}</option>
                                                    ))}
                                                </Select>
                                            </Box>
                                            <Box style={{ marginLeft: 4 }}>
                                                <Select
                                                    native
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={date.st !== undefined ? date.st : 0}
                                                    onChange={event => {
                                                        let val = parseInt(event.target.value)

                                                        if (val >= date.et && date.sdow === date.edow) {
                                                            if (date.edow === 6 && val >= 1365) {
                                                                val = 1365
                                                            }

                                                            let val2 = val + 60

                                                            if (val2 >= 1440) {
                                                                val2 -= 1440

                                                                setDate({
                                                                    ...date,
                                                                    st: val,
                                                                    et: val2,
                                                                    edow: date.edow + 1,
                                                                })
                                                            } else {
                                                                setDate({ ...date, st: val, et: val2 })
                                                            }
                                                        } else {
                                                            setDate({ ...date, st: val })
                                                        }
                                                    }}
                                                    style={{ marginTop: 4, marginBottom: 4 }}
                                                >
                                                    {times.map(time => (date.sdow !== 6 || time.val < 1380) && <option value={time.val}>{time.str}</option>)}
                                                </Select>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', flexDirection: 'row', marginLeft: 8, marginRight: 8 }}>
                                            <Typography variant="body1"> to </Typography>
                                        </Box>

                                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box style={{ marginRight: 4 }}>
                                                <Select
                                                    native
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={date.et !== undefined ? date.et : 15}
                                                    onChange={event => setDate({ ...date, et: parseInt(event.target.value) })}
                                                    style={{ marginTop: 4, marginBottom: 4 }}
                                                >
                                                    {times.map(
                                                        time =>
                                                            (date.sdow !== date.edow ||
                                                                (time.val > date.st && date.st !== undefined) ||
                                                                (time.val > 0 && date.st === undefined)) && <option value={time.val}>{time.str}</option>,
                                                    )}
                                                </Select>
                                            </Box>
                                            <Box>
                                                <Select
                                                    native
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={date.edow !== undefined ? date.edow : 0}
                                                    onChange={event => {
                                                        let val = parseInt(event.target.value)

                                                        if ((date.et <= date.st && val === date.sdow) || val < date.sdow) {
                                                            let et = date.et ? date.et : 540 // 9 am
                                                            let newSTOW = val
                                                            if (newSTOW === 0 && date.et < 60) {
                                                                et = 60
                                                            }

                                                            let val2 = et - 60

                                                            if (val2 < 0) {
                                                                val2 += 1440

                                                                setDate({
                                                                    ...date,
                                                                    edow: val,
                                                                    st: val2,
                                                                    sdow: newSTOW - 1,
                                                                })
                                                            } else {
                                                                setDate({ ...date, et: et, edow: val, st: val2, sdow: newSTOW })
                                                            }
                                                        } else {
                                                            setDate({ ...date, edow: val })
                                                        }
                                                    }}
                                                    style={{ marginTop: 4, marginBottom: 4 }}
                                                >
                                                    {days.map(days => (
                                                        <option value={days.val}>{days.str}</option>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </FormSection>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Subtasks *" showHeader={true}>
                    {task &&
                        task.subtasks &&
                        task.subtasks.map((item, index) => (
                            <>
                                <Box style={{ display: 'flex', flexDirection: 'row' }} key={'Item_' + index}>
                                    <Grid container spacing={1} style={{ flexGrow: 1 }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                label={item.name ? '' : 'Task'}
                                                type="text"
                                                onChange={e => {
                                                    let curItems = [...task.subtasks]
                                                    curItems[index].name = e.target.value
                                                    setTask({ ...task, subtasks: curItems })
                                                }}
                                                value={item.name}
                                                inputProps={{
                                                    maxLength: 128,
                                                }}
                                                style={{ marginTop: 4 }}
                                                InputLabelProps={{ shrink: false }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        style={{
                                            margin: 4,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    let curItems = [...task.subtasks]
                                                    curItems.splice(index, 1)
                                                    setTask({ ...task, subtasks: curItems })
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                                {!atLeastSmall && index < task.subtasks.length - 1 ? (
                                    <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                                ) : (
                                    <Divider style={{ backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }} />
                                )}
                            </>
                        ))}

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ButtonCore
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    let newItems = [...task.subtasks]
                                    newItems.push(Subtasks.getNew())
                                    setTask({ ...task, subtasks: newItems })
                                }}
                                size="large"
                                disabled={task.subtasks.length >= 50}
                                style={{ marginTop: 4, marginBottom: 4 }}
                            >
                                Add Task
                            </ButtonCore>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Assignees *" icon={<AssigneesIcon />} showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectAutocomplete
                                inputLabelProps={{ shrink: false }}
                                style={{ marginTop: 4 }}
                                variant="outlined"
                                value={convertObjectToList(task.assignees)}
                                onUpdate={(event, value) => {
                                    setTask({ ...task, assignees: convertListToObject(value, true) })
                                }}
                                freeSolo={false}
                                aria-label="Assignees"
                                events={getPossibleEditors()}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Approval" icon={<ApprovalIcon />} showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                <Select
                                    native
                                    aria-label="Approval"
                                    value={task.approval}
                                    onChange={e => {
                                        setTask({ ...task, approval: parseInt(e.target.value) })
                                    }}
                                >
                                    <option value={Tasks.APPROVAL_AUTOMATIC}>Automatic</option>
                                    <option value={Tasks.APPROVAL_MANUAL}>Manual</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            {task.approval === Tasks.APPROVAL_MANUAL && (
                <Grid item xs={12}>
                    <FormSection label="Supervisors *" icon={<SupervisedIcon />} showHeader={true}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <SelectAutocomplete
                                    inputLabelProps={{ shrink: false }}
                                    style={{ marginTop: 4 }}
                                    variant="outlined"
                                    value={convertObjectToList(task.supervisors)}
                                    onUpdate={(event, value) => {
                                        setTask({ ...task, supervisors: convertListToObject(value, true) })
                                    }}
                                    freeSolo={false}
                                    aria-label="Supervisors"
                                    events={getPossibleEditors()}
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Description" icon={<DescriptionIcon />} showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                type="text"
                                onChange={e => {
                                    setTask({ ...task, description: e.target.value })
                                }}
                                value={task.description}
                                InputProps={{
                                    multiline: true,
                                    rows: 4,
                                }}
                                style={{ marginTop: 4 }}
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                    maxLength: 1024,
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <GappedLabel
                        control={
                            <Switch
                                checked={task.notifications}
                                onChange={handle => {
                                    setTask({ ...task, notifications: !task.notifications })
                                }}
                                color="primary"
                            />
                        }
                        label="Notifications"
                        labelPlacement="start"
                    />
                </FormGroup>
                <MuiAlert severity="info" variant="outlined" sx={{ mt: 1 }}>
                    Notifications will be sent to the assigned members and supervisors when the task is created and when a task approval status updates.
                </MuiAlert>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSubmission
                    isLoading={isLoading}
                    onCancel={() => props.history.replace('/app/applications/task')}
                    onSubmit={() => updateTask()}
                    submitText={isNew ? 'Create' : 'Update'}
                    submitDisabled={
                        task.name.length === 0 ||
                        task.subtasks.length === 0 ||
                        convertObjectToList(task.assignees).length === 0 ||
                        new Tasks(task).hasAnyInvalidSubtasks() ||
                        (task.approval === Tasks.APPROVAL_MANUAL && convertObjectToList(task.supervisors).length === 0)
                    }
                />
            </Grid>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={getNavTitles()}
                    rightButtons={
                        isNew
                            ? []
                            : [
                                  {
                                      name: 'Delete',
                                      type: 'icon',
                                      innerIcon: <DeleteIcon />,
                                      onClick: () => {
                                          setDeleteDialog({ open: true })
                                      },
                                  },
                              ]
                    }
                    key={isNew ? 'new' : 'old'}
                    grid
                />
                {hasGrabbed && (
                    <Grid item xs={12}>
                        {isMobileDevice() ? (
                            renderEditor()
                        ) : (
                            <Widget disableWidgetMenu inheritHeight>
                                {renderEditor()}
                            </Widget>
                        )}
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={deleteDialog.open}
                onClose={() => {
                    if (!deleteDialog.loading) {
                        setDeleteDialog({ open: false })
                    }
                }}
                aria-labelledby="delete-title"
            >
                <DialogTitle id="delete-title">Delete Task?</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this task?</Typography>
                    {deleteDialog.error && <ErrorTypography text={deleteDialog.error} isCentered={true} />}
                </DialogContent>
                <DialogActions>
                    <Button disabled={deleteDialog.loading} onClick={() => setDeleteDialog({ open: false })} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={deleteDialog.loading} onClick={() => deleteTask()} color="red">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
