import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useGenerateEnhancedContent } from 'hooks/mutations/ai/useGenerateEnhancedContent';

const enhancementTypes = [
  { value: 'professional', label: 'Professional' },
  { value: 'more detailed', label: 'More Detailed' },
  { value: 'more concise', label: 'More Concise' },
  { value: 'friendly/casual', label: 'Friendly/Casual' },
  { value: 'action-oriented', label: 'Action-Oriented' },
  { value: 'recruitment focused', label: 'Recruitment Focused' },
  { value: 'alumni engagement', label: 'Alumni Engagement' }
];

/**
 * Dialog for enhancing event descriptions using AI
 */
export default function EnhanceDescriptionDialog({ 
  open, 
  onClose, 
  initialDescription, 
  onDescriptionGenerated 
}) {
  const [enhancementType, setEnhancementType] = useState('professional');
  
  const { mutate: generateContent, isLoading, error, isPending } = useGenerateEnhancedContent({
    onSuccess: (data) => {
      if (data.success && data.generatedContent) {
        onDescriptionGenerated(data.generatedContent);
        onClose();
      }
    }
  });

  const handleSubmit = () => {
    generateContent({
      initialDescription,
      enhancementType
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Events Elevated: Speedy Event Descriptions</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" paragraph>
          Add a short description, and let our AI do the rest!  Save time and energy for more important things.
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          This is a beta feature and may not always work as expected.
        </Typography>
        {error && (
          <Typography variant="body2" color="error" paragraph>
            Error: {error.message || 'Failed to generate content. Please try again.'}
          </Typography>
        )}
        
        <FormControl fullWidth margin="normal">
          <InputLabel id="enhancement-type-label">Enhancement Style</InputLabel>
          <Select
            labelId="enhancement-type-label"
            value={enhancementType}
            onChange={(e) => setEnhancementType(e.target.value)}
            disabled={isLoading}
          >
            {enhancementTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Typography variant="caption" color="textSecondary" style={{ marginTop: 8, display: 'block' }}>
          {enhancementType === 'professional' && 'Creates formal, structured content suitable for business communication.'}
          {enhancementType === 'more detailed' && 'Adds important details like agenda items, prep work, and follow-up information.'}
          {enhancementType === 'more concise' && 'Streamlines the description while preserving essential information.'}
          {enhancementType === 'friendly/casual' && 'Creates warm, approachable content suitable for team-building and social events.'}
          {enhancementType === 'action-oriented' && 'Focuses on clear next steps, responsibilities, and expected outcomes.'}
          {enhancementType === 'recruitment focused' && 'Creates engaging content that appeals to potential new members while highlighting chapter values and benefits.'}
          {enhancementType === 'alumni engagement' && 'Crafts content designed to encourage alumni participation, support, and connection with the chapter.'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isPending}>
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          variant="contained" 
          disabled={isPending || !initialDescription}
          startIcon={isPending ? <CircularProgress size={20} /> : null}
        >
          {isPending ? 'Generating...' : 'Enhance'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}