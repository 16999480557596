import React from 'react'

import {
    Add as AddIcon,
    CancelOutlined as CancelIcon,
    CheckCircleOutlineOutlined as CheckIcon,
    DoneOutline as DoneIcon,
    ArrowDownward as DownArrowIcon,
    FirstPage as FirstPageIcon,
    LastPage as LastPageIcon,
    ChevronRight as NextPageIcon,
    ChevronLeft as PrevPageIcon,
    VisibilityOutlined as PreviewIcon,
    Search as SearchIcon,
    ArrowUpward as UpArrowIcon,
} from '@material-ui/icons'

import { useTheme } from '@material-ui/styles'

import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    InputBase,
    Link,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from '@material-ui/core'

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import { TransitionGroup } from 'react-transition-group'

import { Typography } from '../../Wrappers'

import { getFirebaseDate } from '../../../code/TimeAgo'

import { withStyles } from '@material-ui/core/styles'

import classNames from 'classnames'

// styles
import useStyles from './styles'

import './styles.css'

import moment from 'moment'

import useMediaQuery from '@material-ui/core/useMediaQuery'

const PaginationInput = withStyles(theme => ({
    input: {
        paddingTop: 5,
        paddingBottom: 6,
    },
}))(InputBase)

export default function Table(props) {
    var theme = useTheme()
    var classes = useStyles()

    const isSmall = useMediaQuery(theme.breakpoints.down('xs'))

    const {
        title,
        columns,
        data: _data,
        onAdd,
        rowActions,
        showOnEmpty,
        emptyText,
        addRowText,
        disableAddRow,
        selectActions,
        numRows,
        isLoading,
        onClick,
        onUpdateRow,
        defaultSortable,
        expandToFit,
        minRows,
        hideTableHeader,
        searchable,
    } = props

    const id = props.id ? props.id : 'id'

    const [editing, setEditing] = React.useState({ id: null })
    const [isAdding, setIsAdding] = React.useState(false)
    const [newMember, setNewMember] = React.useState(null)
    const [checkedMembers, setCheckedMembers] = React.useState([])
    const [pageOffset, setPageOffset] = React.useState(0)
    const [sort, setSort] = React.useState(defaultSortable ? defaultSortable : null)
    const [search, setSearch] = React.useState('')
    const [selectedRowId, setSelectedRowId] = React.useState(null)

    // Select the first row by default when data is loaded
    React.useEffect(() => {
        if (_data && _data.length > 0 && onClick) {
            // Only set selected row if no row is currently selected or the currently selected row doesn't exist in the data
            if (selectedRowId === null || !_data.some(item => item[id] === selectedRowId)) {
                setSelectedRowId(_data[0][id])
                // Also trigger the onClick handler to maintain existing behavior
                onClick(_data[0])
            }
        }
    }, [_data, id, onClick, selectedRowId])

    const data =
        !search || search.length < 2
            ? _data
            : _data.filter(member => {
                  const searchWords = search.toLowerCase().split(' ')
                  for (let i = 0; i < columns.length; i++) {
                      if (columns[i].searchable && member[columns[i].value]) {
                          const columnType = typeof member[columns[i].value]
                          const textValue = columnType === 'string' ? member[columns[i].value] : `${member[columns[i].value]}`
                          const value = textValue.toLowerCase()
                          if (searchWords.some(word => word.length > 1 && value.includes(word))) {
                              return true
                          }
                      }
                  }
                  return false
              })

    const [rowsPerPage, setRowsPerPage] = React.useState(numRows ? numRows : 5)

    if (sort !== null) {
        let col = null
        let defaultCol = null

        for (let i = 0; i < columns.length; i++) {
            let curCol = columns[i]
            if (curCol.value === sort.value) {
                col = curCol
            }

            if (curCol.value === defaultSortable.value) {
                defaultCol = col
            }
        }

        if (col) {
            data.sort(function(a, b) {
                let val = getColVal(sort, col, a, b)

                if (val !== 0) {
                    return val
                }

                if (defaultCol) {
                    return getColVal(sort, defaultCol, a, b)
                }

                return 0
            })
        }
    }

    if (data.length > 0 && pageOffset * rowsPerPage >= data.length) {
        let off = pageOffset - 1

        while (off * rowsPerPage >= data.length) {
            off -= 1
        }

        if (off < 0) {
            off = 0
        }

        setPageOffset(off)
    }

    if (pageOffset < 0) {
        setPageOffset(0)
    }

    if (!newMember) {
        redoNewMember()
    }

    function getColVal(sort, col, a, b) {
        if (col.type === 'NUMBER') {
            let val = a[sort.value] - b[sort.value]
            if (val !== 0) {
                return sort.dir === 'asc' ? val : -1 * val
            }
        } else if (col.type === 'BOOLEAN' || col.type === 'IMAGE') {
            let aVal = a[sort.value]
            let bVal = b[sort.value]

            if (aVal && !bVal) {
                return sort.dir === 'asc' ? -1 : 1
            } else if (bVal && !aVal) {
                return sort.dir === 'asc' ? 1 : -1
            }
        } else if (col.type === 'TIME' || col.type === 'DATE') {
            const timeA = a[sort.value].seconds ? a[sort.value].seconds : a[sort.value]._seconds
            const timeB = b[sort.value].seconds ? b[sort.value].seconds : b[sort.value]._seconds
            let val = timeA - timeB
            if (val !== 0) {
                return sort.dir === 'asc' ? val : -1 * val
            }
        } else {
            let val = String(a[sort.value]).localeCompare(String(b[sort.value]))
            if (val !== 0) {
                return sort.dir === 'asc' ? val : -1 * val
            }
        }

        return 0
    }

    function redoNewMember() {
        let nm = {}
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].type === 'SELECT') {
                let keys = Object.keys(columns[i].options)
                let val = ''
                for (let j = 0; j < keys.length; j++) {
                    let key = keys[j]
                    let option = columns[i].options[key]
                    if (option.default) {
                        val = key
                    }
                }
                nm = { ...nm, [columns[i].value]: val }
            } else if (columns[i].type === 'BOOLEAN') {
                nm = { ...nm, [columns[i].value]: false }
            } else {
                nm = { ...nm, [columns[i].value]: '' }
            }
        }
        setNewMember(nm)
    }

    function hasFilled(member) {
        if (!newMember) {
            return false
        }

        for (let i = 0; i < columns.length; i++) {
            if (columns[i].type !== 'BOOLEAN' && columns[i].required === true) {
                if (!member || !member[columns[i].value]) {
                    return false
                }

                let val = member[columns[i].value]
                if (typeof val === 'string' && val.length === 0) {
                    return false
                }
            }
        }

        return true
    }

    function gotoPage(offset) {
        setPageOffset(offset)
    }

    function gotoLastPage() {
        let numMembers = data.length
        let lastPage = Math.ceil(numMembers / rowsPerPage)
        setPageOffset(lastPage - 1)
    }

    function getNumBlankRowsNoAdding() {
        if (expandToFit) {
            if (minRows) {
                return data.length < minRows ? minRows - data.length : 0
            }
            return 0
        }

        let numMembers = data.length
        let startIndex = rowsPerPage * pageOffset

        if (startIndex + rowsPerPage > numMembers) {
            return startIndex + rowsPerPage - numMembers
        }

        return 0
    }

    function getStartingPageIndex() {
        return rowsPerPage * pageOffset
    }

    function getEndingPageIndex() {
        let numMembers = data.length
        let startIndex = rowsPerPage * pageOffset
        let endIndex = startIndex + rowsPerPage < numMembers ? startIndex + rowsPerPage : numMembers
        return endIndex
    }

    function getPagingText() {
        if (expandToFit) {
            return `1-${data.length} of ${data.length}`
        }
        let numMembers = data.length
        let startIndex = rowsPerPage * pageOffset
        let endIndex = startIndex + rowsPerPage < numMembers ? startIndex + rowsPerPage : numMembers

        startIndex++

        return startIndex + '-' + endIndex + ' of ' + numMembers
    }

    function hasNextPage() {
        let numMembers = data.length
        let startIndex = rowsPerPage * pageOffset
        return startIndex + rowsPerPage < numMembers
    }

    function hasPrevPage() {
        return pageOffset > 0
    }

    function getActionPadding(extra) {
        let size = 0

        if (rowActions) {
            size += rowActions.length
        }

        //Adds space for checkbox if selecting stuff
        if (selectActions) {
            size += 1
        }

        size -= 1

        if (extra) {
            size -= extra
        }

        size *= 15
        return size
    }

    function clickRow(rowData) {
        if (onClick) {
            setSelectedRowId(rowData[id])
            onClick(rowData)
        }
    }

    function renderRow(key, column, value, isEditing, onChange, isDisabled) {
        if (isEditing) {
            if (column.type === 'BOOLEAN') {
                return (
                    <div
                        className={classNames({
                            [classes.rowTFWrapperLarge]: column.size === 'large',
                            [classes.rowTFWrapperMedium]: column.size !== 'large' && column.size !== 'small',
                            [classes.rowTFWrapperSmall]: column.size === 'small',
                        })}
                        key={key}
                    >
                        <div className={classes.rowCheckbox}>
                            <Checkbox
                                checked={value}
                                style={{
                                    padding: 3,
                                }}
                                onChange={e => onChange(e)}
                                disabled={column.disabled === true}
                            />
                        </div>
                    </div>
                )
            }

            if (column.type === 'IMAGE') {
                return (
                    <div
                        className={classNames({
                            [classes.rowTFWrapperLarge]: column.size === 'large',
                            [classes.rowTFWrapperMedium]: column.size !== 'large' && column.size !== 'small',
                            [classes.rowTFWrapperSmall]: column.size === 'small',
                        })}
                        key={key}
                    ></div>
                )
            }

            if (column.type === 'SELECT') {
                return (
                    <div
                        className={classNames({
                            [classes.rowTFWrapperLarge]: column.size === 'large',
                            [classes.rowTFWrapperMedium]: column.size !== 'large' && column.size !== 'small',
                            [classes.rowTFWrapperSmall]: column.size === 'small',
                        })}
                        key={key}
                    >
                        <div
                            className={classNames({
                                [classes.rowTextFieldLarge]: column.size === 'large',
                                [classes.rowTextFieldMedium]: column.size !== 'large' && column.size !== 'small',
                                [classes.rowTextFieldSmall]: column.size === 'small',
                            })}
                        >
                            <Select
                                value={value}
                                disabled={column.disabled === true}
                                fullWidth
                                inputProps={{
                                    style: {
                                        paddingTop: 5,
                                        paddingBottom: 6,
                                        textAlign: `center`,
                                        textAlignLast: `center`,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                    },
                                }}
                                native={true}
                                onChange={e => {
                                    onChange(e)
                                }}
                            >
                                {Object.keys(column.options)
                                    .sort((a, b) => column.options[a].title.localeCompare(column.options[b].title))
                                    .map(rowID => (
                                        <option key={rowID} value={rowID}>
                                            {column.options[rowID].title}
                                        </option>
                                    ))}
                            </Select>
                        </div>
                    </div>
                )
            }

            return (
                <div
                    className={classNames({
                        [classes.rowTFWrapperLarge]: column.size === 'large',
                        [classes.rowTFWrapperMedium]: column.size !== 'large' && column.size !== 'small',
                        [classes.rowTFWrapperSmall]: column.size === 'small',
                    })}
                    key={key}
                >
                    <div
                        className={classNames({
                            [classes.rowTextFieldLarge]: column.size === 'large',
                            [classes.rowTextFieldMedium]: column.size !== 'large' && column.size !== 'small',
                            [classes.rowTextFieldSmall]: column.size === 'small',
                        })}
                    >
                        <TextField
                            className="remove-number-arrows"
                            placeholder={column.placeholder ? column.placeholder : column.title}
                            type={column.type ? column.type.toLowerCase() : 'text'}
                            margin="none"
                            size="small"
                            fullWidth
                            disabled={column.disabled === true}
                            value={value}
                            onChange={e => onChange(e)}
                            inputProps={{
                                style: {
                                    paddingTop: 5,
                                    paddingBottom: 6,
                                    textAlign: `center`,
                                },
                                maxLength: 128,
                            }}
                        />
                    </div>
                </div>
            )
        }
    }

    function toggleSortCol(column) {
        if (sort) {
            if (sort.value === column.value) {
                setSort({ ...sort, dir: sort.dir === 'asc' ? 'desc' : 'asc' })
            } else {
                setSort({ ...sort, value: column.value, dir: defaultSortable.dir })
            }
        }
    }

    function renderColumn(column, sortable) {
        if (sortable) {
            // TODO: On hover show arrow. Once sorting has started show it on anything...
            return (
                <Link
                    key={column.title}
                    underline="none"
                    className={classNames({
                        [classes.columnHeaderLarge]: column.size === 'large',
                        [classes.columnHeaderMedium]: column.size !== 'large' && column.size !== 'small',
                        [classes.columnHeaderSmall]: column.size === 'small',
                        [classes.sortLink]: true,
                    })}
                    onClick={() => toggleSortCol(column)}
                >
                    <Typography className={classes.sortingRow} noWrap>
                        {sort.dir === 'asc' ? (
                            <>
                                <UpArrowIcon className={classes.sortingIcon} style={{ opacity: sort.value === column.value ? 1 : 0 }} />
                                {column.title}
                                <UpArrowIcon className={classes.sortingIcon} style={{ opacity: 0 }} />
                            </>
                        ) : (
                            <>
                                <DownArrowIcon className={classes.sortingIcon} style={{ opacity: sort.value === column.value ? 1 : 0 }} />
                                {column.title}
                                <DownArrowIcon className={classes.sortingIcon} style={{ opacity: 0 }} />
                            </>
                        )}
                    </Typography>
                </Link>
            )
        }

        return (
            <Typography
                className={classNames({
                    [classes.columnHeaderLarge]: column.size === 'large',
                    [classes.columnHeaderMedium]: column.size !== 'large' && column.size !== 'small',
                    [classes.columnHeaderSmall]: column.size === 'small',
                })}
                key={column.title}
                noWrap
            >
                {column.title}
            </Typography>
        )
    }

    function renderColumns() {
        return columns.map(column => renderColumn(column, sort !== null && column.sortable))
    }

    function getSlicedData() {
        if (expandToFit) {
            return data
        }

        return data.slice(getStartingPageIndex(), getEndingPageIndex())
    }

    function getCurrentTransitionGroup() {
        return (
            <TransitionGroup
                key={`table_${isLoading ? 'loading_' : ''}page_${data.length === 0 ? '-1' : pageOffset}_${
                    !sort ? '_unsorted' : '_sorted' + sort.value + '_' + sort.dir
                }`}
            >
                {data.length > 0 ? (
                    getSlicedData().map((item, index3) =>
                        editing.id === item[id] ? (
                            <Collapse key={item[id]}>
                                <Box className={classes.tableRow}>
                                    <Box display="flex">
                                        <div
                                            style={{
                                                marginLeft: getActionPadding(hasFilled(editing.data) ? 1 : 0),
                                                marginRight: getActionPadding(hasFilled(editing.data) ? 1 : 0),
                                                display: `flex`,
                                            }}
                                        >
                                            {hasFilled(editing.data) && (
                                                <Tooltip title="Save Changes">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            onUpdateRow(editing.data)
                                                            setEditing({ id: null })
                                                        }}
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Cancel">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setEditing({ id: null })
                                                    }}
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <Box display="flex" justifyContent="space-evenly" flexGrow="1">
                                            {columns.map((column, index4) =>
                                                column.type === 'BOOLEAN'
                                                    ? renderRow(
                                                          index4,
                                                          column,
                                                          column.value && editing && editing.data ? editing.data[column.value] : false,
                                                          true,
                                                          e => {
                                                              setEditing(val => ({
                                                                  id: val.id,
                                                                  data: {
                                                                      ...val.data,
                                                                      [column.value]: e.target.checked,
                                                                  },
                                                              }))
                                                          },
                                                          !column.editable,
                                                      )
                                                    : renderRow(
                                                          index4,
                                                          column,
                                                          column.value && editing && editing.data ? editing.data[column.value] : false,
                                                          true,
                                                          e => {
                                                              setEditing(val => ({
                                                                  id: val.id,
                                                                  data: {
                                                                      ...val.data,
                                                                      [column.value]: e.target.value,
                                                                  },
                                                              }))
                                                          },
                                                          !column.editable,
                                                      ),
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Collapse>
                        ) : item.updating ? (
                            <Collapse key={item[id] ? item[id] : `loading`}>
                                <Box className={classes.tableLoadingInline}>
                                    <Box className={classes.tableLoadingInlineInner}>
                                        <CircularProgress size={24} />
                                    </Box>
                                </Box>
                            </Collapse>
                        ) : item.error ? (
                            <Collapse key={item[id] ? item[id] : `error_${item.error}`}>
                                <Box className={classes.tableRow}>
                                    <Box display="flex">
                                        <Box display="flex" justifyContent="space-evenly" flexGrow="1">
                                            <Typography className={classes.rowTextMedium} noWrap color="red">
                                                {item.error}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Collapse>
                        ) : (
                            <Collapse key={item[id] ? item[id] : index3}>
                                <Box
                                    className={classNames(classes.tableRow, {
                                        [classes.selectableRow]: onClick !== undefined,
                                        [classes.selectedRow]: selectedRowId === item[id],
                                    })}
                                >
                                    <Box display="flex">
                                        {selectActions ? (
                                            <div className={classes.rowCheckbox}>
                                                <Checkbox
                                                    checked={checkedMembers.includes(item[id])}
                                                    style={{
                                                        padding: 3,
                                                    }}
                                                    onChange={e => {
                                                        const index = checkedMembers.indexOf(item[id])
                                                        if (index > -1) {
                                                            let newChecked = []
                                                            for (let i = 0; i < checkedMembers.length; i++) {
                                                                if (index !== i) {
                                                                    newChecked.push(checkedMembers[i])
                                                                }
                                                            }
                                                            setCheckedMembers(newChecked)
                                                        } else {
                                                            let newChecked = [...checkedMembers]
                                                            newChecked.push(item[id])
                                                            setCheckedMembers(newChecked)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {rowActions ? (
                                            rowActions.map((action, index2) => (
                                                <Tooltip title={action.title} key={index2}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            if (action.toggleEditing) {
                                                                let d = { ...item }
                                                                for (let i = 0; i < columns.length; i++) {
                                                                    let column = columns[i]
                                                                    if (column.type === 'TIME' || column.type === 'DATE') {
                                                                        d[column.value] = moment(getFirebaseDate({ date: d[column.value] })).format(
                                                                            column.format,
                                                                        )
                                                                    }
                                                                }
                                                                setEditing({ data: d, id: item[id] })
                                                            } else {
                                                                action.onClick(item)
                                                            }
                                                        }}
                                                    >
                                                        {action.icon}
                                                    </IconButton>
                                                </Tooltip>
                                            ))
                                        ) : (
                                            <></>
                                        )}

                                        <Box display="flex" justifyContent="space-evenly" flexGrow="1" onClick={() => clickRow(item)}>
                                            {columns.map((column, index) =>
                                                column.type && column.type === 'BOOLEAN' ? (
                                                    <div
                                                        className={classNames({
                                                            [classes.rowCheckLarge]: column.size === 'large',
                                                            [classes.rowCheckMedium]: column.size !== 'large' && column.size !== 'small',
                                                            [classes.rowCheckSmall]: column.size === 'small',
                                                        })}
                                                        key={index}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: 3,
                                                                height: 24,
                                                            }}
                                                        >
                                                            {item[column.value] === true ? (
                                                                <CheckIcon />
                                                            ) : column.showFalse !== undefined && column.showFalse(item) ? (
                                                                <CancelIcon />
                                                            ) : (
                                                                <div className={classes.emptyIcon} />
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : column.type && column.type === 'IMAGE' ? (
                                                    <div
                                                        className={classNames({
                                                            [classes.rowCheckLarge]: column.size === 'large',
                                                            [classes.rowCheckMedium]: column.size !== 'large' && column.size !== 'small',
                                                            [classes.rowCheckSmall]: column.size === 'small',
                                                        })}
                                                        key={index}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: 3,
                                                                height: 24,
                                                            }}
                                                        >
                                                            {item[column.value] ? (
                                                                <Tooltip title={column.tooltip}>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => {
                                                                            column.onClick(item)
                                                                        }}
                                                                    >
                                                                        <PreviewIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <div className={classes.emptyIcon} />
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : column.type && column.type === 'DATE' && column.specialty === 'DATE_SIMPLIFIED' ? (
                                                    <Typography
                                                        className={classNames({
                                                            [classes.rowTextLarge]: column.size === 'large',
                                                            [classes.rowTextMedium]: column.size !== 'large' && column.size !== 'small',
                                                            [classes.rowTextSmall]: column.size === 'small',
                                                        })}
                                                        noWrap
                                                        key={index}
                                                    >
                                                        {item[column.value] ? format(parseISO(item[column.value]), 'MMMM d, yyyy') : ''}
                                                    </Typography>
                                                ) : column.type && (column.type === 'TIME' || column.type === 'DATE') ? (
                                                    <Typography
                                                        className={classNames({
                                                            [classes.rowTextLarge]: column.size === 'large',
                                                            [classes.rowTextMedium]: column.size !== 'large' && column.size !== 'small',
                                                            [classes.rowTextSmall]: column.size === 'small',
                                                        })}
                                                        noWrap
                                                        key={index}
                                                    >
                                                        {item[column.value] ? moment(getFirebaseDate({ date: item[column.value] })).format(column.format) : ''}
                                                    </Typography>
                                                ) : column.type && column.type === 'SELECT' ? (
                                                    <Typography
                                                        className={classNames({
                                                            [classes.rowTextLarge]: column.size === 'large',
                                                            [classes.rowTextMedium]: column.size !== 'large' && column.size !== 'small',
                                                            [classes.rowTextSmall]: column.size === 'small',
                                                        })}
                                                        noWrap
                                                        key={index}
                                                    >
                                                        {column.options && item[column.value] in column.options
                                                            ? column.options[item[column.value]].title
                                                            : item[column.value]}
                                                    </Typography>
                                                ) : column.tooltipOnHover ? (
                                                    <Tooltip title={item[column.value]} key={index}>
                                                        <span
                                                            className={classNames({
                                                                [classes.rowTextLarge]: column.size === 'large',
                                                                [classes.rowTextMedium]: column.size !== 'large' && column.size !== 'small',
                                                                [classes.rowTextSmall]: column.size === 'small',
                                                            })}
                                                        >
                                                            <Typography noWrap>{item[column.value]}</Typography>
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <Typography
                                                        className={classNames({
                                                            [classes.rowTextLarge]: column.size === 'large',
                                                            [classes.rowTextMedium]: column.size !== 'large' && column.size !== 'small',
                                                            [classes.rowTextSmall]: column.size === 'small',
                                                        })}
                                                        noWrap
                                                        key={index}
                                                    >
                                                        {item[column.value]}
                                                    </Typography>
                                                ),
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Collapse>
                        ),
                    )
                ) : showOnEmpty && !isAdding && !isLoading ? (
                    <Collapse key={`empty`}>
                        <Box className={classes.tableRow}>
                            <Box display="flex">
                                <Box display="flex" justifyContent="space-evenly" flexGrow="1">
                                    <Typography className={classes.rowTextMedium} noWrap>
                                        {emptyText ? emptyText : 'No records to display'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                ) : (
                    <></>
                )}
                {isAdding && (
                    <Collapse key={`adding`}>
                        <Box className={classes.tableRow}>
                            <Box display="flex">
                                {hasFilled(newMember) ? (
                                    <div
                                        style={{
                                            marginLeft: getActionPadding(),
                                            marginRight: getActionPadding(),
                                        }}
                                    >
                                        <Tooltip title={addRowText ? addRowText : 'Add Member'}>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    onAdd(newMember)
                                                    setIsAdding(false)
                                                    redoNewMember()
                                                }}
                                            >
                                                <DoneIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            marginLeft: getActionPadding(),
                                            marginRight: getActionPadding(),
                                        }}
                                    >
                                        <Tooltip title={'Cancel'}>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setIsAdding(false)
                                                    redoNewMember()
                                                }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}

                                <Box display="flex" justifyContent="space-evenly" flexGrow="1">
                                    {columns.map((column, index4) =>
                                        column.type === 'BOOLEAN'
                                            ? renderRow(index4, column, newMember[column.value], true, e => {
                                                  setNewMember({
                                                      ...newMember,
                                                      [column.value]: e.target.checked,
                                                  })
                                              })
                                            : renderRow(index4, column, newMember[column.value], true, e => {
                                                  setNewMember({
                                                      ...newMember,
                                                      [column.value]: e.target.value,
                                                  })
                                              }),
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                )}
            </TransitionGroup>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {searchable && (
                    <Box
                        style={{
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 12,
                            marginBottom: -18,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <TextField
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            variant="outlined"
                            placeholder="Search members"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSearch('')
                                            }}
                                            disabled={search.length === 0}
                                            style={{ opacity: search.length === 0 ? 0 : 1 }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth={isSmall}
                        />
                    </Box>
                )}
                {(!hideTableHeader || title) && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        className={classes.tableHeader}
                        style={hideTableHeader ? { paddingLeft: 12, paddingTop: 2, paddingRight: 4 } : null}
                    >
                        {title ? (
                            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                                {title}
                            </Typography>
                        ) : (
                            <Box />
                        )}
                        <Box display="flex">
                            {selectActions && checkedMembers.length > 0 ? (
                                selectActions.map(action => (
                                    <Tooltip title={action.title ? action.title(checkedMembers.length) : null} key={action.key}>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                let d = []
                                                for (let i = 0; i < data.length; i++) {
                                                    if (checkedMembers.indexOf(data[i].id) !== -1) {
                                                        d.push(data[i])
                                                    }
                                                }
                                                action.onClick(d)
                                                setCheckedMembers([])
                                            }}
                                            style={{
                                                marginRight: 4,
                                                padding: 2,
                                            }}
                                            disabled={isAdding || isLoading}
                                        >
                                            {action.icon}
                                        </IconButton>
                                    </Tooltip>
                                ))
                            ) : (
                                <></>
                            )}
                            {onAdd ? (
                                <Tooltip title={addRowText ? addRowText : 'Add Member'}>
                                    <span>
                                        <IconButton
                                            size="small"
                                            onClick={() => setIsAdding(true)}
                                            style={{
                                                marginRight: 4,
                                                padding: 2,
                                            }}
                                            disabled={isAdding || isLoading || disableAddRow}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Box>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                container
                className={classes.table}
                style={{
                    height:
                        31 * (expandToFit ? (minRows ? Math.max(data.length, minRows) : data.length) : rowsPerPage) +
                        30 +
                        (isAdding && getNumBlankRowsNoAdding() === 0 ? 30 : 0),
                }}
            >
                <Grid item xs={12} className={classes.tableContent}>
                    <Box className={classes.tableTopRow}>
                        <Box display="flex">
                            {selectActions && data.length > 0 ? (
                                <div key={'Select'} className={classes.rowCheckbox}>
                                    <Checkbox
                                        indeterminate={checkedMembers.length > 0 && checkedMembers.length < data.length}
                                        checked={checkedMembers.length === data.length}
                                        style={{
                                            padding: 3,
                                        }}
                                        onChange={e => {
                                            if (checkedMembers.length < data.length) {
                                                //Go through all of them
                                                let newChecked = []
                                                for (let i = 0; i < data.length; i++) {
                                                    newChecked.push(data[i].id)
                                                }
                                                setCheckedMembers(newChecked)
                                            } else {
                                                setCheckedMembers([])
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {rowActions ? rowActions.map(act => <div key={act.title} className={classes.emptyIcon} />) : <></>}
                            <Box display="flex" justifyContent="space-evenly" flexGrow="1">
                                {renderColumns()}
                            </Box>
                        </Box>
                    </Box>
                    {getCurrentTransitionGroup()}
                    {isLoading && data.length === 0 && (
                        <>
                            <Box className={classes.tableLoading}>
                                <Box className={classes.tableLoadingInner}>
                                    <CircularProgress size={30} />
                                </Box>
                            </Box>
                            <Box className={classes.tableRow}>
                                <Box className={classes.emptyTableTopRow}></Box>
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
            {(getNumBlankRowsNoAdding() > 0 || !isAdding) && (
                <Grid item xs={12}>
                    {expandToFit ? (
                        <Box display="flex" justifyContent="flex-end" className={classes.tableFooter}>
                            <Typography className={classes.pageText} noWrap style={{ marginRight: 8 }}>
                                {getPagingText()}
                            </Typography>
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="flex-end" className={classes.tableFooter}>
                            {numRows ? (
                                <></>
                            ) : (
                                <Select
                                    input={<PaginationInput />}
                                    value={rowsPerPage}
                                    renderValue={value => {
                                        return <div style={{ padding: '0px 5px' }}>{value + ' rows '}</div>
                                    }}
                                    onChange={event => setRowsPerPage(event.target.value)}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            )}
                            <Tooltip title={'First Page'}>
                                <span>
                                    <IconButton
                                        size="small"
                                        onClick={() => gotoPage(0)}
                                        style={{
                                            marginRight: 4,
                                            padding: 2,
                                        }}
                                        disabled={!hasPrevPage()}
                                    >
                                        <FirstPageIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={'Prev Page'}>
                                <span>
                                    <IconButton
                                        size="small"
                                        onClick={() => gotoPage(pageOffset - 1)}
                                        style={{
                                            marginRight: 4,
                                            padding: 2,
                                        }}
                                        disabled={!hasPrevPage()}
                                    >
                                        <PrevPageIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Typography className={classes.pageText} noWrap>
                                {getPagingText()}
                            </Typography>
                            <Tooltip title={'Next Page'}>
                                <span>
                                    <IconButton
                                        size="small"
                                        onClick={() => gotoPage(pageOffset + 1)}
                                        style={{
                                            marginRight: 4,
                                            padding: 2,
                                        }}
                                        disabled={!hasNextPage()}
                                    >
                                        <NextPageIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={'Last Page'}>
                                <span>
                                    <IconButton
                                        size="small"
                                        onClick={() => gotoLastPage()}
                                        style={{
                                            marginRight: 4,
                                            padding: 2,
                                        }}
                                        disabled={!hasNextPage()}
                                    >
                                        <LastPageIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                </Grid>
            )}
        </Grid>
    )
}
