import {
    Box,
    Button as ButtonCore,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Select,
    Switch,
    TextField,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
    Close as CloseIcon,
    DeleteOutline as DeleteIcon,
    HomeOutlined as HomeIcon,
    Link as LinkIcon,
    LocationOnOutlined as LocationIcon,
    ReportProblemOutlined as WarningIcon,
    Subject as DescriptionIcon,
    Timeline as PointsIcon,
    SupervisedUserCircleOutlined as AdminIcon,
} from '@material-ui/icons'
import React from 'react'

import { Points } from 'objects/Points'

import { MembershipIcon } from 'components/Icons'
import FormSection from 'components/Forms/FormSection'
import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import SelectAutocomplete from 'components/SelectAutocomplete'
import ErrorTypography from 'components/Typography/ErrorTypography'
import Widget from 'components/Widget'
import { Button, Typography } from 'components/Wrappers'

import Tip from 'components/Tip'

import UpdateLocation from '../components/UpdateLocation'

import app from 'firebase/app'

import { convertListToObject, convertObjectToList, getPossibleMembers as _getPossibleMembers, isMobileDevice, markSetupStepComplete } from 'code/Helper'
import { v4 as uuidv4 } from 'uuid';

import { expandVisibility, visibilityOptions, compileVisibility } from 'data/Visibility'
import { expandMembers, compileMembers } from 'data/Members'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { LocationContext } from 'context/LocationContext'

import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function UpdatePoints(props) {
    const theme = useTheme()

    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const pointsId = props.match.params.id
    const isNew = !pointsId

    const { chapter, requestChapterUpdate } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)
    const { mapkit, getCurrentLocation } = React.useContext(LocationContext)

    const [isLoading, setIsLoading] = React.useState(false)
    const [hasGrabbed, setHasGrabbed] = React.useState(isNew ? true : false)
    const [errorText, setErrorText] = React.useState(false)
    const [deleteDialog, setDeleteDialog] = React.useState({ open: false })

    const dividerStyle = isMobileDevice() ? { marginLeft: -16, marginRight: -16 } : { marginLeft: -24, marginRight: -24 }

    if (!hasGrabbed && !isLoading) {
        setIsLoading(true)
        grabPoints()
    }

    const [points, setPoints] = React.useState(Points.getNew())
    const [pointsSupervisors, setPointsSupervisors] = React.useState([])

    function getPossibleMembers() {
        return _getPossibleMembers(chapter.members, [])
    }

    const updatePoints = () => {
        setIsLoading(true)

        let submission = {
            chapter: user.getChapter(),
            name: points.name,
            description: points.description,
            links: points.links,
            type: points.type,
            mType: points.mType,
            goal: points.goal,
            rankingsVisibility: points.rankingsVisibility,
            allowRequests: points.allowRequests,
            allowLocationTrackingRequests: points.allowLocationTrackingRequests,
            locations: convertListToObject(points.locations),
            admins: pointsSupervisors.map(supervisor => supervisor.id),
        }

        if (points.mType === 0) {
            submission.vis = points.vis
        } else {
            submission.mems = points.mems
        }

        if (isNew) {
            submission = {
                ...submission,
                author: user.getAuthor(),
            }
        } else {
            submission = { ...submission, id: pointsId }
        }

        let functionsCall = app.functions().httpsCallable('updatePoints')
        functionsCall(submission)
            .then(function(result) {
                setIsLoading(false)
                requestChapterUpdate()

                markSetupStepComplete('points')

                if (props.history.length === 0) {
                    if (isNew) {
                        props.history.replace('/app/applications/points/')
                    } else {
                        props.history.replace('/app/applications/points/view/' + pointsId)
                    }
                } else {
                    props.history.goBack()
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoading(false)
                setErrorText(message)
            })

        setIsLoading(true)
    }

    function deletePoints() {
        const data = {
            chapter: user.getChapter(),
            id: pointsId,
            action: 'delete',
        }

        setDeleteDialog({ ...deleteDialog, loading: true })

        let functionsCall = app.functions().httpsCallable('removePoints')
        functionsCall(data)
            .then(function() {
                requestChapterUpdate()
                if (props.history.length <= 1) {
                    props.history.replace('/app/applications/points/')
                } else {
                    props.history.go(-2)
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setDeleteDialog({ ...deleteDialog, loading: false, error: message })
            })
    }

    function isUserPointsCreateOnly(userId) {
        if (chapter && chapter.members && userId) {
            let mem = chapter.members[userId]
            if (mem) {
                return Array.isArray(chapter.perms['pointsCreate']) && chapter.perms['pointsCreate'].includes(mem.role)
            }
        }

        return false
    }

    function getPointsSupervsiors(data) {
        if (!data || !Array.isArray(data.admins)) {
            return []
        }

        return data.admins.filter(memId => memId in chapter.members && isUserPointsCreateOnly(memId)).map(id => convertUserData(id, chapter.members[id]))
    }

    function onUpdate(field, val) {
        setPoints(points => ({ ...points, [field]: val }))
    }

    function grabPoints() {
        const db = app.firestore()
        let pointRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('points')
            .doc(pointsId)

        pointRef
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    if (data.locations) {
                        data.locations = convertObjectToList(data.locations)
                    }

                    setPoints(new Points(data))
                    setPointsSupervisors(getPointsSupervsiors(data))
                    setHasGrabbed(true)
                    setIsLoading(false)
                } else {
                    window.alert('Points does not exist')
                }
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error)
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function getRankingsVisibilityHelperText(rankingsVisibility) {
        if (rankingsVisibility === Points.RANKINGS_ADMIN) {
            return 'Everyone will be able to see their points, but only admins will be able to see rankings'
        }

        if (rankingsVisibility === Points.RANKINGS_PLACE) {
            return 'Everyone will be able to see their points and ranking in chapter'
        }

        if (rankingsVisibility === Points.RANKINGS_ALL) {
            return "Everyone will be able to see their and other people's rankings"
        }
    }

    function getPointsTypeHelperText(type) {
        if (type === Points.TYPE_MAXIMUM) {
            return 'Members try to get as many points as possible'
        }

        if (type === Points.TYPE_GOAL) {
            return 'Members try to reach a set number of points (most common)'
        }

        if (type === Points.TYPE_PERCENTAGE) {
            return 'Members try to reach a set percentage number of possible points'
        }
    }

    function getNavTitles() {
        let titles = [
            {
                name: 'My House',
                link: '/app/dashboard/',
                icon: <HomeIcon />,
            },
            {
                name: 'Points',
                link: '/app/applications/points/',
                icon: <PointsIcon />,
                iconMobileOnly: true,
            },
        ]

        if (isNew) {
            titles.push({ name: 'Create' })
        } else {
            titles.push({
                name: 'System',
                link: '/app/applications/points/view/' + pointsId,
            })
            titles.push({
                name: 'Update',
            })
        }

        return titles
    }

    function convertUserData(id, memberData) {
        return {
            id: id,
            name: memberData.first + ' ' + memberData.last,
            first: memberData.first,
            last: memberData.last,
        }
    }

    function getPossibleSupervisors() {
        let mems = chapter.members
        let memIds = Object.keys(mems)
        let finishedMems = []
        for (let i = 0; i < memIds.length; i++) {
            let id = memIds[i]
            let mem = mems[id]

            // They cannot select themselves if they are creating a new system
            if ((isNew && id !== user.getId()) || (!isNew && id !== points.author.id)) {
                // They can only select users with points create permission
                if (isUserPointsCreateOnly(id)) {
                    finishedMems.push(convertUserData(id, mem))
                }
            }
        }

        let finalMems = finishedMems.sort(function(a, b) {
            let val = a.last.localeCompare(b.last)
            if (val !== 0) {
                return val
            }

            return a.first.localeCompare(b.first)
        })

        return finalMems
    }

    const renderEditor = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    autoFocus={isNew}
                    margin="dense"
                    label={points.name ? '' : 'Name'}
                    type="text"
                    InputLabelProps={{ shrink: false }}
                    value={points.name}
                    onChange={e => {
                        setPoints({ ...points, name: e.target.value })
                    }}
                    inputProps={{
                        maxLength: 64,
                    }}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Membership" icon={<MembershipIcon />} showHeader>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                                <InputLabel id="update-point-system-membership-label">Type</InputLabel>
                                <Select
                                    native
                                    labelId="update-point-system-membership-label"
                                    label="Type"
                                    value={points.mType}
                                    onChange={e => onUpdate('mType', parseInt(e.target.value))}
                                >
                                    <option value={Points.MEMBERSHIP_TYPE_AUTOMATIC}>Automatic</option>
                                    <option value={Points.MEMBERSHIP_TYPE_CUSTOM}>Custom</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        {points.mType === Points.MEMBERSHIP_TYPE_AUTOMATIC && (
                            <Grid item xs={12}>
                                <SelectAutocomplete
                                    style={{ marginTop: 4 }}
                                    variant="outlined"
                                    value={points.vis ? expandVisibility(points.vis.filter(vis => vis !== null)) : []}
                                    onUpdate={(_, value) => {
                                        onUpdate('vis', compileVisibility(value))
                                    }}
                                    title="Visibility"
                                    freeSolo={false}
                                    aria-label="Visibility"
                                    events={visibilityOptions(true).slice(0, 6)}
                                />
                            </Grid>
                        )}
                        {points.mType === Points.MEMBERSHIP_TYPE_CUSTOM && (
                            <Grid item xs={12}>
                                <SelectAutocomplete
                                    style={{ marginTop: 4 }}
                                    variant="outlined"
                                    value={points.mems ? expandMembers(points.mems, getPossibleMembers()) : []}
                                    onUpdate={(_, value) => {
                                        onUpdate('mems', compileMembers(value))
                                    }}
                                    title="Members"
                                    freeSolo={false}
                                    aria-label="Members"
                                    events={getPossibleMembers()}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Type" showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={points.type === Points.TYPE_GOAL ? 8 : 12}>
                            <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                <Select
                                    native
                                    aria-label="Type"
                                    value={points.type}
                                    onChange={e => {
                                        setPoints({ ...points, type: parseInt(e.target.value) })
                                    }}
                                >
                                    <option value={Points.TYPE_MAXIMUM}>Maximum</option>
                                    <option value={Points.TYPE_GOAL}>Goal</option>
                                    {/*<MenuItem value={Points.TYPE_PERCENTAGE}>Percentage</MenuItem>*/}
                                </Select>
                                <FormHelperText>{getPointsTypeHelperText(points.type)}</FormHelperText>
                            </FormControl>
                        </Grid>
                        {points.type === Points.TYPE_GOAL && (
                            <Grid item xs={12} md={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label="Goal"
                                    type="number"
                                    value={points.goal}
                                    onChange={e => setPoints({ ...points, goal: parseInt(e.target.value) })}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                    <InputLabel id="rankings-select">Show Rankings</InputLabel>
                    <Select
                        native
                        labelId="rankings-select"
                        label="Show Rankings"
                        value={points.rankingsVisibility}
                        onChange={e => {
                            setPoints({ ...points, rankingsVisibility: e.target.value })
                        }}
                    >
                        <option value={Points.RANKINGS_ADMIN}>Only to admins</option>
                        <option value={Points.RANKINGS_PLACE}>Personal</option>
                        <option value={Points.RANKINGS_ALL}>Everyone</option>
                    </Select>
                    <FormHelperText>{getRankingsVisibilityHelperText(points.rankingsVisibility)}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={points.allowRequests}
                            onChange={e => setPoints({ ...points, allowRequests: e.target.checked })}
                            color="primary"
                            margin="dense"
                        />
                    }
                    label="Allow Point Requests"
                    style={{ marginTop: 3 }}
                />
            </Grid>
            {points.allowRequests && (
                <>
                    <Grid item xs={12}>
                        <Divider style={dividerStyle} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSection
                            label="Location Tracking (Beta)"
                            icon={<LocationIcon />}
                            control={
                                <Switch
                                    color="primary"
                                    checked={points.allowLocationTrackingRequests}
                                    onChange={e => {
                                        setPoints(points => ({
                                            ...points,
                                            allowLocationTrackingRequests: e.target.checked,
                                            locations: e.target.checked && !points.locations ? [] : points.locations,
                                        }))
                                    }}
                                    size="small"
                                />
                            }
                            enabled={points.allowLocationTrackingRequests}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Tip
                                        color="yellow"
                                        id="mobile_only"
                                        disableElevation
                                        icon={<WarningIcon />}
                                        hideAction
                                        title="Mobile Only"
                                        description="Location Tracking is only available on the mobile app"
                                        titleStyle={{ textAlign: 'center' }}
                                    />
                                </Grid>
                                {points &&
                                    points.locations &&
                                    points.locations.map((location, index) => (
                                        <Grid item xs={12} md={6} lg={4} key={'Location_' + index}>
                                            <UpdateLocation
                                                location={location}
                                                onUpdate={val => {
                                                    let curLocations = [...points.locations]
                                                    curLocations[index] = val
                                                    setPoints({ ...points, locations: curLocations })
                                                }}
                                                onDelete={() => {
                                                    let curLocations = [...points.locations]
                                                    curLocations.splice(index, 1)
                                                    setPoints({ ...points, locations: curLocations })
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                <Grid item xs={12}>
                                    <ButtonCore
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => {
                                            const addLocation = async () => {
                                                const loc = await getCurrentLocation()

                                                if (loc && loc.coords) {
                                                    let newLocations = [...points.locations]
                                                    newLocations.push({
                                                        name: '',
                                                        data: {
                                                            coordinate: new mapkit.Coordinate(loc.coords.latitude, loc.coords.longitude),
                                                            accuracy: loc.coords.accuracy,
                                                        },
                                                        radius: 100,
                                                        points: { value: 1, length: 6, lengthUnits: 'minutes', round: 0 },
                                                        id: uuidv4(),
                                                    })
                                                    setPoints({ ...points, locations: newLocations })
                                                } else {
                                                    alert('Need location access to use this feature')
                                                }
                                            }

                                            addLocation()
                                        }}
                                        size="large"
                                        disabled={points.locations && points.locations.length >= 9}
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            marginTop: 4,
                                            marginBottom: 4,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            maxWidth: 512,
                                        }}
                                    >
                                        Add Location
                                    </ButtonCore>
                                </Grid>
                            </Grid>
                        </FormSection>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Links" icon={<LinkIcon />} showHeader={true}>
                    {points &&
                        points.links &&
                        points.links.map((link, index) => (
                            <>
                                <Box style={{ display: 'flex', flexDirection: 'row' }} key={'Link_' + index}>
                                    <Grid container spacing={1} style={{ flexGrow: 1 }}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                label={link.name ? '' : 'Name'}
                                                type="text"
                                                onChange={e => {
                                                    let curLinks = [...points.links]
                                                    curLinks[index].name = e.target.value
                                                    setPoints({ ...points, links: curLinks })
                                                }}
                                                value={link.name}
                                                inputProps={{
                                                    maxLength: 64,
                                                }}
                                                style={{ marginTop: 4 }}
                                                InputLabelProps={{ shrink: false }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                label={link.url ? '' : 'Link'}
                                                type="url"
                                                onChange={e => {
                                                    let curLinks = [...points.links]
                                                    curLinks[index].url = e.target.value
                                                    setPoints({ ...points, links: curLinks })
                                                }}
                                                value={link.url}
                                                inputProps={{
                                                    maxLength: 256,
                                                }}
                                                style={{ marginTop: 4 }}
                                                InputLabelProps={{ shrink: false }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        style={{
                                            margin: 4,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    let curLinks = [...points.links]
                                                    curLinks.splice(index, 1)
                                                    setPoints({ ...points, links: curLinks })
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                                {!atLeastSmall && index < points.links.length - 1 ? (
                                    <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                                ) : (
                                    <Divider style={{ backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }} />
                                )}
                            </>
                        ))}

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ButtonCore
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    let newLinks = [...points.links]
                                    newLinks.push({ name: '', url: '' })
                                    setPoints({ ...points, links: newLinks })
                                }}
                                size="large"
                                disabled={points.links.length >= 10}
                                style={{ marginTop: 4, marginBottom: 4 }}
                            >
                                Add Link
                            </ButtonCore>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Description" icon={<DescriptionIcon />} showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                type="text"
                                onChange={e => {
                                    setPoints({ ...points, description: e.target.value })
                                }}
                                value={points.description}
                                InputProps={{
                                    multiline: true,
                                    rows: 4,
                                }}
                                style={{ marginTop: 4 }}
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                    maxLength: 1024,
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label="Supervisors"
                    icon={<AdminIcon />}
                    showHeader={true}
                    labelTooltip="Supervisors are members with points create permission who can award points to members for this point system."
                >
                    <SelectAutocomplete
                        inputLabelProps={{ shrink: false }}
                        style={{ marginTop: 4 }}
                        variant="outlined"
                        value={pointsSupervisors}
                        onUpdate={(ignored, value) => {
                            setPointsSupervisors(value)
                        }}
                        freeSolo={false}
                        aria-label="Supervisors"
                        events={getPossibleSupervisors()}
                    />
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={dividerStyle} />
            </Grid>
            <Grid item xs={12}>
                <FormSubmission
                    isLoading={isLoading}
                    onCancel={() => props.history.push('/app/applications/points')}
                    onSubmit={() => updatePoints()}
                    submitText={isNew ? 'Create' : 'Update'}
                    errorText={errorText}
                    submitDisabled={
                        points.name.length === 0 ||
                        (points.mType === Points.MEMBERSHIP_TYPE_AUTOMATIC && (!points.vis || points.vis.length === 0)) ||
                        (points.mType === Points.MEMBERSHIP_TYPE_CUSTOM && (!points.mems || points.mems.length === 0))
                    }
                />
            </Grid>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={getNavTitles()}
                    rightButtons={
                        isNew
                            ? []
                            : [
                                  {
                                      name: 'Delete',
                                      type: 'icon',
                                      innerIcon: <DeleteIcon />,
                                      onClick: () => {
                                          setDeleteDialog({ open: true })
                                      },
                                  },
                              ]
                    }
                    key={isNew ? 'new' : 'old'}
                    grid
                />
                {hasGrabbed && (
                    <Grid item xs={12}>
                        {isMobileDevice() ? (
                            renderEditor()
                        ) : (
                            <Widget disableWidgetMenu inheritHeight>
                                {renderEditor()}
                            </Widget>
                        )}
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={deleteDialog.open}
                onClose={() => {
                    if (!deleteDialog.loading) {
                        setDeleteDialog({ open: false })
                    }
                }}
                aria-labelledby="delete-title"
            >
                <DialogTitle id="delete-title">Delete System?</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this points system?</Typography>
                    {deleteDialog.error && <ErrorTypography text={deleteDialog.error} isCentered={true} />}
                </DialogContent>
                <DialogActions>
                    <Button disabled={deleteDialog.loading} onClick={() => setDeleteDialog({ open: false })} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={deleteDialog.loading} onClick={() => deletePoints()} color="red">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
