import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Box,
    Button as ButtonBase,
    Collapse,
    Grid,
    Grow,
    IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { 
    Timeline as PointsIcon,
    ChevronLeft,
    ChevronRight 
} from '@material-ui/icons'
import SwipeableViews from 'react-swipeable-views'
import Widget from 'components/Widget'
import { Typography } from 'components/Wrappers'
import { getUserStats } from 'pages/applications/points/utils/stats'
import Skeleton from '@material-ui/lab/Skeleton'

// Reuse the styles from House.js
const useStyles = makeStyles(theme => ({
    componentBox: {
        width: '100%',
    },
    componentBoxContainer: {
        width: '100%',
    },
    widgetParent: {
        maxWidth: `max(640px, 75%)`,
        margin: '0 auto',
        fontSize: '1rem',
    },
    navButton: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0
    },
    navButtonIcon: {
        fontSize: '2rem'
    },
    contentContainer: {
        position: 'relative'
    },
    pointSystemContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '500px',
        margin: '0 auto',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        borderRadius: 2,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    pointSystemHeader: {
        width: '100%',
        textAlign: 'center',
        marginBottom: 16
    },
    pointsDisplay: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 8
    }
}))

/**
 * PointSystemViewer Component - Displays point systems in a swipeable view
 * 
 * @param {Object} props - Component props
 * @param {Array} props.pointSystems - Array of point system objects to display
 * @param {Object} props.user - User object
 * @param {boolean} props.isLoading - Whether the point systems data is currently loading
 * @returns {JSX.Element} The rendered PointSystemViewer component
 */
const PointSystemViewer = ({ pointSystems, user, isLoading = false }) => {
    const classes = useStyles()
    const history = useHistory()
    const [activeIndex, setActiveIndex] = useState(0)
    
    // Show skeleton loader while loading
    if (isLoading) {
        return (
            <Collapse classes={{ root: classes.componentBoxContainer }}>
                <Grow appear in>
                    <Box className={classes.componentBox}>
                        <Box className={classes.widgetParent}>
                            <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                <Box p={2}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1}>
                                            <Box className={classes.navButton}>
                                                <Skeleton variant="circular" width={40} height={40} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} className={classes.contentContainer}>
                                            <Box p={1}>
                                                <Box className={classes.pointSystemContent}>
                                                    <Box className={classes.pointSystemHeader} width="100%">
                                                        <Skeleton variant="text" width="60%" height={28} style={{ margin: '0 auto' }} />
                                                    </Box>
                                                    <Box className={classes.pointsDisplay}>
                                                        <Skeleton variant="circular" width={24} height={24} style={{ marginRight: 8 }} />
                                                        <Skeleton variant="text" width={50} height={32} />
                                                        <Skeleton variant="text" width={120} height={20} style={{ marginLeft: 8 }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box className={classes.navButton}>
                                                <Skeleton variant="circular" width={40} height={40} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Widget>
                        </Box>
                    </Box>
                </Grow>
            </Collapse>
        )
    }
    
    if (!pointSystems) {
        return (
            <Collapse classes={{ root: classes.componentBoxContainer }}>
                <Grow appear in>
                    <Box className={classes.componentBox}>
                        <Box className={classes.widgetParent}>
                            <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                <Box p={2}>
                                    <Typography variant="h6" color="error">Point Systems Error</Typography>
                                    <Typography variant="body2">Point systems data is undefined.</Typography>
                                </Box>
                            </Widget>
                        </Box>
                    </Box>
                </Grow>
            </Collapse>
        )
    }
    
    if (pointSystems.length === 0) {
        return (
            <Collapse key="debug_after" classes={{ root: classes.componentBoxContainer }}>
                <Grow appear in>
                    <Box className={classes.componentBox}>
                        <Box className={classes.widgetParent}>
                            <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                <Box p={2}>
                                    <Typography variant="h6">No Point Systems Available</Typography>
                                    <Typography variant="body2">No point systems have been configured or you don't have access to any.</Typography>
                                </Box>
                            </Widget>
                        </Box>
                    </Box>
                </Grow>
            </Collapse>
        )
    }
    
    const handleChangeIndex = (index) => {
        setActiveIndex(index)
    }
    
    const goToPrevious = () => {
        setActiveIndex(prev => (prev > 0 ? prev - 1 : pointSystems.length - 1))
    }
    
    const goToNext = () => {
        setActiveIndex(prev => (prev < pointSystems.length - 1 ? prev + 1 : 0))
    }
    
    return (
        <Box>
            <Grow appear in>
                <Box className={classes.componentBox}>
                    <Box className={classes.widgetParent}>
                        <Widget disableWidgetMenu inheritHeight noBodyPadding>
                            <Box p={2}>
                                <Grid container spacing={0}>
                                    {pointSystems.length > 1 && (
                                        <Grid item xs={1}>
                                            <IconButton 
                                                className={classes.navButton}
                                                onClick={goToPrevious}
                                                aria-label="Previous system"
                                            >
                                                <ChevronLeft className={classes.navButtonIcon} />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    
                                    <Grid item xs={pointSystems.length > 1 ? 10 : 12} className={classes.contentContainer}>
                                        <SwipeableViews
                                            index={activeIndex}
                                            onChangeIndex={handleChangeIndex}
                                            enableMouseEvents
                                        >
                                            {pointSystems.map((system, index) => {
                                                // Calculate user stats for this point system
                                                const stats = getUserStats(system, user?.id || '');
                                                
                                                return (
                                                    <Box 
                                                        key={system.id} 
                                                        p={1}
                                                    >
                                                        <Box 
                                                            className={classes.pointSystemContent}
                                                            onClick={() => history.push(`/app/applications/points/view/${system.id}`)}
                                                            role="button"
                                                            aria-label={`View ${system.name} point system details`}
                                                        >
                                                            <Box className={classes.pointSystemHeader}>
                                                                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                                                                    {system.name}
                                                                </Typography>
                                                            </Box>
                                                            <Box className={classes.pointsDisplay}>
                                                                <PointsIcon style={{ marginRight: 8 }} />
                                                                <Typography variant="h5" color="primary">
                                                                    {stats.error ? (system.mypoints || 0) : stats.approvedPoints}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" style={{ marginLeft: 8 }}>
                                                                    (+{stats.error ? 0 : stats.pendingPoints} awaiting)
                                                                </Typography>
                                                                {system.contributed !== undefined && (
                                                                    <Typography variant="body2" color="textSecondary" style={{ marginLeft: 8 }}>
                                                                        ({system.contributed || 0} contributed)
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </SwipeableViews>
                                    </Grid>
                                    
                                    {pointSystems.length > 1 && (
                                        <Grid item xs={1}>
                                            <IconButton 
                                                className={classes.navButton}
                                                onClick={goToNext}
                                                aria-label="Next system"
                                            >
                                                <ChevronRight className={classes.navButtonIcon} />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Widget>
                    </Box>
                </Box>
            </Grow>
        </Box>
    )
}

export default PointSystemViewer 