import app from 'firebase/app'
import 'firebase/storage'

import DateFnsUtils from '@date-io/date-fns'
import { Box, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Divider } from '@material-ui/core'
import { DeleteOutlined as DeleteIcon, HomeOutlined as HomeIcon, WhatshotOutlined as RushIcon } from '@material-ui/icons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Skeleton from '@mui/material/Skeleton'
import MuiPhoneNumber from 'material-ui-phone-number'
import React from 'react'

import LoadingButton from '@mui/lab/LoadingButton'

import DocumentUpload from 'components/DocumentUpload'
import NavigationBar from 'components/NavigationBar'
import SelectAutocomplete from 'components/SelectAutocomplete'
import Widget from 'components/Widget'
import {
    Button,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    Dialog as DialogMobile,
    DialogTitle as DialogTitleMobile,
    Typography,
} from 'components/Wrappers'
import useStyles from './styles'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import Resizer from 'react-image-file-resizer'

import { isMobileDevice } from 'code/Helper'
import { v4 as uuidv4 } from 'uuid';
import { isUserRushAdmin } from 'utils/authorization'
import { yearTypes, bidStatusTypes, rusheeSource } from '../utils'
import { db} from 'config/firebase'

export default function EditRushee(props) {
    var classes = useStyles()
    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    let chapterID = user ? user.getChapter() : ''

    const [first, setFirstName] = React.useState('')
    const [last, setLastName] = React.useState('')
    const [major, setMajor] = React.useState('')
    const [hometown, setHometown] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [year, setYear] = React.useState('')
    const [contact, setContact] = React.useState('')
    const [instagram, setInstagram] = React.useState('')
    const [snapchat, setSnapchat] = React.useState('')
    const [bidStatus, setBidStatus] = React.useState('')
    const [amNumber, setAMNumber] = React.useState('')
    var [isLoading, setIsLoading] = React.useState(false)
    const [newMemberName, setNewMemberName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [source, setSource] = React.useState('')
    const [photoURL, setPhotoURL] = React.useState('')

    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false)
    const [eventsAttended, setEventsAttended] = React.useState([])

    const rusheeID = props.match.params.id

    const [hasTried, setHasTried] = React.useState(false)
    const [isGrabbingStart, setIsGrabbingStart] = React.useState(true)
    const [error, setError] = React.useState(true)

    const [eventsAttendedCatID, setEventsAttendedCatID] = React.useState(null)
    const [eventResults, setEventResults] = React.useState([])

    if (!eventResults && eventsAttendedCatID) {
        if (chapter && chapter.events) {
            let events = []
            let eventIDs = Object.keys(chapter.events)
            for (let i = 0; i < eventIDs.length; i++) {
                let e = chapter.events[eventIDs[i]]
                let contains = false

                let newE = {}

                for (let j = 0; j < e.categories.length; j++) {
                    let category = e.categories[j]
                    if (category.id === eventsAttendedCatID) {
                        contains = true
                    }
                }

                newE.id = eventIDs[i]
                newE.name = e.name

                if (contains) {
                    events.push(newE)
                }
            }
            events.sort(function(a, b) {
                return a.name.localeCompare(b.name)
            })
            setEventResults(events)
        }
    }

    if (!hasTried) {
        setHasTried(true)
        getRushee()
    }

    function getRushee() {
        let activeRush = chapter.activeRush

        let rushDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(activeRush)

        let rusheeDoc = rushDoc.collection('rushees').doc(rusheeID)

        rushDoc
            .get()
            .then(function(rushDoc) {
                if (rushDoc.exists) {
                    let rushData = rushDoc.data()

                    rusheeDoc
                        .get()
                        .then(function(doc) {
                            if (doc.exists) {
                                let curRushee = doc.data()
                                setHasTried(true)
                                setIsGrabbingStart(false)
                                setFirstName(curRushee.first)
                                setLastName(curRushee.last)
                                setMajor(curRushee.major)
                                setHometown(curRushee.hometown)
                                setPhone(curRushee.phone)
                                setYear(curRushee.year ?? '')
                                setContact(curRushee.contact ?? '')
                                setPhotoURL(curRushee.photoURL ?? '')
                                setInstagram(curRushee.instagram ?? '')
                                setSnapchat(curRushee.snapchat ?? '')
                                setBidStatus(curRushee.bidStatus ?? '')
                                setAMNumber(curRushee.newMemberNumber ? curRushee.newMemberNumber : '')
                                setNewMemberName(chapter.newMemberName)
                                setEventsAttended(curRushee.eventsAttended)
                                setEventsAttendedCatID(rushData.eventsAttendedCatID) // TODO...
                                setEmail(curRushee.email ?? '')
                                setSource(curRushee.source ?? '')
                            }
                        })
                        .catch(function(error) {
                            console.log('Error getting document:', error)
                        })
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    const [uploadProgress, setUploadProgress] = React.useState(null)

    function uploadURI(uri) {
        setUploadProgress(0)
        let imageName = `rush/` + chapterID + `/` + uuidv4() + `.jpg`
        const uploadTask = app
            .storage()
            .ref(imageName)
            .put(uri, { cacheControl: 'private, max-age=15552000' })
        uploadTask.on(
            'state_changed',
            snapshot => {
                // progress function ...
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                //this.setState({ progress })
                setUploadProgress(progress)
            },
            error => {
                // Error function ...
                console.log('error', error)
                setUploadProgress(null)
            },
            () => {
                // complete function ...
                app.storage()
                    .ref(imageName)
                    .getDownloadURL()
                    .then(function(url) {
                        setUploadProgress(100)
                        setPhotoURL(url)
                        //setPhotoReference(imageName)
                        console.log('finished uploading')
                    })
                    .catch(function(error) {
                        // Handle any errors
                    })
            },
        )
    }

    function uploadImage(image) {
        Resizer.imageFileResizer(
            image, //is the file of the new image that can now be uploaded...
            512, // is the maxWidth of the  new image
            512, // is the maxHeight of the  new image
            'JPEG', // is the compressFormat of the  new image
            80, // is the quality of the  new image
            0, // is the rotatoion of the  new image
            uri => {
                uploadURI(uri)
            },
            'blob', // is the output type of the new image
        )
    }

    function getNewMemberNumber() {
        if (bidStatus === 2) {
            return parseInt(amNumber)
        }

        return null
    }

    function handleSubmit() {
        if (first.length === 0) {
            setError('First name must be filled in')
            return
        }

        if (last.length === 0) {
            setError('Last name must be filled in')
            return
        }

        if (bidStatus === 2 && (amNumber === null || amNumber.length === 0)) {
            setError('New member number must be filled in')
            return
        }

        setIsLoading(true)

        const rusheeDoc = db
            .collection('chapters')
            .doc(chapterID)
            .collection('rushes')
            .doc(chapter.activeRush)
            .collection('rushees')
            .doc(rusheeID)

        const updatedData = {
            first: first,
            last: last,
            hometown: hometown,
            major: major,
            year: year,
            photoURL: photoURL,
            eventsAttended: eventsAttended,
            contact: contact,
            instagram: instagram,
            snapchat: snapchat,
            phone: !phone || phone.length < 16 ? '' : phone,
            bidStatus: bidStatus,
            newMemberNumber: getNewMemberNumber(),
            email: email,
            source: source
        }

        rusheeDoc
            .update(updatedData)
            .then(function() {
                setIsLoading(false)
                props.history.replace(`/app/applications/rush/rushee/${rusheeID}`)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoading(false)
            })
    }

    function handleYearsChange(event) {
        setYear(event.target.value)
    }

    function handleBidStatusChange(event) {
        setBidStatus(event.target.value)
    }

    async function deleteRushee() {
        setIsLoading(true)

        const rushRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(chapter.activeRush)

        const rusheeRef = rushRef.collection('rushees').doc(rusheeID)

        let errored = false

        await db
            .runTransaction(async function(transaction) {
                const rushDoc = await transaction.get(rushRef)
                if (rushDoc.exists) {
                    const rushData = rushDoc.data()
                    let rusheeDoc = await transaction.get(rusheeRef)
                    if (rusheeDoc.exists) {
                        if (Array.isArray(rushData.rushees)) {
                            // transaction.update(rushRef, { rushees: rushData.rushees.filter(rushee => rushee.id !== rusheeID)})
                            transaction.delete(rusheeRef)
                        }
                    }
                }
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                console.log('AN ERROR OCCURRED', code, message)
                setIsLoading(false)
                errored = true
            })

        if (!errored) {
            // Wait a couple of seconds so that the rushee is hopefully gone
            setTimeout(() => {
                if (props.history.length >= 2) {
                    props.history.replace('/app/applications/rush/')
                } else {
                    props.history.go(-2)
                }
            }, 1500)
        }
    }

    const renderEditor = () => (
        <Grid container spacing={2}>
             <Grid item xs={12}>
                <Typography variant="subtitle1">Personal Information</Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
                <>
                    <TextField
                        margin="dense"
                        label={first ? '' : 'First'}
                        type="text"
                        variant="outlined"
                        inputProps={{
                            maxLength: 16,
                        }}
                        onChange={e => {
                            setFirstName(e.target.value)
                        }}
                        style={{ marginTop: 4 }}
                        InputLabelProps={{ shrink: false }}
                        value={first}
                        fullWidth
                    />
                    <FormHelperText error>{first.length === 0 && "The first name field can't be blank"}</FormHelperText>
                </>
            </Grid>
            <Grid item xs={12} sm={6}>
                <>
                    <TextField
                        margin="dense"
                        label={last ? '' : 'Last'}
                        type="text"
                        variant="outlined"
                        inputProps={{
                            maxLength: 16,
                        }}
                        onChange={e => {
                            setLastName(e.target.value)
                        }}
                        value={last}
                        style={{ marginTop: 4 }}
                        InputLabelProps={{ shrink: false }}
                        fullWidth
                    />
                    <FormHelperText error>{last.length === 0 && "The last name field can't be blank"}</FormHelperText>
                </>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label="Major"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 16,
                    }}
                    onChange={e => {
                        setMajor(e.target.value)
                    }}
                    value={major}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label="Hometown"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setHometown(e.target.value)
                    }}
                    value={hometown}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="year-id">Year</InputLabel>
                    <Select
                        labelId="year-id"
                        value={year}
                        onChange={event => {
                            handleYearsChange(event)
                        }}
                        fullWidth
                        label="Year"
                    >
                        {yearTypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPhoneNumber
                    defaultCountry={'us'}
                    margin="dense"
                    label="Phone"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setPhone(e)
                    }}
                    value={phone}
                    fullWidth
                    className={classes.phoneNumberField}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label="Email"
                    type="text"
                    variant="outlined"
                    onChange={e => {
                        setEmail(e.target.value)
                    }}
                    value={email}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="source-id">Source</InputLabel>
                    <Select
                        labelId="source-id"
                        value={source}
                        onChange={event => {
                            setSource(event.target.value)
                        }}
                        fullWidth
                        label="Source"
                    >
                        {rusheeSource.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {contact && (<Grid item xs={12}>
                <TextField
                    margin="dense"
                    label="Contact (deprecated - set Chapter Contact in Rushee View)"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setContact(e.target.value)
                    }}
                    value={contact}
                    fullWidth
                    />
                </Grid>
            )}
           <Grid item xs={12}>
           <Typography variant="subtitle1">Socials</Typography>
           <Divider/>
           </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label="Instagram Handle"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={e => {
                        setInstagram(e.target.value)
                    }}
                    value={instagram}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label="Snapchat"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={e => {
                        setSnapchat(e.target.value)
                    }}
                    value={snapchat}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="bid-status-id">Bid Status</InputLabel>
                    <Select
                        labelId="bid-status-id"
                        value={bidStatus}
                        onChange={event => {
                            handleBidStatusChange(event)
                        }}
                        fullWidth
                        label="Bid Status"
                    >
                        {bidStatusTypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                {bidStatus === 2 && (
                    <>
                        <TextField
                            margin="dense"
                            label={newMemberName + ' #'}
                            type="number"
                            variant="outlined"
                            required
                            inputProps={{
                                maxLength: 3,
                            }}
                            onChange={e => {
                                setAMNumber(e.target.value)
                            }}
                            value={amNumber}
                            fullWidth
                        />
                        <FormHelperText error>{amNumber !== null && amNumber.length === 0 && newMemberName + " number can't be blank"}</FormHelperText>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" style={{ height: 16 }}>
                    New Photo
                </Typography>
                <DocumentUpload
                    onUpdateFile={file => {
                        /*setFile(file)
            parseFile(file)*/
                        console.log('todo', file)
                        if (file !== null) {
                            uploadImage(file)
                        } else {
                            setUploadProgress(null)
                        }
                    }}
                    progress={uploadProgress}
                    size="small"
                    multiple={false}
                    fileType="image/png,image/jpeg"
                    fileTypeText=".png, .jpg, or .jpeg"
                />
            </Grid>
            {eventsAttended && eventsAttended.length > 0 && (<Grid item xs={12} className={classes.section}>
                <SelectAutocomplete
                    value={eventsAttended}
                    freeSolo={true}
                    variant="outlined"
                    onUpdate={(e, value) => {
                        setEventsAttended(value)
                    }}
                    title="Events Attended (deprecating - use Rush Events Attendance tracking in Rushee View)"
                    events={eventResults}
                />
            </Grid> )}
            <Grid item xs={12}>
                <FormHelperText error style={{ textAlign: 'center' }}>
                    {error !== null ? error : null}
                </FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{
                                marginRight: 4,
                            }}
                            onClick={() => {
                                props.history.push('/app/applications/rush/rushee/' + rusheeID)
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {isLoading || (uploadProgress !== null && uploadProgress < 100) ? (
                            <Box
                                style={{
                                    paddingLeft: 25,
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => handleSubmit()}
                                style={{
                                    marginLeft: 4,
                                }}
                            >
                                Update
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const renderPreview = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Skeleton sx={{ height: 56, width: '100%' }} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Skeleton variant="rectangular" width={128} height={36} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Skeleton variant="rectangular" width={128} height={36} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form>
                    <Grid container spacing={2}>
                        <NavigationBar
                            titles={[
                                {
                                    name: 'My House',
                                    link: '/app/dashboard/',
                                    icon: <HomeIcon />,
                                },
                                {
                                    name: 'Rush',
                                    link: '/app/applications/rush/',
                                    icon: <RushIcon />,
                                    iconMobileOnly: true,
                                },
                                {
                                    name: 'Rushee',
                                    link: '/app/applications/rush/rushee/' + rusheeID,
                                },
                                {
                                    name: 'Update',
                                },
                            ]}
                            rightButtons={
                                isUserRushAdmin(chapter, user)
                                    ? [
                                          {
                                              name: 'Delete',
                                              type: 'icon',
                                              tooltip: 'Delete Rushee',
                                              innerIcon: <DeleteIcon />,
                                              onClick: () => {
                                                  setConfirmDeleteOpen(true)
                                              },
                                          },
                                      ]
                                    : null
                            }
                            grid
                        />
                        {isGrabbingStart ? (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    renderPreview()
                                ) : (
                                    <Widget disableWidgetMenu inheritHeight>
                                        {renderPreview()}
                                    </Widget>
                                )}
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    renderEditor()
                                ) : (
                                    <Widget disableWidgetMenu inheritHeight>
                                        {renderEditor()}
                                    </Widget>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </form>
            </MuiPickersUtilsProvider>
            <DialogMobile open={confirmDeleteOpen} fullWidth onClose={() => setConfirmDeleteOpen(false)} disableClose={isLoading}>
                <DialogTitleMobile onClose={() => setConfirmDeleteOpen(false)} disableClose={isLoading}>
                    Delete Rushee?
                </DialogTitleMobile>
                <DialogContentMobile>
                    <Typography>This is permanent and cannot be undone.</Typography>
                </DialogContentMobile>
                <DialogActionsMobile>
                    <Button onClick={() => setConfirmDeleteOpen(false)} color="primary" disabled={isLoading}>
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={() => {
                            deleteRushee()
                        }}
                        loading={isLoading}
                        color="red"
                    >
                        Delete Rushee
                    </LoadingButton>
                </DialogActionsMobile>
            </DialogMobile>
        </>
    )
}
