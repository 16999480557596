import { SocialLogin } from '@capgo/capacitor-social-login';
import app from 'firebase/app'
import 'firebase/firestore'
import React, { useEffect, useState } from 'react'

import { User } from '../objects/User'
import { useAnalytics } from 'services/mixpanel/analytics'

export const AuthContext = React.createContext()

export const AuthProvider = props => {
    const children = props.children
    const db = app.firestore()
    let storageItem = localStorage.getItem('user')
    let userDateStorageItem = localStorage.getItem('cache_userDate')
    const [currentUser, setCurrentUser] = useState(storageItem ? JSON.parse(storageItem) : null)
    const [userStorageDate, setUserStorageDate] = useState(userDateStorageItem ? new Date(userDateStorageItem) : new Date(0))
    const analytics = useAnalytics();
    let user = currentUser ? new User(currentUser) : null

    function isCacheOld(cacheTimestamp) {
        let timeDiff = Math.floor((new Date() - cacheTimestamp) / 1000 / 60)
        return timeDiff > 60 * 24 || isNaN(timeDiff)
    }

    function resetCacheDate() {
        setUserStorageDate(new Date())
        localStorage.setItem('cache_userDate', new Date().toString())
    }

    function setUser(u) {
        localStorage.setItem('user', JSON.stringify(u))
        setCurrentUser(u)
        resetCacheDate()
    }

    async function signOut() {
        try {
            // Sign out from social login providers if available
            if (SocialLogin) {
                await SocialLogin.logout({
                    'provider': 'google'
                })
                .catch(error => console.log('Social logout error:', error));
            }
            
            //TODO: Remove the notifications for the user with the current token?
            await app.auth().signOut()
            localStorage.removeItem('chapter_setup')
            
            let itemsToRemove = [
                'user',
                'cache_chapter',
                'cache_chapterDate',
                'cache_fraternity',
                'cache_fraternityDate',
                'cache_members',
                'cache_members_date',
                'cache_chapterTimezone',
            ]

            for (let i = 0; i < itemsToRemove.length; i++) {
                localStorage.removeItem(itemsToRemove[i])
            }
            
            await analytics.reset()
        } catch (error) {
            console.error('Error during sign out:', error);
        }
    }

    const requestUserUpdate = async dontUpdate => {
        if (currentUser) {
            try {
                let doc = await db
                    .collection('users')
                    .doc(currentUser.uid)
                    .get()

                if (doc.exists) {
                    var data = doc.data()
                    data.uid = currentUser.uid

                    if (dontUpdate !== true) {
                        setCurrentUser(data)
                    }

                    localStorage.setItem('user', JSON.stringify(data))
                } else {
                    // doc.data() will be undefined in this case
                    // Take the user to an 'auth code page'
                    let tempUser = {
                        isTryingToRegister: true,
                        userID: currentUser.uid,
                    }

                    setCurrentUser(tempUser)

                    localStorage.setItem('user', JSON.stringify(tempUser))
                    console.log("User doesn't exist")
                }

                resetCacheDate()
            } catch (error) {
                console.log('Error getting document:', error)
            }
        }
    }

    useEffect(() => {
        app.auth()
            .getRedirectResult()
            .then(function(result) {
                let user = result.user

                if (user && user.uid) {
                    logInUser(user)
                }
            })
        app.auth().onAuthStateChanged(user => {
            if (user && user.uid) {
                logInUser(user)
            } else {
                setCurrentUser(null)

                let itemsToRemove = [
                    'user',
                    'cache_chapter',
                    'cache_chapterDate',
                    'cache_fraternity',
                    'cache_fraternityDate',
                    'cache_members',
                    'cache_members_date',
                    'cache_chapterTimezone',
                ]

                for (let i = 0; i < itemsToRemove.length; i++) {
                    localStorage.removeItem(itemsToRemove[i])
                }
            }
        })

        if (currentUser && !currentUser.isTryingToRegister && isCacheOld(userStorageDate)) {
            requestUserUpdate(true)
        }
    }, [])

    const logInUser = async user => {
        try {
            const doc = await db
                .collection('users')
                .doc(user.uid)
                .get()

            if (doc.exists) {
                var data = doc.data()
                data.uid = user.uid
                let cachedItem = localStorage.getItem('user')
                if (!cachedItem || JSON.parse(cachedItem).uid !== user.uid) {
                    setCurrentUser(data)
                }

                localStorage.setItem('user', JSON.stringify(data))
                resetCacheDate()
            }
        } catch (error) {
            console.log('Error getting document:', error)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                requestUserUpdate,
                user,
                setUser,
                signOut,
                logInUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
