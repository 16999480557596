import React from 'react'

import { Box } from '@material-ui/core'

import { calculateCoordinateDistanceInMeters, metersToFeet } from 'code/Helper'

import LocationOption from '../components/LocationOption'

import useStyles from '../styles'

const LocationOptions = ({ locations, userLocation, selected, onSelect, ...props }) => {
    const classes = useStyles()

    const sortedLocations = locations.map(location => ({
        ...location,
        distance: metersToFeet(calculateCoordinateDistanceInMeters(location?.data?.coordinate || location.center, userLocation)) - location.radius,
        id: `${location?.data?.coordinate?.latitude || location.center.latitude}.${location?.data?.coordinate?.longitude || location.center.longitude}`,
    }))

    sortedLocations.sort((a, b) => a.distance - b.distance)

    return (
        <Box className={classes.locationOptionsBox}>
            {sortedLocations.map(location => (
                <Box key={location.id} component="section">
                    <LocationOption
                        userLocation={userLocation}
                        location={location}
                        selected={selected === location.id}
                        onClick={() => {
                            onSelect(location, location.distance > 0)
                        }}
                        distance={location.distance}
                    />
                </Box>
            ))}
        </Box>
    )
}

export default LocationOptions
