import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core'

export default function DeleteEventDialog({ 
    open, 
    isLoading, 
    errorText, 
    onClose, 
    onDelete,
    recurringEventId 
}) {
    const [deleteOption, setDeleteOption] = useState('single')
    console.log(`recurringEventId: ${recurringEventId}`)

    const handleDelete = () => {
        onDelete(deleteOption === 'series' ? recurringEventId : undefined)
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!isLoading) {
                    onClose()
                    setDeleteOption('single') // Reset on close
                }
            }}
        >
            <DialogTitle>Delete event?</DialogTitle>
            <DialogContent>
                {recurringEventId ? (
                    <RadioGroup
                        value={deleteOption}
                        onChange={(e) => setDeleteOption(e.target.value)}
                    >
                        <FormControlLabel 
                            value="single" 
                            control={<Radio />} 
                            label="Delete this event only"
                        />
                        <FormControlLabel 
                            value="series" 
                            control={<Radio />} 
                            label="Delete entire series"
                        />
                    </RadioGroup>
                ) : (
                    <DialogContentText>Are you sure you want to delete this event?</DialogContentText>
                )}

                {isLoading ? (
                    <>
                        <CircularProgress
                            style={{
                                display: 'block',
                                margin: 'auto',
                                marginTop: 12,
                            }}
                        />
                    </>
                ) : (
                    errorText && (
                        <DialogContentText
                            style={{
                                color: '#E66868',
                                textAlign: `center`,
                                marginTop: 12,
                                marginBottom: 0,
                            }}
                        >
                            {errorText}
                        </DialogContentText>
                    )
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={isLoading}>
                    Cancel
                </Button>
                <Button onClick={handleDelete} disabled={isLoading} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteEventDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errorText: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    recurringEventId: PropTypes.string,
} 