import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';

const CurrencyInput = ({
  label = "Amount",
  currency = "$",
  initialValue = "",
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  
  // Format the number with commas for thousands
  const formatCurrency = (val) => {
    // Remove non-numeric characters except decimal point
    const numericValue = val.replace(/[^0-9.]/g, '');
    
    // Make sure we only have one decimal point
    const parts = numericValue.split('.');
    let formatted = parts[0];
    
    // Add commas for thousands
    if (formatted.length > 3) {
      formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    
    // Add decimal part back if it exists
    if (parts.length > 1) {
      formatted += '.' + parts[1].slice(0, 2); // Limit to 2 decimal places
    }
    
    return formatted;
  };
  
  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, '');
    const formatted = formatCurrency(rawValue);
    
    setValue(formatted);
    
    // Call external onChange with the numeric value
    if (onChange) {
      // Convert to number and call onChange
      const numericValue = parseFloat(rawValue) || 0;
      onChange(numericValue);
    }
  };
  
  return (
    <TextField
      label={label}
      value={value}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{currency}</InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default CurrencyInput;