import React from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Switch,
} from '@material-ui/core'
import Stack from '@mui/material/Stack'
import { CalendarToday as CalendarIcon } from '@material-ui/icons'
import FormSection from 'components/Forms/FormSection'
import moment from 'moment'
import { getDefaultEventTiming } from 'utils'
import SingleDatePicker from 'components/date-picker/DatePicker'

/**
 * A form component that allows users to configure recurring event options.
 * 
 * This component provides controls for:
 * - Enabling/disabling recurring events
 * - Setting the frequency (weekly, monthly, yearly)
 * - Setting the interval between occurrences
 * - Configuring when the recurring series ends (after X occurrences or until a specific date)
 * 
 * @component
 * @param {Object} props
 * @param {Object} props.recurringOptions - The current recurring event configuration
 * @param {boolean} props.recurringOptions.enabled - Whether recurring events are enabled
 * @param {('weekly'|'monthly'|'yearly')} props.recurringOptions.frequency - How often the event repeats
 * @param {number} props.recurringOptions.interval - Number of units (weeks/months/years) between occurrences
 * @param {Object} props.recurringOptions.ends - Configuration for when the recurring series ends
 * @param {('after'|'until')} props.recurringOptions.ends.type - Whether to end after X occurrences or on a specific date
 * @param {number} props.recurringOptions.ends.occurrences - Number of times the event should repeat
 * @param {moment.Moment} [props.recurringOptions.ends.until] - The date when the recurring series should end
 * @param {Function} props.setRecurringOptions - Callback to update the recurring options
 * @param {boolean} [props.readonly=false] - Whether the component should be in readonly mode
 */
const RecurringEventComponentOptions = ({ recurringOptions, setRecurringOptions, readonly = false }) => {
    const defaultUntilDate = getDefaultEventTiming({ 
        date: moment().add(1, 'month') 
    })
    return (
        <Box width="100%">
            <FormSection 
                label="Recurring Options" 
                icon={<CalendarIcon />}
                showHeader
                control={
                    <Switch
                        color="primary"
                        checked={recurringOptions.enabled}
                        onChange={() => !readonly && setRecurringOptions(prev => ({...prev, enabled: !prev.enabled}))}
                        size="small"
                        disabled={readonly}
                    />
                }
                enabled={recurringOptions.enabled && !readonly}
            >
                <Stack spacing={3} style={{ marginTop: 5 }}>
                    <FormControl variant="outlined">
                        <InputLabel>Repeat</InputLabel>
                        <Select
                            margin="dense"
                            variant="outlined"
                            value={recurringOptions.frequency}
                            onChange={(e) => setRecurringOptions(prev => ({
                                ...prev,
                                frequency: e.target.value
                            }))}
                            label="Repeat"
                            disabled={readonly}
                        >
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="yearly">Yearly</MenuItem>
                        </Select>
                    </FormControl>

                    <Stack direction="row" spacing={2} alignItems="center" style={{ marginTop: 5 }}>
                        <Typography variant="body1">Repeat every</Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            value={recurringOptions.interval}
                            onChange={(e) => setRecurringOptions(prev => ({
                                ...prev,
                                interval: Math.max(1, parseInt(e.target.value) || 1)
                            }))}
                            InputProps={{ inputProps: { min: 1 } }}
                            style={{ width: '80px' }}
                            disabled={readonly}
                        />
                        <Typography>
                            {recurringOptions.frequency === 'weekly' ? 'week(s)' : 
                             recurringOptions.frequency === 'monthly' ? 'month(s)' : 'year(s)'}
                        </Typography>
                    </Stack>
                    {/* form control to select if the event should repeat after a certain number of occurrences or until a certain date */}
                    <FormControl component="fieldset" style={{ marginTop: 5 }}>
                        <RadioGroup
                            value={recurringOptions.ends.type}
                            onChange={(e) => setRecurringOptions(prev => ({
                                ...prev,
                                ends: {...prev.ends, type: e.target.value}
                            }))}
                        >
                                <FormControlLabel
                                    value="after"
                                    control={<Radio color="primary" disabled={readonly} />}
                                    label={
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Typography style={{ marginRight: 25 }}>After</Typography>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                value={recurringOptions.ends.occurrences}
                                                onChange={(e) => setRecurringOptions(prev => ({
                                                    ...prev,
                                                    ends: {...prev.ends, occurrences: Math.max(1, parseInt(e.target.value) || 1)}
                                                }))}
                                                disabled={recurringOptions.ends.type !== 'after' || readonly}
                                                style={{ width: '80px' }}
                                            />
                                            <Typography>occurrences</Typography>
                                        </Stack>
                                    }
                                />
                                <FormControlLabel
                                    value="until"
                                    control={<Radio color="primary" disabled={readonly} />}
                                    label={
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Typography style={{ marginRight: 25 }}>Until</Typography>
                                            <SingleDatePicker
                                                date={recurringOptions.ends.until || defaultUntilDate}
                                                setDate={date => setRecurringOptions(prev => ({
                                                    ...prev,
                                                    ends: {...prev.ends, until: date}
                                                }))}
                                                disabled={recurringOptions.ends.type !== 'until' || readonly}
                                            />
                                        </Stack>
                                    }
                                />
                        </RadioGroup>
                    </FormControl>
                </Stack>
            </FormSection>
        </Box>
    )
}

RecurringEventComponentOptions.propTypes = {
    recurringOptions: PropTypes.shape({
        enabled: PropTypes.bool.isRequired,
        frequency: PropTypes.oneOf(['weekly', 'monthly', 'yearly']).isRequired,
        interval: PropTypes.number.isRequired,
        ends: PropTypes.shape({
            type: PropTypes.oneOf(['after', 'until']).isRequired,
            occurrences: PropTypes.number.isRequired,
            until: PropTypes.object
        }).isRequired
    }).isRequired,
    setRecurringOptions: PropTypes.func.isRequired,
    readonly: PropTypes.bool
}

export default RecurringEventComponentOptions