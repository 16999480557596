/**
 * @typedef {Object} VisibilityOption
 * @property {number} id - The numeric identifier for the visibility level
 * @property {string} name - The display name of the visibility level
 */

/**
 * Returns an array of visibility options based on whether to include non-member options
 * @param {boolean} includeOthers - Whether to include Alumni, Guest, and Public options
 * @param {Object.<string, {id: number}>} [additionalGroups={}] - Optional additional groups to include in the visibility options
 * @returns {VisibilityOption[]} Array of visibility options
 */
export const visibilityOptions = (includeOthers, additionalGroups = {}) => {
    if (includeOthers) {
        return [
            { id: 0, name: 'New Member' },
            { id: 1, name: 'Member' },
            { id: 2, name: 'Inactive Member' },
            { id: 3, name: 'Officer' },
            { id: 4, name: 'Alumni' },
            { id: 5, name: 'Guest' },
            { id: 6, name: 'Public' },
            ...Object.entries(additionalGroups).map(([name, { id }]) => ({ id, name })),
        ]
    }

    return [
        { id: 0, name: 'New Member' },
        { id: 1, name: 'Member' },
        { id: 2, name: 'Inactive Member' },
        { id: 3, name: 'Officer' },
        ...Object.entries(additionalGroups).map(([name, { id }]) => ({ id, name })),
    ]
}

/**
 * Converts an array of visibility options into a select-compatible object format
 * @param {VisibilityOption[]} data - Array of visibility options
 * @returns {Object.<number, {title: string, default: boolean}>} Object with visibility options
 */
export const convertToSelect = data => {
    let obj = {}

    for (let i = 0; i < data.length; i++) {
        let d = data[i]
        obj[d.id] = { title: d.name, default: d.id === 1 }
    }

    return obj
}

/**
 * Renders a human-readable string from an array of visibility levels
 * @param {number[]} visibility - Array of visibility level IDs
 * @returns {string} Formatted string of visibility names (e.g., "Member & Officer" or "Member, Officer, & Alumni")
 */
export const renderVisibility = visibility => {
    if (!Array.isArray(visibility) || visibility.length === 0) {
        return 'None'
    }
    let str = ''

    let sortedVis = [...visibility].sort((a, b) => a - b)

    for (let i = 0; i < sortedVis.length; i++) {
        str += visibilityNameOptions[sortedVis[i]]
        if (i === 0 && sortedVis.length === 2) {
            str += ' & '
        } else if (sortedVis.length - i === 2) {
            str += ', & '
        } else if (sortedVis.length - i > 2) {
            str += ', '
        }
    }

    return str
}

/**
 * Array of visibility level names in order of their IDs
 * @type {string[]}
 */
export const visibilityNameOptions = ['New Member', 'Member', 'Inactive Member', 'Officer', 'Alumni', 'Guest', 'Public']

/**
 * Array of visibility level names excluding the 'Public' option
 * @type {string[]}
 */
export const visibilityNameOptionsNoPublic = ['New Member', 'Member', 'Inactive Member', 'Officer', 'Alumni', 'Guest']

/**
 * Array of colors corresponding to each visibility level
 * @type {string[]}
 */
export const visibilityColorOptions = ['yellow', 'green', 'orange', 'blue', 'red', 'pink', 'darkorange']

/**
 * Default visibility options used when creating new items
 * @type {VisibilityOption[]}
 */
export const defaultVisibilityOptions = [
    { id: 0, name: 'New Member' },
    { id: 1, name: 'Member' },
    { id: 3, name: 'Officer' },
]

/**
 * Compiles an array of visibility options into an array of just their IDs
 * @param {VisibilityOption[]} arr - Array of visibility options
 * @returns {number[]} Array of visibility IDs
 */
export const compileVisibility = arr => {
    if (!arr || !Array.isArray(arr)) {
        return []
    }

    let vis = []
    for (let i = 0; i < arr.length; i++) {
        vis.push(arr[i].id)
    }

    return vis
}

/**
 * Expands an array of visibility IDs into their full visibility option objects
 * @param {number[]} arr - Array of visibility level IDs
 * @returns {VisibilityOption[]} Array of visibility options
 */
export const expandVisibility = arr => {
    if (!arr || !Array.isArray(arr)) {
        return []
    }

    let visibility = []

    let options = visibilityOptions(true)

    arr.sort((a, b) => {
        return a - b
    })

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] >= 0 && arr[i] < options.length) {
            visibility.push(options[arr[i]])
        }
    }

    return visibility
}
