import React from 'react'
import { Box, Typography, Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import PopupDrawer from 'components/PopupDrawer'
import { WhatsNewIcon } from 'components/Icons'
// import { Map as MapIcon } from '@material-ui/icons'
import {
//     WhatshotOutlined as RushIcon,
    HomeOutlined as HomeIcon,
    ContactsOutlined as MembersIcon,
    CalendarToday as CalendarIcon,
    Timeline as PointsIcon,
 } from '@material-ui/icons'

const WhatsNew = ({ open, onClose }) => {
    const theme = useTheme()
    // src\components\Sidebar\SidebarStructure.js has all the sidebar icons
    return (
        <PopupDrawer
            id="whats-new"
            icon={<WhatsNewIcon />}
            title="What's New"
            subtitle="version 2.9.11"
            open={open}
            onClose={onClose}
        >
            {/* Latest Release Notes */}
            <Box style={{ display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center' }}>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center', maxWidth: 512, width: '100%' }}>
                    <PointsIcon
                        style={{
                            fontSize: '2.3rem',
                            lineHeight: 1,
                            width: '2rem',
                            height: '2rem',
                            verticalAlign: 'text-bottom',
                            color: theme.palette.secondary.main,
                        }}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Typography style={{ fontWeight: 600, fontSize: '1em' }}>Point System Analytics</Typography>
                        <Typography style={{ fontSize: '0.875em' }}>We've supercharged your point system with robust analytics capabilities that transform how you understand member engagement:
                            <br />
                            <br />
                            <strong>Chapter Stats</strong>: Gain insights into your chapter's engagement patterns and member contributions
                            <br />
                            <strong>Event Analytics</strong>: Track member participation in events and see trends in points participation
                            <br />
                            <br />
                            Navigate to the points section in your app, and check out the Point System details for this info.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Divider between releases */}
            <Box style={{ width: '100%', padding: '16px 0', textAlign: 'center' }}>
                <Divider />
                <Typography variant="caption" style={{ marginTop: 8, display: 'inline-block' }}>
                    Previous Updates - v2.9.8 (Spring 2025 Release)
                </Typography>
            </Box>

            {/* Previous Release Notes */}
            <Box style={{ display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center' }}>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center', maxWidth: 512, width: '100%' }}>
                    <HomeIcon
                        style={{
                            fontSize: '2.3rem',
                            lineHeight: 1,
                            width: '2rem',
                            height: '2rem',
                            verticalAlign: 'text-bottom',
                            color: 'blue',
                        }}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Typography style={{ fontWeight: 600, fontSize: '1em' }}>Activity Feed Glow Up</Typography>
                        <Typography style={{ fontSize: '0.875em' }}>Quickly filter by major event types for the weekly view and see a breakdown of your points progress.</Typography>
                    </Box>
                </Box>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center' }}>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center', maxWidth: 512, width: '100%' }}>
                    <CalendarIcon
                        style={{
                            fontSize: '2.3rem',
                            lineHeight: 1,
                            width: '2rem',
                            height: '2rem',
                            verticalAlign: 'text-bottom',
                            color: 'green',
                        }}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Typography style={{ fontWeight: 600, fontSize: '1em' }}>Calendar Management</Typography>
                        <Typography style={{ fontSize: '0.875em' }}>Recurring events are here!  We have also added the ability to create event descriptions with a click of a button using our new AI assistance. Check out the magic, let us know if you find any pesky bugs.</Typography>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center', maxWidth: 512, width: '100%' }}>
                    <MembersIcon
                        style={{
                            fontSize: '2.3rem',
                            lineHeight: 1,
                            width: '2rem',
                            height: '2rem',
                            verticalAlign: 'text-bottom',
                            color: theme.palette.primary.main
                        }}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Typography style={{ fontWeight: 600, fontSize: '1em' }}>Member Management</Typography>
                        <Typography style={{ fontSize: '0.875em' }}>Each member can now add emergency contact information and tshirt size to their profile.</Typography>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center', maxWidth: 512, width: '100%' }}>
                    <HomeIcon
                        style={{
                            fontSize: '2.3rem',
                            lineHeight: 1,
                            width: '2rem',
                            height: '2rem',
                            verticalAlign: 'text-bottom'                            
                        }}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Typography style={{ fontWeight: 600, fontSize: '1em' }}>Misc Improvements</Typography>
                        <Typography style={{ fontSize: '0.875em' }}>Added top level delete option for events, added top level option to set admins on Messages creation ❤️.</Typography>
                    </Box>
                </Box>
            </Box>
        </PopupDrawer>
    )
}

export default WhatsNew