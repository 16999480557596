import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from 'components/Wrappers'

/**
 * Dialog that asks the user if they want to edit just the current event or the entire recurring series
 */
export default function RecurringEditDialog({ open, onClose, onEditSingle, onEditSeries }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="recurring-edit-dialog-title"
            aria-describedby="recurring-edit-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="recurring-edit-dialog-title">Edit Recurring Event</DialogTitle>
            <DialogContent>
                <DialogContentText id="recurring-edit-dialog-description">
                    This is part of a recurring event series. Would you like to edit just this occurrence or the entire series?
                </DialogContentText>
                <DialogContentText style={{ marginTop: 16 }}>
                    <strong>Just This Event:</strong> Only this specific occurrence will be modified.
                </DialogContentText>
                <DialogContentText style={{ marginTop: 8 }}>
                    <strong>Entire Series:</strong> All events in this recurring series will be updated.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onEditSingle} 
                    color="secondary"
                    variant="outlined"
                >
                    Just This Event
                </Button>
                <Button 
                    onClick={onEditSeries} 
                    color="primary"
                    variant="contained" 
                    autoFocus
                >
                    Entire Series
                </Button>
            </DialogActions>
        </Dialog>
    )
}

RecurringEditDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onEditSingle: PropTypes.func.isRequired,
    onEditSeries: PropTypes.func.isRequired
} 