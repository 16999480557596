import React from 'react'

import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Typography } from '@material-ui/core'
import {
    DeleteOutline as DeleteIcon,
    Favorite as LoveSelectedIcon,
    FavoriteBorder as LoveIcon,
    ThumbDown as ThumbsDownSelectedIcon,
    ThumbDownOutlined as ThumbsDownIcon,
    ThumbUp as ThumbsUpSelectedIcon,
    ThumbUpOutlined as ThumbsUpIcon,
    ReportOutlined as ReportIcon,
} from '@material-ui/icons'

import CopyToClipboard from 'components/copy-to-clipboard/CopyToClipboard'
import { ClipboardIcon, EmojiAddOutlineIcon } from 'components/Icons'
import { IconButton } from 'components/Wrappers'

import { useTheme } from '@material-ui/styles'

import { Node } from 'slate'

const MessageDetailDrawer = ({ message, open, onClose, onDelete, onReport, reactionsEnabled, curReaction, onSetReaction, setAddReaction, time, ...props }) => {
    const theme = useTheme()

    const reactions = [
        {
            emoji: '👍',
            label: 'Thumbs Up',
            icon: <ThumbsUpIcon />,
            iconSelected: <ThumbsUpSelectedIcon />,
        },

        {
            emoji: '👎',
            label: 'Thumbs Down',
            icon: <ThumbsDownIcon />,
            iconSelected: <ThumbsDownSelectedIcon />,
        },

        {
            emoji: '❤️',
            label: 'Love',
            icon: <LoveIcon />,
            iconSelected: <LoveSelectedIcon />,
        },
    ]

    const renderReaction = reaction => {
        if (curReaction === reaction.emoji) {
            return (
                <IconButton aria-label={reaction.label} onClick={() => onSetReaction('')} key={`${reaction.emoji}_selected`}>
                    {reaction.iconSelected}
                </IconButton>
            )
        }

        return (
            <IconButton aria-label={reaction.label} onClick={() => onSetReaction(reaction.emoji)} key={reaction.emoji}>
                {reaction.icon}
            </IconButton>
        )
    }

    return (
        <SwipeableDrawer anchor="bottom" open={open} onClose={onClose} disableSwipeToOpen onOpen={() => {}}>
            <Box
                style={{
                    height: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    paddingTop: 12,
                    paddingBottom: 'env(safe-area-inset-bottom)',
                }}
            >
                <Box style={{ flexDirection: 'row', paddingLeft: 16, paddingRight: 16 }}>
                    <Typography align="center">Sent at {time}</Typography>
                </Box>
                {reactionsEnabled && (
                    <>
                        <Divider />
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 16, justifyContent: 'center', paddingLeft: 16, paddingRight: 16 }}>
                            {reactions.map(reaction => renderReaction(reaction))}
                            <IconButton aria-label="Custom Reaction" onClick={() => setAddReaction(true)}>
                                <EmojiAddOutlineIcon />
                            </IconButton>
                        </Box>
                    </>
                )}
                <Divider />
                <List>
                    <CopyToClipboard>
                        {({ copy }) => (
                            <ListItem button onClick={() => copy(message.map(n => Node.string(n)).join('\n'))}>
                                <ListItemIcon>
                                    <ClipboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Copy Message Text" />
                            </ListItem>
                        )}
                    </CopyToClipboard>
                    {onReport && (
                        <ListItem button onClick={onReport} style={{ color: theme.palette.red.main }}>
                            <ListItemIcon>
                                <ReportIcon style={{ color: theme.palette.red.main }} />
                            </ListItemIcon>
                            <ListItemText primary="Report" />
                        </ListItem>
                    )}
                    {onDelete && (
                        <ListItem button onClick={onDelete} style={{ color: theme.palette.red.main }}>
                            <ListItemIcon>
                                <DeleteIcon style={{ color: theme.palette.red.main }} />
                            </ListItemIcon>
                            <ListItemText primary="Delete Message" />
                        </ListItem>
                    )}
                </List>
            </Box>
        </SwipeableDrawer>
    )
}

export default React.memo(MessageDetailDrawer)
