// src/lib/queryKeys.js
export const queryKeys = {
    users: {
      all: ['users'],
      infinite: ['users', 'infinite'],
      detail: (id) => ['users', id],
      settings: (id) => ['users', id, 'settings'],
    },
    rush: {
      all: ['rush'],
      active: ['rush', 'active'],
      detail: (id) => ['rush', id]
    },
    rushee: {
      all: (chapterId, rushId) => ['rushee', chapterId, rushId],
      detail: (chapterId, rushId, rusheeId) => ['rushee', chapterId, rushId, rusheeId],
    },
    chapter: 'chapter',
    fraternity: {
      all: ['fraternity'],
      detail: (id) => ['fraternity', id],
      chapters: (fraternityId) => ['fraternity', fraternityId, 'chapters'],
    },
    calendar: {
      all: ['calendar'],
      categories: (chapterId) => ['calendar', 'categories', chapterId],
    },
    points: {
      all: (chapterId, userId, memberStatus) => ['points', chapterId, userId, memberStatus],
    },
    ai: {
      contentGeneration: ['ai', 'contentGeneration']
    }
  };
  