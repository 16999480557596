import { useMutation } from '@tanstack/react-query';
import { db } from 'config/firebase';
import { queryClient } from 'lib/queryClient';
import { queryKeys } from 'lib/queryKeys';

/**
 * Invites a new chapter to join a fraternity
 * @param {Object} data - Chapter data
 * @param {string} data.fraternityId - Fraternity ID
 * @param {string} data.fullName - Chapter full name
 * @param {string} data.school - School name
 * @param {string} data.email - Contact email
 * @param {string} data.location - Chapter location (city, state)
 * @param {string} data.foundingYear - Year the chapter was founded
 * @param {string} data.contactNote - Optional note to the chapter contact
 * @returns {Promise<Object>} - The created chapter reference
 */
const inviteChapter = async ({ fraternityId, fullName, school, email, location, foundingYear, contactNote }) => {
  // Create a new chapter document
  const chapterRef = db.collection('chapters').doc();
  
  // Generate a unique invitation code
  const inviteCode = Math.random().toString(36).substring(2, 10).toUpperCase();
  
  // Process founding year to ensure it's an integer
  const processedFoundingYear = foundingYear ? parseInt(foundingYear, 10) : null;

  // Create the chapter data
  const chapterData = {
    fullName,
    school,
    fraternity: fraternityId,
    inviteCode,
    status: 'invited',
    createdAt: new Date(),
    contactEmail: email,
    location: location || '',
    foundingYear: processedFoundingYear,
    contactNote: contactNote || '',
    members: {},
    invitedMembers: {},
    enabledFeatures: {
      calendar: true,
      messages: true,
      polls: true,
      points: true,
    }
  };
  
  // Save the chapter to Firestore
  await chapterRef.set(chapterData);
  
  return {
    id: chapterRef.id,
    ...chapterData
  };
};

/**
 * Hook to invite a new chapter to join a fraternity
 * @param {string} fraternityId - The ID of the fraternity
 * @returns {Object} Mutation object for inviting a chapter
 */
export const useInviteChapter = (fraternityId) => {
  return useMutation({
    mutationFn: inviteChapter,
    onSuccess: () => {
      // Invalidate the chapters query to refresh the list
      queryClient.invalidateQueries({
        queryKey: queryKeys.fraternity.chapters(fraternityId)
      });
    }
  });
}; 