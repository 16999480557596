import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import TextFieldOld from '@material-ui/core/TextField'
import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import moment from 'moment'

import { getDateFromYMD, getYMDFromDate } from 'code/TimeAgo'
import { isIOSDevice } from 'code/Helper'
/**
 * date expects a YMD object (similar to the original component's format)
 * setDate is a callback function that receives the new YMD object
 * const [selectedDate, setSelectedDate] = useState({ y: 2024, m: 3, d: 21 }); // Example initial date
 * <SingleDatePicker 
 *     date={selectedDate}
 *     setDate={setSelectedDate}
 * />
 */
const SingleDatePicker = ({ date, setDate, disabled }) => {
    const dateRef = React.useRef(null)
    const [isSafari] = React.useState(
        isIOSDevice() || (navigator.userAgent && navigator.userAgent.indexOf('Safari') > -1 && !(navigator.userAgent.indexOf('Chrome') > -1)),
    )

    const onDateChange = (newDate) => {
        if (newDate !== null) {
            const newYMD = getYMDFromDate(newDate)
            setDate(newYMD)
        }
    }

    const DateTextField = function DateTextField(props) {
        return (
            <TextField
                {...props}
                label={props.value && props.value.length > 0 ? '' : 'Select Date'}
                margin="dense"
                size="small"
                style={{ marginTop: 4, width: 130.5 }}
                InputLabelProps={{ shrink: false }}
                fullWidth={false}
                helperText={null}
                variant="outlined"
            />
        )
    }

    const RenderDateField = () => {
        if (isSafari) {
            let value = moment(getDateFromYMD(date)).format('YYYY-MM-DD')

            if (dateRef.current) {
                dateRef.current.value = value
            }

            return (
                <TextFieldOld
                    label={value && value.length > 0 ? '' : 'Select Date'}
                    margin="dense"
                    size="small"
                    style={{ marginTop: 4, flexGrow: 1, minWidth: 123 }}
                    InputLabelProps={{ shrink: false }}
                    fullWidth={false}
                    helperText={null}
                    type="date"
                    variant="outlined"
                    defaultValue={value}
                    ref={dateRef}
                    onBlur={e => {
                        if (e && e.target.value) {
                            let date = moment(e.target.value).toDate()
                            onDateChange(date)
                        }
                    }}
                />
            )
        }

        return (
            <DatePicker
                label="Select Date"
                value={moment(getDateFromYMD(date)).startOf('day')}
                onChange={newValue => {
                    onDateChange(newValue.toDate())
                }}
                slots={{
                    textField: DateTextField,
                }}
                disabled={disabled}
            />
        )
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <RenderDateField />
        </Box>
    )
}

export default React.memo(SingleDatePicker) 