import { Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Slide, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import classnames from 'classnames'
import { isMobileDevice as _isMobileDevice } from 'code/Helper'
import { useLayoutState } from 'context/LayoutContext'
import { MessagesContext } from 'context/MessagesContext'
import React, { useContext, useEffect } from 'react'
import { Route, Switch, matchPath, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Footer from '../Footer'
import Header from '../Header'
import Sidebar from '../Sidebar'
import { Avatar } from '../Wrappers'

import PopupDrawer from 'components/PopupDrawer'

import { BugReportOutlined as BugReportIcon, Timeline as PointsIcon, WhatshotOutlined as RushIcon, Map as MapIcon } from '@material-ui/icons'

import { WhatsNewIcon } from 'components/Icons'

import { sidebarIconsPrimary, sidebarIconsSecondary, sidebarIconsTertiary } from './MobileLayout'

import { animated, useSpring } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

import { AuthContext } from 'context/AuthContext'

import structure from '../Sidebar/SidebarStructure'
import useStyles from './styles'

import { paths } from './data.js'
import WhatsNew from './WhatsNew'

function Layout({ history, location, ...props }) {
    const classes = useStyles()
    const theme = useTheme()

    const { user, signOut } = useContext(AuthContext)

    const { getConversation } = useContext(MessagesContext)

    var layoutState = useLayoutState()

    var classes2 = classnames(classes.contentNoBottom, {
        [classes.contentShift]: layoutState.isSidebarOpened,
    })

    if (!location.pathname.includes('/app/messages')) {
        classes2 = classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
        })
    }

    const [isMobileDevice] = React.useState(_isMobileDevice())

    const getSwitches = () => {
        return (
            <Switch location={location}>
                {paths.map(path => (
                    <Route {...path} key={path.path} />
                ))}
            </Switch>
        )
    }

    const justWentBack = React.useRef(false)

    //const justUpdatedProfile = React.useRef(false)

    const [justUpdatedProfile, setJustUpdatedProfile] = React.useState(false)

    const [showWhatsNew, setShowWhatsNew] = React.useState(false)

    useEffect(() => {
        if (sessionStorage.getItem('versionUpdated')) {
            // setShowWhatsNew(true) // - trigger update if we have updated it
            sessionStorage.removeItem('versionUpdated')
        }
    }, [])

    const currentRoute = location.pathname

    const { setCurrentlyViewingConversations } = useContext(MessagesContext)

    useEffect(() => {
        setCurrentlyViewingConversations(location.pathname.includes('/app/messages'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const isHomeRoute = currentRoute.indexOf('app/dashboard') >= 0
    let isBaseRoute = false

    let path = null

    for (let i = 0; i < paths.length; i++) {
        let val = matchPath(currentRoute, paths[i])

        if (val) {
            path = { ...paths[i], ...val }
            break
        }
    }

    let title = 'Home'
    let color = null

    if (path) {
        title = path.title

        isBaseRoute = path.baseRoute ? true : false

        if (path.feature === 'messages') {
            if (path.params && path.params.conversationId && path.title === 'Conversation') {
                let conversation = getConversation(path.params.conversationId)

                if (conversation) {
                    if (conversation.avatar && conversation.avatar.type === 0) {
                        title = `${conversation.avatar.emoji} ${conversation.name}`

                        color = conversation.avatar.background
                    } else {
                        title = conversation.name
                    }
                }
            }
        } else if (path.feature === 'drive' || path.feature === 'polls') {
            const sessionTitle = sessionStorage.getItem(path.url)

            if (typeof sessionTitle === 'string' && !!sessionTitle) {
                title = sessionTitle
            }
        }
    }

    const curBackgroundColor =
        currentRoute.includes('/app/messages') ||
        (isMobileDevice && currentRoute.includes('/app/calendar')) ||
        (isMobileDevice && currentRoute === '/app/applications') ||
        (isMobileDevice && currentRoute.includes('/app/applications/polls') && !currentRoute.includes('/app/applications/polls/edit')) ||
        (isMobileDevice && currentRoute.includes('/app/applications/drive')) ||
        (isMobileDevice && currentRoute.includes('/app/applications/members')) ||
        (isMobileDevice && currentRoute.includes('/app/applications/points')) ||
        (isMobileDevice &&
            currentRoute.includes('/app/applications/rush') &&
            currentRoute !== '/app/applications/rush' &&
            currentRoute !== '/app/applications/rush/') ||
        (isMobileDevice && currentRoute.includes('/app/applications/tasks')) ||
        (isMobileDevice && currentRoute.includes('/app/profile')) ||
        (isMobileDevice && currentRoute.includes('/app/settings')) ||
        (isMobileDevice && currentRoute.includes('/app/notifications')) ||
        currentRoute === '/setup' ||
        (isMobileDevice && currentRoute.includes('/app/admin'))
            ? theme.palette.background.paper
            : theme.palette.background.default

    const prevBackgroundColor = React.useRef(curBackgroundColor)

    const { backgroundColor } = useSpring({
        from: { backgroundColor: prevBackgroundColor.current },
        backgroundColor: curBackgroundColor,
        onChange: props => {
            prevBackgroundColor.current = props.value.backgroundColor
        },
        config: { duration: theme.transitionDuration },
    })

    const [{ x }, api] = useSpring(() => ({ x: 0 }), { config: { duration: theme.transitionDuration } })

    const bind = useDrag(
        ({ active, down, last, intentional, initial: [ix], movement: [mx], direction: [xDir], cancel }) => {
            if (intentional && !isBaseRoute && !sessionStorage.getItem('previewItem')) {
                if (active) {
                    if (ix <= 64) {
                        // If they start their finger within the first 64 pixels of the screen
                        if (mx >= 128) {
                            // If they've swiped at least 128 pixels to the right
                            api.set({ x: 0 })
                            justWentBack.current = true
                            history.goBack()
                            cancel()
                        } else {
                            api.start({ x: mx, immediate: true })
                        }
                    }
                } else if (last) {
                    cancel()
                    api.set({ x: 0 })
                }
            }
        },
        { axis: 'x', pointer: { touch: true } },
    )

    if (isMobileDevice) {
        return (
            <>
                <WhatsNew open={showWhatsNew} onClose={() => setShowWhatsNew(false)} />
                <div className={classes.root}>
                    <Header
                        history={history}
                        variant="mobile"
                        title={title}
                        showBack={!isBaseRoute}
                        path={path}
                        showAvatar={isHomeRoute}
                        section={isBaseRoute ? 'base' : currentRoute}
                        color={color}
                        onGoBack={() => {
                            justWentBack.current = true
                        }}
                        onClickProfile={() => {
                            if (isHomeRoute) {
                                setJustUpdatedProfile(!justUpdatedProfile)
                            }
                        }}
                    />
                    <TransitionGroup className={isBaseRoute || justWentBack.current ? 'right' : 'left'}>
                        <CSSTransition
                            key={isBaseRoute ? 'main' : location.key}
                            classNames="transition-wrap"
                            timeout={{ enter: 0, exit: theme.transitionDuration }}
                            onExited={() => {
                                justWentBack.current = false
                            }}
                        >
                            <animated.div
                                {...bind()}
                                className={classes.contentMobile}
                                style={{ backgroundColor: backgroundColor, left: x.interpolate(v => `${v}px`) }}
                            >
                                <Box className={classes.fakeToolbarNew} />
                                {getSwitches()}
                                <Slide direction="up" in={isBaseRoute} appear={false} unmountOnExit>
                                    <Box className={classes.fakeFooterNew} />
                                </Slide>
                            </animated.div>
                        </CSSTransition>
                    </TransitionGroup>
                    <Slide direction="up" in={isBaseRoute} appear={false} timeout={375}>
                        <Footer history={history} />
                    </Slide>
                    <Drawer anchor="right" open={justUpdatedProfile} onClose={() => setJustUpdatedProfile(false)} style={{ zIndex: 2001 }}>
                        <div role="presentation" style={{ width: 240 }}>
                            <div
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    height: 'calc(56px + env(safe-area-inset-top))',
                                    width: '100%',
                                    position: 'relative',
                                    marginBottom: 34,
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: -34,
                                        left: 'calc(50% - 34px)',
                                        borderRadius: '50%',
                                        backgroundColor: theme.palette.background.paper,
                                    }}
                                >
                                    <Box style={{ padding: 4, userSelect: 'none', webkitUserSelect: 'none' }}>
                                        <Avatar src={user.getPhoto()} style={{ width: 60, height: 60 }}>
                                            {user.getInitials()}
                                        </Avatar>
                                    </Box>
                                </div>
                            </div>
                            {user && (
                                <Typography align="center" style={{ paddingTop: 8 }}>
                                    <b>{user.getFirst()}</b>
                                </Typography>
                            )}
                            <List>
                                {sidebarIconsPrimary.map(icon => (
                                    <ListItem
                                        button
                                        key={icon.id}
                                        onClick={
                                            icon.onClick !== null
                                                ? () => {
                                                      icon.onClick({
                                                          ...props,
                                                          history: history,
                                                          user: user,
                                                          signOut: signOut,
                                                          whatsNew: () => setShowWhatsNew(true),
                                                      })
                                                      setJustUpdatedProfile(false)
                                                  }
                                                : null
                                        }
                                    >
                                        <ListItemIcon>{icon.icon}</ListItemIcon>
                                        <ListItemText primary={icon.label} />
                                        {icon.secondaryIcon && <>{icon.secondaryIcon}</>}
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <List>
                                {sidebarIconsSecondary.map(icon => (
                                    <ListItem
                                        button
                                        key={icon.id}
                                        onClick={
                                            icon.onClick !== null
                                                ? () => {
                                                      icon.onClick({
                                                          ...props,
                                                          history: history,
                                                          user: user,
                                                          signOut: signOut,
                                                          whatsNew: () => setShowWhatsNew(true),
                                                      })
                                                      setJustUpdatedProfile(false)
                                                  }
                                                : null
                                        }
                                        {...icon.props}
                                    >
                                        <ListItemIcon>{icon.icon}</ListItemIcon>
                                        <ListItemText primary={icon.label} />
                                        {icon.secondaryIcon && <>{icon.secondaryIcon}</>}
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <List>
                                {sidebarIconsTertiary.map(icon => (
                                    <ListItem
                                        button
                                        key={icon.id}
                                        onClick={
                                            icon.onClick !== null
                                                ? () => {
                                                      icon.onClick({
                                                          ...props,
                                                          history: history,
                                                          user: user,
                                                          signOut: signOut,
                                                          whatsNew: () => setShowWhatsNew(true),
                                                      })
                                                      setJustUpdatedProfile(false)
                                                  }
                                                : null
                                        }
                                        {...icon.props}
                                    >
                                        <ListItemIcon>{icon.icon}</ListItemIcon>
                                        <ListItemText primary={icon.label} />
                                        {icon.secondaryIcon && <>{icon.secondaryIcon}</>}
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Drawer>
                </div>
            </>
        )
    }

    const isMessages = currentRoute.includes('/app/messages')

    return (
        <>
            <WhatsNew open={showWhatsNew} onClose={() => setShowWhatsNew(false)} />
            <animated.div className={classes.root} style={{ backgroundColor: backgroundColor }}>
                {isMessages ? (
                    <Header
                        key="header"
                        history={history}
                        variant="mobile"
                        title={title}
                        showBack={!isBaseRoute}
                        path={path}
                        section={isBaseRoute ? 'base' : currentRoute}
                        color={color}
                        onGoBack={() => {
                            justWentBack.current = true
                        }}
                        showHome={isBaseRoute}
                    />
                ) : (
                    <Header key="header" history={history} variant="desktop" showAvatar showWhatsNew={() => setShowWhatsNew(true)} />
                )}
                <Slide direction="right" in={!isMessages} appear={false} unmountOnExit>
                    <Box>
                        <Sidebar structure={structure} />
                    </Box>
                </Slide>
                <div className={classes2}>
                    <div className={classes.fakeToolbarNew} />
                    {getSwitches()}
                </div>
            </animated.div>
        </>
    )
}

export default withRouter(Layout)
