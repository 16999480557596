import { Avatar, Box, Button, ButtonBase, Divider, FormControl, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core'
import {
    SupervisedUserCircleOutlined as AvatarIcon,
    EmojiEmotionsOutlined as EmojiIcon,
    ImageOutlined as ImageIcon,
    InsertEmoticon as InsertEmojiIcon,
    PeopleOutline as PeopleIcon,
} from '@material-ui/icons'
import React from 'react'

import app from 'firebase/app'

import { Emoji, emojiIndex } from 'emoji-mart'

import { getEmojiSet, getNativeEmoji } from 'code/Helper'
import { resizeImage, uploadURI } from 'code/Image'
import { generateUUID } from 'code/UUID'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import ConversationAvatar from '../Conversation/ConversationAvatar'

import FormSection from 'components/Forms/FormSection'
import FormSubmission from 'components/Forms/FormSubmission'
import { MembershipIcon } from 'components/Icons'
import SelectEmojiDialog from 'components/Messages/SelectEmojiDialog'
import SelectAutocomplete from 'components/SelectAutocomplete'
import { TabsIcon } from 'components/Tabs'
import { IconChip } from 'components/Wrappers'
import MembersList from 'components/members-list'

import { compileMembers, expandMembers } from 'data/Members'
import { compileVisibility, expandVisibility, visibilityOptions } from 'data/Visibility'

import { emojiColors } from 'objects/Messages'

import _ from 'lodash'

import useStyles from 'pages/messages/styles'

import cn from 'classnames'

const UpdateConversation = ({ conversationId, conversation, getPossibleMembers, isNew, onCancel, onUpdate, onSubmit, ...props }) => {
    const classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [addEmojiDialog, setAddEmojiDialog] = React.useState(false)
    const [showAdminSelection, setShowAdminSelection] = React.useState(false)

    const getEmoji = React.useCallback(emoji => getNativeEmoji(emoji), [])

    let emoji = conversation && conversation.avatar && conversation.avatar.emoji ? conversation.avatar.emoji : ''
    let emojiOptions = ['😀', '👍', '😁', '😊', '🤩']

    // Format members for MembersList component
    const getFormattedMembers = () => {
        if (!conversation || !conversation.mems || !chapter || !chapter.members) {
            return []
        }

        return conversation.mems
            .filter(memId => memId in chapter.members)
            .map(memId => {
                const memberData = chapter.members[memId]
                // Check if member is owner, admin, or regular member
                let status = 'member'
                if (conversation.owner === memId) {
                    status = 'owner'
                } else if (Array.isArray(conversation.admins) && conversation.admins.includes(memId)) {
                    status = 'admin'
                }
                
                return {
                    id: memId,
                    first: memberData.first,
                    last: memberData.last,
                    status
                }
            })
    }

    // Handle promoting a member to admin
    const handlePromoteToAdmin = (memberId) => {
        const currentAdmins = Array.isArray(conversation.admins) ? conversation.admins : []
        if (!currentAdmins.includes(memberId)) {
            onUpdate('admins', [...currentAdmins, memberId])
        }
    }

    // Handle demoting a member from admin
    const handleDemoteToMember = (memberId) => {
        if (Array.isArray(conversation.admins) && conversation.admins.includes(memberId)) {
            onUpdate('admins', conversation.admins.filter(id => id !== memberId))
        }
    }

    if (conversation.name) {
        let emojis = {}

        let possibleWords = conversation.name.split(' ')

        for (let i = 0; i < possibleWords.length; i++) {
            let word = possibleWords[i]
            let options = emojiIndex.search(word)

            if (options) {
                for (let j = 0; j < options.length; j++) {
                    let option = options[j].native

                    if (option in emojis) {
                        emojis[option] += 1
                    } else {
                        emojis[option] = 1
                    }
                }
            }
        }

        emojiOptions = [...Object.keys(emojis).sort((a, b) => emojis[b] - emojis[a]), ...emojiOptions]
    }

    emojiOptions = _.uniq(emojiOptions).filter((emoji, i) => i < 10)

    if (emoji && !emojiOptions.includes(emoji)) {
        emojiOptions.push(emoji)
    }

    emoji = emoji ? emoji : emojiOptions[0]

    const uploadPossiblePhoto = async image => {
        let folderName = conversationId ? conversationId : 'NEW_CHATS'
        let path = `chapters/${user.getChapter()}/messages/${folderName}/avatars/${generateUUID()}.jpg`

        const uri = await resizeImage(image, 128, 128, 'JPEG', 80)
        const url = await uploadURI(uri, path)

        // TODO: Set the avatar url to url
        onUpdate('avatar', { ...conversation.avatar, url: url })
    }

    const [calendarEvent, setCalendarEvent] = React.useState({ loading: false, data: null })

    React.useEffect(() => {
        if (isNew && conversation.event && conversation.event.id && calendarEvent.loading === false && calendarEvent.data === null) {
            const db = app.firestore()

            setCalendarEvent(ce => ({ ...ce, loading: true }))

            db.collection('chapters')
                .doc(user.getChapter())
                .collection('events')
                .doc(conversation.event.id)
                .get()
                .then(function(doc) {
                    if (doc.exists) {
                        let data = doc.data()
                        setCalendarEvent(ce => ({ ...ce, loading: false, data: data }))
                        onUpdate('name', data.name)
                        onUpdate('event', { id: conversation.event.id, name: data.name })
                    } else {
                        if (props.history) {
                            props.history.goBack()
                        }
                    }
                })
                .catch(function(error) {
                    if (props.history) {
                        props.history.goBack()
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Grid container spacing={1} style={{ marginBottom: 16 }}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        autoFocus={isNew}
                        margin="dense"
                        label={conversation.name ? '' : 'Name'}
                        InputLabelProps={{ shrink: false }}
                        type="text"
                        value={conversation.name}
                        onChange={e => {
                            onUpdate('name', e.target.value)
                        }}
                        inputProps={{
                            maxLength: 32,
                        }}
                        disabled={calendarEvent.loading}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel id="update-conversation-type-label">Type</InputLabel>
                        <Select
                            native
                            labelId="update-conversation-type-label"
                            label="Type"
                            value={conversation.type}
                            onChange={e => onUpdate('type', parseInt(e.target.value))}
                            disabled={!isNew || true}
                        >
                            <option value={0}>Chat</option>
                            <option value={1}>Channel</option>
                        </Select>
                        {/*<FormHelperText>{getSignInTypeHelperText()}</FormHelperText>*/}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider style={{ marginLeft: -12, marginRight: -12 }} />
                </Grid>
                <Grid item xs={12}>
                    <FormSection label="Membership" icon={<MembershipIcon />} showHeader>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel id="new-conversation-membership-label">Type</InputLabel>
                                    <Select
                                        disabled={!isNew || conversation.mType > 1}
                                        native
                                        labelId="new-conversation-membership-label"
                                        label="Type"
                                        value={conversation.mType}
                                        onChange={e => onUpdate('mType', parseInt(e.target.value))}
                                    >
                                        <option value={0}>Automatic</option>
                                        <option value={1}>Custom</option>
                                        {conversation.mType === 2 && <option value={2}>Event Present</option>}
                                        {conversation.mType === 3 && <option value={3}>Event RSVP</option>}
                                    </Select>
                                    {/*<FormHelperText>{getSignInTypeHelperText()}</FormHelperText>*/}
                                </FormControl>
                            </Grid>
                            {conversation.mType === 0 && (
                                <Grid item xs={12}>
                                    <SelectAutocomplete
                                        style={{ marginTop: 4 }}
                                        variant="outlined"
                                        value={expandVisibility(conversation.vis)}
                                        onUpdate={(event, value) => {
                                            onUpdate('vis', compileVisibility(value))
                                        }}
                                        title="Visibility"
                                        freeSolo={false}
                                        aria-label="Visibility"
                                        events={visibilityOptions(true).slice(0, 6)}
                                        disabled={!isNew}
                                    />
                                </Grid>
                            )}
                            {isNew && conversation.mType === 1 && (
                                <Grid item xs={12}>
                                    <SelectAutocomplete
                                        style={{ marginTop: 4 }}
                                        variant="outlined"
                                        value={expandMembers(conversation.mems, getPossibleMembers())}
                                        onUpdate={(event, value) => {
                                            onUpdate('mems', compileMembers(value))
                                        }}
                                        title="Members"
                                        freeSolo={false}
                                        aria-label="Members"
                                        events={getPossibleMembers()}
                                    />
                                </Grid>
                            )}
                            {isNew && (
                                <Grid item xs={12}>
                                    <Button 
                                        variant="outlined" 
                                        color="primary" 
                                        startIcon={<PeopleIcon />}
                                        onClick={() => setShowAdminSelection(!showAdminSelection)}
                                        fullWidth
                                    >
                                        {showAdminSelection ? "Hide Admin Selection" : "Select Admin Members"}
                                    </Button>
                                    
                                    {showAdminSelection && (
                                        <Box mt={2}>
                                            <MembersList 
                                                members={getFormattedMembers()}
                                                currentUserId={user.getId()}
                                                ownerId={conversation.owner || user.getId()}
                                                adminIds={Array.isArray(conversation.admins) ? conversation.admins : []}
                                                mutedIds={[]}
                                                onPromoteToAdmin={handlePromoteToAdmin}
                                                onDemoteToMember={handleDemoteToMember}
                                                version="desktop"
                                            />
                                        </Box>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={12}>
                    <Divider style={{ marginLeft: -12, marginRight: -12 }} />
                </Grid>
                {calendarEvent.loading ? (
                    <Typography align="center" style={{ fontWeight: 500 }}>
                        Loading event...
                    </Typography>
                ) : (
                    <Grid item xs={12}>
                        <FormSection label="Avatar" icon={<AvatarIcon />} showHeader>
                            <TabsIcon
                                tabs={[
                                    { id: 'emoji', key: 'tab-emoji', icon: <EmojiIcon aria-label="emoji" /> },
                                    { id: 'image', key: 'tab-image', icon: <ImageIcon aria-label="image" /> },
                                ]}
                                selected={conversation.avatar.type === 0 ? 'emoji' : 'image'}
                                onChange={val => {
                                    if (val !== (conversation.avatar.type === 0 ? 'emoji' : 'image')) {
                                        if (val === 'emoji') {
                                            onUpdate('avatar', { type: 0, background: _.sample(emojiColors), emoji: '' })
                                        } else {
                                            onUpdate('avatar', { type: 1, url: '' })
                                        }
                                    }
                                }}
                            />
                            <Divider style={{ marginLeft: -12, marginRight: -12, marginBottom: 12 }} />
                            {conversation.avatar.type === 0 ? (
                                <>
                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <ConversationAvatar avatar={{ ...conversation.avatar, emoji: emoji }} size={48} />
                                    </Box>
                                    <Divider style={{ marginLeft: -12, marginRight: -12, marginTop: 12 }} />
                                    <Box className={classes.avatarEmojiSelectionBoxParent}>
                                        <Box className={classes.avatarEmojiSelectionBox}>
                                            {emojiOptions.map((emoji, index) => (
                                                <Box key={`update-conversation-emoji-${emoji}`} style={{ padding: 4 }}>
                                                    <ButtonBase
                                                        onClick={() => {
                                                            onUpdate('avatar', { ...conversation.avatar, emoji: emoji })
                                                        }}
                                                        style={{
                                                            borderRadius: '50%',
                                                        }}
                                                        disabled={conversation.avatar.emoji === emoji}
                                                    >
                                                        <Box
                                                            className={cn(classes.avatarEmojiParentBox, {
                                                                [classes.avatarEmojiParentBoxSelected]: conversation.avatar.emoji === emoji,
                                                            })}
                                                        >
                                                            <Box
                                                                style={{
                                                                    width: 34,
                                                                    height: 34,
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Emoji
                                                                    size={32}
                                                                    emoji={getEmoji(emoji)}
                                                                    native={getEmojiSet() === 'apple'}
                                                                    set={getEmojiSet()}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </ButtonBase>
                                                </Box>
                                            ))}
                                            <Box style={{ width: 58, height: 58, padding: 13 }}>
                                                <IconChip icon={<InsertEmojiIcon />} variant="outlined" onClick={e => setAddEmojiDialog(true)} />
                                            </Box>
                                            <Box style={{ width: 0, height: 48, padding: 6 }} />
                                        </Box>
                                    </Box>
                                    <Divider style={{ marginLeft: -12, marginRight: -12 }} />
                                    <Box className={classes.avatarEmojiSelectionBoxParent}>
                                        <Box className={classes.avatarEmojiSelectionBox}>
                                            {emojiColors.map((color, index) => (
                                                <Box key={`update-conversation-color-${color}`} style={{ padding: 4 }}>
                                                    <ButtonBase
                                                        onClick={() => {
                                                            onUpdate('avatar', { ...conversation.avatar, background: color })
                                                        }}
                                                        style={{ borderRadius: '50%' }}
                                                    >
                                                        <Box
                                                            className={cn(classes.avatarEmojiParentBox, {
                                                                [classes.avatarEmojiParentBoxSelected]: conversation.avatar.background === color,
                                                            })}
                                                        >
                                                            <Box style={{ width: 32, height: 32, backgroundColor: color, borderRadius: '50%' }} />
                                                        </Box>
                                                    </ButtonBase>
                                                </Box>
                                            ))}
                                            <Box style={{ width: 0, height: 48, padding: 6 }} />
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Avatar src={conversation.avatar.url} style={{ width: 48, height: 48 }} />
                                    </Box>
                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 12, marginTop: 12 }}>
                                        <label htmlFor="upload-photo">
                                            <input
                                                style={{ display: 'none' }}
                                                id="upload-photo"
                                                name="upload-photo"
                                                type="file"
                                                onChange={e => {
                                                    let files = e.target.files
                                                    if (files.length > 0) {
                                                        uploadPossiblePhoto(files[0])
                                                    }
                                                }}
                                            />
                                            <Button variant="contained" component="span" color="primary">
                                                Edit
                                            </Button>
                                        </label>
                                    </Box>
                                </>
                            )}
                            <Divider style={{ marginLeft: -12, marginRight: -12 }} />
                        </FormSection>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <FormSubmission
                        isLoading={false}
                        onCancel={onCancel}
                        onSubmit={() => {
                            let convo = _.cloneDeep(conversation)

                            if (convo.avatar.type === 0 && convo.avatar.emoji === '') {
                                convo.avatar.emoji = emoji
                            }

                            // Make sure admins is properly initialized
                            if (!Array.isArray(convo.admins)) {
                                convo.admins = []
                            }

                            onSubmit(convo)
                        }}
                        submitText={isNew ? 'Create' : 'Update'}
                        errorText={''}
                        submitDisabled={
                            conversation.name.length === 0 || (conversation.mType === 0 && (!Array.isArray(conversation.vis) || conversation.vis.length === 0))
                        }
                    />
                </Grid>
            </Grid>
            {addEmojiDialog && (
                <SelectEmojiDialog
                    id="add-emoji-dialog"
                    title="Add Emoji"
                    onSelect={emoji => {
                        onUpdate('avatar', { ...conversation.avatar, emoji: emoji.native })
                        setAddEmojiDialog(false)
                    }}
                    onClose={() => setAddEmojiDialog(false)}
                />
            )}
        </>
    )
}

export default React.memo(UpdateConversation)
