import { useQuery } from '@tanstack/react-query';
import { db } from 'config/firebase';
import { queryKeys } from '../../../lib/queryKeys';

/**
 * Hook to fetch all chapters for a fraternity
 * @param {string} fraternityId - The ID of the fraternity
 * @param {boolean} useMockData - Whether to use mock data (defaults to false in production, true in development)
 * @returns {Object} Query object containing chapters data and status
 */
export const useGetFraternityChapters = (fraternityId, useMockData = process.env.NODE_ENV === 'development') => {
  return useQuery({
    queryKey: queryKeys.fraternity.chapters(fraternityId),
    queryFn: async () => {
      // Return mock data if useMockData is true
      if (useMockData) {
        console.log('Using mock data for fraternity chapters');
        return getMockChapters(fraternityId);
      }
      
      // Otherwise fetch real data from Firebase
      const chaptersSnapshot = await db.collection('chapters')
        .where('fraternity', '==', fraternityId)
        .get();
      
      if (chaptersSnapshot.empty) {
        return [];
      }
      
      const chapters = [];
      chaptersSnapshot.forEach(doc => {
        chapters.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      return chapters;
    },
    // Keep cached data for 5 minutes
    staleTime: 5 * 60 * 1000,
  });
};

/**
 * Returns mock chapters data for development
 * @param {string} fraternityId - The ID of the fraternity
 * @returns {Array} Array of mock chapter objects
 */
const getMockChapters = (fraternityId) => {
  // Generate chapter letter based on index
  const getGreekLetter = (index) => {
    const letters = ['Alpha', 'Beta', 'Gamma', 'Delta', 'Epsilon', 'Zeta', 'Eta', 'Theta', 'Iota', 'Kappa'];
    return letters[index % letters.length];
  };

  // Generate 10 mock chapters with realistic data
  return Array.from({ length: 10 }, (_, i) => ({
    id: i === 0 ? 'L8p2j1ZymODoVJCvREaV' : `mock-chapter-${i + 1}`,
    name: `${getGreekLetter(i)} Chapter`,
    fraternity: fraternityId,
    university: `University of ${['California', 'Texas', 'Michigan', 'Florida', 'New York', 'Washington', 'Oregon', 'Illinois', 'Ohio', 'Virginia'][i % 10]}`,
    address: `${100 + i} Campus Drive`,
    city: ['Los Angeles', 'Austin', 'Ann Arbor', 'Miami', 'Albany', 'Seattle', 'Portland', 'Chicago', 'Columbus', 'Richmond'][i % 10],
    state: ['CA', 'TX', 'MI', 'FL', 'NY', 'WA', 'OR', 'IL', 'OH', 'VA'][i % 10],
    zipCode: `${90000 + i * 100}`,
    founded: `${1950 + i}`,
    status: i % 5 === 0 ? 'inactive' : 'active',
    memberCount: 20 + i * 5,
    president: {
      id: `mock-user-${i}`,
      name: `John Doe ${i}`,
      email: `president${i}@university.edu`
    },
    createdAt: new Date(Date.now() - i * 86400000).toISOString(),
  }));
}; 