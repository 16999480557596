import { useMutation } from '@tanstack/react-query'
import app from 'firebase/app'

export function useDeleteEvent(onSuccess) {
    return useMutation({
        mutationFn: async ({ chapterId, eventId, recurringEventId }) => {
            if (recurringEventId && eventId) {
                throw new Error('Cannot provide both eventId and recurringEventId')
            }
            
            if (!recurringEventId && !eventId) {
                throw new Error('Must provide either eventId or recurringEventId')
            }

            const submission = {
                chapter: chapterId,
                // If recurringEventId is provided, use that. Otherwise use eventId
                ...(recurringEventId 
                    ? { recurringEventId } 
                    : { eventID: eventId }
                ),
            }
            
            const functionsCall = app.functions().httpsCallable('deleteCalendarEvent')
            await functionsCall(submission)
        },
        onSuccess: onSuccess,
        onError: (error) => {
            console.error(error)
            return error
        }
    })
} 