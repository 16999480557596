import React from 'react'

import { getCoordinate } from 'code/Helper'

import { useTheme } from '@material-ui/core/styles'

import { LocationContext } from 'context/LocationContext'
import { MapContext } from '../Map'

const Circle = React.forwardRef(({ radius, centerRegion, ...props }, marker) => {
    const theme = useTheme()

    const { mapkit } = React.useContext(LocationContext)
    const { map } = React.useContext(MapContext)

    const coordinate = getCoordinate(mapkit, props?.data?.coordinate ?? props.center)

    React.useEffect(() => {
        if (mapkit && map) {
            const circleStyle = {
                lineWidth: 2, // 2 CSS pixels
                strokeColor: theme.palette.primary.main,
                fillColor: theme.palette.primary.light,
            }

            let style = new mapkit.Style(circleStyle)

            const feetToMeters = 0.3048

            let circle = new mapkit.CircleOverlay(coordinate, radius * feetToMeters)
            circle.style = style

            map.addOverlay(circle)

            if (centerRegion !== false) {
                const span = new mapkit.CoordinateSpan(0.00001 * radius)
                const region = new mapkit.CoordinateRegion(coordinate, span)

                map.setRegionAnimated(region)
            }

            return () => {
                circle && map && map.removeOverlay(circle)
            }
        }
    }, [mapkit, map, coordinate, radius, centerRegion, theme.palette.primary.main, theme.palette.primary.light])
    return null
})

const StagnantCircle = props => {
    const ref = React.useRef()
    return <Circle {...props} ref={ref} />
}

export default Circle

export { StagnantCircle }
