import React from 'react'

import { PollOutlined as PollsIcon } from '@material-ui/icons'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from '@material-ui/core'

import Tabs from 'components/Tabs'

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import { DialogContent as DialogContentMobile, Dialog as DialogMobile } from 'components/Wrappers'

import PollAuthors from 'components/Polls/PollAuthors'
import PollResponseOrdering from 'components/Polls/PollResponseOrdering'
import QuestionPercentage from 'components/Question/QuestionPercentage'

import { Capacitor } from '@capacitor/core'

import { AuthContext } from 'context/AuthContext'
import { MessagesContext } from 'context/MessagesContext'

import { Question as Qs } from 'objects/AnnualReport/Question'

import { useTheme } from '@material-ui/styles'

import useStyles from './styles'

const ViewPollDialog = ({ conversationId, data, id, messageId, onClose, onUpdate, history }) => {
    const classes = useStyles()
    const theme = useTheme()

    const { user } = React.useContext(AuthContext)
    const { addResponseToMessagePoll, getResponsesToMessagePoll } = React.useContext(MessagesContext)

    const responses = getResponsesToMessagePoll(conversationId, messageId, data.id)

    const calculateStats = () => {
        const stats = {
            responseCount: 0,
            options: {},
            userVals: [],
        }

        let responseKeys = Object.keys(responses)

        for (let i = 0; i < responseKeys.length; i++) {
            let key = responseKeys[i]
            let data = responses[key]
            let response = data.val

            stats.userVals.push(data)

            if (Array.isArray(response)) {
                stats.responseCount += 1

                for (let j = 0; j < response.length; j++) {
                    let resp = response[j]
                    if (resp !== '') {
                        if (stats.options[resp]) {
                            stats.options[resp] += 1
                        } else {
                            stats.options[resp] = 1
                        }
                    }
                }
            } else {
                if (response !== '') {
                    stats.responseCount += 1

                    if (stats.options[response]) {
                        stats.options[response] += 1
                    } else {
                        stats.options[response] = 1
                    }
                }
            }
        }

        stats.userVals.sort((b, a) => (b.cd.seconds ? b.cd.seconds - a.cd.seconds : b.cd._seconds - a.cd._seconds))

        return stats
    }

    const stats = calculateStats()

    const [state, setState] = React.useState({
        tab: 'vote',
        resultsTab: 'overall',
        individualIndex: 0,
    })

    const userValue = responses && user.getId() in responses ? responses[user.getId()].val : data.question.type === Qs.CHECKBOX ? [] : ''

    React.useEffect(() => {
        if (Capacitor.isPluginAvailable('PushNotifications') && !(theme.palette.type === 'dark')) {
            StatusBar.setStyle({
                style: StatusBarStyle.Light,
            })
        }

        return () => {
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                StatusBar.setStyle({
                    style: StatusBarStyle.Dark,
                })
            }
        }
    }, [])

    return (
        <DialogMobile maxWidth="md" id={id} aria-labelledby={`${id}-title`} open fullWidth onClose={onClose} floatingClose>
            <DialogContentMobile
                dividers
                style={{
                    height: '100%',
                    overflow: 'hidden',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: 'none',
                    position: 'relative',
                    fontSize: '1rem',
                    borderTop: 'none',
                }}
            >
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '2em', marginBottom: '0.25em' }}>
                    <PollsIcon style={{ fontSize: '1.15em', lineHeight: 1, width: '1em', height: '1em', verticalAlign: 'text-bottom' }} />
                </Box>
                <Box style={{ marginBottom: '0.5rem' }}>
                    <Typography id={`${id}-title`} align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                        {data.title}
                    </Typography>
                    <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                        {stats.responseCount === 1 ? '1 Response' : `${stats.responseCount} Responses`}
                    </Typography>
                </Box>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                <Box style={{ marginTop: 8, height: 31 }}>
                    <Tabs
                        autoSize
                        onChange={val => {
                            setState(state => ({
                                ...state,
                                tab: val,
                            }))
                        }}
                        selected={state.tab}
                        tabs={[
                            { id: 'vote', name: 'Vote' },
                            { id: 'results', name: 'Results', disabled: stats.responseCount === 0 },
                        ]}
                    />
                </Box>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                {!data.anonymous && state.tab === 'results' && (
                    <>
                        <Box style={{ marginTop: 8, height: 31 }}>
                            <Tabs
                                autoSize
                                onChange={val => {
                                    setState(state => ({
                                        ...state,
                                        resultsTab: val,
                                    }))
                                }}
                                selected={state.resultsTab}
                                tabs={[
                                    { id: 'overall', name: 'Overall' },
                                    { id: 'individual', name: 'Individual' },
                                ]}
                            />
                        </Box>
                        <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                    </>
                )}
                {state.tab === 'vote' && (
                    <Box style={{ paddingTop: '1em' }}>
                        {data.question.type === Qs.MC && (
                            <FormControl component="fieldset" fullWidth>
                                <RadioGroup
                                    value={userValue}
                                    onChange={e => {
                                        const newValue = e.target.value

                                        addResponseToMessagePoll(conversationId, messageId, data.id, {
                                            val: newValue,
                                            author: user.getAuthor(),
                                            cd: new Date(),
                                        })
                                    }}
                                >
                                    {data.question.choices.map((choice, index) => (
                                        <FormControlLabel key={`vote-${choice.id}`} value={choice.id} control={<Radio color="primary" />} label={choice.text} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        )}
                        {data.question.type === Qs.CHECKBOX && (
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {data.question.choices.map((choice, index) => (
                                        <FormControlLabel
                                            key={`vote-${choice.id}`}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={userValue ? userValue.includes(choice.id) : false}
                                                    onChange={e => {
                                                        let newValue = Array.isArray(userValue) ? [...userValue] : []

                                                        let checked = newValue.includes(choice.id)

                                                        if (checked) {
                                                            newValue = newValue.filter(val => val !== choice.id)
                                                        } else {
                                                            newValue.push(choice.id)
                                                        }

                                                        addResponseToMessagePoll(conversationId, messageId, data.id, {
                                                            val: newValue,
                                                            author: user.getAuthor(),
                                                            cd: new Date(),
                                                        })
                                                    }}
                                                />
                                            }
                                            label={choice.text}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        )}
                    </Box>
                )}
                {state.tab === 'results' && state.resultsTab === 'individual' && (
                    <>
                        <PollAuthors
                            responses={stats.userVals}
                            curIndex={state.individualIndex}
                            onChangeIndex={index => {
                                setState(state => ({
                                    ...state,
                                    individualIndex: index,
                                }))
                            }}
                            history={history}
                        />
                        <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                        <PollResponseOrdering
                            responses={stats.userVals}
                            curIndex={state.individualIndex}
                            onChangeIndex={index => {
                                setState(state => ({
                                    ...state,
                                    individualIndex: index,
                                }))
                            }}
                        />
                        <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                    </>
                )}
                {state.tab === 'results' && (
                    <Box style={{ paddingTop: '1em' }}>
                        {state.resultsTab === 'individual'
                            ? data.question.choices.map((choice, index) => (
                                  <Box skey={`results-${choice.id}`} style={{ paddingTop: index > 0 ? 4 : 0, width: '100%' }}>
                                      <QuestionPercentage
                                          showValue
                                          value={
                                              Array.isArray(stats.userVals[state.individualIndex].val)
                                                  ? stats.userVals[state.individualIndex].val.includes(choice.id)
                                                      ? 1
                                                      : 0
                                                  : stats.userVals[state.individualIndex].val === choice.id
                                                  ? 1
                                                  : 0
                                          }
                                          text={choice.text}
                                          count={1}
                                      />
                                  </Box>
                              ))
                            : data.question.choices.map((choice, index) => (
                                  <Box skey={`results-${choice.id}`} style={{ paddingTop: index > 0 ? 4 : 0, width: '100%' }}>
                                      <QuestionPercentage
                                          showValue
                                          value={choice.id in stats.options ? stats.options[choice.id] : 0}
                                          text={choice.text}
                                          count={stats.responseCount}
                                      />
                                  </Box>
                              ))}
                    </Box>
                )}
            </DialogContentMobile>
        </DialogMobile>
    )
}

export default React.memo(ViewPollDialog)
