import { useMutation } from '@tanstack/react-query';
import app from 'firebase/app';
import { queryKeys } from '../../../lib/queryKeys';

/**
 * Hook for generating enhanced content using AI
 * 
 * @param {Object} options - The options for the mutation
 * @param {Function} options.onSuccess - Callback function to run on successful mutation
 * @returns {Object} The mutation object
 */
export function useGenerateEnhancedContent({ onSuccess } = {}) {
  return useMutation({
    mutationKey: queryKeys.ai.contentGeneration,
    mutationFn: async ({ initialDescription, enhancementType }) => {
      if (!initialDescription) {
        throw new Error('Initial description is required');
      }
      
      if (!enhancementType) {
        throw new Error('Enhancement type is required');
      }
      
      const functionsCall = app.functions().httpsCallable('generateEnhancedContent');
      
      const result = await functionsCall({
        initialDescription,
        enhancementType
      });
      
      return result.data;
    },
    onSuccess,
    onError: (error) => {
      console.error('Error generating enhanced content:', error);
      return error;
    }
  });
}