import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    table: {
        overflowX: `scroll`,
        overflowY: `hidden`,
        '-ms-overflow-style': `none`,
        '&::-webkit-scrollbar': {
            display: `none`,
        },
    },
    tableHeader: {
        padding: 18,
        paddingBottom: 0,
        borderBottom: theme.border,
    },
    tableFooter: {
        borderTop: theme.border,
    },
    selectableRow: {
        cursor: `pointer`,
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
    },
    selectedRow: {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        },
    },
    pageText: {
        margin: 3,
    },
    rowTextSmall: {
        margin: 3,
        width: `25%`,
        minWidth: 64,
        textAlign: `center`,
    },
    rowTextMedium: {
        margin: 3,
        width: `50%`,
        minWidth: 128,
        textAlign: `center`,
    },
    rowTextLarge: {
        margin: 3,
        width: `100%`,
        minWidth: 256,
        textAlign: `center`,
    },
    rowCheckSmall: {
        width: `25%`,
        minWidth: 64,
        textAlign: `center`,
        paddingLeft: 3,
        paddingRight: 3,
    },
    rowCheckMedium: {
        width: `50%`,
        minWidth: 128,
        textAlign: `center`,
        paddingLeft: 3,
        paddingRight: 3,
    },
    rowCheckLarge: {
        width: `100%`,
        minWidth: 256,
        textAlign: `center`,
        paddingLeft: 3,
        paddingRight: 3,
    },
    rowTextFieldSmall: {
        maxWidth: 128,
        margin: '0 auto',
    },
    rowTextFieldMedium: {
        maxWidth: 256,
        margin: '0 auto',
    },
    rowTextFieldLarge: {
        margin: '0 auto',
    },
    rowCheckbox: {
        display: 'flex',
        justifyContent: 'center',
    },
    rowTFWrapperSmall: {
        width: '25%',
        minWidth: 64,
        marginLeft: 3,
        marginRight: 3,
    },
    rowTFWrapperMedium: {
        width: '50%',
        minWidth: 128,
        marginLeft: 3,
        marginRight: 3,
    },
    rowTFWrapperLarge: {
        width: '100%',
        minWidth: 256,
        marginLeft: 3,
        marginRight: 3,
    },
    tableTopRow: {
        paddingLeft: `16px !important`,
        paddingRight: `16px !important`,
        /*width: '-webkit-max-content',*/
    },
    tableRow: {
        borderTop: theme.border,
        paddingLeft: `16px !important`,
        paddingRight: `16px !important`,
        /*width: '-webkit-max-content',*/
    },
    tableLoading: {
        borderTop: theme.border,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        position: `absolute`,
        width: `100%`,
        height: `calc(100% - 31px)`,
    },
    tableLoadingInline: {
        borderTop: theme.border,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        width: `100%`,
        height: `31px`,
    },
    tableLoadingInner: {
        margin: `0 auto`,
    },
    tableLoadingInlineInner: {
        margin: `0 auto`,
        paddingTop: 4,
    },
    emptyTableRow: {
        height: 31,
    },
    emptyTableTopRow: {
        height: 30,
    },
    tableContent: {
        maxWidth: `unset`,
        position: `relative`,
    },
    columnHeaderLarge: {
        margin: 3,
        width: `100%`,
        minWidth: 256,
        textAlign: `center`,
        fontWeight: 500,
    },
    columnHeaderMedium: {
        margin: 3,
        width: `50%`,
        minWidth: 128,
        textAlign: `center`,
        fontWeight: 500,
    },
    columnHeaderSmall: {
        margin: 3,
        width: `25%`,
        minWidth: 64,
        textAlign: `center`,
        fontWeight: 500,
    },
    emptyIcon: {
        width: 24,
        height: 24,
        margin: 3,
    },
    checkedActionButton: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    sortLink: {
        cursor: `grab`,
        userSelect: `none`,
        color: theme.palette.text.primary,
        height: 24,
    },
    sortingRow: {
        display: `inline-flex`,
        alignContent: `center`,
        flexDirection: `row`,
    },
    sortingIcon: {
        fontSize: 16,
        margin: 4,
    },
}))
