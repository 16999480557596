import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Typography,
    Grid,
    IconButton,
    Tooltip
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import CrownOutlineIcon from '@material-ui/icons/StarBorder' // Using similar icon
import ModeratorOutlineIcon from '@material-ui/icons/SupervisorAccount' // Using similar icon
import DeleteIcon from '@material-ui/icons/Delete'
import CrownAddOutlineIcon from '@material-ui/icons/Star' // Using similar icon
import ModeratorAddOutlineIcon from '@material-ui/icons/PersonAdd' // Using similar icon
import ModeratorRemoveOutlineIcon from '@material-ui/icons/PersonAddDisabled' // Using similar icon
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
    membersBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        maxHeight: '50vh',
        overflow: 'auto'
    },
    membersGrid: {
        padding: theme.spacing(1)
    },
    membersGridItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    membersGridItemLast: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    membersGridItemBottom: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    membersGridItemTop: {
        borderTop: `1px solid ${theme.palette.divider}`
    },
    deleteIcon: {
        color: theme.palette.error.main
    }
}))

/**
 * A reusable component for displaying a list of members with their statuses and actions
 * 
 * @param {Object} props
 * @param {Array} props.members - List of member objects with id, first, last, status properties
 * @param {String} props.currentUserId - ID of the current user
 * @param {String} props.ownerId - ID of the owner (if applicable)
 * @param {Array} props.adminIds - Array of admin IDs (if applicable)
 * @param {Array} props.mutedIds - Array of IDs of members who have muted
 * @param {Function} props.onPromoteToOwner - Function to promote a member to owner
 * @param {Function} props.onPromoteToAdmin - Function to promote a member to admin
 * @param {Function} props.onDemoteToMember - Function to demote an admin to member
 * @param {Function} props.onRemoveMember - Function to remove a member
 * @param {Function} props.onViewProfile - Function to view a member's profile
 * @param {String} props.version - 'mobile' or 'desktop'
 * @param {Boolean} props.isAutomatic - Whether membership is automatic
 * @param {Object} props.visibilityInfo - Object containing visibility information
 * @param {Function} props.renderVisibility - Function to render visibility text
 */
const MembersList = ({
    members,
    currentUserId,
    ownerId,
    adminIds = [],
    mutedIds = [],
    onPromoteToOwner,
    onPromoteToAdmin,
    onDemoteToMember,
    onRemoveMember,
    onViewProfile,
    version = 'desktop',
    isAutomatic = false,
    visibilityInfo = null,
    renderVisibility = null
}) => {
    const classes = useStyles()
    
    // Check if current user has admin or owner permissions
    const isCurrentUserOwner = currentUserId === ownerId
    const isCurrentUserAdmin = adminIds.includes(currentUserId)
    
    const getMembers = (status = 'all') => {
        let filteredMembers = members

        if (status !== 'all') {
            filteredMembers = members.filter(mem => mem.status === status)
        }

        return filteredMembers.map((mem, i) => {
            let fullSize = version === 'mobile' || (i === filteredMembers.length - 1 && i % 2 === 0)
            let lastRow = i === filteredMembers.length - 1 || (i === filteredMembers.length - 2 && filteredMembers.length % 2 === 0 && !fullSize)
            let firstRow = i === 0 || (i === 1 && !fullSize)

            let memberHasMuted = mutedIds.includes(mem.id)

            return (
                <Grid
                    key={mem.id}
                    item
                    xs={fullSize ? 12 : 6}
                    className={cn(fullSize ? classes.membersGridItemLast : classes.membersGridItem, {
                        [classes.membersGridItemBottom]: !lastRow,
                        [classes.membersGridItemTop]: !firstRow,
                    })}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', paddingLeft: 12, width: '100%' }}>
                        <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ lineHeight: '30px' }}>
                                {mem.first} {mem.last}
                            </Typography>
                            {mem.status === 'owner' && (
                                <Box style={{ padding: 3, height: 30 }}>
                                    <CrownOutlineIcon />
                                </Box>
                            )}
                            {mem.status === 'admin' && (
                                <Box style={{ padding: 3, height: 30 }}>
                                    <ModeratorOutlineIcon />
                                </Box>
                            )}
                            {memberHasMuted && (
                                <Box style={{ padding: 3, height: 30 }}>
                                    <NotificationsOffIcon aria-label="muted" />
                                </Box>
                            )}
                        </Box>
                        {isCurrentUserOwner && mem.status === 'admin' && onPromoteToOwner && (
                            <Tooltip title="Promote to Owner">
                                <IconButton
                                    aria-label="promote to owner"
                                    size="small"
                                    onClick={() => onPromoteToOwner(mem.id)}
                                >
                                    <CrownAddOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {(isCurrentUserAdmin || isCurrentUserOwner) && mem.status === 'member' && onPromoteToAdmin && (
                            <Tooltip title="Promote to Admin">
                                <IconButton
                                    aria-label="promote to admin"
                                    size="small"
                                    onClick={() => onPromoteToAdmin(mem.id)}
                                >
                                    <ModeratorAddOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isCurrentUserOwner && mem.status === 'admin' && onDemoteToMember && (
                            <Tooltip title="Demote to Member">
                                <IconButton
                                    aria-label="demote to member"
                                    size="small"
                                    onClick={() => onDemoteToMember(mem.id)}
                                >
                                    <ModeratorRemoveOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {(isCurrentUserAdmin || isCurrentUserOwner) && mem.status === 'member' && !isAutomatic && onRemoveMember && (
                            <Tooltip title="Remove from Conversation">
                                <IconButton
                                    aria-label="remove from conversation"
                                    className={classes.deleteIcon}
                                    size="small"
                                    onClick={() => onRemoveMember(mem.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {onViewProfile && (
                            <Tooltip title="View Profile">
                                <IconButton 
                                    aria-label="view profile" 
                                    size="small" 
                                    onClick={() => onViewProfile(mem.id)}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Grid>
            )
        })
    }

    const renderAllMemberSections = () => {
        let ownerMembers = getMembers('owner')
        let adminMembers = getMembers('admin')
        let regularMembers = getMembers('member')

        return (
            <Box>
                {ownerMembers.length > 0 && (
                    <Box style={{ marginTop: 12 }}>
                        <Typography align="center" style={{ fontWeight: 500 }}>
                            Owner
                        </Typography>
                        <Box style={{ marginTop: 12 }}>
                            <Grid container spacing={1} className={classes.membersGrid}>
                                {ownerMembers}
                            </Grid>
                        </Box>
                    </Box>
                )}
                {adminMembers.length > 0 && (
                    <Box style={{ marginTop: 12 }}>
                        <Typography align="center" style={{ fontWeight: 500 }}>
                            Admins
                        </Typography>
                        <Box style={{ marginTop: 12 }}>
                            <Grid container spacing={1} className={classes.membersGrid}>
                                {adminMembers}
                            </Grid>
                        </Box>
                    </Box>
                )}
                {regularMembers.length > 0 && (
                    <Box style={{ marginTop: 12 }}>
                        <Typography align="center" style={{ fontWeight: 500 }}>
                            Members
                        </Typography>
                        <Box style={{ marginTop: 12 }}>
                            <Grid container spacing={1} className={classes.membersGrid}>
                                {regularMembers}
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }

    return (
        <Box className={classes.membersBox}>
            <Typography align="center" variant="h6" style={{ fontWeight: 500 }}>
                All Members
            </Typography>
            {isAutomatic && visibilityInfo && renderVisibility && (
                <Typography align="center" variant="caption">
                    {renderVisibility(visibilityInfo)}
                </Typography>
            )}
            {renderAllMemberSections()}
        </Box>
    )
}

export default MembersList 