import React from 'react'

import app from 'firebase/app'

import { Box, Divider, Typography } from '@material-ui/core'
import {
    LocationDisabledOutlined as BackgroundLocationDisabledIcon,
    LocationOnOutlined as LocationIcon,
    NotificationsOffOutlined as NotificationsDisabledIcon,
} from '@material-ui/icons'

import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { LocalNotifications } from '@capacitor/local-notifications'
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import Map from 'components/Map'
import { StagnantCircle as MapCircle } from 'components/Map/Circle'
import { StagnantMarker as MapMarker } from 'components/Map/Marker'
import MapUserMarker from 'components/Map/Marker/UserMarker'

import Tip from 'components/Tip'

import { Button, DialogContent as DialogContentMobile, Dialog as DialogMobile } from 'components/Wrappers'

import { AuthContext } from 'context/AuthContext'
import { BackgroundGeolocation, LocationContext } from 'context/LocationContext'

import { useTheme } from '@material-ui/styles'

import { calculateCoordinateDistanceInMeters, getCoordinate, metersToFeet } from 'code/Helper'

import useStyles from '../styles'

const LocationTrackingDialog = ({ points, onClose, ...props }) => {
    const classes = useStyles()
    const theme = useTheme()

    const id = 'location-tracking'

    const { user } = React.useContext(AuthContext)
    const { mapkit } = React.useContext(LocationContext)

    const [location, setLocation] = React.useState(props.location)

    const [usingBackgroundGeolocation] = React.useState(Capacitor.isPluginAvailable('BackgroundGeolocation'))
    const [hasNotificationsEnabled, setHasNotificationsEnabled] = React.useState(true)

    const watchPosition = React.useRef()

    const [tracker, setTracker] = React.useState(null)
    const finishedTracking = React.useRef(false)

    const isActiveRef = React.useRef(false)
    const [isActive, setIsActive] = React.useState(false)

    const [millis, setMillis] = React.useState()
    const lastTimestamp = React.useRef()

    const [hasGrabbed, setHasGrabbed] = React.useState(false)

    const [userLocation, setUserLocation] = React.useState(null)

    function getDBRef() {
        const databaseDB = app.database()
        return databaseDB.ref(`chapters/${user.getChapter()}/points/${points.id}/locationTracking/${user.getId()}`)
    }

    async function setupDB(ref) {
        let snapshot
        let snapshotVal

        try {
            snapshot = await ref.once('value')
            if (snapshot.exists()) {
                snapshotVal = await snapshot.val()

                if (snapshotVal.location) {
                    setLocation(snapshotVal.location)
                } else {
                    snapshotVal = null
                }
            }
        } catch (e) {
            // Doesn't exist
        }

        if (!snapshotVal) {
            if (!props.location) {
                onClose()
                return
            }

            const data = {
                location: props.location,
                status: 'active',
                timer: 0 /* seconds -> updates every 10 */,
                spots: [] /* Timestamp ts, coords: [int lat, int long ]*/,
            }

            await ref.set(data)
            setMillis(0)
        } else {
            setMillis(snapshotVal.timer)
        }

        lastTimestamp.current = Date.now()

        await ref.onDisconnect().update({ status: 'paused_disconnect' })

        setHasGrabbed(true)
    }

    const updateLocation = (coords, accuracy, timestamp) => {
        const newUserLocation = {
            accuracy: metersToFeet(accuracy),
            latitude: coords.latitude,
            longitude: coords.longitude,
        }

        const mapkitLocation = getCoordinate(mapkit, newUserLocation)

        // Check distance to nearest edge of circle (if value < 0, then they are inside the circle - within the gps reading's accuracy)
        const distance = metersToFeet(calculateCoordinateDistanceInMeters(location.center, mapkitLocation)) - location.radius - newUserLocation.accuracy

        if (distance > 0 && isActiveRef.current) {
            // Check if they are out of bounds and are still tracking points
            const updateStatus = async () => {
                const ref = getDBRef()
                await ref.update({ status: 'paused_out_of_bounds' })
            }

            lastTimestamp.current = Date.now()

            updateStatus()
        } else if (distance <= 0 && !isActiveRef.current) {
            if (!finishedTracking.current) {
                // Auto resume if they have been paused and came back into the radius
                const updateStatus = async () => {
                    const ref = getDBRef()
                    await ref.update({ status: 'active' })
                }

                lastTimestamp.current = Date.now()

                updateStatus()
            }
        } else {
            setMillis(oldMillis => {
                const curMillis = Date.now()
                let newMillis = curMillis - lastTimestamp.current

                if (newMillis > 30 * 1000) {
                    // Don't let an update provide more than 30 seconds due to the lack of location tracking
                    newMillis = 30 * 1000
                }

                newMillis += oldMillis

                if (Math.floor(newMillis / 1000) % 10 === 0) {
                    const update = async () => {
                        const ref = getDBRef()
                        await ref.update({ timer: newMillis })
                    }

                    update()
                }

                lastTimestamp.current = curMillis

                return newMillis
            })
        }

        setUserLocation(mapkitLocation)
    }

    React.useEffect(() => {
        if (Capacitor.isPluginAvailable('PushNotifications') && !(theme.palette.type === 'dark')) {
            StatusBar.setStyle({
                style: StatusBarStyle.Light,
            })
        }

        const ref = getDBRef()

        const runDB = async ref => {
            await setupDB(ref)

            ref.on('value', snapshot => {
                if (snapshot.exists()) {
                    const data = snapshot.val()

                    setTracker(data)

                    const isActive = data.status === 'active'
                    setIsActive(isActive)
                    isActiveRef.current = isActive
                } else {
                    onClose()
                }
            })
        }

        runDB(ref)

        const checkIfNotificationsAreEnabled = async () => {
            const available = Capacitor.isPluginAvailable('LocalNotifications')

            if (available) {
                const enabled = await LocalNotifications.areEnabled()
                setHasNotificationsEnabled(enabled.value)
            } else {
                setHasNotificationsEnabled(false)
            }
        }

        checkIfNotificationsAreEnabled()

        return () => {
            ref.off('value')

            if (!finishedTracking.current) {
                const finishDB = async ref => {
                    await ref.update({ status: 'paused' })
                    await ref.onDisconnect().cancel()
                }

                finishDB(ref)
            }

            if (Capacitor.isPluginAvailable('PushNotifications')) {
                StatusBar.setStyle({
                    style: StatusBarStyle.Dark,
                })
            }
        }
    }, [])

    React.useEffect(() => {
        if (hasGrabbed && location) {
            if (usingBackgroundGeolocation) {
                BackgroundGeolocation.addWatcher(
                    {
                        backgroundMessage: 'Cancel to prevent battery drain.',
                        backgroundTitle: 'Tracking You.',
                        requestPermissions: true,
                        stale: false,
                        distanceFilter: 10,
                    },
                    function callback(position, error) {
                        if (error) {
                            if (error.code === 'NOT_AUTHORIZED') {
                                if (window.confirm('This app needs your location, but does not have permission.\n\nOpen settings now?')) {
                                    BackgroundGeolocation.openSettings()
                                }
                            }
                            return console.error(error)
                        }
                        console.log(`in initial callback ${position}`)

                        updateLocation({ latitude: position.latitude, longitude: position.longitude }, position.accuracy, position.time)
                    },
                ).then(function after_the_watcher_has_been_added(watcher_id) {
                    watchPosition.current = watcher_id
                })
            } else {
                watchPosition.current = Geolocation.watchPosition({ timeout: 10000 }, (position, err) => {
                    if (err) {
                        return
                    }
                    console.log(`else in watch position ${position}`)
                    updateLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude }, position.coords.accuracy, position.timestamp)
                })
            }

            return () => {
                if (watchPosition.current) {
                    if (usingBackgroundGeolocation) {
                        BackgroundGeolocation.removeWatcher({
                            id: watchPosition.current,
                        })
                    } else {
                        Geolocation.clearWatch({ id: watchPosition.current })
                    }
                }
            }
        }
    }, [hasGrabbed, location])

    React.useEffect(() => {
        // This is run every time it is active
        if (isActive) {
            // Start the timer and add up how many seconds we are at
            let interval = setInterval(() => {
                setMillis(oldMillis => {
                    const curMillis = Date.now()
                    let newMillis = curMillis - lastTimestamp.current

                    if (newMillis > 30 * 1000) {
                        newMillis = 30 * 1000
                    }

                    newMillis += oldMillis

                    if (Math.floor(newMillis / 1000) % 10 === 0) {
                        const update = async () => {
                            const ref = getDBRef()
                            await ref.update({ timer: newMillis })
                        }

                        update()
                    }

                    lastTimestamp.current = curMillis

                    return newMillis
                })
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [isActive])

    function finishTracking(ref) {
        setMillis(oldMillis => {
            const curMillis = Date.now()
            let newMillis = curMillis - lastTimestamp.current

            if (newMillis > 30 * 1000) {
                newMillis = 30 * 1000
            }

            newMillis += oldMillis

            const update = async () => {
                if (tracker && tracker.status === 'complete') {
                    await ref.update({ status: 'completed' })
                }

                await ref.update({ status: 'complete', timer: newMillis })
                await ref.onDisconnect().cancel()

                finishedTracking.current = true

                onClose()
            }

            update()

            return newMillis
        })
    }

    const _onClose = async () => {
        const ref = getDBRef()
        await ref.update({ timer: millis })

        onClose()
    }

    const getEarnedPoints = () => {
        if (!location) {
            return 0
        }

        const val = Math.floor(millis / 1000) / (location.points.length * 60 * (location.points.lengthUnits === 'hours' ? 60 : 1))

        if (location.points.round === 1) {
            return Math.ceil(val)
        }

        if (location.points.round === -1) {
            return Math.floor(val)
        }

        return Math.round(val)
    }

    return (
        <DialogMobile maxWidth="md" id={id} aria-labelledby={`${id}-title`} open fullWidth onClose={_onClose} floatingClose alwaysFullscreen>
            <DialogContentMobile
                dividers
                style={{
                    height: '100%',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: 'none',
                    position: 'relative',
                    fontSize: '1rem',
                    borderTop: 'none',
                }}
            >
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '2em', marginBottom: '0.25em' }}>
                    <LocationIcon style={{ fontSize: '1.15em', lineHeight: 1, width: '1em', height: '1em', verticalAlign: 'text-bottom' }} />
                </Box>
                {location && (
                    <Box style={{ marginBottom: '0.5rem' }}>
                        <Typography id={`${id}-title`} align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                            Location Tracking Now
                        </Typography>
                        <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                            {points.name} - {location.name}
                        </Typography>
                        <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                            {location.points.value} {location.points.value === 1 ? 'point' : 'points'} for every{' '}
                            {location.points.length === 1
                                ? location.points.lengthUnits.slice(0, -1)
                                : `${location.points.length} ${location.points.lengthUnits}`}
                        </Typography>
                    </Box>
                )}
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                <Box style={{ marginLeft: -16, marginRight: -16 }}>
                    {location && userLocation ? (
                        <Map
                            center={userLocation}
                            style={{
                                height: 384,
                                maxHeight: '50vh',
                            }}
                        >
                            <MapUserMarker location={userLocation} />
                            <MapCircle data={location?.data?.coordinate ?? location.center } radius={location.radius} centerRegion={false} />
                            <MapMarker title={location.name} data={location?.data?.coordinate ?? location.center } />
                        </Map>
                    ) : (
                        <Box style={{ height: 384 }}>Vibing... </Box>
                    )}
                </Box>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                {!usingBackgroundGeolocation && (
                    <Box style={{ paddingTop: '1em' }}>
                        <Tip
                            color="red"
                            id="no_background"
                            disableElevation
                            icon={<BackgroundLocationDisabledIcon />}
                            hideAction
                            title="KEEP OPEN"
                            description="Keep app in foreground or use the mobile app for background location tracking"
                            titleStyle={{ textAlign: 'center' }}
                        />
                    </Box>
                )}
                {usingBackgroundGeolocation && !hasNotificationsEnabled && (
                    <Box style={{ paddingTop: '1em' }}>
                        <Tip
                            color="primary"
                            id="no_notifications"
                            icon={<NotificationsDisabledIcon />}
                            disableElevation
                            hideAction
                            title="Notifications Disabled"
                            description="Please check back to ensure you're earning points"
                            titleStyle={{ textAlign: 'center' }}
                        />
                    </Box>
                )}
                {tracker && (
                    <Box style={{ paddingTop: '1em' }}>
                        <Typography align="center">Time Spent</Typography>
                        <Typography align="center" style={{ fontSize: 24, fontWeight: 'bold', lineHeight: 1 }}>
                            {millis > 1000 * 60 * 60 ? `${Math.floor(Math.floor(Math.floor(millis / 1000) / 60) / 60)}:` : ''}
                            {Math.floor(Math.floor(millis / 1000) / 60) % 60}:
                            {(Math.floor(millis / 1000) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                        </Typography>
                    </Box>
                )}
                {tracker && location && (
                    <Box style={{ paddingTop: '1em' }}>
                        <Typography align="center">Points Earned</Typography>
                        <Typography align="center" style={{ fontSize: 24, fontWeight: 'bold', lineHeight: 1 }}>
                            {getEarnedPoints()}
                        </Typography>
                    </Box>
                )}
                {tracker && (
                    <Box style={{ paddingTop: '1em' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => finishTracking(getDBRef())}
                            disableElevation
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Finish
                        </Button>
                    </Box>
                )}
            </DialogContentMobile>
        </DialogMobile>
    )
}

export default LocationTrackingDialog
