import { Box, Button, CircularProgress, Divider, IconButton, Tooltip } from '@material-ui/core'
import React from 'react'

import {
    /*Tasks Icons*/
    /*Tip Icons*/
    /* Other Icons*/
    AccessTime as TimeIcon,
    CalendarToday as CalendarIcon,
    FastfoodOutlined as MealsIcon,
    LocationOnOutlined as LocationIcon,
    PollOutlined as PollsIcon,
} from '@material-ui/icons'

import { Avatar, Chip as CustomChip, Typography } from 'components/Wrappers'
import { AwardIcon } from 'components/Icons'

import { getEventTiming, timeAgo } from 'code/TimeAgo'

import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'

import { AuthContext } from 'context/AuthContext'

import cn from 'classnames'

// styles
import useStyles from './styles'

/**
 * GCWidget (Greek Community Widget) - A versatile display component for various content types
 * 
 * GCWidget is a reusable UI component that displays different types of content in a card-like format.
 * It adapts its layout and content based on the 'type' prop, supporting events, polls, and menu displays.
 * 
 * The component handles loading states and missing data gracefully, showing appropriate loading indicators
 * or error messages when content is unavailable. It provides consistent styling across different content types
 * while adapting the display to the specific requirements of each type.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {string} props.type - The type of widget to display ('events', 'polls', or 'menu')
 * @param {Object} props.history - React Router history object for navigation
 * @param {boolean} [props.hideHeader=false] - Whether to hide the header section with author information
 * @param {boolean} [props.loading=false] - Whether the widget data is still loading
 * @param {boolean} [props.exists] - Whether the requested widget data exists
 * @param {boolean} [props.shrunk] - Whether to use smaller icon sizes (for compact displays)
 * 
 * @param {Object} [props.creator] - For 'events' type: Information about the event creator
 * @param {string} [props.creator.photo] - Creator's profile photo URL
 * @param {string} [props.creator.first] - Creator's first name
 * @param {string} [props.creator.last] - Creator's last name
 * @param {string} [props.name] - For 'events' type: Event name
 * @param {string} [props.location] - For 'events' type: Event location name
 * @param {string} [props.address] - For 'events' type: Event address (alternative to location)
 * @param {Array} [props.categories] - For 'events' type: Categories the event belongs to
 * 
 * @param {Object} [props.author] - For 'polls' and 'menu' types: Information about the author
 * @param {string} [props.author.photo] - Author's profile photo URL
 * @param {string} [props.author.id] - Author's user ID
 * @param {string} [props.author.first] - Author's first name
 * @param {string} [props.author.last] - Author's last name
 * @param {string} [props.title] - For 'polls' type: Poll title
 * @param {string} [props.description] - For 'polls' type: Poll description
 * @param {boolean} [props.ar] - For 'polls' type: Whether the user has already responded
 * 
 * @param {Object} [props.settings] - For 'polls' type: Poll settings
 * @param {Object} [props.settings.luopen] - Timestamp when the poll was last updated
 * 
 * @param {Object} [props.cd] - For 'menu' type: Creation date timestamp
 * @param {string} [props.photo] - For 'menu' type: URL to the menu image
 * 
 * @returns {React.Element} The rendered widget component
 */
export default function GCWidget(props) {
    var classes = useStyles()

    const { user } = React.useContext(AuthContext)

    const { type, history, hideHeader } = props

    function getDateFromTimestamp(d) {
        if (d) {
            return new Date(0).setUTCSeconds(d.seconds ? d.seconds : d._seconds)
        }

        return new Date()
    }

    function getAvatar(photoURL, userId) {
        return (
            <Box className={classes.avatarParent}>
                <IconButton className={classes.avatarButton} onClick={_ => history.push(`/app/profile/${userId}`)}>
                    <Avatar src={photoURL} className={classes.avatar} />
                </IconButton>
            </Box>
        )
    }

    if (props.loading) {
        let text = type
        if (text === 'events') {
            text = 'Event'
        } else if (text === 'polls') {
            text = 'Poll'
        }

        return (
            <Box className={classes.boxLoading}>
                <Typography style={{ fontWeight: 500, marginLeft: 24, marginRight: 8 }}>Loading {text}</Typography>
                <CircularProgress size="1.5rem" className={classes.progressCircle} />
            </Box>
        )
    }

    if (props.exists === false) {
        let text = type
        if (text === 'events') {
            text = 'Event'
        } else if (text === 'polls') {
            text = 'Poll'
        }

        return (
            <Box className={classes.boxLoading}>
                <Typography style={{ fontWeight: 500, marginLeft: 24, marginRight: 24 }}>{text} doesn't exist</Typography>
            </Box>
        )
    }

    if (type === 'events') {
        // Calculate total available points for this event if any
        const totalPoints = Array.isArray(props.points) 
            ? props.points.reduce((total, pointItem) => total + pointItem.points, 0) 
            : 0;
            
        const hasPoints = totalPoints > 0;

        return (
            <Box className={classes.root}>
                {hideHeader ? (
                    <Box className={classes.topIconHeader}>
                        <CalendarIcon className={cn(classes.icon, classes.topIconHeaderIcon, { [classes.iconShrunk]: props.shrunk })} />
                    </Box>
                ) : (
                    <>
                        <Box className={classes.top}>
                            <Box className={classes.topTitle}>
                                {getAvatar(props.creator.photo, props.author)}
                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography
                                        style={{ fontWeight: 500, lineHeight: 1, fontSize: '1em' }}
                                    >{`${props.creator.first} ${props.creator.last}`}</Typography>
                                    <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                        <CalendarIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                        {props.location || props.address ? (
                                            <>
                                                {` • `}
                                                <LocationIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                                {props.location ? props.location : props.address}
                                            </>
                                        ) : (
                                            <>
                                                {` • `}
                                                <TimeIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                                {` ${getEventTiming(props, { includeLength: false, includeTZ: false, notRelative: true })}`}
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                )}
                <Box className={classes.body}>
                    <Typography align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                        {props.name}
                        {hasPoints && (
                            <Tooltip title={`Earn ${totalPoints} points for participating`}>
                                <Box component="span" style={{ display: 'inline-flex', verticalAlign: 'middle', marginLeft: 8 }}>
                                    <AwardIcon style={{ color: '#FFC107', fontSize: '1.2em' }} />
                                    <Typography variant="body2" style={{ fontWeight: 'bold', marginLeft: 2 }}>
                                        {totalPoints}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        )}
                    </Typography>
                    {hideHeader && (props.location || props.address) && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <LocationIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                            {props.location ? props.location : props.address}
                        </Box>
                    )}
                    <Typography align="center" style={{ fontSize: '1em' }} className={classes.nonOverflowText}>
                        {getEventTiming(props, { includeLength: true, includeTZ: props.isDifferentTimezone })}
                    </Typography>
                    {props.message}
                    {!hideHeader && Array.isArray(props.categories) && props.categories.length > 0 && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            {props.categories.map(category => (
                                <CustomChip
                                    key={`${props.id}.${category.id}`}
                                    className={classes.chip}
                                    color={category.color}
                                    label={category.name}
                                    style={{ margin: '0.25em' }}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <Divider />
                <Box className={classes.bottom}>
                    <Button
                        color="primary"
                        className={classes.action}
                        onClick={() => {
                            history.push('/app/calendar/event/' + props.id)
                        }}
                    >
                        View
                    </Button>
                </Box>
            </Box>
        )
    }

    if (type === 'polls') {
        return (
            <Box className={classes.root}>
                {hideHeader ? (
                    <Box className={classes.topIconHeader}>
                        <PollsIcon className={cn(classes.icon, classes.topIconHeaderIcon, { [classes.iconShrunk]: props.shrunk })} />
                    </Box>
                ) : (
                    <>
                        <Box className={classes.top}>
                            <Box className={classes.topTitle}>
                                {getAvatar(props.author.photo, props.author.id)}
                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography
                                        style={{ fontWeight: 500, lineHeight: 1, fontSize: '1em' }}
                                        className={classes.nonOverflowText}
                                    >{`${props.author.first} ${props.author.last}`}</Typography>
                                    <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                        <PollsIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                        {` `}
                                        {props.settings.luopen ? ` • ${timeAgo(getDateFromTimestamp(props.settings.luopen))}` : ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                )}
                <Box className={classes.body}>
                    <Typography align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                        {props.title ? props.title : 'Untitled'}
                    </Typography>
                    {!hideHeader && (
                        <Typography align="center" style={{ fontSize: '1em' }} className={classes.nonOverflowText}>
                            {props.description}
                        </Typography>
                    )}
                    {!hideHeader && (
                        <Typography align="center" style={{ fontSize: '1em' }} className={classes.nonOverflowText}>
                            {props.ar ? 'Already Responded' : 'Not Yet Responded'}
                        </Typography>
                    )}
                </Box>
                <Divider />
                <Box className={classes.bottom}>
                    <Button
                        color="primary"
                        className={classes.action}
                        onClick={() => {
                            props.onClick ? props.onClick() : history.push(`/app/applications/polls/view/${user.getChapter()}/${props.id}`)
                        }}
                    >
                        View
                    </Button>
                </Box>
            </Box>
        )
    }

    if (type === 'menu') {
        return (
            <Box className={classes.root}>
                <Box className={classes.top}>
                    <Box className={classes.topTitle}>
                        {getAvatar(props.author.photo, props.author.id)}
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ fontWeight: 500, lineHeight: 1 }}>{`${props.author.first} ${props.author.last}`}</Typography>
                            <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                <MealsIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                {` • ${timeAgo(getDateFromTimestamp(props.cd))}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box className={classes.body}>
                    <Typography align="center" style={{ fontWeight: 500 }}>
                        Week of {format(startOfWeek(new Date()), 'MMMM do')}
                    </Typography>
                    <img src={props.photo} className={classes.menuImage} alt="" />
                </Box>
                <Divider />
                <Box className={classes.bottom}>
                    <Button
                        color="primary"
                        className={classes.action}
                        onClick={() => {
                            history.push('/app/applications/meals/')
                        }}
                    >
                        View
                    </Button>
                </Box>
            </Box>
        )
    }

    return <></>
}
