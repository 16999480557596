import React from 'react'
import { Grid, TextField, Paper, MenuItem } from '@material-ui/core'
import MuiPhoneNumber from 'material-ui-phone-number'

export default function EmergencyContactForm({ emergencyContact, setEmergencyContact, classes }) {
    const relationshipOptions = [
        'Parent',
        'Friend',
        'Spouse',
        'Significant Other',
        'Relative',
        'Other'
    ]

    const handleChange = (field, value) => {
        setEmergencyContact(prev => ({
            ...prev,
            [field]: value
        }))
    }

    return (
        <Paper 
            variant="outlined" 
            style={{ 
                padding: 24,
                borderRadius: 12,
                backgroundColor: 'transparent'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        label="Emergency Contact Name"
                        type="text"
                        inputProps={{
                            maxLength: 50,
                        }}
                        onChange={e => handleChange('name', e.target.value)}
                        value={emergencyContact?.name || ''}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Relationship"
                        onChange={e => handleChange('relationship', e.target.value)}
                        value={emergencyContact?.relationship || ''}
                        fullWidth
                    >
                        {relationshipOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <MuiPhoneNumber
                        variant="outlined"
                        defaultCountry={'us'}
                        margin="dense"
                        label="Phone"
                        type="text"
                        onChange={value => handleChange('phone', value)}
                        value={emergencyContact?.phone || ''}
                        fullWidth
                        className={classes?.phoneNumberField}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}