import React from 'react'

import { Box, FormControl, Grid, IconButton, InputLabel, Select, TextField } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { useTheme } from '@material-ui/core/styles'

import { getCoordinate } from 'code/Helper'

import { LocationContext } from 'context/LocationContext'

import Map from 'components/Map'
import MapCircle from 'components/Map/Circle'
import MapMarker from 'components/Map/Marker'

import Search from 'components/Map/Search'

import useStyles from '../styles'

const UpdateLocation = ({ location, onUpdate, onDelete, ...props }) => {
    const classes = useStyles()
    const theme = useTheme()

    const pointerRef = React.useRef()
    const circleRef = React.useRef()

    const { mapkit } = React.useContext(LocationContext)

    const center = getCoordinate(mapkit, location?.data?.coordinate || location.center)

    return (
        <Box style={{ position: 'relative', border: theme.border, borderRadius: 4, padding: 4 }}>
            <Grid container spacing={1} style={{ flexGrow: 1, paddingLeft: 2, paddingRight: 2 }}>
                <Grid item xs={12}>
                    <Map
                        center={center}
                        listeners={[
                            {
                                type: 'drag-end',
                                listener: e => {
                                    if (pointerRef.current && onUpdate) {
                                        onUpdate({ ...location, center: pointerRef.current.coordinate })

                                        if (circleRef.current) {
                                            circleRef.current.coordinate = pointerRef.current.coordinate
                                        }
                                    }
                                },
                            },
                        ]}
                        style={{
                            marginTop: -4,
                            marginLeft: -6,
                            marginRight: -6,
                            marginBottom: 0,
                            width: 'calc(100% + 12px)',
                        }}
                        className={classes.map}
                    >
                        <MapMarker
                            data={{
                                coordinate: center
                            }}
                            draggable
                            title={location.name}
                            ref={pointerRef}
                        />
                        <MapCircle
                            data={{
                                coordinate: center
                            }}
                            radius={location.radius}
                            ref={circleRef}
                        />
                    </Map>
                </Grid>
                <Grid item xs={12}>
                    <Search
                        variant="outlined"
                        margin="dense"
                        label="Search"
                        fullWidth
                        location={center}
                        onChange={(name, coordinate) => {
                            onUpdate({ ...location, name: name, center: coordinate ? coordinate : center })
                        }}
                        defaultValue={location.name}
                        value={location.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense" style={{ margin: 0 }}>
                        <InputLabel>Radius</InputLabel>
                        <Select
                            native
                            aria-label="Radius"
                            label="Radius"
                            value={location.radius}
                            onChange={e => {
                                onUpdate({ ...location, radius: parseInt(e.target.value) })
                            }}
                        >
                            <option value={50}>50 feet</option>
                            <option value={100}>100 feet</option>
                            <option value={200}>200 feet</option>
                            <option value={300}>300 feet</option>
                            <option value={5280 * 0.125}>1/8 mile</option>
                            <option value={5280 * 0.25}>1/4 mile</option>
                            <option value={5280 * 0.5}>1/2 mile</option>
                            <option value={5280}>1 mile</option>
                            <option value={5280 * 2}>2 miles</option>
                            <option value={5280 * 5}>5 miles</option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 8,
                        width: '100%',
                    }}
                >
                    {/*<TextField
                        variant="outlined"
                        margin="dense"
                        aria-label="Points"
                        type="number"
                        onChange={e => {
                            onUpdate({ ...location, points: { ...location.points, value: parseInt(e.target.value) } })
                        }}
                        value={location.points.value}
                        inputProps={{
                            maxLength: 4,
                        }}
                        style={{ margin: 0 }}
                        InputLabelProps={{ shrink: false }}
                        fullWidth
                    />*/}
                    <div style={{ minWidth: /*77*/ 90, textAlign: 'center' }}>{`${
                        location.points.value === 1 ? '1 point' : `${location.points.value} points`
                    } every`}</div>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        aria-label="Length"
                        type="number"
                        onChange={e => {
                            onUpdate({ ...location, points: { ...location.points, length: parseInt(e.target.value) } })
                        }}
                        value={location.points.length}
                        inputProps={{
                            maxLength: 4,
                        }}
                        style={{ margin: 0 }}
                        InputLabelProps={{ shrink: false }}
                        fullWidth
                    />
                    <FormControl fullWidth variant="outlined" margin="dense" style={{ margin: 0, minWidth: 112 }}>
                        <Select
                            native
                            value={location.points.lengthUnits}
                            onChange={e => {
                                onUpdate({ ...location, points: { ...location.points, lengthUnits: e.target.value } })
                            }}
                        >
                            <option value="minutes">{location.points.length === 1 ? 'minute' : 'minutes'}</option>
                            <option value="hours">{location.points.length === 1 ? 'hour' : 'hours'}</option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense" style={{ margin: 0 }}>
                        <Select
                            native
                            value={location.points.round}
                            onChange={e => {
                                onUpdate({ ...location, points: { ...location.points, round: parseInt(e.target.value) } })
                            }}
                        >
                            <option value={-1}>rounded down</option>
                            <option value={0}>rounded</option>
                            <option value={1}>rounded up</option>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box
                style={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <IconButton size="small" onClick={onDelete}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
}

export default UpdateLocation
