import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Paper,
  Box,
  Chip,
  Button,
  Tab,
  Tabs,
  CircularProgress,
//  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
//  ListItemAvatar,
  Avatar,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  LocationOn as LocationIcon,
  Mail as MailIcon,
  School as SchoolIcon,
  Phone as PhoneIcon,
  Group as GroupIcon,
  Event as EventIcon,
  History as HistoryIcon,
  Person as PersonIcon,
//  Assessment as AssessmentIcon,
  Assignment as AssignmentIcon,
  Star as StarIcon,
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { format } from 'date-fns';

import NavigationBar from 'components/NavigationBar';
import Widget from 'components/Widget';
// import { AuthContext } from 'context/AuthContext';
// import { FraternityContext } from 'context/FraternityContext';
import app from 'firebase/app';

import useStyles from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`chapter-tabpanel-${index}`}
      aria-labelledby={`chapter-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function ChapterDetail() {
  const classes = useStyles();
  const { chapterId } = useParams();
  const history = useHistory();
  console.log("ChapterDetail loaded with ID:", chapterId); // Debug statement
  
  const [chapter, setChapter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [members, setMembers] = useState([]);
  const [events, setEvents] = useState([]);
  
  // Fetch chapter data
  useEffect(() => {
    const fetchChapter = async () => {
      try {
        if (!chapterId) {
          console.error("No chapterId provided");
          setError('Chapter ID is missing');
          setLoading(false);
          return;
        }
        
        console.log("Fetching chapter with ID:", chapterId);
        setLoading(true);
        const db = app.firestore();
        const doc = await db.collection('chapters').doc(chapterId).get();
        
        if (doc.exists) {
          const chapterData = doc.data();
          setChapter(chapterData);
          
          // Convert members object to array if exists
          if (chapterData.members) {
            const memberArray = Object.keys(chapterData.members).map(key => ({
              id: key,
              ...chapterData.members[key]
            }));
            setMembers(memberArray);
          }
          
          // Convert events object to array if exists
          if (chapterData.events) {
            const eventsArray = Object.keys(chapterData.events).map(key => ({
              id: key,
              ...chapterData.events[key]
            }));
            setEvents(eventsArray);
          }
        } else {
          setError('Chapter not found');
        }
      } catch (err) {
        console.error('Error fetching chapter:', err);
        setError('Failed to load chapter data');
      } finally {
        setLoading(false);
      }
    };
    
    fetchChapter();
  }, [chapterId]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  // Navigate back to chapters dashboard
  const handleBack = () => {
    history.push('/app/hq/chapters-dashboard');
  };
  
  // Member status data for pie chart
  const memberStatusData = [
    { name: 'Active', value: members.filter(m => m.status === 'active' || m.status === 1).length, color: '#4caf50' },
    { name: 'Inactive', value: members.filter(m => m.status === 'inactive' || m.status === 2).length, color: '#ff9800' },
    { name: 'Alumni', value: members.filter(m => m.status === 'alumni' || m.status === 4).length, color: '#2196f3' },
    { name: 'Associate', value: members.filter(m => m.status === 'associate' || m.status === 0).length, color: '#9c27b0' },
  ].filter(item => item.value > 0);
  
  if (loading) {
    return (
      <>
        <NavigationBar title="Chapter Details" />
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      </>
    );
  }
  
  if (error) {
    return (
      <>
        <NavigationBar title="Chapter Details" />
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh" flexDirection="column">
          <Typography variant="h6" color="error" gutterBottom>{error}</Typography>
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
          >
            Back to Chapters
          </Button>
        </Box>
      </>
    );
  }
  
  return (
    <>
      <NavigationBar 
        title={chapter?.fullName || 'Chapter Details'} 
        backPath="/app/hq/chapters-dashboard"
      />
      <Grid container spacing={3} className={classes.container}>
        {/* Chapter Header Card */}
        <Grid item xs={12}>
          <Paper className={classes.headerCard}>
            <Box p={3}>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <Box>
                  <Typography variant="h4" component="h1">
                    {chapter?.fullName} 
                    {chapter?.letters && ` (${chapter?.letters})`}
                  </Typography>
                  {chapter?.founded && (
                    <Typography variant="subtitle1" color="textSecondary">
                      Founded: {chapter?.founded}
                    </Typography>
                  )}
                </Box>
                <Box>
                  {chapter?.status === 'invited' && (
                    <Chip label="Invited" color="primary" />
                  )}
                  {chapter?.status === 'inactive' && (
                    <Chip label="Inactive" color="default" />
                  )}
                  {chapter?.status === 'active' && (
                    <Chip label="Active" color="secondary" />
                  )}
                </Box>
              </Box>
              
              <Grid container spacing={2}>
                {chapter?.school && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" alignItems="center">
                      <SchoolIcon className={classes.infoIcon} />
                      <Typography variant="body1">{chapter.school}</Typography>
                    </Box>
                  </Grid>
                )}
                
                {chapter?.city && chapter?.state && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" alignItems="center">
                      <LocationIcon className={classes.infoIcon} />
                      <Typography variant="body1">
                        {chapter.city}, {chapter.state} {chapter.zipCode && chapter.zipCode}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                
                {(chapter?.contactEmail || (chapter?.president && chapter?.president.email)) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" alignItems="center">
                      <MailIcon className={classes.infoIcon} />
                      <Typography variant="body1">
                        {chapter.contactEmail || (chapter.president && chapter.president.email)}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                
                <Grid item xs={12} sm={6} md={3}>
                  <Box display="flex" alignItems="center">
                    <GroupIcon className={classes.infoIcon} />
                    <Typography variant="body1">
                      {members.length || (chapter?.members ? Object.keys(chapter.members).length : 0)} members
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>

        {/* Tab Navigation */}
        <Grid item xs={12}>
          <Paper>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Overview" />
              <Tab label="Members" />
              <Tab label="Events" />
              <Tab label="Statistics" />
            </Tabs>
            
            {/* Overview Tab */}
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Widget title="Chapter Information">
                    <List>
                      {chapter?.description && (
                        <ListItem>
                          <ListItemText 
                            primary="About"
                            secondary={chapter.description}
                          />
                        </ListItem>
                      )}
                      
                      {chapter?.advisor && (
                        <ListItem>
                          <ListItemIcon>
                            <PersonIcon />
                          </ListItemIcon>
                          <ListItemText 
                            primary="Chapter Advisor"
                            secondary={chapter.advisor.name || chapter.advisor}
                          />
                        </ListItem>
                      )}
                      
                      {chapter?.president && (
                        <ListItem>
                          <ListItemIcon>
                            <StarIcon />
                          </ListItemIcon>
                          <ListItemText 
                            primary="Chapter President"
                            secondary={chapter.president.name || chapter.president}
                          />
                        </ListItem>
                      )}
                      
                      {chapter?.website && (
                        <ListItem button component="a" href={chapter.website} target="_blank" rel="noopener noreferrer">
                          <ListItemIcon>
                            <AssignmentIcon />
                          </ListItemIcon>
                          <ListItemText 
                            primary="Chapter Website"
                            secondary={chapter.website}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Widget>
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <Widget title="Membership Breakdown">
                    <Box height={300} display="flex" flexDirection="column" justifyContent="center">
                      {memberStatusData.length > 0 ? (
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={memberStatusData}
                              cx="50%"
                              cy="50%"
                              innerRadius={60}
                              outerRadius={80}
                              paddingAngle={5}
                              dataKey="value"
                              label={({name, percent}) => `${name}: ${(percent * 100).toFixed(0)}%`}
                            >
                              {memberStatusData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                              ))}
                            </Pie>
                            <Tooltip formatter={(value) => [`${value} members`, 'Count']} />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                          <Typography variant="body1" color="textSecondary">No member data available</Typography>
                        </Box>
                      )}
                    </Box>
                  </Widget>
                </Grid>
              </Grid>
            </TabPanel>
            
            {/* Members Tab */}
            <TabPanel value={tabValue} index={1}>
              <Widget title={`Members (${members.length})`}>
                {members.length > 0 ? (
                  <Grid container spacing={2}>
                    {members.map(member => (
                      <Grid item xs={12} sm={6} md={4} key={member.id}>
                        <Card>
                          <CardContent>
                            <Box display="flex" alignItems="center" mb={1}>
                              <Avatar className={classes.memberAvatar}>
                                {member.firstName?.charAt(0) || member.name?.charAt(0) || 'M'}
                              </Avatar>
                              <Box ml={2}>
                                <Typography variant="h6">
                                  {member.firstName && member.lastName 
                                    ? `${member.firstName} ${member.lastName}` 
                                    : member.name || 'Unknown Member'}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {typeof member.status === 'number' 
                                    ? ['Associate', 'Active', 'Inactive', 'Officer', 'Alumni'][member.status] || 'Unknown'
                                    : member.status || 'Unknown'
                                  }
                                </Typography>
                              </Box>
                            </Box>
                            {member.email && (
                              <Box display="flex" alignItems="center" mt={1}>
                                <MailIcon fontSize="small" className={classes.memberInfoIcon} />
                                <Typography variant="body2">{member.email}</Typography>
                              </Box>
                            )}
                            {member.phone && (
                              <Box display="flex" alignItems="center" mt={1}>
                                <PhoneIcon fontSize="small" className={classes.memberInfoIcon} />
                                <Typography variant="body2">{member.phone}</Typography>
                              </Box>
                            )}
                            {member.joinDate && (
                              <Box display="flex" alignItems="center" mt={1}>
                                <HistoryIcon fontSize="small" className={classes.memberInfoIcon} />
                                <Typography variant="body2">
                                  Joined: {typeof member.joinDate === 'object' 
                                    ? format(member.joinDate.toDate(), 'MMM yyyy')
                                    : member.joinDate}
                                </Typography>
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                    <Typography variant="body1" color="textSecondary">No member information available</Typography>
                  </Box>
                )}
              </Widget>
            </TabPanel>
            
            {/* Events Tab */}
            <TabPanel value={tabValue} index={2}>
              <Widget title={`Events (${events.length})`}>
                {events.length > 0 ? (
                  <List>
                    {events.map(event => (
                      <ListItem key={event.id} divider>
                        <ListItemIcon>
                          <EventIcon />
                        </ListItemIcon>
                        <ListItemText 
                          primary={event.title || event.name} 
                          secondary={
                            <>
                              {event?.date && (
                                <Typography component="span" variant="body2" color="textPrimary">
                                  {typeof event.date === 'object' 
                                    ? (typeof event.date.toDate === 'function'
                                        ? format(event.date.toDate(), 'MMMM dd, yyyy h:mm a')
                                        : (event.date instanceof Date 
                                            ? format(event.date, 'MMMM dd, yyyy h:mm a')
                                            : String(event.date)))
                                    : event.date}
                                </Typography>
                              )}
                              {event.location && (
                                <Typography component="span" variant="body2" display="block">
                                  {event.location}
                                </Typography>
                              )}
                              {event.description && (
                                <Typography component="span" variant="body2">
                                  {event.description}
                                </Typography>
                              )}
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                    <Typography variant="body1" color="textSecondary">No events available</Typography>
                  </Box>
                )}
              </Widget>
            </TabPanel>
            
            {/* Statistics Tab */}
            <TabPanel value={tabValue} index={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Widget title="Member Statistics">
                    <List>
                      <ListItem>
                        <ListItemIcon><GroupIcon /></ListItemIcon>
                        <ListItemText 
                          primary="Total Members" 
                          secondary={members.length || (chapter?.members ? Object.keys(chapter.members).length : 0)}
                        />
                      </ListItem>
                      
                      <ListItem>
                        <ListItemIcon><StarIcon /></ListItemIcon>
                        <ListItemText 
                          primary="Officers" 
                          secondary={members.filter(m => m.status === 'officer' || m.status === 3).length}
                        />
                      </ListItem>
                      
                      <ListItem>
                        <ListItemIcon><HistoryIcon /></ListItemIcon>
                        <ListItemText 
                          primary="Alumni" 
                          secondary={members.filter(m => m.status === 'alumni' || m.status === 4).length}
                        />
                      </ListItem>
                    </List>
                  </Widget>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Widget title="Chapter Activity">
                    <List>
                      <ListItem>
                        <ListItemIcon><EventIcon /></ListItemIcon>
                        <ListItemText 
                          primary="Total Events" 
                          secondary={events.length || (chapter?.events ? Object.keys(chapter.events).length : 0)}
                        />
                      </ListItem>
                      
                      {chapter?.createdAt && (
                        <ListItem>
                          <ListItemIcon><HistoryIcon /></ListItemIcon>
                          <ListItemText 
                            primary="Chapter Created" 
                            secondary={
                              typeof chapter.createdAt === 'object' 
                                ? format(chapter.createdAt.toDate(), 'MMMM dd, yyyy')
                                : chapter.createdAt
                            }
                          />
                        </ListItem>
                      )}
                    </List>
                  </Widget>
                </Grid>
              </Grid>
            </TabPanel>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Button 
              variant="contained"
              color="default"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Back to Chapters
            </Button>
            
            <Button 
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => history.push('/app/hq/chapters-dashboard')}
            >
              Edit Chapter
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
} 