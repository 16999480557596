import { db, functions } from 'config/firebase'
import { Box, Grid, TextField, InputAdornment } from '@material-ui/core'
import {
    Settings as AdminIcon,
    CloudUploadOutlined as BulkUploadIcon,
    DeleteOutline,
    CloudDownloadOutlined as DownloadIcon,
    CreateOutlined as EditOutline,
    HomeOutlined as HomeIcon,
    Search as SearchIcon,
    Person as PersonIcon,
} from '@material-ui/icons'
import Papa from 'papaparse'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import './MemberManagement.css'
import moment from 'moment'
import { isMobileDevice } from 'code/Helper'
import { ShareIosIcon } from 'components/Icons'
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'

import { convertToSelect, visibilityNameOptions, visibilityOptions } from 'data/Visibility'
import ParentTable from 'components/Tables/ParentTable'
import Table from 'components/Tables/Table'

import { Link as LinkHeader, Progress as ProgressHeader } from 'components/Tables/TableHeader'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import DeleteMemberDialog from './DeleteMemberDialog'
import JoinableLinkDialog from './JoinableLinkDialog'
import CSVUploadDialog from 'components/CSVUploadDialog'
const sampleCsvFile = process.env.PUBLIC_URL + '/static/csv/example_member_csv.csv'

export default function MemberManagement(props) {
    const chapterID = props.match.params.chapterID

    //const { fraternity } = React.useContext(FraternityContext)
    const { chapter, requestChapterUpdate } = useContext(ChapterContext)
    const { user } = useContext(AuthContext)

    const [data, setData] = useState(null)
    const [invitedMembers, setInvitedMembers] = useState(null)
    const [members, setMembers] = useState(null)

    const [open, setOpen] = useState(false)
    const [dialogError, setDialogError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [deleteMemberDialog, setDeleteMemberDialog] = useState({ open: false, type: null, id: null, name: null })

    const [roles, setRoles] = useState({})

    const statuses = convertToSelect(visibilityOptions(true).slice(0, 6))

    const [memberSearch, setMemberSearch] = useState('')
    const [invitedMemberSearch, setInvitedMemberSearch] = useState('')

    const [active, setActive] = useState(members ? members : [])
    const [userHasInteracted, setUserHasInteracted] = useState(false)

    const getChapterID = useCallback(() => {
        if (user && user.getChapter()) {
            return user.getChapter()
        }

        if (user && (user.isHeadquartersStaff() || user.isGCStaff()) && chapterID) {
            return chapterID
        }

        return ''
    }, [user, chapterID])

    const curChapterId = getChapterID()

    const [joinableLinkDialog, setJoinableLinkDialog] = useState({
        open: false,
        enabled: chapter && chapter.joinLink && chapter.joinLink.enabled,
        loading: false,
        available: true,
        code:
            user && user.getChapter() === curChapterId && chapter && chapter.joinLink && chapter.joinLink.code
                ? chapter.joinLink.code
                : curChapterId.slice(0, 8).toUpperCase(),
    })

    const [curChapterData, setCurChapterData] = useState(user && user.getChapter() ? chapter : null)

    useEffect(() => {
        if (user && (user.isHeadquartersStaff() || user.isGCStaff()) && curChapterId) {
            // todo: get chapter and and then set the chapter data to it...
            db.collection('chapters')
                .doc(curChapterId)
                .get()
                .then(function(doc) {
                    const data = doc.data()
                    setCurChapterData(data)

                    setJoinableLinkDialog(dialog => ({
                        ...dialog,
                        available: !!data && !!data.joinLink,
                        code: data && data.joinLink && data.joinLink.code ? data.joinLink.code : curChapterId.slice(0, 8).toUpperCase(),
                    }))
                })
                .catch(function(error) {
                    console.log('Error getting document: ', error)
                })
        }
    }, [curChapterId, user])

    function deleteMember(memberID, isActive) {
        if (isActive) {
            let aMembers = []
            for (let i = 0; i < active.length; i++) {
                let mem = active[i]
                if (mem.id === memberID) {
                    mem.updating = true
                }

                aMembers.push(mem)
            }
            setActive(aMembers)
        } else {
            let iMembers = []
            for (let i = 0; i < invitedMembers.length; i++) {
                let mem = invitedMembers[i]
                if (mem.code === memberID) {
                    mem.updating = true
                }

                iMembers.push(mem)
            }
            setInvitedMembers(iMembers)
        }

        var removeMember = functions.httpsCallable('deleteMember')
        removeMember({ chapter: curChapterId, memberID: memberID })
            .then(function(result) {
                if (isActive) {
                    let aMembers = []
                    for (let i = 0; i < active.length; i++) {
                        let mem = active[i]
                        if (mem.id !== memberID) {
                            aMembers.push(mem)
                        }
                    }
                    setActive(aMembers)
                } else {
                    let iMembers = []
                    for (let i = 0; i < invitedMembers.length; i++) {
                        let mem = invitedMembers[i]
                        if (mem.code !== memberID) {
                            iMembers.push(mem)
                        }
                    }
                    setInvitedMembers(iMembers)
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                console.log('AN ERROR OCCURRED', code, message)

                if (isActive && data !== null) {
                    let aMembers = []
                    for (let i = 0; i < active.length; i++) {
                        let mem = active[i]
                        if (mem.id === data.id) {
                            mem.updating = false
                            mem.error = message
                        }

                        aMembers.push(mem)
                    }
                    setActive(aMembers)

                    setTimeout(function() {
                        let aMembers = []
                        for (let i = 0; i < active.length; i++) {
                            let mem = active[i]
                            if (mem.id === data.id) {
                                mem.error = null
                            }

                            aMembers.push(mem)
                        }
                        setActive(aMembers)
                    }, 3000)
                } else {
                    let iMembers = []
                    for (let i = 0; i < invitedMembers.length; i++) {
                        let mem = invitedMembers[i]
                        if (mem.code === memberID) {
                            mem.updating = false
                            mem.error = message
                        }

                        iMembers.push(mem)
                    }
                    setInvitedMembers(iMembers)

                    setTimeout(function() {
                        let iMembers = []
                        for (let i = 0; i < invitedMembers.length; i++) {
                            let mem = invitedMembers[i]
                            if (mem.code === memberID) {
                                mem.error = null
                            }

                            iMembers.push(mem)
                        }
                        setInvitedMembers(iMembers)
                    }, 3000)
                }
            })
    }

    async function parseFile(parseFile) {
        if (parseFile) {
            Papa.parse(parseFile, {
                header: true,
                worker: true, // Don't bog down the main thread if its a big file
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: function(results, file) {
                    let data = results.data
                    let dataIsValid = true
                    for (let i = 0; i < data.length; i++) {
                        let user = data[i]
                        if (!user.First || !user.Last || !user.Status) {
                            dataIsValid = false
                        }

                        user.Status = visibilityNameOptions.map(a => a.toLowerCase()).indexOf(user.Status.toLowerCase())

                        if (user.Status === -1) {
                            dataIsValid = false
                        }

                        if (user.Role !== null) {
                            user.Role = String(user.Role)

                            if (user.Role !== 'ADMIN') {
                                let foundRole = null

                                for (let roleID in roles) {
                                    if (roles[roleID] && roles[roleID].title && roles[roleID].title === user.Role) {
                                        foundRole = roleID
                                    }
                                }

                                if (foundRole) {
                                    user.Role = foundRole
                                } else {
                                    delete user.Role
                                }
                            }
                        }

                        data[i] = user
                    }

                    if (dataIsValid) {
                        if (data.length === 0) {
                            setDialogError("File doesn't have any users to import, make sure it is not blank and that there is a header.")
                        } else {
                            setData(data)
                        }
                    } else {
                        setData(null)
                        setDialogError(
                            'Missing fields. Please make sure you have the required "First" and "Last" fields filled for each user, and the heading at the top. For help, download the template CSV or the sample filled CSV.',
                        )
                    }
                },
                error: function(error, file) {
                    setData(null)
                    setDialogError(
                        'Unable to read CSV file. Please make sure you fill out all required fields for each person and that you have the required headers.',
                    )
                },
            })
        } else {
            setData(null)
            setDialogError('Must submit CSV file to add members')
        }
    }

    if (!invitedMembers) {
        setInvitedMembers([])
        setMembers([])
        setIsLoading(true)

        var getMembers = functions.httpsCallable('getAllMembers')
        getMembers({ chapter: curChapterId })
            .then(function(result) {
                let roles = result.data.roles

                let memberMap = result.data.members
                let members = []
                let memberKeys = Object.keys(memberMap)

                for (let i = 0; i < memberKeys.length; i++) {
                    let member = memberMap[memberKeys[i]]
                    member.id = memberKeys[i]
                    member.enabled = !member.disabled
                    delete member.disabled

                    members.push(member)
                }

                let roleKeys = Object.keys(roles)
                let foundAdminElsewhere = false

                for (let i = 0; i < roleKeys.length; i++) {
                    if (roles[roleKeys[i]].default) {
                        foundAdminElsewhere = true
                        i = roleKeys.length
                    }
                }

                roles['ADMIN'] = { title: 'Admin', default: foundAdminElsewhere === false }

                setRoles(roles)
                setMembers(members)

                let iMembers = result.data.invitedMembers

                for (let i = 0; i < iMembers.length; i++) {
                    let status = iMembers[i].status

                    if ('' + status in statuses) {
                        iMembers[i].status = statuses['' + status].title
                    }
                }

                setInvitedMembers(iMembers)
                setActive(members)

                setIsLoading(false)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
            })
    }

    function viewProfile(memberID) {
        if (userHasInteracted && memberID) {
            props.history.push('/app/profile/' + memberID)
        }
    }

    function updateMember(data) {
        let chapterID = curChapterId

        let submission = {
            chapter: chapterID,
            first: data.first,
            last: data.last,
            role: data.role,
            memberID: data.id,
            status: parseInt(data.status),
            email: data.email,
            disabled: data.enabled === false,
        }

        if (isNaN(submission.status)) {
            submission.status = visibilityNameOptions.map(a => a.toLowerCase()).indexOf(data.status.toLowerCase())
        }

        if (data.roll) {
            submission.roll = parseInt(data.roll)
        }

        var functionsCall = functions.httpsCallable('updateMember')
        functionsCall(submission)
            .then(function(result) {
                if (data !== null) {
                    let aMembers = []
                    for (let i = 0; i < active.length; i++) {
                        let mem = active[i]
                        if (mem.id === data.id) {
                            mem.updating = false
                            mem.first = data.first
                            mem.last = data.last
                            mem.role = data.role
                            mem.enabled = data.enabled === true
                            mem.email = data.email

                            if (data.roll) {
                                mem.roll = data.roll
                            } else if (mem.roll) {
                                delete mem.roll
                            }

                            mem.status = visibilityNameOptions[data.status % visibilityNameOptions.length]
                        }

                        aMembers.push(mem)
                    }
                    setActive(aMembers)
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)

                let aMembers = []
                for (let i = 0; i < active.length; i++) {
                    let mem = active[i]
                    if (mem.id === data.id) {
                        mem.updating = false
                        mem.first = data.first
                        mem.last = data.last
                        mem.roll = data.roll
                        mem.role = data.role
                        mem.error = message
                    }

                    aMembers.push(mem)
                }
                setActive(aMembers)

                setTimeout(function() {
                    //Start the timer
                    let aMembers = []
                    for (let i = 0; i < active.length; i++) {
                        let mem = active[i]
                        if (mem.id === data.id) {
                            mem.error = null
                        }

                        aMembers.push(mem)
                    }
                    setActive(aMembers)
                }, 3000)
            })
    }

    function addMembers() {
        setIsLoading(true)

        let submission = {
            chapter: curChapterId,
            invitedMembers: data,
        }

        if (!canInviteMemberCount(data.length)) {
            setIsLoading(false)
            setData(null)
            setDialogError("You cannot invite this many members due to your chapter's size! Talk to your chapter's representative to upgrade")
            return
        }

        var inviteMembers = functions.httpsCallable('inviteAccounts')
        inviteMembers(submission)
            .then(function(result) {
                let iMembers = result.data.users

                for (let i = 0; i < iMembers.length; i++) {
                    let status = iMembers[i].status

                    if ('' + status in statuses) {
                        iMembers[i].status = statuses['' + status].title
                    }
                }

                setInvitedMembers(iMembers)
                setOpen(false)
                setIsLoading(false)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoading(false)
                setData(null)
                setDialogError(message)
            })
    }

    function addMember(member) {
        setIsLoading(true)

        let memStatus = parseInt(member.status)

        if (isNaN(memStatus)) {
            member.status = visibilityNameOptions.map(a => a.toLowerCase()).indexOf(member.status.toLowerCase())
        } else {
            member.status = memStatus
        }

        if (member.status === -1) {
            member.status = 1
        }

        let newMem = {
            First: member.first,
            Last: member.last,
            Roll: member.roll,
            Email: member.email,
            Status: member.status,
            Role: member.role,
        }

        let mems = [newMem]

        let submission = {
            chapter: curChapterId,
            invitedMembers: mems,
        }

        var inviteMembers = functions.httpsCallable('inviteAccounts')
        inviteMembers(submission)
            .then(function(result) {
                let iMembers = result.data.users

                for (let i = 0; i < iMembers.length; i++) {
                    let status = iMembers[i].status

                    if ('' + status in statuses) {
                        iMembers[i].status = statuses['' + status].title
                    }
                }

                setInvitedMembers(iMembers)
                setOpen(false)
                setIsLoading(false)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoading(false)
                setData(null)
                setDialogError(message)
                alert(message)
            })
    }

    const exportMemberRoster = () => {
        const exportFile = (text, name, filetype = 'text/csv') => {
            const element = document.createElement('a')
            const file = new File([text], name, { type: filetype })
            const blob = new Blob([text], {
                type: filetype,
            })

            const url = URL.createObjectURL(blob)

            if (isMobileDevice() && navigator.canShare && navigator.canShare({ files: [file] })) {
                navigator.share({
                    files: [file],
                })
            } else {
                element.href = url
                element.download = name
                document.body.appendChild(element) // Required for this to work in FireFox
                element.click()
            }
        }

        if (members) {
            const includeRoll = curChapterData && curChapterData.settings && curChapterData.settings.roll
            const mems = members
            let str = `${includeRoll ? 'Roll,' : ''}First,Last,Email,Role,Status`

            for (let i = 0; i < mems.length; i++) {
                let mem = mems[i]

                if (mem !== null) {
                    let email = mem.email

                    let role = chapter.members[mem.id].role

                    if (chapter.roles[role] && chapter.roles[role].title) {
                        role = chapter.roles[role].title
                    }

                    if (!email) {
                        email = chapter.members[mem.id].email
                    }

                    str += '\n'

                    if (includeRoll) {
                        str += mem.roll + ','
                    }

                    str += mem.first
                    str += ',' + mem.last
                    str += ',' + (email ? email : '')
                    str += ',' + (role ? role : '')

                    const memStatus = parseInt(mem.status)
                    if (!isNaN(memStatus) && memStatus >= 0 && memStatus < visibilityNameOptions.length) {
                        str += ',' + visibilityNameOptions[memStatus]
                    }
                }
            }
            exportFile(str, 'member_roster_' + moment().format('YYYY-MM-DD') + '.csv')
        }
    }

    const canInviteMemberCount = memCount => {
        if (!memCount || typeof memCount !== 'number') {
            return false
        }

        if (
            Array.isArray(active) &&
            Array.isArray(invitedMembers) &&
            curChapterData?.settings?.maxMembers &&
            curChapterData.settings.maxMembers !== 'unlimited'
        ) {
            const max = curChapterData.settings.maxMembers - active.length

            if (max <= 0) {
                return false
            }

            return memCount <= max
        }

        return true
    }

    const getInvitedMembersRows = () => {
        const joinLinkHeader = (
            <LinkHeader
                title={`Joinable Link: ${joinableLinkDialog.enabled ? 'On' : 'Off'}`}
                icon={<ShareIosIcon />}
                onClick={() => {
                    setJoinableLinkDialog(dialog => ({ ...dialog, open: true }))
                }}
                disabled={!curChapterData}
            />
        )

        if (joinableLinkDialog.available) {
            return [joinLinkHeader]
        }

        return []
    }

    const isChapterFull = useCallback(() => {
        if (Array.isArray(active)) {
            if (curChapterData?.settings?.maxMembers) {
                return curChapterData.settings.maxMembers === 'unlimited' || parseInt(curChapterData.settings.maxMembers) < active.length
            }
        }

        return false
    }, [curChapterData, active])

    const getMemberRows = () => {
        if (
            Array.isArray(active) &&
            active.length > 0 &&
            curChapterData &&
            curChapterData.settings &&
            curChapterData.settings.maxMembers &&
            curChapterData.settings.maxMembers !== 'unlimited'
        ) {
            const num = active.length
            const max = parseInt(curChapterData.settings.maxMembers)
            let val = (num / max) * 100

            if (val >= 100) {
                val = 100
            }

            return [
                <ProgressHeader
                    percentage={val}
                    displayValue={num + ' / ' + max + ' (' + Number(val.toFixed(2)) + '%)'}
                    description="Based on the your plan's maximum number of members. Contact your chapter's representative to increase this cap."
                />,
            ]
        }

        return []
    }

    const getInvitedMembersColumns = () => {
        let tabs = []

        if (curChapterData && curChapterData.settings && curChapterData.settings.roll) {
            tabs.push({
                title: 'Roll',
                value: 'roll',
                size: 'small',
                type: 'NUMBER',
                sortable: true,
            })
        }

        tabs = [
            ...tabs,
            {
                title: 'First',
                value: 'first',
                required: true,
                sortable: true,
            },
            {
                title: 'Last',
                value: 'last',
                required: true,
                sortable: true,
            },
            {
                title: 'Email',
                value: 'email',
                size: 'large',
                sortable: true,
            },
            {
                title: 'Role',
                value: 'role',
                type: 'SELECT',
                options: roles,
            },
            {
                title: 'Visibility',
                value: 'status',
                type: 'SELECT',
                options: statuses,
                required: true,
            },
            {
                title: 'Access Code',
                value: 'code',
                size: 'large',
                disabled: true,
            },
        ]

        return tabs
    }

    const getMembersColumns = () => {
        const tabs =
            curChapterData && curChapterData.settings && curChapterData.settings.roll
                ? [
                      {
                          title: 'Roll',
                          value: 'roll',
                          size: 'small',
                          type: 'NUMBER',
                          sortable: true,
                      },
                  ]
                : []

        return [
            ...tabs,
            {
                title: 'First',
                value: 'first',
                required: true,
                sortable: true,
            },
            {
                title: 'Last',
                value: 'last',
                required: true,
                sortable: true,
            },
            {
                title: 'Enabled',
                value: 'enabled',
                type: 'BOOLEAN',
                size: 'small',
            },
            {
                title: 'Role',
                value: 'role',
                type: 'SELECT',
                options: roles,
                required: true,
            },
            {
                title: 'Visibility',
                value: 'status',
                type: 'SELECT',
                options: statuses,
                required: true,
            },
            {
                title: 'Email',
                value: 'email',
                size: 'large',
                required: true,
                sortable: true,
            },
        ]
    }

    const triggerLinkUpdate = async () => {
        setJoinableLinkDialog(dialog => ({ ...dialog, loading: true }))

        const chapterID = curChapterId

        const updatedVal = !joinableLinkDialog.enabled
        let chapterRef = db.collection('chapters').doc(chapterID)

        let chapterDoc = await chapterRef.get()

        if (chapterDoc.exists) {
            let chapterData = chapterDoc.data()

            if (chapterData.joinLink && chapterData.joinLink.code) {
                chapterRef
                    .update({ [`joinLink.enabled`]: updatedVal })
                    .then(function(doc) {
                        setJoinableLinkDialog(dialog => ({ ...dialog, enabled: updatedVal, loading: false }))
                    })
                    .catch(function(error) {
                        console.log('Error getting document: ', error)
                        setJoinableLinkDialog(dialog => ({ ...dialog, enabled: !dialog.enabled, loading: false }))
                    })
            } else {
                chapterRef
                    .update({ joinLink: { enabled: updatedVal, code: joinableLinkDialog.code } })
                    .then(function(doc) {
                        setJoinableLinkDialog(dialog => ({ ...dialog, enabled: updatedVal, loading: false }))
                    })
                    .catch(function(error) {
                        console.log('Error getting document: ', error)
                        setJoinableLinkDialog(dialog => ({ ...dialog, enabled: !dialog.enabled, loading: false }))
                    })
            }

            requestChapterUpdate()
        }
    }

    const filterData = (data, searchTerm) => {
        if (!searchTerm) return data
        return data.filter(item => {
            const searchString = searchTerm.toLowerCase()
            return (
                (item.first && item.first.toLowerCase().includes(searchString)) ||
                (item.last && item.last.toLowerCase().includes(searchString)) ||
                (item.email && item.email.toLowerCase().includes(searchString)) ||
                (item.roll && item.roll.toString().includes(searchString))
            )
        })
    }

    const getInvitedMembersTable = () => (
        <ParentTable
            title="Invited Members"
            actions={[
                {
                    title: 'Bulk Invite Members',
                    icon: <BulkUploadIcon />,
                    onClick: event => {
                        setDialogError(null)
                        setData(null)
                        setOpen(true)
                    },
                    disabled: isChapterFull(),
                },
            ]}
            rows={[
                <Box 
                    key="search"
                    paddingLeft={2} 
                    paddingTop={2}
                    sx={{
                        width: '95%',
                        padding: theme => theme.spacing(2),
                        paddingBottom: 0,
                        marginBottom: theme => theme.spacing(2)
                    }}
                >
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Search invited members..."
                        value={invitedMemberSearch}
                        onChange={(e) => setInvitedMemberSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'text.secondary' }} />
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: theme => theme.palette.background.paper,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme => theme.palette.divider,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme => theme.palette.primary.main,
                                },
                                maxWidth: { sm: '300px', xs: '100%' },
                                marginLeft: 'auto',
                                marginRight: theme => theme.spacing(2),
                            }
                        }}
                    />
                </Box>,
                ...getInvitedMembersRows()
            ]}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Table
                        id="code"
                        columns={getInvitedMembersColumns()}
                        rowActions={[
                            {
                                title: 'Delete Invite',
                                icon: <DeleteOutline />,
                                onClick: rowData => {
                                    setDeleteMemberDialog(dialog => ({
                                        ...dialog,
                                        open: true,
                                        type: 'invite',
                                        id: rowData.code,
                                        name: `${rowData.first} ${rowData.last}`,
                                    }))
                                },
                            },
                        ]}
                        onAdd={
                            canInviteMemberCount(1)
                                ? member => {
                                      addMember(member)
                                  }
                                : null
                        }
                        addRowText="Invite Member"
                        disableAddRow={isChapterFull()}
                        showOnEmpty={true}
                        isLoading={isLoading}
                        emptyText="No Invites"
                        data={filterData(invitedMembers || [], invitedMemberSearch)}
                        defaultSortable={
                            curChapterData && curChapterData.settings && curChapterData.settings.roll
                                ? {
                                      value: 'roll',
                                      dir: 'desc',
                                  }
                                : {
                                      value: 'last',
                                      dir: 'asc',
                                  }
                        }
                    />
                </Grid>
            </Grid>
        </ParentTable>
    )

    const getMembersTable = () => (
        <ParentTable
            title="Members"
            rows={[
                <Box 
                    key="search"
                    paddingLeft={2}
                    paddingTop={2} 
                    sx={{
                        width: '95%',
                        padding: theme => theme.spacing(2),
                        paddingBottom: 0,
                        marginBottom: theme => theme.spacing(2)
                    }}
                >
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Search members..."
                        value={memberSearch}
                        onChange={(e) => setMemberSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'text.secondary' }} />
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: theme => theme.palette.background.paper,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme => theme.palette.divider,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme => theme.palette.primary.main,
                                },
                                maxWidth: { sm: '300px', xs: '100%' },
                                marginLeft: 'auto',
                                marginRight: theme => theme.spacing(2),
                            }
                        }}
                    />
                </Box>,
                Array.isArray(active) &&
                active.length > 0 &&
                curChapterData?.settings?.maxMembers &&
                curChapterData.settings.maxMembers !== 'unlimited' && (
                    <Box 
                        key="status"
                        sx={{
                            width: '100%',
                            padding: theme => theme.spacing(2),
                            paddingTop: 0,
                            paddingBottom: theme => theme.spacing(2),
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        {(() => {
                            const num = active.length;
                            const max = parseInt(curChapterData.settings.maxMembers);
                            let val = (num / max) * 100;
                            if (val >= 100) val = 100;
                            
                            return (
                                <ProgressHeader
                                    percentage={val}
                                    displayValue={`${num} / ${max} (${Number(val.toFixed(2))}%)`}
                                    description="Based on the your plan's maximum number of members. Contact your chapter's representative to increase this cap."
                                />
                            );
                        })()}
                    </Box>
                )
            ]}
            actions={[
                {
                    title: 'Download Member Roster',
                    icon: <DownloadIcon />,
                    onClick: exportMemberRoster,
                },
            ]}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Table
                        id="id"
                        columns={getMembersColumns()}
                        onUpdateRow={rowData => {
                            rowData.updating = true
                            let newMemberData = rowData

                            updateMember(newMemberData)

                            let aMembers = []
                            for (let i = 0; i < active.length; i++) {
                                let mem = active[i]
                                if (mem.id === rowData.id) {
                                    mem.updating = true
                                }

                                aMembers.push(mem)
                            }
                            setActive(aMembers)
                        }}
                        rowActions={[
                            {
                                title: 'View Profile',
                                icon: <PersonIcon />,
                                onClick: rowData => {
                                    setUserHasInteracted(true);
                                    viewProfile(rowData.id);
                                },
                            },
                            {
                                title: 'Edit Member',
                                icon: <EditOutline />,
                                toggleEditing: true,
                            },
                            {
                                title: 'Delete Member',
                                icon: <DeleteOutline />,
                                onClick: rowData => {
                                    if (rowData.id !== user.getId()) {
                                        setDeleteMemberDialog(dialog => ({
                                            ...dialog,
                                            open: true,
                                            type: 'member',
                                            id: rowData.id,
                                            name: `${rowData.first} ${rowData.last}`,
                                        }))
                                    } else {
                                        alert('Silly goose, you cannot delete yourself!')
                                    }
                                },
                            },
                        ]}
                        showOnEmpty={true}
                        emptyText="No Members"
                        isLoading={isLoading}
                        data={filterData(active || [], memberSearch)}
                        defaultSortable={
                            curChapterData && curChapterData.settings && curChapterData.settings.roll
                                ? {
                                      value: 'roll',
                                      dir: 'desc',
                                  }
                                : {
                                      value: 'last',
                                      dir: 'asc',
                                  }
                        }
                    />
                </Grid>
            </Grid>
        </ParentTable>
    )

    const validateMemberData = (data) => {
        for (let i = 0; i < data.length; i++) {
            let user = data[i]
            if (!user.First || !user.Last || !user.Status) {
                return {
                    isValid: false,
                    error: 'Missing fields. Please make sure you have the required "First" and "Last" fields filled zfor each user, and the heading at the top. For help, download the template CSV or the sample filled CSV.'
                }
            }

            user.Status = visibilityNameOptions.map(a => a.toLowerCase()).indexOf(user.Status.toLowerCase())

            if (user.Status === -1) {
                return {
                    isValid: false,
                    error: 'Invalid Status value. The status field should have either "NEW MEMBER", "MEMBER", "INACTIVE MEMBER", "OFFICER", "GUEST", or "ALUMNI".'
                }
            }

            if (user.Role !== null) {
                user.Role = String(user.Role)

                if (user.Role !== 'ADMIN') {
                    let foundRole = null

                    for (let roleID in roles) {
                        if (roles[roleID] && roles[roleID].title && roles[roleID].title === user.Role) {
                            foundRole = roleID
                        }
                    }

                    if (foundRole) {
                        user.Role = foundRole
                    } else {
                        delete user.Role
                    }
                }
            }

            data[i] = user
        }

        return { isValid: true }
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Admin',
                            link: '/app/admin',
                            iconMobileOnly: true,
                            icon: <AdminIcon />,
                        },
                        {
                            name: 'Member Management',
                        },
                    ]}
                    grid
                />
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        <Box style={{ margin: -16 }}>{getInvitedMembersTable()}</Box>
                    ) : (
                        <Widget disableWidgetMenu inheritHeight noBodyPadding>
                            {getInvitedMembersTable()}
                        </Widget>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        <Box style={{ margin: -16 }}>{getMembersTable()}</Box>
                    ) : (
                        <Widget disableWidgetMenu inheritHeight noBodyPadding>
                            {getMembersTable()}
                        </Widget>
                    )}
                </Grid>
            </Grid>
            <CSVUploadDialog
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={(newData) => {
                    if (!newData || !Array.isArray(newData)) {
                        setDialogError("Invalid data format. Please try uploading the CSV file again.")
                        return
                    }

                    setIsLoading(true)
                    setData(newData)

                    let submission = {
                        chapter: curChapterId,
                        invitedMembers: newData,
                    }

                    if (!canInviteMemberCount(newData.length)) {
                        setIsLoading(false)
                        setData(null)
                        setDialogError("You cannot invite this many members due to your chapter's size! Talk to your chapter's representative to upgrade")
                        return
                    }

                    var inviteMembers = functions.httpsCallable('inviteAccounts')
                    inviteMembers(submission)
                        .then(function(result) {
                            let iMembers = result.data.users

                            for (let i = 0; i < iMembers.length; i++) {
                                let status = iMembers[i].status

                                if ('' + status in statuses) {
                                    iMembers[i].status = statuses['' + status].title
                                }
                            }

                            setInvitedMembers(iMembers)
                            setOpen(false)
                            setIsLoading(false)
                        })
                        .catch(function(error) {
                            console.log('AN ERROR OCCURRED', error.code, error.message, error.details)
                            setIsLoading(false)
                            setData(null)                            
                            const errorMessage = error.message                            
                            setDialogError(errorMessage)
                            setOpen(true)
                        })
                }}
                title="Bulk Invite Members"
                instructions={[
                    <>
                        Invite members by uploading a CSV file with their account information. Required fields are "First", "Last", and "Status". Optional
                        fields are "Email"{curChapterData?.settings?.roll ? `, "Roll" (roll number),` : ''} and
                        "Role".
                    </>,
                    "If an email address is specified, the access code will only be valid for that email address (highly recommended if member's email is known).",
                    "The role field should have the exact name of the user's role (example: \"Officer\"), or \"ADMIN\".",
                    "The status field should have either \"NEW MEMBER\", \"MEMBER\", \"INACTIVE MEMBER\", \"OFFICER\", \"GUEST\", or \"ALUMNI\"."
                ]}
                error={dialogError}
                isLoading={isLoading}
                validateData={validateMemberData}
                sampleFileUrl={sampleCsvFile}
            />
            <DeleteMemberDialog
                {...deleteMemberDialog}
                onClose={() => {
                    setDeleteMemberDialog({ open: false, type: null, id: null, name: null })
                }}
                onConfirmDelete={() => {
                    deleteMember(deleteMemberDialog.id, deleteMemberDialog.type === 'member')
                    setDeleteMemberDialog({ open: false, type: null, id: null, name: null })
                }}
            />
            <JoinableLinkDialog
                code={joinableLinkDialog.code}
                open={joinableLinkDialog.open}
                enabled={joinableLinkDialog.enabled}
                toggleEnabled={() => {
                    triggerLinkUpdate()
                }}
                loading={joinableLinkDialog.loading}
                onClose={() => setJoinableLinkDialog(dialog => ({ ...dialog, open: false }))}
            />
        </>
    )
}
