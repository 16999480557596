import React, { useState, useContext, useEffect } from 'react';
import { 
  Grid, 
  Typography, 
  Button, 
  TextField, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Paper,
  Box,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  CardActions,
  Switch,
  FormControlLabel,
  Tooltip,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { 
  Add as AddIcon, 
  Link as LinkIcon,
  Mail as MailIcon,
  School as SchoolIcon,
  People as PeopleIcon,
  Event as EventIcon,
  Info as InfoIcon,
  Search as SearchIcon,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon
} from '@material-ui/icons';

import NavigationBar from 'components/NavigationBar';
import Widget from 'components/Widget';
import { AuthContext } from 'context/AuthContext';
import { FraternityContext } from 'context/FraternityContext';
import { useHistory } from 'react-router-dom';

import { useGetFraternityChapters } from 'hooks/queries/fraternity/useGetFraternityChapters';
import { useInviteChapter } from 'hooks/mutations/fraternity/useInviteChapter';
import { useLinkChapter } from 'hooks/mutations/fraternity/useLinkChapter';

import useStyles from './styles';

export default function ChaptersDashboard() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { fraternity } = useContext(FraternityContext);
  const history = useHistory();
  
  const [chapterSearch, setChapterSearch] = useState('');
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [useMockData, setUseMockData] = useState(process.env.NODE_ENV === 'development');
  const [newChapter, setNewChapter] = useState({
    fullName: '',
    school: '',
    email: '',
    location: '',
    foundingYear: '',
    contactNote: '',
  });
  const [linkChapterId, setLinkChapterId] = useState('');
  const [invitedChapter, setInvitedChapter] = useState(null);
  const [viewMode, setViewMode] = useState('table');
  
  // Check if chapterId is the allowed one, otherwise redirect to dashboard
  useEffect(() => {
    if (user) {
      const chapterId = user.getChapter();
      if (chapterId !== 'L8p2j1ZymODoVJCvREaV') {
        history.push('/app/dashboard');
      }
    }
  }, [user, history]);
  
  // Get fraternity ID from user
  // const fraternityId = user?.fraternity;
  const fraternityId = 'L8p2j1ZymODoVJCvREaV';
  // Fetch chapters for this fraternity
  const { 
    data: chapters = [], 
    isLoading: isLoadingChapters,
    isError: isChaptersError,
    error: chaptersError
  } = useGetFraternityChapters(fraternityId, useMockData);
  
  // Mutations for inviting and linking chapters
  const inviteChapterMutation = useInviteChapter(fraternityId);
  const linkChapterMutation = useLinkChapter(fraternityId);
  
  // Handle inviting a new chapter
  const handleInviteChapter = async () => {
    try {
      const result = await inviteChapterMutation.mutateAsync({
        fraternityId,
        ...newChapter
      });
      
      setInvitedChapter(result);
      resetNewChapterForm();
    } catch (error) {
      console.error('Error inviting chapter:', error);
    }
  };
  
  // Handle linking an existing chapter
  const handleLinkChapter = async () => {
    try {
      await linkChapterMutation.mutateAsync({
        fraternityId,
        chapterId: linkChapterId
      });
      
      setLinkDialogOpen(false);
      setLinkChapterId('');
    } catch (error) {
      console.error('Error linking chapter:', error);
    }
  };
  
  // Reset the new chapter form
  const resetNewChapterForm = () => {
    setNewChapter({
      fullName: '',
      school: '',
      email: '',
      location: '',
      foundingYear: '',
      contactNote: '',
    });
  };
  
  // Close the invite dialog and reset state
  const handleCloseInviteDialog = () => {
    setInviteDialogOpen(false);
    if (invitedChapter) {
      setInvitedChapter(null);
    }
  };
  
  // Filter chapters based on search
  const filteredChapters = chapters.filter(chapter => 
    (chapter.fullName?.toLowerCase().includes(chapterSearch.toLowerCase()) || chapter.name?.toLowerCase().includes(chapterSearch.toLowerCase())) ||
    (chapter.letters?.toLowerCase().includes(chapterSearch.toLowerCase())) ||
    (chapter.school?.toLowerCase().includes(chapterSearch.toLowerCase()) || chapter.university?.toLowerCase().includes(chapterSearch.toLowerCase()))
  );
  
  // Calculate statistics - updated to handle both real and mock data formats
  const activeChapters = chapters.filter(chapter => chapter.status !== 'invited' && chapter.status !== 'inactive').length;
  const inactiveChapters = chapters.filter(chapter => chapter.status === 'inactive').length;
  const invitedChapters = chapters.filter(chapter => chapter.status === 'invited').length;
  const totalMembers = chapters.reduce((total, chapter) => {
    // Handle both data formats
    if (chapter.members) {
      return total + (typeof chapter.members === 'object' ? Object.keys(chapter.members).length : 0);
    } else if (chapter.memberCount) {
      return total + chapter.memberCount;
    }
    return total;
  }, 0);
  
  const handleViewChapterDetails = (chapterId) => {
    history.push(`/app/hq/chapters-dashboard/${chapterId}`);
  };
  
  return (
    <Grid container spacing={2}>
      <NavigationBar
        titles={[
          {
            name: 'Chapter Dashboard',
          },
        ]}
        grid
      />
      
      {process.env.NODE_ENV === 'development' && (
        <Grid item xs={12}>
          <Paper className={classes.devModeCard}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <InfoIcon className={classes.infoIcon} color="primary" />
                <Typography variant="body1">
                  Development Mode
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={useMockData}
                    disabled
                    onChange={(e) => setUseMockData(e.target.checked)}
                    color="primary"
                  />
                }
                label="Use Mock Data"
              />
            </Box>
            {useMockData && (
              <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                Using mock data for fraternity chapters. These chapters are not saved to the database.
              </Typography>
            )}
          </Paper>
        </Grid>
      )}
      
      <Grid item xs={12}>
        <Widget disableWidgetMenu>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Paper className={classes.statsCard}>
                <Typography className={classes.statValue}>{chapters.length}</Typography>
                <Typography className={classes.statLabel}>Total Chapters</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.statsCard}>
                <Typography className={classes.statValue}>{activeChapters}</Typography>
                <Typography className={classes.statLabel}>Active Chapters</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.statsCard}>
                <Typography className={classes.statValue}>{inactiveChapters}</Typography>
                <Typography className={classes.statLabel}>Inactive Chapters</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.statsCard}>
                <Typography className={classes.statValue}>{totalMembers}</Typography>
                <Typography className={classes.statLabel}>Total Members</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      
      <Grid item xs={12}>
        <Widget
          title="Chapters"
          disableWidgetMenu
          noBodyPadding
          headerActions={
            <>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setInviteDialogOpen(true)}
                style={{ marginRight: 8 }}
              >
                Invite Chapter
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<LinkIcon />}
                onClick={() => setLinkDialogOpen(true)}
              >
                Link Chapter
              </Button>
            </>
          }
        >
          <Box p={2}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search chapters..."
                value={chapterSearch}
                onChange={(e) => setChapterSearch(e.target.value.toLowerCase())}
                className={classes.searchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              
              <Box ml={2} mb={2}>
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={(e, newView) => {
                    if (newView) setViewMode(newView);
                  }}
                  size="small"
                  aria-label="view mode"
                >
                  <ToggleButton value="table" aria-label="table view">
                    <ViewListIcon />
                  </ToggleButton>
                  <ToggleButton value="grid" aria-label="grid view">
                    <ViewModuleIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            
            {isLoadingChapters ? (
              <Box display="flex" justifyContent="center" p={4}>
                <CircularProgress />
              </Box>
            ) : isChaptersError ? (
              <Box p={4} textAlign="center">
                <Typography color="error">Error loading chapters. Please try again.</Typography>
                {chaptersError && <Typography variant="body2" color="error">{chaptersError.message}</Typography>}
              </Box>
            ) : filteredChapters.length === 0 ? (
              <Box className={classes.noChapters}>
                <Typography variant="h6" color="textSecondary">
                  No chapters found
                </Typography>
                {chapterSearch ? (
                  <Typography variant="body2" color="textSecondary">
                    Try adjusting your search criteria
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Invite your first chapter to get started
                  </Typography>
                )}
              </Box>
            ) : viewMode === 'grid' ? (
              <Grid container spacing={2}>
                {filteredChapters.map(chapter => (
                  <Grid item xs={12} sm={6} md={4} key={chapter.id}>
                    <Card className={classes.chapterCard}>
                      <CardContent className={classes.chapterCardContent}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Typography variant="h6" component="h2">
                            {chapter.fullName || chapter.name}
                            {chapter.letters && ` (${chapter.letters})`}
                          </Typography>
                          <Box>
                            {useMockData && (
                              <Tooltip title="Mock Data">
                                <Chip 
                                  size="small" 
                                  label="MOCK" 
                                  color="default"
                                  className={classes.mockChip} 
                                />
                              </Tooltip>
                            )}
                            {chapter.status === 'invited' && (
                              <Chip 
                                size="small" 
                                label="Invited" 
                                color="primary" 
                                className={classes.chapterStatus} 
                              />
                            )}
                            {chapter.status === 'inactive' && (
                              <Chip 
                                size="small" 
                                label="Inactive" 
                                color="default" 
                                className={classes.chapterStatus} 
                              />
                            )}
                          </Box>
                        </Box>
                        
                        {(chapter.school || chapter.university) && (
                          <Typography className={classes.chapterSchool}>
                            <SchoolIcon fontSize="small" style={{ marginRight: 4 }} />
                            {chapter.school || chapter.university}
                          </Typography>
                        )}
                        
                        {chapter.city && chapter.state && (
                          <Typography className={classes.chapterLocation}>
                            {chapter.city}, {chapter.state} {chapter.zipCode && `${chapter.zipCode}`}
                          </Typography>
                        )}
                        
                        {(chapter.contactEmail || (chapter.president && chapter.president.email)) && (
                          <Typography className={classes.chapterEmail}>
                            <MailIcon fontSize="small" style={{ marginRight: 4 }} />
                            {chapter.contactEmail || (chapter.president && chapter.president.email)}
                          </Typography>
                        )}
                        
                        <Box className={classes.chapterMetrics}>
                          <PeopleIcon className={classes.metricIcon} />
                          <span className={classes.metricValue}>
                            {chapter.members ? Object.keys(chapter.members).length : chapter.memberCount || 0} members
                          </span>
                          
                          {chapter.events && (
                            <>
                              <EventIcon className={classes.metricIcon} />
                              <span className={classes.metricValue}>
                                {Object.keys(chapter.events || {}).length} events
                              </span>
                            </>
                          )}
                        </Box>
                        
                        {chapter.founded && (
                          <Typography variant="body2" className={classes.chapterFounded}>
                            Founded: {chapter.founded}
                          </Typography>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button 
                          size="small" 
                          color="primary"
                          onClick={() => handleViewChapterDetails(chapter.id)}
                        >
                          View Details
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Chapter Name</TableCell>
                      <TableCell>School</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Members</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredChapters.map(chapter => (
                      <TableRow key={chapter.id} hover>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body1">
                              {chapter.fullName || chapter.name}
                              {chapter.letters && ` (${chapter.letters})`}
                            </Typography>
                            {useMockData && (
                              <Tooltip title="Mock Data">
                                <Chip 
                                  size="small" 
                                  label="MOCK" 
                                  color="default"
                                  className={classes.mockChip} 
                                  style={{ marginLeft: 8 }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>{chapter.school || chapter.university || '-'}</TableCell>
                        <TableCell>{(chapter.city && chapter.state) ? `${chapter.city}, ${chapter.state}` : '-'}</TableCell>
                        <TableCell>{chapter.members ? Object.keys(chapter.members).length : chapter.memberCount || 0}</TableCell>
                        <TableCell>
                          {chapter.status === 'invited' ? (
                            <Chip 
                              size="small" 
                              label="Invited" 
                              color="primary" 
                            />
                          ) : chapter.status === 'inactive' ? (
                            <Chip 
                              size="small" 
                              label="Inactive" 
                              color="default" 
                            />
                          ) : (
                            <Chip 
                              size="small" 
                              label="Active" 
                              color="secondary" 
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Button 
                            size="small" 
                            color="primary"
                            onClick={() => handleViewChapterDetails(chapter.id)}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Widget>
      </Grid>
      
      {/* Invite Chapter Dialog */}
      <Dialog 
        open={inviteDialogOpen} 
        onClose={handleCloseInviteDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {invitedChapter ? 'Chapter Invited Successfully' : 'Invite New Chapter'}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {inviteChapterMutation.isLoading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress />
            </Box>
          ) : invitedChapter ? (
            <>
              <Typography variant="body1">
                The chapter <strong>{invitedChapter.fullName}</strong> has been invited to join your fraternity.
              </Typography>
              
              <Box className={classes.inviteCodeBox}>
                <Typography variant="body2" gutterBottom>
                  Share this invitation code with the chapter:
                </Typography>
                <Typography className={classes.inviteCode}>
                  {invitedChapter.inviteCode}
                </Typography>
              </Box>
              
              <Typography variant="body2" style={{ marginTop: 16 }}>
                The chapter will need this code to join your fraternity through the app.
              </Typography>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Chapter Name"
                  variant="outlined"
                  value={newChapter.fullName}
                  onChange={(e) => setNewChapter({...newChapter, fullName: e.target.value})}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="School/University"
                  variant="outlined"
                  value={newChapter.school}
                  onChange={(e) => setNewChapter({...newChapter, school: e.target.value})}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Location"
                  variant="outlined"
                  placeholder="City, State"
                  value={newChapter.location}
                  onChange={(e) => setNewChapter({...newChapter, location: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Founding Year"
                  variant="outlined"
                  placeholder="YYYY"
                  value={newChapter.foundingYear}
                  onChange={(e) => {
                    // Only allow integer values
                    const value = e.target.value.replace(/\D/g, '');
                    setNewChapter({...newChapter, foundingYear: value});
                  }}
                  error={newChapter.foundingYear && !/^\d+$/.test(newChapter.foundingYear)}
                  helperText={
                    newChapter.foundingYear && !/^\d+$/.test(newChapter.foundingYear)
                      ? "Please enter a valid year (numbers only)"
                      : ""
                  }
                  inputProps={{ 
                    maxLength: 4,
                    inputMode: 'numeric', 
                    pattern: '[0-9]*'
                  }}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Contact Email"
                  variant="outlined"
                  type="email"
                  value={newChapter.email}
                  onChange={(e) => setNewChapter({...newChapter, email: e.target.value})}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Note to chapter contact"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={newChapter.contactNote}
                  onChange={(e) => setNewChapter({...newChapter, contactNote: e.target.value})}
                  placeholder="Add any additional information for the chapter contact..."
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {invitedChapter ? (
            <Button onClick={handleCloseInviteDialog} color="primary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleCloseInviteDialog}>
                Cancel
              </Button>
              <Button 
                onClick={handleInviteChapter} 
                color="primary" 
                variant="contained"
                disabled={
                  !newChapter.fullName || 
                  !newChapter.school || 
                  !newChapter.email ||
                  inviteChapterMutation.isLoading
                }
              >
                Invite Chapter
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      
      {/* Link Chapter Dialog */}
      <Dialog 
        open={linkDialogOpen} 
        onClose={() => setLinkDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Link Existing Chapter</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {linkChapterMutation.isLoading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="body2" paragraph>
                Enter the ID of an existing chapter to link it to your HQ. Chapters can find this information in the Admin section of the app.
              </Typography>
              <TextField
                fullWidth
                label="Chapter ID"
                variant="outlined"
                value={linkChapterId}
                onChange={(e) => setLinkChapterId(e.target.value)}
                required
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLinkDialogOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleLinkChapter} 
            color="primary" 
            variant="contained"
            disabled={!linkChapterId || linkChapterMutation.isLoading}
          >
            Link Chapter
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
} 