import React from 'react';
import { 
    Box, Grid, LinearProgress, Link, TextField, Typography, Divider, useMediaQuery 
} from '@material-ui/core';
import { 
    InsertPhoto as ImageIcon,
    Link as LinkIcon,
    LocationOnOutlined as LocationIcon,
    Subject as DescriptionIcon,
    PersonOutlineOutlined as AuthorIcon,
    Timeline as PointsIcon,
    VisibilityOutlined as VisibilityIcon,
    CreateOutlined as EditIcon
} from '@material-ui/icons';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DataGrid } from '@mui/x-data-grid';
import MuiLinkify from 'material-ui-linkify';
import { Capacitor } from '@capacitor/core';

import DocumentUploadLarge from 'components/DocumentUpload/DocumentUploadLarge';
import { Button } from 'components/Wrappers';
import Map from 'components/Map';
import { StagnantCircle as MapCircle } from 'components/Map/Circle';
import { StagnantMarker as MapMarker } from 'components/Map/Marker';
import MapUserMarker from 'components/Map/Marker/UserMarker';
import MapRef from 'components/Map/Ref';
import ErrorTypography from 'components/Typography/ErrorTypography';
import ParentTable from 'components/Tables/ParentTable';

import { Points } from 'objects/Points';

import LocationOptions from '../../components/LocationOptions';
import RequestButton from '../../components/RequestButton';

import { getOrdinalSuffix, isMobileDevice } from 'code/Helper';
import { getFirebaseDate } from 'code/TimeAgo';
import { renderVisibility } from 'data/Visibility';

const MyPointsTab = ({ 
    user, 
    chapter, 
    points, 
    compiledMembers, 
    pointsRequest, 
    setPointsRequest, 
    userLocation, 
    mapRef, 
    canViewPersonalPoints, 
    hasActiveLocationTrackingRequest, 
    setLocationTracking, 
    hasPointsAdmin,
    classes, 
    submitPointRequest, 
    theme,
    setViewAttachment,
    mapkit,
    getCurrentLocation
}) => {
    // Add useMediaQuery at component level
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    
    function getUserPoints(uid) {
        for (let i = 0; i < compiledMembers.length; i++) {
            let mem = compiledMembers[i]
            if (mem.id === uid) {
                return mem.points
            }
        }

        return 0
    }
    
    function getPoints() {
        let pointObject = new Points(points)

        let userPoints = getUserPoints(user.getId())

        if (pointObject.getType() === Points.TYPE_GOAL) {
            let goal = points.goal

            let val = (userPoints / goal) * 100

            if (val > 100) {
                val = 100
            } else if (val < 0) {
                val = 0
            }

            return (
                <Box display="flex" className={classes.progressBox}>
                    <LinearProgress
                        variant="determinate"
                        value={val}
                        style={{ flexGrow: 1 }}
                        classes={{
                            barColorPrimary: classes.progressBar,
                        }}
                        className={classes.progress}
                    />
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {userPoints}/{goal} ({Number(val.toFixed(2))}%)
                    </Typography>
                </Box>
            )
        } else {
            return (
                <Box display="flex" className={classes.progressBox}>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {userPoints} Points
                    </Typography>
                </Box>
            )
        }
    }
    
    function getMemberPlacing(userId) {
        let mems = [...compiledMembers]

        if (mems.length === 0) {
            return 1
        }

        mems.sort((a, b) => b.points - a.points)

        let lastPlace = 1
        let lastPoints = mems[0].points

        for (let i = 0; i < mems.length; i++) {
            let mem = mems[i]

            let place = mem.points === lastPoints ? lastPlace : i + 1

            if (mem.id === userId) {
                return place
            }

            lastPlace = place
            lastPoints = mem.points
        }

        return mems.length
    }
    
    function getPlacing(val, total) {
        // Generate the background color based on placement
        const getPlacementColor = (position) => {
            switch(position) {
                case 1: return { background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)', text: '#000' }; // Gold
                case 2: return { background: 'linear-gradient(45deg, #E0E0E0 30%, #C0C0C0 90%)', text: '#000' }; // Silver
                case 3: return { background: 'linear-gradient(45deg, #CD7F32 30%, #A0522D 90%)', text: '#fff' }; // Bronze
                default: return { background: 'linear-gradient(45deg, #3f51b5 30%, #2196f3 90%)', text: '#fff' }; // Blue
            }
        };
        
        const colorScheme = getPlacementColor(val);
        
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: colorScheme.background,
                    color: colorScheme.text,
                    borderRadius: '8px',
                    p: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                    minHeight: '100px',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                <Box 
                    sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0.1,
                        background: `repeating-linear-gradient(
                            45deg,
                            #fff,
                            #fff 10px,
                            transparent 10px,
                            transparent 20px
                        )`
                    }}
                />
                
                <Box sx={{ 
                    position: 'relative', 
                    zIndex: 1, 
                    display: 'flex', 
                    alignItems: 'baseline',
                    justifyContent: 'center'
                }}>
                    <Typography 
                        variant="h1" 
                        component="span"
                        sx={{ 
                            fontWeight: 'bold', 
                            fontSize: { xs: '3rem', sm: '4rem' },
                            lineHeight: 1,
                            textShadow: '2px 2px 4px rgba(0,0,0,0.2)'
                        }}
                    >
                        {val}
                    </Typography>
                    <Typography 
                        component="span" 
                        sx={{ 
                            fontSize: { xs: '1.2rem', sm: '1.5rem' }, 
                            ml: 0.5,
                            fontWeight: 'medium',
                            verticalAlign: 'super'
                        }}
                    >
                        {getOrdinalSuffix(val)}
                    </Typography>
                </Box>
                
                <Typography 
                    variant="subtitle1"
                    sx={{ 
                        fontWeight: 'medium',
                        opacity: 0.9,
                        fontSize: { xs: '0.9rem', sm: '1rem' }
                    }}
                >
                    of {total} {total === 1 ? 'member' : 'members'}
                </Typography>
                
                <Typography 
                    variant="caption" 
                    sx={{ 
                        mt: 1,
                        textAlign: 'center',
                        fontStyle: 'italic',
                        opacity: 0.8,
                        fontSize: { xs: '0.7rem', sm: '0.8rem' }
                    }}
                >
                    {val === 1 ? 'Excellent work! You\'re leading the chapter!' : 
                     val <= 3 ? 'Great job! You\'re in the top positions!' : 
                     val <= Math.ceil(total/2) ? 'You\'re in the top half!' : 
                     'Keep participating to improve your ranking!'}
                </Typography>
            </Box>
        );
    }
    
    function getPersonalRecordsColumns() {
        // Use the isMobile variable from the component scope
        // For mobile view, only return title, date and points columns
        if (isMobile) {
            return [
                {
                    field: 'name',
                    headerName: 'Title',
                    flex: 1.5,
                    minWidth: 150,
                    renderCell: ({ value }) => {
                        return value ? (
                            <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                {value}
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="textSecondary">—</Typography>
                        );
                    }
                },
                {
                    field: 'dt',
                    headerName: 'Date',
                    width: 85,
                    renderCell: ({ value }) => {
                        if (!value) return <Typography variant="body2" color="textSecondary">—</Typography>;
                        
                        try {
                            // Handle different timestamp formats from Firebase
                            let date;
                            if (value.seconds || value._seconds) {
                                // It's a Firebase timestamp object
                                date = getFirebaseDate({ date: value });
                            } else if (value instanceof Date) {
                                // It's already a Date object
                                date = value;
                            } else {
                                // Try to parse it as a string
                                date = new Date(value);
                            }
                            
                            if (!isNaN(date.getTime())) {
                                return (
                                    <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {moment(date).format('MM/DD/YYYY')}
                                    </Typography>
                                );
                            }
                        } catch (error) {
                            console.log("Date formatting error:", error);
                        }
                        
                        return <Typography variant="body2" color="textSecondary">Invalid date</Typography>;
                    },
                    valueGetter: ({ value }) => {
                        if (!value) return null;
                        
                        try {
                            if (value.seconds || value._seconds) {
                                const seconds = value.seconds || value._seconds;
                                return new Date(seconds * 1000);
                            } else if (value instanceof Date) {
                                return value;
                            } else {
                                const date = new Date(value);
                                return !isNaN(date.getTime()) ? date : null;
                            }
                        } catch (e) {
                            return null;
                        }
                    }
                },
                {
                    field: 'points',
                    headerName: 'Points',
                    width: 100,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: ({ value }) => (
                        <Typography 
                            align="center"
                            variant="body2" 
                            sx={{ 
                                fontWeight: 'medium',
                                color: value < 0 ? theme.palette.error.main : 'inherit',
                                width: '100%'
                            }}
                        >
                            {value}
                        </Typography>
                    )
                }
            ];
        }
        
        // For desktop view, return all columns
        return [
            {
                field: 'attachment',
                headerName: 'Attachment',
                width: 100,
                renderCell: ({ value }) => {
                    return value ? (
                        <Button
                            size="small"
                            onClick={() => setViewAttachment(value)}
                        >
                            <VisibilityIcon fontSize="small" />
                        </Button>
                    ) : (
                        <Typography variant="body2" color="textSecondary">—</Typography>
                    );
                }
            },
            {
                field: 'name',
                headerName: 'Title',
                flex: 1.5,
                minWidth: 150,
                renderCell: ({ value }) => {
                    return value ? (
                        <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {value}
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="textSecondary">—</Typography>
                    );
                }
            },
            {
                field: 'description',
                headerName: 'Description',
                flex: 1.5,
                minWidth: 150,
                renderCell: ({ value }) => {
                    return value ? (
                        <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {value}
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="textSecondary">—</Typography>
                    );
                }
            },
            {
                field: 'statusStr',
                headerName: 'Status',
                width: 100,
                renderCell: ({ value }) => {
                    let color = 'default';
                    if (value === 'Approved') color = 'success';
                    else if (value === 'Awaiting') color = 'info';
                    else if (value === 'Denied') color = 'error';
                    
                    return (
                        <Box 
                            sx={{ 
                                color: theme.palette[color].main,
                                fontWeight: 'medium',
                                fontSize: '0.875rem',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {value}
                        </Box>
                    );
                }
            },
            {
                field: 'dt',
                headerName: 'Date',
                width: 100,
                renderCell: ({ value }) => {
                    if (!value) return <Typography variant="body2" color="textSecondary">—</Typography>;
                    
                    try {
                        // Handle different timestamp formats from Firebase
                        let date;
                        if (value.seconds || value._seconds) {
                            // It's a Firebase timestamp object
                            date = getFirebaseDate({ date: value });
                        } else if (value instanceof Date) {
                            // It's already a Date object
                            date = value;
                        } else {
                            // Try to parse it as a string
                            date = new Date(value);
                        }
                        
                        if (!isNaN(date.getTime())) {
                            return (
                                <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {moment(date).format('MM/DD/YYYY')}
                                </Typography>
                            );
                        }
                    } catch (error) {
                        console.log("Date formatting error:", error);
                    }
                    
                    return <Typography variant="body2" color="textSecondary">Invalid date</Typography>;
                },
                valueGetter: ({ value }) => {
                    if (!value) return null;
                    
                    try {
                        if (value.seconds || value._seconds) {
                            const seconds = value.seconds || value._seconds;
                            return new Date(seconds * 1000);
                        } else if (value instanceof Date) {
                            return value;
                        } else {
                            const date = new Date(value);
                            return !isNaN(date.getTime()) ? date : null;
                        }
                    } catch (e) {
                        return null;
                    }
                }
            },
            {
                field: 'points',
                headerName: 'Points',
                width: 100,
                align: 'center',
                headerAlign: 'center',
                renderCell: ({ value }) => (
                    <Typography 
                        align="center"
                        variant="body2" 
                        sx={{ 
                            fontWeight: 'medium',
                            color: value < 0 ? theme.palette.error.main : 'inherit',
                            width: '100%'
                        }}
                    >
                        {value}
                    </Typography>
                )
            }
        ];
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontWeight: 700,
                        margin: '0 auto',
                    }}
                    align="center"
                >
                    {points.name}
                </Typography>
            </Grid>
           
            
            {canViewPersonalPoints && (
                <Grid item xs={12}>
                    {getPoints()}
                </Grid>
            )}
            {canViewPersonalPoints && points.rankingsVisibility !== Points.RANKINGS_ADMIN && (
                <Grid item xs={12}>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center',
                        width: '100%',
                        my: 2
                    }}>
                        <Box sx={{ 
                            width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },
                            maxWidth: '500px' 
                        }}>
                            {getPlacing(getMemberPlacing(user.getId()), compiledMembers.length)}
                        </Box>
                    </Box>
                </Grid>
            )}
            
            {/* Remove the original visibility and author sections since they're now in the top box */}
            {points.author && points.author.id !== user.getId() && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <AuthorIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{points.author.first + ' ' + points.author.last}</Typography>
                    </Grid>
                </Grid>
            )}
            
            {/* Rest of the existing content */}
            {points.links &&
                points.links.length > 0 &&
                points.links.map(link => (
                    <Grid key={link.url} container direction="row" style={{ flexWrap: `nowrap` }}>
                        <Grid
                            item
                            style={{
                                height: 24,
                            }}
                        >
                            <LinkIcon />
                        </Grid>
                        <Grid item style={{ overflow: 'hidden' }}>
                            <Link target="_blank" rel="noreferrer" href={link.url}>
                                <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{link.name ? link.name : link.url}</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                ))}
            
            {/* Rest of the existing renderPrimaryWidget code */}
            {points.description && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <DescriptionIcon />
                    </Grid>
                    <Grid item>
                        <MuiLinkify>
                            <Typography
                                style={{
                                    whiteSpace: `break-spaces`,
                                    wordBreak: `break-word`,
                                }}
                            >
                                {points.description}
                            </Typography>
                        </MuiLinkify>
                    </Grid>
                </Grid>
            )}
            {canViewPersonalPoints && pointsRequest !== null && pointsRequest.type === 'location' && (
                <>
                    <Grid item xs={12}>
                        <Map
                            center={userLocation.current}
                            style={{
                                height: 384,
                            }}
                        >
                            <MapRef ref={mapRef} />
                            <MapUserMarker location={userLocation.current} />
                            {points.locations.map(location => (
                                <MapCircle
                                    key={`${location.name}.${location?.data?.coordinate?.latitude}.${location?.data?.coordinate?.longitude}.circle`}
                                    data={{
                                        coordinate: location?.data?.coordinate || location.center
                                    }}
                                    radius={location.radius}
                                    centerRegion={false}
                                />
                            ))}
                            {points.locations.map(location => (
                                <MapMarker
                                    title={location.name}
                                    key={`${location.name}.${location?.data?.coordinate?.latitude}.${location?.data?.coordinate?.longitude}.marker`}
                                    data={{
                                        coordinate: location?.data?.coordinate || location.center
                                    }}
                                />
                            ))}
                        </Map>
                    </Grid>
                    <Grid item xs={12}>
                        <LocationOptions
                            onSelect={(location, isOutOfBounds) => {
                                if (mapkit && mapRef && mapRef.current) {
                                    let latitudes = [userLocation.current.latitude , location?.data?.coordinate?.latitude || location.center.latitude]
                                    let longitudes = [userLocation.current.longitude, location?.data?.coordinate?.longitude || location.center.longitude]

                                    const boundingRegion = new mapkit.BoundingRegion(
                                        Math.max(...latitudes),
                                        Math.max(...longitudes),
                                        Math.min(...latitudes),
                                        Math.min(...longitudes),
                                    )

                                    const coordinateRegion = boundingRegion.toCoordinateRegion()

                                    coordinateRegion.span = new mapkit.CoordinateSpan(
                                        coordinateRegion.span.latitudeDelta * 1.2,
                                        coordinateRegion.span.longitudeDelta * 1.2,
                                    )

                                    mapRef.current.setRegionAnimated(coordinateRegion)
                                }

                                setPointsRequest(request => ({
                                    ...request,
                                    selectedLocation: `${location?.data?.coordinate?.latitude || location.center.latitude}.${location?.data?.coordinate?.longitude || location.center.longitude}`,
                                    selectedLocationOutOfBounds: isOutOfBounds,
                                }))
                            }}
                            locations={points.locations}
                            selected={pointsRequest.selectedLocation}
                            userLocation={userLocation.current}
                        />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setPointsRequest(null)}
                            disableElevation
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setLocationTracking(true)}
                            disableElevation
                            disabled={!pointsRequest.selectedLocation || pointsRequest.selectedLocationOutOfBounds}
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Start
                        </Button>
                    </Grid>
                </>
            )}
            {canViewPersonalPoints && pointsRequest !== null && pointsRequest.type === null && (
                <>
                    <Grid item xs={12} sm={6}>
                        <RequestButton
                            onClick={() =>
                                setPointsRequest(request => ({
                                    ...request,
                                    type: 'custom',
                                }))
                            }
                            title="Custom"
                            description="Submit requests that are reviewed by a chapter officer"
                            icon={<EditIcon />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RequestButton
                            onClick={() => {
                                // Request their location first, then display the locations based on distance...
                                const addLocation = async () => {
                                    try {
                                        const loc = await getCurrentLocation()

                                        if (loc && (loc?.coords || loc?.data)) {
                                            const coordinate = new mapkit.Coordinate(loc?.data?.coordinate?.latitude ?? loc?.coords?.latitude, loc?.data?.coordinate?.longitude ?? loc?.coords?.longitude)

                                            userLocation.current = coordinate

                                            setPointsRequest(request => ({
                                                ...request,
                                                type: 'location',
                                            }))
                                        } else {
                                            alert('Need location access to use this feature')
                                        }
                                    } catch (e) {
                                        console.log('unable to grab location', e)
                                        alert('Need location access to use this feature')
                                    }
                                }

                                addLocation()
                            }}
                            title="Location"
                            description="Earn points based on how long you are in designated areas"
                            disabled={false && !Capacitor.isPluginAvailable('BackgroundGeolocation')}
                            icon={<LocationIcon />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setPointsRequest(null)}
                            disableElevation
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </>
            )}
            {canViewPersonalPoints && pointsRequest !== null && pointsRequest.type === 'custom' && (
                <>
                    <Grid item xs={12} sm={6} lg={4} id="point_request_name">
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label={pointsRequest.name ? '' : `Title`}
                            type="text"
                            onChange={e => {
                                setPointsRequest({ ...pointsRequest, name: e.target.value })
                            }}
                            value={pointsRequest.name}
                            inputProps={{
                                maxLength: 64,
                            }}
                            style={{ marginTop: 4 }}
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} id="point_request_description">
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label={pointsRequest.description ? '' : `Description`}
                            type="text"
                            onChange={e => {
                                setPointsRequest({ ...pointsRequest, description: e.target.value })
                            }}
                            value={pointsRequest.description}
                            inputProps={{
                                maxLength: 128,
                            }}
                            style={{ marginTop: 4 }}
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} id="point_request_points">
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label={pointsRequest.points ? '' : `Points`}
                            type="number"
                            onChange={e => {
                                if (e.target.validity.valid || e.target.value === '') {
                                    const value = parseInt(e.target.value)
                                    setPointsRequest({ ...pointsRequest, points: isNaN(value) ? 0 : value })
                                } else {
                                    setPointsRequest({ ...pointsRequest, points: 0 })
                                }
                            }}
                            value={pointsRequest.points}
                            inputProps={{
                                maxLength: 5,
                                pattern: '[0-9]*',
                                inputMode: 'numeric',
                            }}
                            style={{ marginTop: 4 }}
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} id="point_request_date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                inputVariant="outlined"
                                disableToolbar
                                format="MM/dd/yyyy"
                                label={pointsRequest.date ? '' : `Date`}
                                value={pointsRequest.date}
                                fullWidth
                                margin="dense"
                                onChange={e => setPointsRequest({ ...pointsRequest, date: e })}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ marginTop: 4 }}
                                InputLabelProps={{ shrink: false }}
                                disabled={pointsRequest.loading}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} id="point_request_attachment">
                        <DocumentUploadLarge
                            onUpdateFile={file => {
                                setPointsRequest({ ...pointsRequest, attachment: file })
                            }}
                            title="Drag an attachment or click to upload"
                            icon={<ImageIcon style={{ fontSize: '6rem' }} disabled={pointsRequest.loading} />}
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    {pointsRequest.error && (
                        <Grid item xs={12}>
                            <ErrorTypography text={pointsRequest.error} />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setPointsRequest(null)}
                            disabled={pointsRequest.loading}
                            disableElevation
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => submitPointRequest()}
                            disabled={pointsRequest.loading || !pointsRequest.name || pointsRequest.date === null || !pointsRequest.points}
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Submit Request
                        </Button>
                    </Grid>
                </>
            )}
            {hasActiveLocationTrackingRequest && isMobileDevice() ? (
                <Grid item xs={12}>
                    <RequestButton
                        onClick={() => setLocationTracking(true)}
                        title="Location"
                        description="Resume location tracking so you can earn your points"
                        icon={<LocationIcon />}
                    />
                    <Button>Cancel current location tracking</Button>
                </Grid>
            ) : (
                <> 
            {/* New User Info & Actions Section */}
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        alignItems: { xs: 'stretch', sm: 'center' },
                        bgcolor: theme.palette.background.paper,
                        borderRadius: 1,
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                        p: 2,
                        mb: 2,
                        gap: 2
                    }}
                >
                    {/* User Info */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <AuthorIcon color="primary" fontSize="small" />
                            <Typography variant="subtitle1" fontWeight="medium">
                                {user && chapter && chapter.members && user.getId() in chapter.members 
                                    ? `${chapter.members[user.getId()].first} ${chapter.members[user.getId()].last}`
                                    : 'Member'}
                            </Typography>
                        </Box>
                        
                        {points.vis && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <VisibilityIcon color="action" fontSize="small" />
                                <Typography variant="body2" color="textSecondary">
                                    {renderVisibility(points.vis)}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    
                    {/* Request Points Button */}
                    {canViewPersonalPoints && points.allowRequests && pointsRequest === null && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                setPointsRequest({
                                    name: '',
                                    points: '',
                                    type: points.allowLocationTrackingRequests && isMobileDevice() ? null : 'custom',
                                    date: new Date(),
                                    description: '',
                                    attachment: null,
                                    loading: false,
                                    error: false,
                                    selectedLocation: null,
                                })
                            }
                            disableElevation
                            startIcon={<PointsIcon />}
                            sx={{
                                minWidth: { xs: '100%', sm: '160px' },
                                height: '44px',
                                fontWeight: 'medium',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                }
                            }}
                        >
                            Request Points
                        </Button>
                    )}
                </Box>
            </Grid>
                </>
            )}
            {canViewPersonalPoints && (
                <>
                    <Grid item xs={12}>
                        <Divider style={isMobileDevice() ? { margin: '0 -16px' } : { marginLeft: -24, marginRight: -24 }} />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0, width: '100%' }}>
                        <ParentTable title="Your Points">
                            <DataGrid
                                autoHeight
                                density="compact"
                                rows={JSON.parse(JSON.stringify(points.getRecords(user ? user.getId() : '')))}
                                columns={getPersonalRecordsColumns()}
                                loading={false}
                                rowHeight={32}
                                columnHeaderHeight={36}
                                getRowId={row => row.id}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'dt', sort: 'desc' }],
                                    },
                                    pagination: {
                                        paginationModel: { pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25, 50]}
                                disableRowSelectionOnClick
                                disableColumnMenu
                                sx={{
                                    border: 'none',
                                    '& .MuiDataGrid-cell:focus': { outline: 'none' },
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: theme.palette.background.default,
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                        padding: '0 8px',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                        padding: '0 8px',
                                    },
                                    '& .MuiDataGrid-footerContainer': {
                                        borderTop: 'none',
                                        minHeight: '40px',
                                    }
                                }}
                            />
                        </ParentTable>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default MyPointsTab; 