import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, useMediaQuery, useTheme, IconButton, Tabs, Tab } from '@material-ui/core'
import {
    CheckCircleOutlineOutlined as ApproveIcon,
    CloudDownloadOutlined as DownloadIcon,
    CreateOutlined as EditIcon,
    HomeOutlined as HomeIcon,
    RemoveCircleOutline as RemoveIcon,
    Timeline as PointsIcon,
    VisibilityOutlined as VisibilityIcon,
    BarChart as AnalyticsIcon,
    PeopleOutline as GroupIcon,
} from '@material-ui/icons'
import React from 'react'

import Papa from 'papaparse'

import { Points } from 'objects/Points'
import { PointRecord } from 'objects/Points/Record'
import _ from 'lodash'

import moment from 'moment'

import FullScreenImage from 'components/FullScreenImage'
import NavigationBar from 'components/NavigationBar'
import ParentTable from 'components/Tables/ParentTable'
import Table from 'components/Tables/Table'
import ErrorTypography from 'components/Typography/ErrorTypography'
import Widget from 'components/Widget'
import { Button, Typography } from 'components/Wrappers'

import LocationTrackingDialog from '../components/LocationTrackingDialog'
import { convertObjectToList, getOrdinalSuffix, isMobileDevice } from 'code/Helper'
import { getFirebaseDate } from 'code/TimeAgo'

import app from 'firebase/app'
import useStyles from '../styles'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { LocationContext } from 'context/LocationContext'

import { getUserStats } from '../utils/stats'
import { 
    ResponsiveContainer, PieChart, Pie as ReChartsPie, Cell, XAxis, YAxis, CartesianGrid, 
    Tooltip as RechartsTooltip, RadialBarChart, RadialBar, PolarAngleAxis, LineChart, Line, ReferenceLine,
} from 'recharts'
// First, import the tab components
import MyPointsTab from './tabs/MyPointsTab';
import ChapterStatsTab from './tabs/ChapterStatsTab';
import EventAnalyticsTab from './tabs/EventAnalyticsTab';

/**
 * @fileoverview ViewPoints component for displaying and managing point systems for chapter members.
 * 
 * This component provides functionality for:
 * - Viewing individual and chapter-wide point standings
 * - Submitting point requests (custom and location-based)
 * - Approving/denying point requests
 * - Tracking location-based points
 * - Managing point records
 * - Exporting point data
 * 
 * @component
 * 
 * @param {Object} props - Component props
 * @param {Object} props.match - Route match object containing URL parameters
 * @param {Object} props.match.params - URL parameters
 * @param {string} props.match.params.id - Points system ID
 * @param {Object} props.history - React Router history object
 * 
 * @requires React
 * @requires @material-ui/core
 * @requires @material-ui/icons
 * @requires firebase/app
 * @requires moment
 * @requires lodash
 * 
 * @context AuthContext - Provides authenticated user information
 * @context ChapterContext - Provides chapter information and settings
 * @context LocationContext - Provides location tracking functionality
 * 
 * @typedef {Object} PointRecord
 * @property {string} id - Unique identifier for the record
 * @property {string} name - Title of the record
 * @property {string} description - Description of the record
 * @property {number} points - Point value
 * @property {string} statusStr - String representation of status
 * @property {number} status - Numeric status code
 * @property {Date} dt - Date of the record
 * @property {string} attachment - URL to attachment if any
 * 
 * @typedef {Object} Points
 * @property {string} name - Name of the points system
 * @property {string} description - Description of the points system
 * @property {number} mType - Type of membership targeting (0 for status-based, 1 for specific members)
 * @property {Array<string>} vis - Visibility settings (member status types that can view)
 * @property {Array<string>} mems - Array of member IDs if mType is 1
 * @property {Object} author - Author information
 * @property {Object} records - Records by user ID
 * @property {Array<Object>} locations - Location tracking points
 * @property {boolean} allowRequests - Whether point requests are allowed
 * @property {boolean} allowLocationTrackingRequests - Whether location tracking requests are allowed
 * @property {number} rankingsVisibility - Who can see rankings (0=admin, 1=all)
 * 
 * @enum {number} PointRecordStatus - Status codes for point records
 * @property {number} STATUS_AWAITING - Record is awaiting approval (0)
 * @property {number} STATUS_APPROVED - Record has been approved (1)
 * @property {number} STATUS_DENIED - Record has been denied (2)
 * @property {number} STATUS_EXPIRED - Record has expired (3)
 * @property {number} STATUS_INCOMPLETE - Record is incomplete (4)
 * 
 * The status codes determine the state of a point record:
 * - Awaiting: The record has been submitted but not yet reviewed
 * - Approved: The record has been reviewed and points granted
 * - Denied: The record has been reviewed and rejected
 * - Expired: The record was valid for a limited time and has expired
 * - Incomplete: The record is missing required information
 */

export default function ViewPoints(props) {
    var classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    const pointsId = props.match.params.id

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)
    const { mapkit, getCurrentLocation } = React.useContext(LocationContext)

    const [clearPointsDialog, setClearPointsDialog] = React.useState({ open: false })
    const [approveOpenRequestsDialog, setApproveOpenRequestsDialog] = React.useState({ open: false })

    const [isLoading, setIsLoading] = React.useState(false)
    const [hasGrabbed, setHasGrabbed] = React.useState(false)

    const [viewAttachment, setViewAttachment] = React.useState(false)

    const [points, setPoints] = React.useState(Points.getNew())
    const [compiledMembers, setCompiledMembers] = React.useState([])

    const [pointsRequest, setPointsRequest] = React.useState(null)

    const [locationTracking, setLocationTracking] = React.useState(false)
    const [hasActiveLocationTrackingRequest, setHasActiveLocationTrackingRequest] = React.useState(false)

    const userLocation = React.useRef()
    const mapRef = React.useRef()
    const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,  // Number of rows per page
    page: 0,      // Current page (0-indexed)
  });

    const canViewPersonalPoints =
        points &&
        user.getId() in chapter.members &&
        ((!points.mType && points.vis && points.vis.includes(chapter.members[user.getId()].status)) ||
            (points.mType === 1 && Array.isArray(points.mems) && points.mems.includes(user.getId())))

    function hasPointsAdminIgnoringSupervisorInner() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role)) ||
                    (Array.isArray(chapter.perms['pointsCreate']) &&
                        chapter.perms['pointsCreate'].includes(mem.role) &&
                        points &&
                        points.author &&
                        user.getId() === points.author.id)
                )
            }
        }

        return false
    }

    function hasPointsAdminInner() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role)) ||
                    (Array.isArray(chapter.perms['pointsCreate']) &&
                        chapter.perms['pointsCreate'].includes(mem.role) &&
                        points &&
                        points.author &&
                        user.getId() === points.author.id) ||
                    (Array.isArray(chapter.perms['pointsCreate']) &&
                        chapter.perms['pointsCreate'].includes(mem.role) &&
                        points &&
                        Array.isArray(points.admins) &&
                        points.admins.includes(user.getId()))
                )
            }
        }

        return false
    }

    const hasPointsAdmin = hasPointsAdminInner()
    const hasPointsAdminIgnoringSupervisor = hasPointsAdminIgnoringSupervisorInner()

    if (!hasGrabbed && !isLoading) {
        setIsLoading(true)
        if (!hasPointsAdmin) {
            grabPointSystem()
        }
    }

    function getPointsRef() {
        const db = app.firestore()
        return db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('points')
            .doc(pointsId)
    }

    React.useEffect(() => {
        if (points && points.allowLocationTrackingRequests && user) {
            const ref = app.database().ref(`chapters/${user.getChapter()}/points/${pointsId}/locationTracking/${user.getId()}/status`)

            ref.on('value', snapshot => {
                if (snapshot.exists()) {
                    const run = async () => {
                        const data = await snapshot.val()

                        setHasActiveLocationTrackingRequest(data)
                    }
                    run()
                } else {
                    setHasActiveLocationTrackingRequest(false)
                }
            })

            return () => {
                // Get rid of the ref
                ref.off('value')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points])

    React.useEffect(() => {
        if (hasPointsAdmin) {
            const unsubscribe = getPointsRef().onSnapshot(
                function(doc) {
                    if (doc.exists) {
                        setIsLoading(false)
                        setHasGrabbed(true)
                        let p = doc.data()
                        let ps = new Points(p)

                        if (ps.locations) {
                            console.log('ps.locations', ps.locations)
                            ps.locations = convertObjectToList(ps.locations)
                        }

                        const applicableMembers =
                            ps.mType === 1
                                ? Object.keys(chapter.members).filter(memId => ps.mems.includes(memId))
                                : convertObjectToList(chapter.members)
                                      .filter(mem => ps.vis.includes(mem.status))
                                      .map(mem => mem.id)

                        setViewingUser(viewingUser => {
                            if (!viewingUser) {
                                if (applicableMembers.length > 0) {
                                    return applicableMembers[0]
                                }

                                return user ? user.getId() : ''
                            }

                            return viewingUser
                        })

                        setPoints(ps)
                        setCompiledMembers(compileMembers(ps))
                    } else {
                        // TODO: set points system as non existing
                    }
                },
                function(error) {
                    console.log('error', error)
                    //setEventExists(false)
                },
            )
            return () => {
                unsubscribe()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsId, hasPointsAdmin])

    function compileMembers(p) {
        let ps = points
        if (p !== undefined) {
            ps = p
        }

        let mems = chapter.members
        let memIds = Object.keys(mems)
        let memArray = []

        for (let i = 0; i < memIds.length; i++) {
            let memId = memIds[i]
            let memPoints = ps.getPoints(memId)
            let mem = { ...mems[memId], id: memId, points: memPoints }

            if (mem.roll && chapter && chapter.settings && chapter.settings.roll) {
                mem.roll = parseInt(mem.roll)
            }

            mem.or = ps.getNumOpenRequests(memId)

            if ((ps.mType === 0 && ps.vis.includes(mem.status)) || (ps.mType === 1 && ps.mems.includes(memId))) {
                memArray.push(mem)
            }
        }

        return memArray
    }

    function getUserPoints(uid) {
        for (let i = 0; i < compiledMembers.length; i++) {
            let mem = compiledMembers[i]
            if (mem.id === uid) {
                return mem.points
            }
        }

        return 0
    }

    function grabPointSystem() {
        getPointsRef()
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setHasGrabbed(true)
                    setIsLoading(false)
                    let p = doc.data()
                    let ps = new Points(p)

                    if (ps.locations) {
                        ps.locations = convertObjectToList(ps.locations)
                    }

                    setPoints(ps)
                    setCompiledMembers(compileMembers(ps))
                } else {
                    //setEventExists(false)
                }
            })
            .catch(function(error) {
                //setEventExists(false)
                console.log('Error getting point system:', error)
            })
    }

    function getPoints() {
        let pointObject = new Points(points)

        let userPoints = getUserPoints(user.getId())

        if (pointObject.getType() === Points.TYPE_GOAL) {
            let goal = points.goal

            let val = (userPoints / goal) * 100

            if (val > 100) {
                val = 100
            } else if (val < 0) {
                val = 0
            }

            return (
                <Box display="flex" className={classes.progressBox}>
                    <LinearProgress
                        variant="determinate"
                        value={val}
                        style={{ flexGrow: 1 }}
                        classes={{
                            barColorPrimary: classes.progressBar,
                        }}
                        className={classes.progress}
                    />
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {userPoints}/{goal} ({Number(val.toFixed(2))}%)
                    </Typography>
                </Box>
            )
        } else {
            return (
                <Box display="flex" className={classes.progressBox}>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {userPoints} Points
                    </Typography>
                </Box>
            )
        }
    }

    const [viewingUser, setViewingUser] = React.useState('')

    function getMemberPlacing(userId) {
        let mems = [...compiledMembers]

        if (mems.length === 0) {
            return 1
        }

        mems.sort((a, b) => b.points - a.points)

        let lastPlace = 1
        let lastPoints = mems[0].points

        for (let i = 0; i < mems.length; i++) {
            let mem = mems[i]

            let place = mem.points === lastPoints ? lastPlace : i + 1

            if (mem.id === userId) {
                return place
            }

            lastPlace = place
            lastPoints = mem.points
        }

        return mems.length
    }

    function getPlacing(val, total) {
        // Generate the background color based on placement
        const getPlacementColor = (position) => {
            switch(position) {
                case 1: return { background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)', text: '#000' }; // Gold
                case 2: return { background: 'linear-gradient(45deg, #E0E0E0 30%, #C0C0C0 90%)', text: '#000' }; // Silver
                case 3: return { background: 'linear-gradient(45deg, #CD7F32 30%, #A0522D 90%)', text: '#fff' }; // Bronze
                default: return { background: 'linear-gradient(45deg, #3f51b5 30%, #2196f3 90%)', text: '#fff' }; // Blue
            }
        };
        
        const colorScheme = getPlacementColor(val);
        
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: colorScheme.background,
                    color: colorScheme.text,
                    borderRadius: '8px',
                    p: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                    minHeight: '100px',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                <Box 
                    sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0.1,
                        background: `repeating-linear-gradient(
                            45deg,
                            #fff,
                            #fff 10px,
                            transparent 10px,
                            transparent 20px
                        )`
                    }}
                />
                
                <Box sx={{ 
                    position: 'relative', 
                    zIndex: 1, 
                    display: 'flex', 
                    alignItems: 'baseline',
                    justifyContent: 'center'
                }}>
                    <Typography 
                        variant="h1" 
                        component="span"
                        sx={{ 
                            fontWeight: 'bold', 
                            fontSize: { xs: '3rem', sm: '4rem' },
                            lineHeight: 1,
                            textShadow: '2px 2px 4px rgba(0,0,0,0.2)'
                        }}
                    >
                        {val}
                    </Typography>
                    <Typography 
                        component="span" 
                        sx={{ 
                            fontSize: { xs: '1.2rem', sm: '1.5rem' }, 
                            ml: 0.5,
                            fontWeight: 'medium',
                            verticalAlign: 'super'
                        }}
                    >
                        {getOrdinalSuffix(val)}
                    </Typography>
                </Box>
                
                <Typography 
                    variant="subtitle1"
                    sx={{ 
                        fontWeight: 'medium',
                        opacity: 0.9,
                        fontSize: { xs: '0.9rem', sm: '1rem' }
                    }}
                >
                    of {total} {total === 1 ? 'member' : 'members'}
                </Typography>
                
                <Typography 
                    variant="caption" 
                    sx={{ 
                        mt: 1,
                        textAlign: 'center',
                        fontStyle: 'italic',
                        opacity: 0.8,
                        fontSize: { xs: '0.7rem', sm: '0.8rem' }
                    }}
                >
                    {val === 1 ? 'Excellent work! You\'re leading the chapter!' : 
                     val <= 3 ? 'Great job! You\'re in the top positions!' : 
                     val <= Math.ceil(total/2) ? 'You\'re in the top half!' : 
                     'Keep participating to improve your ranking!'}
                </Typography>
            </Box>
        );
    }

    function submitPointRequest() {
        setPointsRequest({ ...pointsRequest, loading: true, error: false })

        let record = PointRecord.getNew()
        record.setName(pointsRequest.name)
        record.setDescription(pointsRequest.description)
        record.setPoints(parseInt(pointsRequest.points))
        record.setAttachment(pointsRequest.attachment)

        let dt = moment(pointsRequest.date)

        if (dt.isValid()) {
            record.setDateFromMoment(dt)
        }

        let submission = {
            chapter: user.getChapter(),
            pointsID: pointsId,
            record: record.getRaw(),
        }

        const functionsCall = app.functions().httpsCallable('submitPointRequest')
        functionsCall(submission)
            .then(function(result) {
                setPointsRequest(null)
                if (!hasPointsAdmin) {
                    setHasGrabbed(false)
                }
            })
            .catch(function(error) {
                var message = error.message
                setPointsRequest({ ...pointsRequest, loading: false, error: message })
            })
    }

    function clearAllPointRecords() {
        const data = {
            chapter: user.getChapter(),
            id: pointsId,
            action: 'remove',
        }

        setClearPointsDialog({ ...clearPointsDialog, loading: true })

        let functionsCall = app.functions().httpsCallable('removePoints')
        functionsCall(data)
            .then(function() {
                setClearPointsDialog({ open: false })
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setClearPointsDialog({ ...clearPointsDialog, loading: false, error: message })
            })
    }

    function getNumOpenRequests() {
        return points.getNumOpenRequests()
    }

    function getPersonalRecordsColumns() {
        // For mobile view, only return title, date and points columns
        if (isMobile) {
            return [
                {
                    field: 'name',
                    headerName: 'Title',
                    flex: 1.5,
                    minWidth: 150,
                    renderCell: ({ value }) => {
                        return value ? (
                            <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                {value}
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="textSecondary">—</Typography>
                        );
                    }
                },
                {
                    field: 'dt',
                    headerName: 'Date',
                    width: 85,
                    renderCell: ({ value }) => {
                        if (!value) return <Typography variant="body2" color="textSecondary">—</Typography>;
                        
                        try {
                            // Handle different timestamp formats from Firebase
                            let date;
                            if (value.seconds || value._seconds) {
                                // It's a Firebase timestamp object
                                date = getFirebaseDate({ date: value });
                            } else if (value instanceof Date) {
                                // It's already a Date object
                                date = value;
                            } else {
                                // Try to parse it as a string
                                date = new Date(value);
                            }
                            
                            if (!isNaN(date.getTime())) {
                                return (
                                    <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {moment(date).format('MM/DD/YYYY')}
                                    </Typography>
                                );
                            }
                        } catch (error) {
                            console.log("Date formatting error:", error);
                        }
                        
                        return <Typography variant="body2" color="textSecondary">Invalid date</Typography>;
                    },
                    valueGetter: ({ value }) => {
                        if (!value) return null;
                        
                        try {
                            if (value.seconds || value._seconds) {
                                const seconds = value.seconds || value._seconds;
                                return new Date(seconds * 1000);
                            } else if (value instanceof Date) {
                                return value;
                            } else {
                                const date = new Date(value);
                                return !isNaN(date.getTime()) ? date : null;
                            }
                        } catch (e) {
                            return null;
                        }
                    }
                },
                {
                    field: 'points',
                    headerName: 'Points',
                    width: 100,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: ({ value }) => (
                        <Typography 
                            align="center"
                            variant="body2" 
                            sx={{ 
                                fontWeight: 'medium',
                                color: value < 0 ? theme.palette.error.main : 'inherit',
                                width: '100%'
                            }}
                        >
                            {value}
                        </Typography>
                    )
                }
            ];
        }
        
        // For desktop view, return all columns
        return [
            {
                field: 'attachment',
                headerName: 'Attachment',
                width: 100,
                renderCell: ({ value }) => {
                    return value ? (
                        <IconButton
                            size="small"
                            onClick={() => setViewAttachment(value)}
                        >
                            <VisibilityIcon fontSize="small" />
                        </IconButton>
                    ) : (
                        <Typography variant="body2" color="textSecondary">—</Typography>
                    );
                }
            },
            {
                field: 'name',
                headerName: 'Title',
                flex: 1.5,
                minWidth: 150,
                renderCell: ({ value }) => {
                    return value ? (
                        <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {value}
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="textSecondary">—</Typography>
                    );
                }
            },
            {
                field: 'description',
                headerName: 'Description',
                flex: 1.5,
                minWidth: 150,
                renderCell: ({ value }) => {
                    return value ? (
                        <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {value}
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="textSecondary">—</Typography>
                    );
                }
            },
            {
                field: 'statusStr',
                headerName: 'Status',
                width: 100,
                renderCell: ({ value }) => {
                    let color = 'default';
                    if (value === 'Approved') color = 'success';
                    else if (value === 'Awaiting') color = 'info';
                    else if (value === 'Denied') color = 'error';
                    
                    return (
                        <Box 
                            sx={{ 
                                color: theme.palette[color].main,
                                fontWeight: 'medium',
                                fontSize: '0.875rem',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {value}
                        </Box>
                    );
                }
            },
            {
                field: 'dt',
                headerName: 'Date',
                width: 100,
                renderCell: ({ value }) => {
                    if (!value) return <Typography variant="body2" color="textSecondary">—</Typography>;
                    
                    try {
                        // Handle different timestamp formats from Firebase
                        let date;
                        if (value.seconds || value._seconds) {
                            // It's a Firebase timestamp object
                            date = getFirebaseDate({ date: value });
                        } else if (value instanceof Date) {
                            // It's already a Date object
                            date = value;
                        } else {
                            // Try to parse it as a string
                            date = new Date(value);
                        }
                        
                        if (!isNaN(date.getTime())) {
                            return (
                                <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {moment(date).format('MM/DD/YYYY')}
                                </Typography>
                            );
                        }
                    } catch (error) {
                        console.log("Date formatting error:", error);
                    }
                    
                    return <Typography variant="body2" color="textSecondary">Invalid date</Typography>;
                },
                valueGetter: ({ value }) => {
                    if (!value) return null;
                    
                    try {
                        if (value.seconds || value._seconds) {
                            const seconds = value.seconds || value._seconds;
                            return new Date(seconds * 1000);
                        } else if (value instanceof Date) {
                            return value;
                        } else {
                            const date = new Date(value);
                            return !isNaN(date.getTime()) ? date : null;
                        }
                    } catch (e) {
                        return null;
                    }
                }
            },
            {
                field: 'points',
                headerName: 'Points',
                width: 100,
                align: 'center',
                headerAlign: 'center',
                renderCell: ({ value }) => (
                    <Typography 
                        align="center"
                        variant="body2" 
                        sx={{ 
                            fontWeight: 'medium',
                            color: value < 0 ? theme.palette.error.main : 'inherit',
                            width: '100%'
                        }}
                    >
                        {value}
                    </Typography>
                )
            }
        ];
    }

    async function approveAllOpenRequests() {
        // TODO: open a dialog showing this action with a confirmation?
        // Upon confirmation, open a transaction to approve all open requests
        // Then close the dialog
        // If an error occurs, show it
        let errored = false

        await app
            .firestore()
            .runTransaction(async function(transaction) {
                const ref = getPointsRef()
                const pointSystemDoc = await transaction.get(ref)
                if (pointSystemDoc.exists) {
                    const pointSystemData = new Points(pointSystemDoc.data())

                    const updatedData = {
                        records: pointSystemData.records,
                    }

                    const userIds = Object.keys(updatedData.records)

                    for (let i = 0; i < userIds.length; i++) {
                        const userId = userIds[i]
                        const records = pointSystemData.getRecords(userId)
                        for (let j = 0; j < records.length; j++) {
                            const record = records[j]
                            if (record.status === PointRecord.STATUS_AWAITING) {
                                record.setStatus(PointRecord.STATUS_APPROVED)
                                records[j] = record
                            }
                        }
                        updatedData.records[userId] = PointRecord.convertArrayToRaw(records)
                    }

                    transaction.update(ref, updatedData)
                }
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                console.log('AN ERROR OCCURRED', code, message)
                setApproveOpenRequestsDialog({ ...approveOpenRequestsDialog, loading: false, error: message })
                errored = true
            })

        if (!errored) {
            setApproveOpenRequestsDialog({ open: false })
        }
    }

    function renderInvididualWidget() {
        // Get user stats for the selected user
        const userStats = viewingUser ? getUserStats(points, viewingUser) : null;

        // Safely check if userStats exists and has valid properties
        const hasStats = userStats && !userStats.error;
        const hasEventBreakdown = hasStats && userStats.eventBreakdown && Object.keys(userStats.eventBreakdown).length > 0;
        const hasApprovedRecords = hasStats && userStats.records && userStats.records.approved && userStats.records.approved.length > 0;
        
        // Format data for Recharts with safety checks
        const attendanceData = hasStats ? [
            { name: 'Attended', value: userStats.eventsAttended || 0, fill: '#4caf50' },
            { name: 'Absent', value: userStats.eventsAbsent || 0, fill: '#f44336' }
        ] : [];

        // Use fill gradient for better visual of progress percentage
        const progressFill = '#3f51b5';
        
        const progressData = hasStats ? [
            { name: 'Progress', value: userStats.progressTowardGoal || 0, fill: progressFill }
        ] : [];

        // Create a mapping of event names to their latest dates
        const eventDates = {};
        if (hasApprovedRecords) {
            userStats.records.approved.forEach(record => {
                if (record.name && record.dt) {
                    // If the event already exists, keep the most recent date
                    if (!eventDates[record.name] || record.dt > eventDates[record.name]) {
                        eventDates[record.name] = record.dt;
                    }
                }
            });
        }
        
        return (
        <ParentTable
            title={`Individual Statistics: ${viewingUser ? `${chapter.members[viewingUser].first} ${chapter.members[viewingUser].last}` : ''}`}
            actions={[
                {
                    title: 'Download Records',
                    icon: <DownloadIcon />,
                    onClick: event => {
                        let records = points.getRecords(viewingUser)

                        let finalized = []

                        for (let i = 0; i < records.length; i++) {
                            let rec = records[i]
                            let dt = moment(getFirebaseDate({ date: rec.dt })).format('l')
                            finalized.push({
                                Title: rec.name,
                                Description: rec.description,
                                Status: rec.statusStr,
                                Date: dt,
                                Points: rec.points,
                                Attachment: rec.attachment,
                            })
                        }

                        const element = document.createElement('a')
                        const file = new Blob([Papa.unparse(finalized)], {
                            type: 'text/csv',
                        })

                        let mem = null

                        for (let i = 0; i < compiledMembers.length; i++) {
                            if (compiledMembers[i].id === viewingUser) {
                                mem = compiledMembers[i]
                                i = compiledMembers.length
                            }
                        }

                        let pointsName = points.name.replace(/\W/g, '')

                        element.href = URL.createObjectURL(file)
                        element.download = mem !== null ? `${pointsName}_records_${mem.first}_${mem.last}.csv` : `${pointsName}_records_${viewingUser}.csv`
                        document.body.appendChild(element)
                        element.click()
                    },
                },
            ]}
        >
            <Grid container>
                {/* Analytics Dashboard */}
                {hasStats && (
                    <Grid item xs={12} style={{ padding: '16px 24px' }}>
                        <Grid container spacing={3}>
                            {/* Progress stats */}
                            <Grid item xs={12} md={6}>
                                <Box p={2} border={1} borderColor="grey.300" borderRadius={4} height="100%">
                                    <Typography variant="subtitle1" gutterBottom>
                                        Contribution to Chapter
                                    </Typography>
                                    <Box height={200} display="flex" flexDirection="column" justifyContent="center">
                                        <ResponsiveContainer width="100%" height="100%">
                                            <RadialBarChart 
                                                cx="50%" 
                                                cy="50%" 
                                                innerRadius="60%" 
                                                outerRadius="80%" 
                                                barSize={10} 
                                                data={progressData}
                                                startAngle={180}
                                                endAngle={0}
                                            >
                                                <PolarAngleAxis
                                                    type="number"
                                                    domain={[0, 100]}
                                                    angleAxisId={0}
                                                    tick={false}
                                                />
                                                <RadialBar
                                                    background
                                                    clockWise
                                                    dataKey="value"
                                                    cornerRadius={15}
                                                    fill={progressFill}
                                                />
                                                <text 
                                                    x="50%" 
                                                    y="50%" 
                                                    textAnchor="middle" 
                                                    dominantBaseline="middle"
                                                    style={{ fontSize: '24px', fontWeight: 'bold' }}
                                                >
                                                    {userStats.progressTowardGoal || 0}%
                                                </text>
                                            </RadialBarChart>
                                        </ResponsiveContainer>
                                        <Box mt={1} textAlign="center">
                                            <Typography variant="body2">
                                                {userStats.approvedPoints || 0} / {(points && points.goal) || 0} points
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {userStats.pendingPoints || 0} points pending
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            
                            {/* Attendance Statistics */}
                            <Grid item xs={12} md={6}>
                                <Box p={2} border={1} borderColor="grey.300" borderRadius={4} height="100%">
                                    <Typography variant="subtitle1" gutterBottom>
                                        Attendance Statistics
                                    </Typography>
                                    <Box height={200}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <PieChart>
                                                <ReChartsPie
                                                    data={attendanceData}
                                                    cx="50%"
                                                    cy="50%"
                                                    innerRadius={50}
                                                    outerRadius={70}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                    labelLine={false}
                                                    label={({name, percent}) => `${name} ${(percent * 100).toFixed(0)}%`}
                                                >
                                                    {attendanceData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.fill} />
                                                    ))}
                                                </ReChartsPie>
                                                <RechartsTooltip formatter={(value) => [`${value} events`, null]} />
                                            </PieChart>
                                        </ResponsiveContainer>
                                        
                                    </Box>
                                </Box>
                            </Grid>
                            {/* Event Breakdown */}
                            {hasEventBreakdown && (
                                <Grid item xs={12}>
                                    <Box p={2} border={1} borderColor="grey.300" borderRadius={4}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Cumulative Point Activity Over Time
                                        </Typography>
                                        <Box height={isMobile ? 350 : 300}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <LineChart
                                                    data={
                                                        hasApprovedRecords ? 
                                                        userStats.records.approved
                                                            .filter(record => record.dt)
                                                            .sort((a, b) => {
                                                                const dateA = getFirebaseDate({ date: a.dt });
                                                                const dateB = getFirebaseDate({ date: b.dt });
                                                                return dateA - dateB;
                                                            })
                                                            .reduce((acc, record, index) => {
                                                                const date = getFirebaseDate({ date: record.dt });
                                                                const dateStr = moment(date).format('MM/DD/YYYY');
                                                                
                                                                // Add cumulative points
                                                                const prevPoints = index > 0 ? acc[index - 1].cumulativePoints : 0;
                                                                const newPoints = prevPoints + record.points;
                                                                
                                                                acc.push({
                                                                    name: dateStr,
                                                                    date: date,
                                                                    points: record.points,
                                                                    cumulativePoints: newPoints,
                                                                    eventName: record.name || 'Unknown Event'
                                                                });
                                                                
                                                                return acc;
                                                            }, [])
                                                        : []
                                                    }
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 40,
                                                    }}
                                                    animationDuration={2000}
                                                    animationEasing="ease-in-out"
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis 
                                                        dataKey="name"
                                                        tick={{ fontSize: 12, angle: -45, textAnchor: 'end' }}
                                                        height={60}
                                                    />
                                                    <YAxis 
                                                        label={{ 
                                                            value: 'Cumulative Points', 
                                                            angle: -90, 
                                                            position: 'insideLeft',
                                                            style: { textAnchor: 'middle' }
                                                        }}
                                                    />
                                                    <ReferenceLine y={0} stroke="#666" strokeDasharray="3 3" />
                                                    <RechartsTooltip 
                                                        formatter={(value, name, props) => [
                                                            `${value} points`, 
                                                            name === 'cumulativePoints' ? 'Total Points' : 'Event Points'
                                                        ]}
                                                        labelFormatter={(value, payload) => {
                                                            if (payload && payload[0] && payload[0].payload) {
                                                                const item = payload[0].payload;
                                                                return `${item.name} - ${item.eventName}`;
                                                            }
                                                            return value;
                                                        }}
                                                    />
                                                    <Line
                                                        type="monotone"
                                                        dataKey="cumulativePoints"
                                                        name="cumulativePoints"
                                                        stroke="#3f51b5"
                                                        strokeWidth={2}
                                                        dot={{ r: 4 }}
                                                        activeDot={{ r: 6 }}
                                                        animationDuration={1200}
                                                        animationEasing="ease-out"
                                                        isAnimationActive={true}
                                                    />
                                                    <Line
                                                        type="monotone"
                                                        dataKey="points"
                                                        name="points"
                                                        stroke="#4caf50"
                                                        strokeWidth={1}
                                                        strokeDasharray="5 5"
                                                        dot={{ r: 3 }}
                                                        animationDuration={800}
                                                        animationEasing="ease-in"
                                                        isAnimationActive={true}
                                                    />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}

                {/* No Data Message */}
                {userStats && userStats.error && (
                    <Grid item xs={12} style={{ padding: '16px 24px' }}>
                        <Box p={3} textAlign="center" border={1} borderColor="grey.300" borderRadius={4}>
                            <Typography variant="h6" color="textSecondary">
                                No points data available for this member
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                This member hasn't participated in any point-earning activities yet.
                            </Typography>
                        </Box>
                    </Grid>
                )}
                
                {/* Original Records Table */}
                <Grid item xs={12} key={`records_${viewingUser}`}>
                    <Table
                        title="Records & Point Requests"
                        sortable
                        defaultSortable={{
                            value: 'dt',
                            dir: 'desc',
                        }}
                        columns={[
                            {
                                title: 'Attachment',
                                value: 'attachment',
                                type: 'IMAGE',
                                onClick: data => {
                                    setViewAttachment(data.attachment)
                                },
                                tooltip: 'View Attachment',
                            },
                            {
                                title: 'Title',
                                value: 'name',
                                size: 'large',
                                required: true,
                                sortable: true,
                            },
                            {
                                title: 'Description',
                                value: 'description',
                                required: false,
                                sortable: true,
                            },
                            {
                                title: 'Status',
                                value: 'statusStr',
                                size: 'small',
                                type: 'SELECT',
                                required: true,
                                sortable: true,
                                options: PointRecord.getStatusOptions(),
                            },
                            {
                                title: 'Date',
                                value: 'dt',
                                type: 'TIME',
                                format: 'l',
                                disabled: true,
                                sortable: true,
                            },
                            {
                                title: 'Points',
                                value: 'points',
                                size: 'small',
                                type: 'NUMBER',
                                required: true,
                                sortable: true,
                            },
                        ]}
                        selectActions={[
                            {
                                key: 0,
                                title: num => {
                                    if (num === 1) {
                                        return 'Remove 1 Record'
                                    }
                                    return 'Remove ' + num + ' Records'
                                },
                                icon: <RemoveIcon />,
                                onClick: rows => {
                                    let p = _.cloneDeep(points)
                                    for (let i = 0; i < rows.length; i++) {
                                        p.removeRecord(viewingUser, rows[i].id)
                                    }

                                    let updatedField = `records.${viewingUser}`

                                    getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(p.records[viewingUser]) })
                                },
                            },
                        ]}
                        rowActions={[
                            {
                                title: 'Approve',
                                icon: <ApproveIcon />,
                                onClick: data => {
                                    if (data.statusStr !== 'Approved') {
                                        let records = points.getRecords(viewingUser)

                                        for (let i = 0; i < records.length; i++) {
                                            let record = records[i]
                                            if (record.id === data.id) {
                                                record = new PointRecord(record)

                                                record.setStatusFromString('Approved')
                                                records[i] = record
                                            }
                                        }

                                        let updatedField = `records.${viewingUser}`

                                        getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(records) })
                                    }
                                },
                            },
                            {
                                title: 'Edit',
                                icon: <EditIcon />,
                                toggleEditing: true,
                            },
                        ]}
                        onUpdateRow={data => {
                            let records = points.getRecords(viewingUser)

                            for (let i = 0; i < records.length; i++) {
                                let record = records[i]
                                if (record.id === data.id) {
                                    let oldAttachment = record.getAttachment()
                                    record = new PointRecord(record)
                                    record.setName(data.name)
                                    record.setDescription(data.description)
                                    record.setPoints(parseInt(data.points))
                                    record.setStatusFromString(data.statusStr)
                                    record.setAttachment(oldAttachment)

                                    let dt = moment(data.dt)

                                    if (dt.isValid()) {
                                        record.setDateFromMoment(dt)
                                    }

                                    records[i] = record
                                }
                            }

                            let updatedField = `records.${viewingUser}`

                            getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(records) })
                        }}
                        id="id"
                        onAdd={data => {
                            let p = _.cloneDeep(points)
                            let record = PointRecord.getNew()
                            record.setName(data.name)
                            record.setDescription(data.description)
                            record.setPoints(parseInt(data.points))
                            record.setStatusFromString(data.statusStr)

                            let dt = moment(data.dt)

                            if (dt.isValid()) {
                                record.setDateFromMoment(dt)
                            }

                            p.addRecord(viewingUser, record)

                            let updatedField = `records.${viewingUser}`

                            getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(p.records[viewingUser]) })
                        }}
                        showOnEmpty={true}
                        emptyText="No Records"
                        addRowText="Add Record"
                        data={points.getRecords(viewingUser)}
                    />
                </Grid>
            </Grid>
        </ParentTable>
    )}

    // Add state for active tab
    const [activeTab, setActiveTab] = React.useState(0);
    
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    
    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Points',
                            link: '/app/applications/points/',
                            icon: <PointsIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'System',
                        },
                    ]}
                    rightButtons={
                        hasPointsAdminIgnoringSupervisor
                            ? [
                                  {
                                      name: 'Edit',
                                      type: 'icon',
                                      innerIcon: <EditIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/points/edit/' + pointsId)
                                      },
                                  },
                              ]
                            : []
                    }
                    key={hasPointsAdminIgnoringSupervisor ? 'admin' : 'nah'}
                    grid
                />
                {hasGrabbed && (
                    <>
                        <Grid item xs={12}>
                            {/* Add Tabs Component */}
                            <Box 
                                sx={{ 
                                    borderBottom: 1, 
                                    borderColor: 'divider',
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                                    borderRadius: '4px 4px 0 0'
                                }}
                            >
                                <Tabs 
                                    value={activeTab} 
                                    onChange={handleTabChange} 
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="points system tabs"
                                >
                                    <Tab 
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <PointsIcon fontSize="small" />
                                                <span>My Points</span>
                                            </Box>
                                        } 
                                    />
                                    {(hasPointsAdmin || points.rankingsVisibility === Points.RANKINGS_ALL) && (
                                        <Tab 
                                            label={
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <GroupIcon fontSize="small" />
                                                    <span>Chapter Stats</span>
                                                </Box>
                                            }
                                        />
                                    )}
                                    {(hasPointsAdmin || points.rankingsVisibility === Points.RANKINGS_ALL) && (
                                        <Tab 
                                            label={
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <AnalyticsIcon fontSize="small" />
                                                    <span>Event Analytics</span>
                                                </Box>
                                            }
                                        />
                                    )}
                                </Tabs>
                            </Box>
                        </Grid>
                        
                        {/* Tab Content for My Points */}
                        {activeTab === 0 && (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    <MyPointsTab 
                                        user={user}
                                        chapter={chapter}
                                        points={points}
                                        compiledMembers={compiledMembers}
                                        pointsRequest={pointsRequest}
                                        setPointsRequest={setPointsRequest}
                                        userLocation={userLocation}
                                        mapRef={mapRef}
                                        canViewPersonalPoints={canViewPersonalPoints}
                                        hasActiveLocationTrackingRequest={hasActiveLocationTrackingRequest}
                                        setLocationTracking={setLocationTracking}
                                        hasPointsAdmin={hasPointsAdmin}
                                        classes={classes}
                                        submitPointRequest={submitPointRequest}
                                        theme={theme}
                                        setViewAttachment={setViewAttachment}
                                        mapkit={mapkit}
                                        getCurrentLocation={getCurrentLocation}
                                    />
                                ) : (
                                    <Widget disableWidgetMenu inheritHeight>
                                        <MyPointsTab 
                                            user={user}
                                            chapter={chapter}
                                            points={points}
                                            compiledMembers={compiledMembers}
                                            pointsRequest={pointsRequest}
                                            setPointsRequest={setPointsRequest}
                                            userLocation={userLocation}
                                            mapRef={mapRef}
                                            canViewPersonalPoints={canViewPersonalPoints}
                                            hasActiveLocationTrackingRequest={hasActiveLocationTrackingRequest}
                                            setLocationTracking={setLocationTracking}
                                            hasPointsAdmin={hasPointsAdmin}
                                            classes={classes}
                                            submitPointRequest={submitPointRequest}
                                            theme={theme}
                                            setViewAttachment={setViewAttachment}
                                            mapkit={mapkit}
                                            getCurrentLocation={getCurrentLocation}
                                        />
                                    </Widget>
                                )}
                            </Grid>
                        )}
                        
                        {/* Tab Content for Chapter Stats */}
                        {activeTab === 1 && (hasPointsAdmin || points.rankingsVisibility === Points.RANKINGS_ALL) && (
                            <>
                                <Grid item xs={12}>
                                    {isMobileDevice() ? (
                                        <Grid container spacing={2} style={{ margin: '0 -16px', width: 'calc(100% + 32px)' }}>
                                            <Grid item xs={12}>
                                                <Box style={{ margin: '0 -8px' }}>
                                                    <ChapterStatsTab 
                                                        compiledMembers={compiledMembers}
                                                        points={points}
                                                        chapter={chapter}
                                                        hasPointsAdmin={hasPointsAdmin}
                                                        theme={theme}
                                                        paginationModel={paginationModel}
                                                        setPaginationModel={setPaginationModel}
                                                        setViewingUser={setViewingUser}
                                                        viewingUser={viewingUser}
                                                        setClearPointsDialog={setClearPointsDialog}
                                                        setApproveOpenRequestsDialog={setApproveOpenRequestsDialog}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Widget disableWidgetMenu noBodyPadding>
                                            <ChapterStatsTab 
                                                compiledMembers={compiledMembers}
                                                points={points}
                                                chapter={chapter}
                                                hasPointsAdmin={hasPointsAdmin}
                                                theme={theme}
                                                paginationModel={paginationModel}
                                                setPaginationModel={setPaginationModel}
                                                setViewingUser={setViewingUser}
                                                viewingUser={viewingUser}
                                                setClearPointsDialog={setClearPointsDialog}
                                                setApproveOpenRequestsDialog={setApproveOpenRequestsDialog}
                                            />
                                        </Widget>
                                    )}
                                </Grid>
                                
                                {hasPointsAdmin && (
                                    <Grid item xs={12}>
                                        {isMobileDevice() ? (
                                            <Grid container spacing={2} style={{ margin: '0 -16px', width: 'calc(100% + 32px)' }}>
                                                <Grid item xs={12}>
                                                    <Box style={{ margin: '0 -8px' }}> {renderInvididualWidget()}</Box>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Widget disableWidgetMenu noBodyPadding>
                                                {renderInvididualWidget()}
                                            </Widget>
                                        )}
                                    </Grid>
                                )}
                            </>
                        )}
                        
                        {/* Tab Content for Event Analytics */}
                        {activeTab === 2 && (hasPointsAdmin || points.rankingsVisibility === Points.RANKINGS_ALL) && (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    <Grid container spacing={2} style={{ margin: '0 -16px', width: 'calc(100% + 32px)' }}>
                                        <Grid item xs={12}>
                                            <Box style={{ margin: '0 -8px' }}>
                                                <EventAnalyticsTab 
                                                    points={points}
                                                    chapter={chapter}
                                                    theme={theme}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Widget disableWidgetMenu noBodyPadding>
                                        <EventAnalyticsTab 
                                            points={points}
                                            chapter={chapter}
                                            theme={theme}
                                        />
                                    </Widget>
                                )}
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            
            {/* Keep the existing dialogs */}
            {clearPointsDialog.open && (
                <Dialog
                    open={clearPointsDialog.open}
                    onClose={() => {
                        if (!clearPointsDialog.loading) {
                            setClearPointsDialog({ open: false })
                        }
                    }}
                    aria-labelledby="clear-points-title"
                >
                    <DialogTitle id="clear-points-title">Clear System Points?</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to clear all the point records for the system?</Typography>
                        {clearPointsDialog.error && <ErrorTypography text={clearPointsDialog.error} isCentered={true} />}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={clearPointsDialog.loading} onClick={() => setClearPointsDialog({ open: false })} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={clearPointsDialog.loading} onClick={() => clearAllPointRecords()} color="red">
                            Clear
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {approveOpenRequestsDialog.open && (
                <Dialog
                    open={approveOpenRequestsDialog.open}
                    onClose={() => {
                        if (!approveOpenRequestsDialog.loading) {
                            setApproveOpenRequestsDialog({ open: false })
                        }
                    }}
                    aria-labelledby="approve-points-title"
                >
                    <DialogTitle id="approve-points-title">Approve all open point requests?</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to approve all the open point requests?</Typography>
                        {approveOpenRequestsDialog.error && <ErrorTypography text={approveOpenRequestsDialog.error} isCentered={true} />}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={approveOpenRequestsDialog.loading} onClick={() => setApproveOpenRequestsDialog({ open: false })} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={approveOpenRequestsDialog.loading} onClick={() => approveAllOpenRequests()} color="primary">
                            Approve
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <FullScreenImage open={viewAttachment !== false} onClose={() => setViewAttachment(false)} src={viewAttachment} />
            {locationTracking && (
                <LocationTrackingDialog
                    points={{ ...points, id: pointsId }}
                    location={
                        pointsRequest && pointsRequest.selectedLocation
                            ? points.locations[
                                  points.locations
                                      .map(location => `${location?.data?.coordinate?.latitude || location.center.latitude}.${location?.data?.coordinate?.longitude || location.center.longitude}` === pointsRequest.selectedLocation)
                                      .indexOf(true)
                              ]
                            : null
                    }
                    onClose={() => {
                        setLocationTracking(false)
                        setPointsRequest(null)
                    }}
                />
            )}
        </>
    )
}
