import React, { useContext, useState } from 'react';
// Removing Swiper imports as we'll use Stack-like structure instead
// import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper core and required modules
// import SwiperCore, { Navigation, Pagination } from 'swiper';
// import { useChapterPoints } from 'hooks/queries/useChapterPoints';
// Import Swiper styles - changed to use bundle
// import 'swiper/swiper-bundle.css';
import { makeStyles } from '@material-ui/styles';
import { ChapterContext } from 'context/ChapterContext';
import { AuthContext } from 'context/AuthContext';
import { 
  Box, 
  Paper, 
  Grid, 
  Avatar, 
  Tabs, 
  Tab, 
  Badge, 
  Divider,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent
} from '@material-ui/core';
import { 
  Home as HomeIcon,
  NotificationsOutlined as NotificationsIcon,
  EventNote as EventIcon,
  AccessTime as TimeIcon,
  CheckCircle as CheckIcon,
  Person as PersonIcon
} from '@material-ui/icons';
import { Typography } from 'components/Wrappers';
import Widget from 'components/Widget';
import { Tasks } from 'objects/Tasks';

// Remove Swiper module installation
// SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles(theme => ({
  activityFeedContainer: {
    maxWidth: '100%',
    width: '800px', // Increased width for web view
    margin: '0 auto',
    padding: 0,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 600,
    }
  },
  header: {
    background: '#4285F4',
    color: 'white',
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: 64,
    height: 64,
    marginRight: theme.spacing(2),
    border: '2px solid rgba(255, 255, 255, 0.6)',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginBottom: theme.spacing(0.5),
  },
  title: {
    opacity: 0.9,
    fontSize: '1rem',
  },
  statsContainer: {
    background: 'rgba(255, 255, 255, 0.15)',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  statBox: {
    flex: 1,
    padding: theme.spacing(1.5),
    textAlign: 'center',
    '&:first-child': {
      borderRight: '1px solid rgba(255, 255, 255, 0.2)',
    },
  },
  statNumber: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },
  statLabel: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  tabsContainer: {
    marginBottom: theme.spacing(1),
    boxShadow: 'none',
    background: 'transparent',
  },
  tabs: {
    borderBottom: '1px solid #eee',
  },
  tab: {
    minWidth: 0,
    '&.Mui-selected': {
      color: '#4285F4',
    },
  },
  tabIndicator: {
    backgroundColor: '#4285F4',
  },
  feedContent: {
    flex: 1,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 220px)', // Adjusted height now that bottom nav is removed
  },
  activityItem: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #eee',
    display: 'flex',
    alignItems: 'flex-start',
    '&:last-child': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
    '&:hover': {
      background: '#f9f9f9',
    },
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamMeetingIcon: {
    color: '#4285F4',
  },
  deadlineIcon: {
    color: '#EA4335',
  },
  taskIcon: {
    color: '#34A853',
  },
  documentIcon: {
    color: '#9C27B0',
  },
  activityDetails: {
    flex: 1,
  },
  activityTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    fontSize: '1rem',
  },
  activityTime: {
    color: '#666',
    marginBottom: theme.spacing(0.5),
    fontSize: '0.85rem',
  },
  activityDescription: {
    fontSize: '0.9rem',
    color: '#444',
  }
}));

// Activity item component with icon based on type
const ActivityItem = ({ activity }) => {
  const classes = useStyles();
  
  const renderIcon = () => {
    switch (activity.type) {
      case 'meeting':
        return <EventIcon className={classes.teamMeetingIcon} />;
      case 'deadline':
        return <TimeIcon className={classes.deadlineIcon} />;
      case 'task':
        return <CheckIcon className={classes.taskIcon} />;
      case 'document':
        return <PersonIcon className={classes.documentIcon} />;
      default:
        return <EventIcon className={classes.teamMeetingIcon} />;
    }
  };
  
  return (
    <Box className={classes.activityItem}>
      <Box className={classes.iconContainer}>
        {renderIcon()}
      </Box>
      <Box className={classes.activityDetails}>
        <Typography className={classes.activityTitle}>
          {activity.title}
        </Typography>
        <Typography className={classes.activityTime}>
          {activity.time}
        </Typography>
        <Typography className={classes.activityDescription}>
          {activity.description}
        </Typography>
      </Box>
    </Box>
  );
};

const ActivityFeed = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { chapter } = useContext(ChapterContext);
  const { user } = useContext(AuthContext);
  const [tabValue, setTabValue] = useState(0);
  
  /*
  const { data: pointSystems, isLoading, error } = useChapterPoints({
    chapterId: chapterId,
    userId: userId,
    memberStatus: memberStatus
  });
  */

  // console.log(pointSystems)
  
  // Example activity data matching the screenshot
  const activities = [
    {
      id: 1,
      type: 'meeting',
      title: 'Team Meeting',
      time: 'Monday, 9:00 AM',
      description: 'Weekly sprint planning with the development team',
    },
    {
      id: 2,
      type: 'deadline',
      title: 'Project Deadline',
      time: 'Wednesday, 5:00 PM',
      description: 'Submit Q1 project report to stakeholders',
    },
    {
      id: 3,
      type: 'task',
      title: 'New Task Assigned',
      time: 'Thursday, 11:30 AM',
      description: 'Review and approve the new UI design mockups',
    },
    {
      id: 4,
      type: 'document',
      title: 'Documents Updated',
      time: 'Friday, 2:15 PM',
      description: 'Project requirements document has been updated',
    },
  ];

  // Example notifications data - replace with actual data
  const notifications = [
    {
      id: 1,
      title: 'Event Sign In Open',
      description: 'Weekly Chapter Meeting sign-in is now open',
      timestamp: '1 hour ago',
      type: 'event'
    },
    {
      id: 2,
      title: 'Task Deadline Approaching',
      description: 'Recruitment task due in 2 days',
      timestamp: '3 hours ago',
      type: 'task'
    },
    {
      id: 3,
      title: 'New Message',
      description: 'You have a new message from Chapter President',
      timestamp: '1 day ago',
      type: 'message'
    }
  ];

  // Example action items data - replace with actual data
  const actionItems = {
    tasks: 3,
    events: 2,
    messages: 5
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderHeader = () => (
    <Box className={classes.header}>
      <Box className={classes.headerContent}>
        <Avatar className={classes.avatar} src="/static/images/avatar/1.jpg">
          {user && user.first ? user.first.charAt(0) : 'A'}
        </Avatar>
        <Box>
          <Typography className={classes.name}>
            {user && user.first ? `${user.first} ${user.last}` : 'Alex Johnson'}
          </Typography>
          <Typography className={classes.title}>
            {chapter && chapter.members && user && 
              chapter.members[user.getId()] ? 
              chapter.members[user.getId()].role : 'Project Manager'}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.statsContainer}>
        <Box className={classes.statBox}>
          <Typography className={classes.statNumber}>3</Typography>
          <Typography className={classes.statLabel}>ACTION ITEMS</Typography>
        </Box>
        <Box className={classes.statBox}>
          <Typography className={classes.statNumber}>7</Typography>
          <Typography className={classes.statLabel}>COMPLETED</Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderTabs = () => (
    <Paper className={classes.tabsContainer}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        classes={{ indicator: classes.tabIndicator }}
        className={classes.tabs}
      >
        <Tab 
          icon={<HomeIcon />} 
          label="Activity" 
          className={classes.tab}
        />
        <Tab 
          icon={
            <Badge badgeContent={2} color="secondary">
              <NotificationsIcon />
            </Badge>
          } 
          label="Notifications" 
          className={classes.tab}
        />
      </Tabs>
    </Paper>
  );

  const renderActivitiesFeed = () => (
    <Box className={classes.feedContent}>
      {activities.map((activity) => (
        <ActivityItem key={activity.id} activity={activity} />
      ))}
    </Box>
  );

  const renderNotificationsTab = () => (
    <Box className={classes.feedContent}>
      <Typography variant="body1" style={{ padding: 16, textAlign: 'center', color: '#666' }}>
        You have 2 unread notifications
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.activityFeedContainer}>
      {renderHeader()}
      {renderTabs()}
      {tabValue === 0 ? renderActivitiesFeed() : renderNotificationsTab()}
    </Box>
  );
};

export default ActivityFeed;
