import React from 'react'

import { Capacitor, registerPlugin } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'

export const BackgroundGeolocation = registerPlugin('BackgroundGeolocation')

export const LocationContext = React.createContext()

/*global mapkit*/
export const LocationProvider = ({ children }) => {
    // mapkit is now initialized in the index.html
    /*
    const tokenID = process.env.REACT_APP_APPLE_MAPKIT_TOKEN ?? 'eyJraWQiOiJIVzhEOTU3QzgyIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiI1OEs3UVYyWjc1IiwiaWF0IjoxNzM3ODMwOTk3LCJvcmlnaW4iOiJncmVla2Nvbm5lY3QuYXBwIn0.NRO8RyajjrrJJTI1DFLyBwNIOflEPBzZ7L2Q1z4fB_B8PXRFMxsiuhplHILIyDSj4dB3lDXNzMbekPuAWZsjVA'

    React.useEffect(() => {
        mapkit.init({
            authorizationCallback: function(done) {
                done(tokenID)
            },
            language: 'en',
        })
    }, [tokenID])
    */

    const getCurrentLocation = async props => {
        if (Capacitor.isPluginAvailable('Geolocation')) {
            const coordinates = await Geolocation.getCurrentPosition()

            return coordinates
        }

        return null
    }

    return (
        <LocationContext.Provider
            value={{
                mapkit,
                getCurrentLocation,
            }}
        >
            {children}
        </LocationContext.Provider>
    )
}
