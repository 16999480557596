import React, { useEffect, useState } from 'react'

import {
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    DialogActions,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Icon,
    IconButton,
    InputBase,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Snackbar,
    TextField,
} from '@material-ui/core'
import Stack from '@mui/material/Stack'
import {
    RemoveCircleOutline as AbsentIcon,
    DeleteOutline as DeleteIcon,
    EmojiEvents as PointsIcon,
    ThumbUpOutlined as ApprovedIcon,
    PersonOutlineOutlined as AuthorIcon,
    CakeOutlined as BirthdayIcon,
    CalendarToday as CalendarIcon,
    CancelOutlined as CloseCircleIcon,
    Close as CloseIcon,
    ThumbDownOutlined as DeniedIcon,
    Subject as DescriptionIcon,
    CloudDownloadOutlined as DownloadIcon,
    FileCopyOutlined as DuplicateIcon,
    CreateOutlined as EditIcon,
    EmailOutlined as EmailIcon,
    FaceOutlined as FaceIcon,
    HomeOutlined as HomeIcon,
    Link as LinkIcon,
    LocationOnOutlined as LocationIcon,
    ForumOutlined as MessagesIcon,
    Check as OpenIcon,
    VpnKey as PasswordIcon,
    PhoneOutlined as PhoneIcon,
    PollOutlined as PollsIcon,
    AddCircleOutline as PresentIcon,
    Sync as SyncIcon,
    SyncDisabled as SyncOffIcon,
    Event as TimeIcon,
    VisibilityOutlined as VisibilityIcon,
} from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'

import app from 'firebase/app'
import 'firebase/firestore'

import { renderVisibility } from 'data/Visibility'

import QRCode from 'qrcode.react'
import QrReader from 'react-qr-scanner'

// components
import MuiAlert from '@mui/material/Alert'
import { addNames, clearNotifications, convertListToObject, convertObjectToList, isMobileDevice } from 'code/Helper'
import { getDateFromYMD, getEventEnd, getEventStart, getEventTiming, getFirebaseDate } from 'code/TimeAgo'
import { generateUUID } from 'code/UUID'
import DocumentUpload from 'components/DocumentUpload'
import { CalendarAddIcon, ShareIosIcon } from 'components/Icons'
import NavigationBar from 'components/NavigationBar'
import ParentTable from 'components/Tables/ParentTable'
import Table from 'components/Tables/Table'
import { Clipboard as ClipboardHeader, Link as LinkHeader, Progress as ProgressHeader, Status as StatusHeader } from 'components/Tables/TableHeader'
import Tabs from 'components/Tabs'
import Timeline from 'components/Timeline'
import CheckedTypography from 'components/Typography/CheckedTypography'
import ErrorTypography from 'components/Typography/ErrorTypography'
import WarningTypography from 'components/Typography/WarningTypography'
import Widget from 'components/Widget'
import {
    Button,
    Chip as CustomChip,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    Dialog as DialogMobile,
    DialogTitle as DialogTitleMobile,
    Typography,
} from 'components/Wrappers'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { FraternityContext } from 'context/FraternityContext'
import { Document, Page, Text } from 'components/PDF'
import CopyToClipboard from 'components/copy-to-clipboard/CopyToClipboard'
import { withStyles } from '@material-ui/core/styles'
import { pdf } from '@react-pdf/renderer'
import MuiLinkify from 'material-ui-linkify'
import moment from 'moment'

// styles
import useStyles from '../styles'

// Add import at the top with other imports
import DeleteEventDialog from '../../DeleteEventDialog'
import { useDeleteEvent } from 'hooks/mutations/useDeleteEvent'

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        fontSize: 16,
        padding: '2px 20px 3px 8px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase)

const ical = require('ical-generator')

export default function ViewEvent(props) {
    var classes = useStyles()

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    const db = app.firestore()

    const { currentUser, user } = React.useContext(AuthContext)
    const { chapter, requestChapterUpdateWithDelay } = React.useContext(ChapterContext)
    const { fraternity } = React.useContext(FraternityContext)

    const [fakeCurrentDate, setFakeCurrentDate] = React.useState(new Date()) // default value can be anything you want

    React.useEffect(() => {
        // Redraw every minute
        setTimeout(
            () => setFakeCurrentDate(new Date()),
            moment()
                .startOf('minute')
                .add(1, 'minute')
                .diff(moment()),
        )
    }, [fakeCurrentDate])

    const qrScannerRef = React.createRef()

    const [isDifferentTimezone] = React.useState(checkTimezoneDF())

    function checkTimezoneDF() {
        let userTZ = moment.tz.guess()

        let chapterTZ = chapter && chapter.settings && chapter.settings.timezone ? chapter.settings.timezone : 'America/New_York'
        return userTZ !== chapterTZ
    }

    const [snackbar, setSnackbar] = React.useState(null)

    let eventID = props.match.params.id
    let signInCode = props.match.params.qrCode

    const [memberNotFound, setMemberNotFound] = React.useState(false)
    const [memberAlreadyAdded, setMemberAlreadyAdded] = React.useState(false)
    const [curEvent, setCurEvent] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)
    const [rsvpMembers, setRSVPMembers] = React.useState([])
    const [members, setMembers] = React.useState([])
    const [nonMarkedMembers, setNonMarkedMembers] = React.useState([])
    const [eventStatus, setEventStatus] = React.useState({ exists: false, cachedResponse: false, grabbed: false })
    const [hasGrabbedEvent, setHasGrabbedEvent] = React.useState(false)
    const [signInStatus, setSignInStatus] = React.useState({
        loading: false,
        error: null,
    })
    const [excuseStatus, setExcuseStatus] = React.useState({
        loading: false,
        error: null,
    })
    const [rsvpStatus, setRSVPStatus] = React.useState({
        loading: false,
        error: null,
    })
    const [submitExcuseDialog, setSubmitExcuseDialog] = React.useState({
        open: false,
        reason: '',
    })
    const [submitPasswordDialog, setSubmitPasswordDialog] = React.useState({
        open: false,
        password: '',
    })
    const [viewQRCodeDialog, setViewQRCodeDialog] = React.useState({
        open: false,
        bgColor: '#ffffff',
        fgColor: '#000000',
    })
    const [shareDialog, setShareDialog] = React.useState({
        open: false,
        tab: 'link',
    })
    const [submitQRCodeDialog, setSubmitQRCodeDialog] = React.useState({ open: false })
    const [scanQRCodesDialog, setScanQRCodesDialog] = React.useState({ open: false })
    const [downloadAttendanceDialog, setDownloadAttendanceDialog] = React.useState({
        open: false,
        onlyPresent: true,
        first: true,
        last: true,
        role: true,
        email: true,
    })
    const [uploadRSVPListDialog, setUploadRSVPListDialog] = React.useState({
        open: false,
        file: true,
        error: false,
    })
    const [editingAuthor, setEditingAuthor] = React.useState({ editing: false, authorId: '' })

    const [signInPassword, setSignInPassword] = React.useState({ password: null, loading: false, qrCode: null })
    const [rsvpFieldsSubmission, setRSVPFieldsSubmission] = React.useState({ open: false })
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)

    // Add this state to track if the current event is part of a series
    const [isRecurringEvent, setIsRecurringEvent] = useState(false)

    const showAttendanceError =
        !downloadAttendanceDialog.first && !downloadAttendanceDialog.last && !downloadAttendanceDialog.role && !downloadAttendanceDialog.email
    const hasQRSignIn = curEvent && curEvent.signIn && curEvent.signIn.type === 2

    const _hasCalendarAdmin = React.useCallback(() => {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    chapter.perms['calendarAdmin'].includes(mem.role) ||
                    (chapter.perms['calendarCreate'].includes(mem.role) && curEvent && curEvent.author !== undefined && curEvent.author === user.getId())
                )
            }
        }

        return false
    }, [chapter, user, curEvent])

    const hasCalendarAdmin = _hasCalendarAdmin()

    const isUserMessagesCreateAdmin = React.useCallback(() => {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (chapter.perms['messagesAdmin'] && chapter.perms['messagesAdmin'].includes(mem.role)) ||
                    (chapter.perms['messagesCreate'] && chapter.perms['messagesCreate'].includes(mem.role))
                )
            }
        }

        return false
    }, [chapter, user])

    useEffect(() => {
        if (hasCalendarAdmin) {
            const unsubscribe = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('events')
                .doc(eventID)
                .onSnapshot(
                    function(doc) {
                        if (doc.exists) {
                            let data = doc.data()
                            updateEvent(data)
                        }

                        let cachedResponse = doc.metadata && doc.metadata.fromCache
                        setEventStatus(status => ({ ...status, cachedResponse: cachedResponse, exists: doc.exists, error: '', grabbed: true }))
                    },
                    function(error) {
                        console.log('unable to load event', error)
                        setEventStatus(status => ({ ...status, cachedResponse: false, exists: false, error: error, grabbed: true }))
                    },
                )
            return () => {
                unsubscribe()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventID, hasCalendarAdmin])

    // In the useEffect where you fetch the event data or where you process curEvent
    // Add this code to detect if the event is part of a recurring series
    useEffect(() => {
        if (curEvent && curEvent.recurringEventId) {
            setIsRecurringEvent(true)
        } else {
            setIsRecurringEvent(false)
        }
    }, [curEvent])

    const downloadAttendanceUpdate = event => {
        setDownloadAttendanceDialog({
            ...downloadAttendanceDialog,
            [event.target.name]: event.target.checked,
        })
    }

    const getPossibleAuthors = curEvent && curEvent.visibility
                ? convertObjectToList(chapter.members)
                      .filter(
                          mem =>
                              curEvent.visibility.includes(mem.status) &&
                              (mem.role === 'ADMIN' ||
                                  (Array.isArray(chapter.perms.calendarCreate) && chapter.perms.calendarCreate.includes(mem.role)) ||
                                  (Array.isArray(chapter.perms.calendarAdmin) && chapter.perms.calendarAdmin.includes(mem.role))),
                      )
                      .map(mem => {
                          return { id: mem.id, first: mem.first, last: mem.last, full: `${mem.first} ${mem.last}` }
                      })
                : []
                
    const attemptUpdateEvent = async authorId => {
        setEditingAuthor(state => ({ ...state, loading: true }))

        try {
            await updateEventAuthor(authorId)
            setEditingAuthor(state => ({ ...state, editing: false, loading: false }))
        } catch (e) {
            setEditingAuthor(state => ({ ...state, error: e, loading: false }))
        }
    }

    const updateEventAuthor = async authorId => {
        let eventRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('events')
            .doc(eventID)

        let authorRef = db.collection('users').doc(authorId)

        await db.runTransaction(async transaction => {
            let authorDoc = await transaction.get(authorRef)

            if (!authorDoc.exists) {
                throw new Error('Author does not exist!')
            }

            let authorData = authorDoc.data()

            let eventDoc = await transaction.get(eventRef)

            if (!eventDoc.exists) {
                throw new Error('Event does not exist!')
            }

            let data = { author: editingAuthor.authorId }

            if (authorData.first) {
                data['creator.first'] = authorData.first
            }

            if (authorData.last) {
                data['creator.last'] = authorData.last
            }

            if (authorData.photoURL) {
                data['creator.photo'] = authorData.photoURL
            } else {
                data['creator.photo'] = ''
            }

            transaction.update(eventRef, data)
        })
    }

    function updateEvent(data) {
        if (data.signIn.enabled) {
            let mems = convertObjectToList(data.attendance)

            let orgMems = convertObjectToList(chapter.members)

            let markedPresent = false
            let adminMarked = false

            for (let i = 0; i < mems.length; i++) {
                let mem = mems[i]
                for (let j = 0; j < orgMems.length; j++) {
                    let orgMem = orgMems[j]
                    if (mem.id === orgMem.id || (data.visibility !== undefined && !data.visibility.includes(orgMem.status))) {
                        orgMems.splice(j, 1)
                        j--
                    }
                }

                if (mem.id === currentUser.uid) {
                    markedPresent = mem.present
                    adminMarked = mem.adminMarked
                }
            }

            if (data.finished !== true) {
                setNonMarkedMembers(orgMems)
            } else {
                setNonMarkedMembers([])
            }

            setMembers(convertObjectToList(data.attendance))

            if (data.signIn.type === 2 && signInCode && hasSignInStarted(data) && !hasEventEnded(data) && !markedPresent && !adminMarked) {
                if (signInCode === data.signIn.qrCode) {
                    submitSignIn()
                } else {
                    setSignInStatus({ loading: false, error: 'QR Code is invalid' })
                }
            }

            if (data.signIn.password && data.signIn.qrCode) {
                setSignInPassword({ ...signInPassword, loading: false, password: data.signIn.password, qrCode: data.signIn.qrCode })
            }
        }

        if (data.rsvp.enabled) {
            setRSVPMembers(addNames(convertObjectToList(data.rsvp.attendees)))
        }

        if (!hasGrabbedEvent) {
            setHasGrabbedEvent(true)
        }

        clearNotifications(`${data.name} ${moment(getFirebaseDate(data)).format('M/D/YY')}`)

        setCurEvent(data)
    }

    function hasFullCalendarAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || chapter.perms['calendarAdmin'].includes(mem.role)
            }
        }

        return false
    }

    function getCalendarEvent() {
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('events')
            .doc(eventID)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    updateEvent(doc.data())
                }

                let cachedResponse = doc.metadata && doc.metadata.fromCache
                setEventStatus(status => ({ ...status, cachedResponse: cachedResponse, exists: doc.exists, error: '', grabbed: true }))
            })
            .catch(function(error) {
                console.log('unable to load event', error)
                setEventStatus(status => ({ ...status, cachedResponse: false, exists: false, error: error, grabbed: true }))
            })
    }

    function submitSignIn(password) {
        let submission = {
            chapter: user.getChapter(),
            eventID: eventID,
        }

        if (password) {
            submission.password = password
        }

        setSignInStatus({ loading: true, error: null })

        var functionsCall = app.functions().httpsCallable('signInToEvent')
        functionsCall(submission)
            .then(function(result) {
                //setIsUpdatingVote(false)
                setSignInStatus({ loading: false, error: null })
                let data = result.data
                updateEvent(data)
            })
            .catch(function(error) {
                var message = error.message
                setSignInStatus({ loading: false, error: message })
            })
    }

    function submitExcuse(reason) {
        if (reason && reason.length > 0) {
            let submission = {
                chapter: user.getChapter(),
                eventID: eventID,
                reason: reason,
            }

            // TODO: Check to make sure they can submit an excuse
            let shouldSubmitExcuse = true

            if (shouldSubmitExcuse) {
                setExcuseStatus({ loading: true, error: null })

                var functionsCall = app.functions().httpsCallable('submitExcuseToEvent')
                functionsCall(submission)
                    .then(function(result) {
                        setExcuseStatus({ loading: false, error: null })

                        let data = result.data
                        updateEvent(data)
                    })
                    .catch(function(error) {
                        let message = error.message
                        setExcuseStatus({ loading: false, error: message })
                        console.log('got error', error)
                    })
            }
        }
    }

    function getBlankGuest() {
        let guest = { id: generateUUID() }
        let fields = []

        const convertFieldIndexToId = index => {
            if (index === 0) return 'name'
            if (index === 1) return 'phone'
            if (index === 2) return 'email'
            if (index === 3) return 'birthday'
        }

        if (curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.enabled) {
            fields = curEvent.rsvp.guests.fields.map(field => convertFieldIndexToId(field))
        }

        for (let i = 0; i < fields.length; i++) {
            guest[fields[i]] = ''
        }

        return guest
    }

    function submitInitialRSVPSubmission() {
        if (curEvent && curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.enabled) {
            let minGuests = curEvent.rsvp.guests.min
            let guests = []

            for (let i = 0; i < minGuests; i++) {
                guests.push(getBlankGuest())
            }

            setRSVPFieldsSubmission({ open: true, guests: guests })
        } else if (curEvent && curEvent.rsvp && curEvent.rsvp.groups && curEvent.rsvp.groups.enabled) {
            setRSVPFieldsSubmission({ open: true })
        } else {
            submitRSVP()
        }
    }

    function submitRSVP() {
        // TODO: add functions call, get feedback, and inform user
        let submission = {
            chapter: user.getChapter(),
            eventID: eventID,
        }

        let shouldSubmitRSVP = true

        const convertFieldIdToIndex = id => {
            if (id === 'name') return 0
            if (id === 'phone') return 1
            if (id === 'email') return 2
            if (id === 'birthday') return 3
            if (id === 'id') return 'id'
        }

        if (curEvent && curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.enabled) {
            submission.guests = rsvpFieldsSubmission.guests.map(guest => {
                let newGuest = {}
                let fields = Object.keys(guest)

                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i]
                    let index = convertFieldIdToIndex(field)
                    newGuest[index] = guest[field]
                }
                return newGuest
            })
        }

        if (rsvpFieldsSubmission.groupId) {
            submission.groupId = rsvpFieldsSubmission.groupId
        }

        if (shouldSubmitRSVP) {
            setRSVPStatus({ loading: true, error: null })

            var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
            functionsCall(submission)
                .then(function(result) {
                    let data = result.data
                    updateEvent(data)
                    setRSVPStatus({ loading: false, error: null })
                })
                .catch(function(error) {
                    var message = error.message
                    setRSVPStatus({ loading: false, error: message })
                })
        }
    }

    function removeExcuse(data) {
        if (hasCalendarAdmin && curEvent && curEvent.excuses.enabled) {
            let attendees = convertListToObject(curEvent.attendance)

            if (data.id in attendees) {
                toggleMemberUpdating(data.id, true)

                let submission = {
                    chapter: user.getChapter(),
                    eventID: eventID,
                    memberID: data.id,
                    deleting: true,
                }

                var functionsCall = app.functions().httpsCallable('voteOnExcuse')
                functionsCall(submission).catch(function(error) {
                    var message = error.message
                    window.alert(message)

                    toggleMemberUpdating(data.id, false)
                })
            }
        }
    }

    function memIdInMems(memId) {
        for (let i = 0; i < members.length; i++) {
            if (members[i].id === memId) {
                return true
            }
        }

        return false
    }

    function memIdInNonMarkedMems(memId) {
        for (let i = 0; i < nonMarkedMembers.length; i++) {
            if (nonMarkedMembers[i].id === memId) {
                return true
            }
        }

        return false
    }

    function toggleMemberUpdating(memId, updating) {
        if (memIdInMems(memId)) {
            setMembers(mems => {
                let members = [...mems]

                let memIndex = members.findIndex(mem => mem.id === memId)

                if (memIndex > -1) {
                    members[memIndex] = { ...members[memIndex], updating: updating ? true : false }
                }

                return members
            })
        } else if (memIdInNonMarkedMems(memId)) {
            setNonMarkedMembers(mems => {
                let members = [...mems]

                let memIndex = members.findIndex(mem => mem.id === memId)

                if (memIndex > -1) {
                    members[memIndex] = { ...members[memIndex], updating: updating ? true : false }
                }

                return members
            })
        }
    }

    function addExcuse(data) {
        if (hasCalendarAdmin && curEvent && curEvent.excuses.enabled) {
            let attendees = convertListToObject(curEvent.attendance)

            let memId = null

            if (data.name in attendees) {
                let mem = attendees[data.name]
                memId = data.name
                data.first = mem.first
                data.last = mem.last
            }

            if (memId === null) {
                let otherMems = convertObjectToList(nonMarkedMembers)
                for (let i = 0; i < otherMems.length; i++) {
                    if (otherMems[i].id === data.name) {
                        memId = otherMems[i].id
                        i = otherMems.length
                    }
                }
            }

            if (memId !== null) {
                let submission = {
                    chapter: user.getChapter(),
                    eventID: eventID,
                    memberID: memId,
                    excused: data.excused ? true : false,
                    reason: data.reason ? data.reason : '',
                }

                var functionsCall = app.functions().httpsCallable('voteOnExcuse')
                functionsCall(submission).catch(function(error) {
                    var message = error.message
                    window.alert(message)
                })
            } else {
                setMemberNotFound(true)
            }
        }
    }

    function approveExcuse(data) {
        if (hasCalendarAdmin && curEvent && curEvent.excuses.enabled) {
            let attendees = convertListToObject(curEvent.attendance)

            if (data.id in attendees && attendees[data.id].excused !== true) {
                toggleMemberUpdating(data.id, true)

                let submission = {
                    chapter: user.getChapter(),
                    eventID: eventID,
                    memberID: data.id,
                    excused: true,
                }

                var functionsCall = app.functions().httpsCallable('voteOnExcuse')
                functionsCall(submission).catch(function(error) {
                    var message = error.message
                    window.alert(message)

                    toggleMemberUpdating(data.id, false)
                })
            }
        }
    }

    function denyExcuse(data) {
        if (hasCalendarAdmin && curEvent && curEvent.excuses.enabled) {
            let attendees = convertListToObject(curEvent.attendance)

            if (data.id in attendees && attendees[data.id].excused !== false) {
                toggleMemberUpdating(data.id, true)

                let submission = {
                    chapter: user.getChapter(),
                    eventID: eventID,
                    memberID: data.id,
                    excused: false,
                }

                var functionsCall = app.functions().httpsCallable('voteOnExcuse')
                functionsCall(submission).catch(function(error) {
                    var message = error.message
                    window.alert(message)

                    toggleMemberUpdating(data.id, false)
                })
            }
        }
    }

    function markAbsent(data) {
        setAttendance(data, false)
    }

    function markPresent(data) {
        setAttendance(data, true)
    }

    function setAttendance(data, isPresent) {
        toggleMemberUpdating(data.id, true)

        if (hasCalendarAdmin && curEvent && curEvent.signIn.enabled) {
            let submission = {
                chapter: user.getChapter(),
                eventID: eventID,
                adminMark: true,
                otherMemberMarking: data.id,
                isPresent: isPresent === true ? true : false,
            }

            var functionsCall = app.functions().httpsCallable('signInToEvent')
            functionsCall(submission).catch(function(error) {
                console.log('got error signing in user', error)

                toggleMemberUpdating(data.id, false)
            })
        }
    }

    function addRSVP(data) {
        if (hasCalendarAdmin && curEvent && curEvent.rsvp.enabled) {
            let rsvp = curEvent.rsvp

            let attendees = convertListToObject(rsvp.attendees)

            let alreadyAdded = false

            if (data.name in attendees) {
                alreadyAdded = true
            }

            if (!alreadyAdded) {
                let newMem = null

                let mems = chapter.members

                if (data.name in mems) {
                    let mem = mems[data.name]
                    newMem = {
                        id: data.name,
                        first: mem.first,
                        last: mem.last,
                    }

                    if (mem.roll) {
                        newMem.roll = mem.roll
                    }
                }

                if (newMem !== null) {
                    let submission = {
                        chapter: user.getChapter(),
                        eventID: eventID,
                        otherMemberMarking: newMem.id,
                        rsvpd: true,
                    }

                    if (data.groupId) {
                        submission.groupId = data.groupId
                    }

                    var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
                    functionsCall(submission).catch(function(error) {
                        console.log("got error rsvp'ing user", error)
                    })
                } else {
                    setMemberNotFound(true)
                }
            } else {
                setMemberAlreadyAdded(true)
            }
        }
    }

    function addMemberWaitlist(data) {
        if (hasCalendarAdmin && curEvent && curEvent.rsvp.enabled && curEvent.rsvp.waitlist && curEvent.rsvp.waitlist.enabled) {
            let rsvp = curEvent.rsvp

            let attendees = convertListToObject(rsvp.attendees)

            let alreadyAdded = false

            if (data.name in attendees) {
                alreadyAdded = true
            }

            if (!alreadyAdded) {
                let memId = null

                let mems = chapter.members

                if (data.name in mems) {
                    memId = data.name
                }

                if (curEvent.rsvp.waitlist.members.map(mem => mem.memberId).includes(memId)) {
                    setMemberAlreadyAdded(true)
                } else {
                    if (memId !== null) {
                        let submission = {
                            chapter: user.getChapter(),
                            eventID: eventID,
                            otherMemberMarking: memId,
                            rsvpd: true,
                            waitlist: true,
                        }

                        var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
                        functionsCall(submission).catch(function(error) {
                            console.log("got error rsvp'ing user", error)
                        })
                    } else {
                        setMemberNotFound(true)
                    }
                }
            } else {
                setMemberAlreadyAdded(true)
            }
        }
    }

    function addGuestRSVP(data) {
        if (hasCalendarAdmin && curEvent && curEvent.rsvp.enabled) {
            let rsvp = curEvent.rsvp

            let memberId = data.memberName

            let attendees = convertListToObject(rsvp.attendees)

            if (memberId in attendees) {
                let member = attendees[memberId]
                data.id = generateUUID()
                delete data.memberName

                if (Array.isArray(member.guests)) {
                    member.guests.push(data)
                } else {
                    member.guests = [data]
                }

                attendees[memberId] = member

                rsvp.attendees = convertListToObject(attendees)

                db.collection('chapters')
                    .doc(user.getChapter())
                    .collection('events')
                    .doc(eventID)
                    .update({ rsvp: rsvp })

                setRSVPMembers(
                    addNames(convertObjectToList(rsvp.attendees)).sort(function(a, b) {
                        return a.roll - b.roll
                    }),
                )
            }
        }
    }

    const removeSelfRSVP = () => {
        if (curEvent && curEvent.rsvp.enabled) {
            let submission = {
                chapter: user.getChapter(),
                eventID: eventID,
                rsvpd: false,
            }

            setRSVPStatus(status => ({ ...status, loading: true }))

            var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
            functionsCall(submission)
                .then(result => {
                    let data = result.data
                    updateEvent(data)
                    setRSVPStatus(status => ({ ...status, loading: false, error: null }))
                })
                .catch(function(error) {
                    setRSVPStatus(status => ({ ...status, loading: false, error: error.message }))
                    console.log('got error removing user rsvp', error)
                })
        }
    }

    const addSelfWaitlist = () => {
        if (curEvent && curEvent.rsvp.enabled) {
            let submission = {
                chapter: user.getChapter(),
                eventID: eventID,
                rsvpd: true,
                waitlist: true,
            }

            setRSVPStatus(status => ({ ...status, loading: true }))

            var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
            functionsCall(submission)
                .then(result => {
                    let data = result.data
                    updateEvent(data)
                    setRSVPStatus(status => ({ ...status, loading: false, error: null }))
                })
                .catch(function(error) {
                    setRSVPStatus(status => ({ ...status, loading: false, error: error.message }))
                    console.log('got error adding user to waitlist', error)
                })
        }
    }

    const removeSelfWaitlist = () => {
        if (curEvent && curEvent.rsvp.enabled) {
            let submission = {
                chapter: user.getChapter(),
                eventID: eventID,
                rsvpd: false,
                waitlist: true,
            }

            setRSVPStatus(status => ({ ...status, loading: true }))

            var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
            functionsCall(submission)
                .then(result => {
                    let data = result.data
                    updateEvent(data)
                    setRSVPStatus(status => ({ ...status, loading: false, error: null }))
                })
                .catch(function(error) {
                    setRSVPStatus(status => ({ ...status, loading: false, error: error.message }))
                    console.log('got error removing user from waitlist', error)
                })
        }
    }

    const removeMemberWaitlist = data => {
        if (hasCalendarAdmin && curEvent && curEvent.rsvp.enabled) {
            let submission = {
                chapter: user.getChapter(),
                eventID: eventID,
                otherMemberMarking: data.id,
                rsvpd: false,
                waitlist: true,
            }

            var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
            functionsCall(submission).catch(function(error) {
                console.log('got error removing user rsvp', error)
            })
        }
    }

    function removeRSVP(data) {
        if (hasCalendarAdmin && curEvent && curEvent.rsvp.enabled) {
            let submission = {
                chapter: user.getChapter(),
                eventID: eventID,
                otherMemberMarking: data.id,
                rsvpd: false,
            }

            var functionsCall = app.functions().httpsCallable('submitRSVPToEvent')
            functionsCall(submission).catch(function(error) {
                console.log('got error removing user rsvp', error)
            })
        }
    }

    function removeGuestRSVP(data) {
        if (hasCalendarAdmin && curEvent && curEvent.rsvp.enabled) {
            let rsvp = curEvent.rsvp

            let attendees = convertObjectToList(rsvp.attendees)

            let member = null
            let memberI = -1

            for (let i = 0; i < attendees.length; i++) {
                let attendee = attendees[i]
                if (attendee.roll === data.roll) {
                    member = { ...attendee }
                    memberI = i
                    i = attendees.length
                }
            }

            if (member !== null) {
                if (Array.isArray(member.guests)) {
                    for (let i = 0; i < member.guests.length; i++) {
                        let guest = member.guests[i]
                        if (guest.id === data.id) {
                            member.guests.splice(i, 1)
                        }
                    }

                    attendees[memberI] = member

                    rsvp.attendees = convertListToObject(attendees)

                    db.collection('chapters')
                        .doc(user.getChapter())
                        .collection('events')
                        .doc(eventID)
                        .update({ rsvp: rsvp })

                    setRSVPMembers(
                        addNames(convertObjectToList(rsvp.attendees)).sort(function(a, b) {
                            return a.roll - b.roll
                        }),
                    )
                }
            } else {
                window.alert("Guest must be attached to RSVP'd member")
            }
        }
    }

    function attemptSubmitExcuse() {
        // TODO: ask the user for their reasoning
        setSubmitExcuseDialog({ open: true, reason: '' })
    }

    function attemptSignIn() {
        //Check to see if there is a password and if so, keep comparing until equal
        if (curEvent && curEvent.signIn.enabled) {
            if (curEvent.signIn.type) {
                if (curEvent.signIn.type === 1 || curEvent.signIn.type === 4) {
                    // Password, so start guessing
                    setSubmitPasswordDialog({ open: true, password: '' })
                } else if (curEvent.signIn.type === 2) {
                    // QR Code
                    setSubmitQRCodeDialog({ open: true, error: null, scan: '', facingMode: 'front' })
                } else if (curEvent.signIn.type === 3) {
                    // Scan Members In
                } else {
                    // No password, just submit...
                    submitSignIn()
                }
            } else {
                submitSignIn()
            }
        }
    }

    function hasPassedExcuseDeadline() {
        if (curEvent) {
            if (curEvent.excuses.enabled) {
                let eventStart = getFirebaseDate(curEvent)
                eventStart = new Date(eventStart.getTime() + curEvent.excuses.deadline * 1000 * 60)
                let diff = new Date() - eventStart
                return diff >= 0
            }
        }

        return false
    }

    function hasEventStarted() {
        return hasStarted(curEvent)
    }

    function hasEventEnded() {
        return hasEnded(curEvent)
    }

    function timeTillExcuseDeadline(e) {
        if (e) {
            let excuseDeadline = getFirebaseDate(e)

            if (e.excuses.deadline !== undefined && e.excuses.deadline !== null && typeof e.excuses.deadline === 'number') {
                excuseDeadline.setMinutes(excuseDeadline.getMinutes() + e.excuses.deadline)
            }

            let deadline = Math.ceil(moment.duration(moment(excuseDeadline).diff(moment())).asMinutes())

            if (deadline < 60 && deadline > 0) {
                return `Excuses due in ${deadline} ${deadline === 1 ? 'minute' : 'minutes'}`
            }

            if (deadline >= 60) {
                let deadlineHours = Math.floor(deadline / 60)

                if (deadlineHours < 24) {
                    return `Excuses due in ${deadlineHours} ${deadlineHours === 1 ? 'hour' : 'hours'}`
                }
            }

            return false
        }

        return false
    }

    function timeTillSignInStarts(e) {
        if (e) {
            let eventStart = getFirebaseDate(e)

            if (e.signIn.start !== undefined && e.signIn.start !== null && typeof e.signIn.start === 'number') {
                eventStart.setMinutes(eventStart.getMinutes() - e.signIn.start)
            }

            return Math.ceil(moment.duration(moment(eventStart).diff(moment())).asMinutes())
        }

        return false
    }

    function hasSignInEnded() {
        return hasEventEnded() || (curEvent && curEvent.signIn && curEvent.signIn.closed)
    }

    function hasSignInStarted(e) {
        if (e) {
            let eventStart = getFirebaseDate(e)

            if (e.signIn.start !== undefined && e.signIn.start !== null && typeof e.signIn.start === 'number') {
                eventStart.setMinutes(eventStart.getMinutes() - e.signIn.start)
            }

            let diff = new Date() - eventStart

            return diff >= 0
        }

        return false
    }

    function hasStarted(e) {
        if (e) {
            let eventStart = getFirebaseDate(e)
            let diff = new Date() - eventStart
            return diff >= 0
        }
        return false
    }

    function hasEnded(e) {
        if (e) {
            // If event length is less than 0 that means there is not any specific length
            if (e.date.ed !== undefined) {
                let eventEnd = getEventEnd(e)
                let diff = new Date() - eventEnd
                return diff >= 0
            } else {
                if (e.length > 0) {
                    let eventStart = getFirebaseDate(e)
                    eventStart = new Date(eventStart.getTime() + e.length * 1000 * 60)
                    let diff = new Date() - eventStart
                    return diff >= 0
                } else if (e.length === 0) {
                    let time = getFirebaseDate(e)
                    //All day
                    time.setDate(time.getDate() + 1)
                    let diff = new Date() - time
                    return diff >= 0
                }
            }
        }

        return false
    }

    function exportRSVPList() {
        if (curEvent && rsvpMembers) {
            let str = 'Name'

            const usesRoll = chapter && chapter.settings && chapter.settings.roll

            if (usesRoll) {
                str += ',Roll'
            }

            str += ',Email'

            let eventName = curEvent.name.replace(/\W/g, '')

            let rsvpd = []
            for (let i = 0; i < rsvpMembers.length; i++) {
                let mem = rsvpMembers[i]

                if (mem !== null) {
                    str += `\n${mem.first} ${mem.last}`

                    if (usesRoll) {
                        str += `\n${mem.roll}`
                    }

                    if (chapter.members[mem.id]) {
                        str += `,${chapter.members[mem.id].email}`
                    }
                }

                rsvpd.push(mem.roll)
            }

            exportFile(str, 'rsvp_' + eventName + '.csv')
        }
    }

    const exportFile = (text, name, filetype = 'text/csv') => {
        const element = document.createElement('a')
        const file = new File([text], name, { type: filetype })
        const blob = new Blob([text], {
            type: filetype,
        })

        const url = URL.createObjectURL(blob)

        if (isMobileDevice() && navigator.canShare && navigator.canShare({ files: [file] })) {
            navigator.share({
                files: [file],
            })
        } else {
            element.href = url
            element.download = name
            document.body.appendChild(element) // Required for this to work in FireFox
            element.click()
        }
    }

    function exportAttendanceList() {
        let mems = getAllMembers()
        if (curEvent && mems) {
            let str = 'Roll'

            if (downloadAttendanceDialog.first) {
                str += ',First'
            }

            if (downloadAttendanceDialog.last) {
                str += ',Last'
            }

            if (downloadAttendanceDialog.present) {
                str += ',Present'
            }

            if (downloadAttendanceDialog.email) {
                str += ',Email'
            }

            if (downloadAttendanceDialog.role) {
                str += ',Role'
            }

            let eventName = curEvent.name.replace(/\W/g, '')

            for (let i = 0; i < mems.length; i++) {
                let mem = mems[i]

                if (mem !== null && (!downloadAttendanceDialog.onlyPresent || mem.present)) {
                    let email = mem.email

                    let role = chapter.members[mem.id].role

                    if (chapter.roles[role] && chapter.roles[role].title) {
                        role = chapter.roles[role].title
                    }

                    if (!email) {
                        email = chapter.members[mem.id].email
                    }

                    str += '\n' + mem.roll

                    if (downloadAttendanceDialog.first) {
                        str += ',' + mem.first
                    }

                    if (downloadAttendanceDialog.last) {
                        str += ',' + mem.last
                    }

                    if (downloadAttendanceDialog.present) {
                        str += ',' + (mem.present ? 'true' : 'false')
                    }

                    if (downloadAttendanceDialog.email) {
                        str += ',' + (email ? email : '')
                    }

                    if (downloadAttendanceDialog.role) {
                        str += ',' + (role ? role : '')
                    }
                }
            }

            exportFile(str, 'attendance_' + eventName + '.csv')
        }
    }

    if (!hasTried) {
        if (!hasCalendarAdmin) {
            getCalendarEvent()
        }
        setHasTried(true)
    }

    function getPointsSection() {
        if (!curEvent.points || !Array.isArray(curEvent.points) || curEvent.points.length === 0 || !(user.getId() in chapter.members)) {
            return <></>
        }

        let points = [...curEvent.points]

        let pointOptions = chapter && chapter.points ? convertListToObject(chapter.points) : {}

        points = points.filter(
            trigger =>
                trigger.system in pointOptions &&
                ((!pointOptions[trigger.system].mType &&
                    Array.isArray(pointOptions[trigger.system].vis) &&
                    pointOptions[trigger.system].vis.includes(chapter.members[user.getId()].status)) ||
                    (pointOptions[trigger.system].mType === 1 &&
                        Array.isArray(pointOptions[trigger.system].mems) &&
                        pointOptions[trigger.system].mems.includes(user.getId()))),
        )

        if (points.length === 0) {
            return <></>
        }

        function getPointsTypography(system) {
            const { caption, icon, severity } = getPointsCaption(system)

            if (caption.length > 0) {
                return <Alert 
                    key={caption}
                    icon={icon} 
                    severity={severity}
                    sx={{ mt: 1 }}
                    variant="outlined"
                >
                    {caption}
                </Alert>
            }

            return <></>
        }

        function getPointsCaption(trigger) {
            let str = `Get ${trigger.points}`
            const icon = <PointsIcon />
            let severity = 'info'
            if (trigger.points < 0) {
                str = `Lose ${Math.abs(trigger.points)}`
                severity = 'error'
            }
            if(trigger.points === -1) {
                str += ' point toward'
                severity = 'error'
            }
            if(trigger.points === 1) {
                str += ' point toward'
                severity = 'info'
            } 
            if(trigger.points > 1) {
                str += ' points toward'
                severity = 'info'
            }

            let foundPointOption = pointOptions[trigger.system]

            if (foundPointOption === undefined) {
                return ''
            }

            str += ` ${foundPointOption.name} for `

            if (trigger.action === 0) {
                str += 'attending this event'
            } else if (trigger.action === 1) {
                str += 'missing this event'
            } else if (trigger.action === 2) {
                str += 'missing this event without an approved excuse'
            } else if (trigger.action === 3) {
                str += 'missing this event with an approved excuse'
            } else if (trigger.action === 4) {
                str += 'having an approved excuse'
            } else if (trigger.action === 5) {
                str += "rsvp'ing to this event"
            }

            return { caption: str, icon, severity }
        }

        function sortPoints(points) {
            return points.sort((a, b) => {
                if (a.action !== b.action) {
                    return a.action - b.action
                }

                return a.points - b.points
            })
        }

        return (
            <Grid item xs={12}  >
                {sortPoints(points).map(system => getPointsTypography(system))}
            </Grid>
        )
    }

    function getSignInSection() {
        if (curEvent.signIn.enabled) {
            if (hasSignInStarted(curEvent)) {
                let attendance = convertObjectToList(curEvent.attendance)

                let markedPresent = false
                let adminMarked = false

                for (let i = 0; i < attendance.length; i++) {
                    let attendee = attendance[i]
                    if (attendee.id === currentUser.uid) {
                        markedPresent = attendee.present
                        adminMarked = attendee.adminMarked
                    }
                }

                if (hasSignInEnded()) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Sign In
                            </Typography>
                            {markedPresent ? (
                                <Alert 
                                    icon={<ApprovedIcon />} 
                                    severity="success"
                                    variant="outlined"
                                    sx={{ mt: 1, mb: 1 }}
                                >
                                    You have successfully signed in!
                            </Alert>
                            ) : adminMarked ? (
                                <>
                                    <Alert 
                                        severity="warning"
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                    >
                                        You've been marked absent
                                    </Alert>
                                    {getAskAdminToChange()}
                                </>
                            ) : (
                                <>
                                    <Alert 
                                        severity="warning"
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                    >
                                        {curEvent.signIn.type === 3 ? "You weren't signed in" : "You didn't sign in"}
                                    </Alert>
                                    {getAskAdminToChange()}
                                </>
                            )}
                        </Grid>
                    )
                }

                return (
                    <Grid item xs={12} className={classes.borderTop}>
                        {markedPresent ? (
                                <Alert 
                                    icon={<ApprovedIcon />} 
                                    severity="success"
                                    sx={{ mt: 1 }}
                                >
                                    You have successfully signed in!
                                </Alert>
                        ) : adminMarked && curEvent.signIn.type !== 3 ? (
                            <>
                                <WarningTypography text="You've been marked absent" />
                                {getAskAdminToChange()}
                            </>
                        ) : signInStatus.loading ? (
                            <CircularProgress size={28} />
                        ) : (
                            <>
                                {signInStatus.error && <ErrorTypography text={signInStatus.error} />}
                                {curEvent.signIn.type === 3 ? (
                                    <>
                                        <Alert severity="warning" variant="outlined" sx={{ mt: 1, mb: 1 }}>An admin must sign you in for this event</Alert>
                                        <Box className={classes.qrCodeParent}>
                                            <Box className={classes.qrCodeOuter}>
                                                <QRCode
                                                    id="scan-in-qr-code"
                                                    className={classes.qrCode}
                                                    renderAs="canvas"
                                                    size={256}
                                                    includeMargin={true}
                                                    value={user.getId()}
                                                />
                                                <Button
                                                    onClick={() => {
                                                        const canvas = document.getElementById('scan-in-qr-code')
                                                        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
                                                        let downloadLink = document.createElement('a')
                                                        downloadLink.href = pngUrl
                                                        downloadLink.download = 'qrCodeScanIn_' + eventID + '.png'
                                                        document.body.appendChild(downloadLink)
                                                        downloadLink.click()
                                                        document.body.removeChild(downloadLink)
                                                    }}
                                                    color="primary"
                                                    className={classes.saveQrCodeButton}
                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{
                                            margin: 4,
                                            width: isMobileDevice() ? '100%' : 'auto',
                                        }}
                                        onClick={() => attemptSignIn()}
                                    >
                                        Sign In
                                    </Button>
                                )}
                            </>
                        )}
                    </Grid>
                )
            } else {
                // Get minutes until it starts and if it's less than 60 say time til start
                let timeTillStart = timeTillSignInStarts(curEvent)
                if (timeTillStart !== false && timeTillStart < 60) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Sign In
                            </Typography>
                            <Alert 
                                severity="warning"
                                variant="outlined"
                                sx={{ mt: 1 }}
                            >
                                Sign in opens in {timeTillStart} {timeTillStart === 1 ? 'minute' : 'minutes'}
                            </Alert>
                        </Grid>
                    )
                }
            }
        }

        return <></>
    }

    function getExcuseSection() {
        if (curEvent.excuses.enabled) {
            let attendance = convertObjectToList(curEvent.attendance)

            let hasAttemptedExcuse = false
            let hasApprovedExcuse = false
            let hasMangementSetExcuse = false

            for (let i = 0; i < attendance.length; i++) {
                let attendee = attendance[i]
                if (attendee.id === currentUser.uid) {
                    hasAttemptedExcuse = attendee.reason !== undefined && attendee.reason !== null && attendee.reason.length > 0
                    hasApprovedExcuse = attendee.excused
                    hasMangementSetExcuse = attendee.excuseVoted
                }
            }

            if (!hasPassedExcuseDeadline()) {
                //Deadline hasn't passed
                /*if (curEvent.excuses.externalLink.length > 0) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Excuses
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{
                                    margin: 4,
                                }}
                                href={curEvent.rsvp.externalLink}
                            >
                                Submit Excuse
                            </Button>
                        </Grid>
                    )
                }*/

                if (hasAttemptedExcuse && hasMangementSetExcuse) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Excuses
                            </Typography>
                            {hasApprovedExcuse ? (
                                <Alert 
                                    severity="success"
                                    variant="outlined"
                                    sx={{ mt: 1 }}
                                >
                                    Your excuse has been approved!
                                </Alert>
                            ) : (
                                <Alert 
                                    severity="error"
                                    variant="outlined"
                                    sx={{ mt: 1 }}
                                >
                                    Your excuse has been denied
                                </Alert>
                            )}
                        </Grid>
                    )
                } else if (hasAttemptedExcuse) {
                    return (
                        <Stack spacing={1} xs={12} marginBottom={2} >
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Excuses
                            </Typography>
                            <Alert 
                                severity="info"
                                variant="outlined"
                                sx={{ mt: 1 }}
                            >
                                Your excuse is awaiting approval
                            </Alert>
                        </Stack>
                    )
                }
                //Hasn't submitted excuse

                let timeTillDeadline = timeTillExcuseDeadline(curEvent)

                return (
                    <Grid item xs={12} className={classes.borderTop}>
                        {excuseStatus.loading ? (
                            <CircularProgress size={28} />
                        ) : (
                            <>
                                {timeTillDeadline !== false && (
                                    <Alert 
                                        severity="warning"
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                    >
                                        {timeTillDeadline}
                                    </Alert>
                                )}
                                {excuseStatus.error && (
                                    <Alert 
                                        severity="error"
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                    >
                                        {excuseStatus.error}
                                    </Alert>
                                )}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        width: isMobileDevice() ? '100%' : 'auto',
                                    }}
                                    onClick={() => attemptSubmitExcuse()}
                                >
                                    Submit Excuse
                                </Button>
                            </>
                        )}
                    </Grid>
                )
            } else {
                if (hasApprovedExcuse) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Excuses
                            </Typography>
                            <Alert severity="success" variant="outlined" sx={{ mt: 1 }}>Your excuse has been approved!</Alert>
                        </Grid>
                    )
                } else if (hasAttemptedExcuse && hasMangementSetExcuse) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Excuses
                            </Typography>
                            <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>Your excuse has been denied</Alert>
                        </Grid>
                    )
                } else if (hasAttemptedExcuse) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Excuses
                            </Typography>
                            <Alert severity="warning" variant="outlined" sx={{ mt: 1 }}>Your excuse is awaiting approval</Alert>
                        </Grid>
                    )
                } else {
                    if (hasEventStarted()) {
                        return <></>
                    }

                    //Event hasn't started, but too late to submit excuse
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                Excuses
                            </Typography>
                            <Alert severity="warning" variant="outlined" sx={{ mt: 1 }}>The excuse deadline has passed</Alert>
                        </Grid>
                    )
                }
            }
        }

        return <></>
    }

    function getCombinedAbsent() {
        let mems = members
            .filter(member => member.present === false)
            .concat(nonMarkedMembers)
            .sort(function(a, b) {
                return a.roll - b.roll
            })

        if (curEvent.visibility !== null && curEvent.visibility !== undefined) {
            mems = mems.filter(mem => {
                if (mem.id in chapter.members) {
                    return curEvent.visibility.includes(chapter.members[mem.id].status)
                }
                return false
            })
        }

        return mems
    }

    function getAllMembers() {
        let mems = members.concat(nonMarkedMembers).sort(function(a, b) {
            return a.roll - b.roll
        })

        if (curEvent && curEvent.visibility !== null && curEvent.visibility !== undefined) {
            mems = mems.filter(mem => {
                if (mem.id in chapter.members) {
                    let orgMem = chapter.members[mem.id]
                    return curEvent.visibility.includes(orgMem.status)
                }
                return false
            })
        }

        return mems
    }

    function getAskAdminToChange() {
        if (curEvent.rsvp.waitlist && curEvent.rsvp.waitlist.enabled) {
            // Check to see if RSVPs are open or not
            if (!curEvent.rsvp.closed && !hasRSVPDeadlinePassed()) {
                return (
                    <>
                        <Typography variant="caption">Please contact an admin to change your information</Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ m: 4, width: { xs: '100%', sm: 'auto' } }}
                            disabled={rsvpStatus.loading}
                            onClick={removeSelfRSVP}
                        >
                            Remove RSVP
                        </Button>
                    </>
                )
            }
        }
        return <Typography variant="caption">Please contact an admin to change your status</Typography>
    }

    function getRSVPButtonInner() {
        const minGuests = curEvent && curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.min ? curEvent.rsvp.guests.min : 0
        const maxGuests = curEvent && curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.max ? curEvent.rsvp.guests.max : 10

        const removeGuest = guest => {
            let guests = [...rsvpFieldsSubmission.guests]
            for (let i = 0; i < guests.length; i++) {
                let curGuest = guests[i]
                if (curGuest.id === guest.id) {
                    guests.splice(i, 1)
                    i--
                }
            }
            setRSVPFieldsSubmission(submission => ({ ...submission, guests: guests }))
        }

        const updateGuest = (guest, field, value) => {
            let guests = [...rsvpFieldsSubmission.guests]
            for (let i = 0; i < guests.length; i++) {
                let curGuest = guests[i]
                if (curGuest.id === guest.id) {
                    curGuest[field] = value
                }
            }
            setRSVPFieldsSubmission(submission => ({ ...submission, guests: guests }))
        }

        const canRemoveGuests = rsvpFieldsSubmission.guests && rsvpFieldsSubmission.guests.length > minGuests

        const convertFieldIndexToId = index => {
            if (index === 0) return 'name'
            if (index === 1) return 'phone'
            if (index === 2) return 'email'
            if (index === 3) return 'birthday'
        }

        const fields =
            curEvent && curEvent.rsvp && curEvent.rsvp.guests && Array.isArray(curEvent.rsvp.guests.fields)
                ? curEvent.rsvp.guests.fields.map(field => convertFieldIndexToId(field))
                : ['name', 'phone', 'email', 'birthday']
        const numFields = fields.length
        const fieldSizes = 12 / numFields
        const fieldSizesSmall = numFields === 1 ? 12 : 24 / numFields

        let fieldsUncompiled = [
            { id: 'name', name: 'Name' },
            { id: 'phone', name: 'Phone' },
            { id: 'email', name: 'Email' },
            { id: 'birthday', name: 'Birthday' },
        ]

        for (let i = 0; i < fieldsUncompiled.length; i++) {
            let field = fieldsUncompiled[i]
            let contains = false

            for (let j = 0; j < fields.length; j++) {
                let fieldId = fields[j]
                if (fieldId === field.id) {
                    contains = true
                    field.spot = j
                }
            }

            if (!contains) {
                fieldsUncompiled.splice(i, 1)
                i--
            }
        }

        let guestCount = []
        for (let i = minGuests; i <= maxGuests; i++) {
            guestCount.push(i)
        }

        const renderGuestEditor = guest => {
            return (
                <Box style={{ display: 'flex', flexDirection: 'row' }} key={guest.id}>
                    <Grid container spacing={1} style={{ flexGrow: 1 }}>
                        {fieldsUncompiled.map(field => (
                            <Grid item xs={12} sm={fieldSizesSmall} md={fieldSizes} id={field.id}>
                                {field.id === 'birthday' ? (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            disableFuture
                                            inputVariant="outlined"
                                            disableToolbar
                                            format="MM/dd/yyyy"
                                            label={guest[field.id] ? '' : `Guest ${field.name}`}
                                            value={guest[field.id] ? parseISO(guest[field.id]) : null}
                                            fullWidth
                                            margin="dense"
                                            onChange={date => updateGuest(guest, field.id, format(date, 'yyyy-MM-dd'))}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ marginTop: 4 }}
                                            InputLabelProps={{ shrink: false }}
                                        />
                                    </MuiPickersUtilsProvider>
                                ) : (
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        label={guest[field.id] ? '' : `Guest ${field.name}`}
                                        type="text"
                                        onChange={e => {
                                            updateGuest(guest, field.id, e.target.value)
                                        }}
                                        value={guest[field.id] ?? ''}
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        style={{ marginTop: 4 }}
                                        InputLabelProps={{ shrink: false }}
                                        fullWidth
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                    {canRemoveGuests && (
                        <Box
                            style={{
                                margin: 4,
                                paddingTop: 5,
                                paddingBottom: 5,
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        removeGuest(guest)
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </Box>
            )
        }

        let canSubmitGuests = true

        if (Array.isArray(rsvpFieldsSubmission.guests)) {
            for (let i = 0; i < rsvpFieldsSubmission.guests.length; i++) {
                let guest = rsvpFieldsSubmission.guests[i]

                for (let j = 0; j < fields.length; j++) {
                    let field = fields[j]
                    if (!guest[field]) {
                        canSubmitGuests = false
                    }
                }
            }
        }

        let guestCountCaption =
            minGuests === maxGuests ? `You can have ${minGuests} ${minGuests === 1 ? 'guest' : 'guests'}` : `You can have ${minGuests} - ${maxGuests} guests`

        if (rsvpFieldsSubmission.open) {
            const isSelectingGroup = curEvent.rsvp.groups && curEvent.rsvp.groups.enabled === true
            let groupOptions = []

            if (isSelectingGroup) {
                groupOptions = curEvent.rsvp.groups.groups.map(group => {
                    const attendees = convertObjectToList(curEvent.rsvp.attendees)
                    const groupAttendees = attendees.filter(attendee => attendee.groupId === group.id)
                    let attendeesLeft = group.numSpots - groupAttendees.length

                    if (attendeesLeft <= 0) {
                        return {
                            id: group.id,
                            label: group.name + ' (Full)',
                            disabled: true,
                        }
                    }

                    return {
                        id: group.id,
                        label: `${group.name} (${attendeesLeft}/${group.numSpots} spots left)`,
                        disabled: false,
                    }
                })
            }

            return (
                <>
                    {isSelectingGroup && groupOptions.length > 0 && (
                        <FormControl fullWidth variant="outlined" margin="dense">
                            <InputLabel id="group-label">Group</InputLabel>
                            <Select
                                labelId="group-label"
                                label="Group"
                                value={rsvpFieldsSubmission.groupId ?? ''}
                                onChange={e => {
                                    setRSVPFieldsSubmission(submission => ({ ...submission, groupId: e.target.value }))
                                }}
                            >
                                {groupOptions.map(groupOption => (
                                    <MenuItem value={groupOption.id} key={groupOption.id} disabled={groupOption.disabled}>
                                        {groupOption.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {curEvent && curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.enabled && (
                        <>
                            {numFields === 0 ? (
                                <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel id="guest-count-label">Guest count</InputLabel>
                                    <Select
                                        labelId="guest-count-label"
                                        label="Guest count"
                                        value={String(rsvpFieldsSubmission.guests.length)}
                                        onChange={e => {
                                            let newGuests = []
                                            let numGuests = parseInt(e.target.value)

                                            if (!numGuests) {
                                                numGuests = 0
                                            }

                                            for (let i = 0; i < numGuests; i++) {
                                                newGuests.push(getBlankGuest())
                                            }

                                            setRSVPFieldsSubmission(submission => ({ ...submission, guests: newGuests }))
                                        }}
                                    >
                                        {guestCount.map(i => (
                                            <MenuItem value={i} key={i}>
                                                {i}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <>
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                        }}
                                    >
                                        Guests
                                    </Typography>
                                    <Typography variant="caption">{guestCountCaption}</Typography>
                                    <Typography variant="caption">All guest fields are required</Typography>
                                    {rsvpFieldsSubmission.guests &&
                                        rsvpFieldsSubmission.guests.map(guest => <React.Fragment key={guest.id}>{renderGuestEditor(guest)}</React.Fragment>)}
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{
                                            margin: 4,
                                        }}
                                        disabled={rsvpFieldsSubmission.guests && rsvpFieldsSubmission.guests.length >= maxGuests}
                                        onClick={() => {
                                            let guests = rsvpFieldsSubmission.guests
                                            guests.push(getBlankGuest())
                                            setRSVPFieldsSubmission(submission => ({ ...submission, guests: guests }))
                                        }}
                                    >
                                        Add Guest
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                    <Button
                        color="primary"
                        variant="contained"
                        style={{
                            margin: 4,
                        }}
                        disabled={!canSubmitGuests}
                        onClick={() => submitRSVP()}
                    >
                        Submit RSVP
                    </Button>
                </>
            )
        }

        return (
            <Button
                color="primary"
                variant="contained"
                style={{
                    margin: 4,
                }}
                onClick={() => submitInitialRSVPSubmission()}
            >
                {curEvent && curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.enabled ? 'RSVP' : 'Submit RSVP'}
            </Button>
        )
    }

    function hasRSVPDeadlinePassed() {
        if (curEvent) {
            let deadlineDate = new Date(getFirebaseDate(curEvent))

            if (curEvent && curEvent.rsvp.deadline !== undefined && curEvent.rsvp.deadline !== null && typeof curEvent.rsvp.deadline === 'number') {
                deadlineDate.setMinutes(deadlineDate.getMinutes() + curEvent.rsvp.deadline)
            }

            let deadline = Math.ceil(moment.duration(moment(deadlineDate).diff(moment())).asMinutes())

            if (deadline <= 0) {
                return true
            }

            return false
        }

        return false
    }

    function timeTillRSVPDeadline() {
        if (curEvent) {
            let deadlineDate = new Date(getFirebaseDate(curEvent))

            if (curEvent && curEvent.rsvp.deadline !== undefined && curEvent.rsvp.deadline !== null && typeof curEvent.rsvp.deadline === 'number') {
                deadlineDate.setMinutes(deadlineDate.getMinutes() + curEvent.rsvp.deadline)
            }

            let deadline = Math.ceil(moment.duration(moment(deadlineDate).diff(moment())).asMinutes())

            if (deadline < 60 && deadline > 0) {
                return `RSVP's due in ${deadline} ${deadline === 1 ? 'minute' : 'minutes'}`
            }

            if (deadline >= 60) {
                let deadlineHours = Math.floor(deadline / 60)

                if (deadlineHours < 24) {
                    return `RSVP's due in ${deadlineHours} ${deadlineHours === 1 ? 'hour' : 'hours'}`
                }
            }

            return false
        }

        return false
    }

    function getRSVPButton() {
        const rsvpDeadline = timeTillRSVPDeadline()

        return (
            <>
                {rsvpDeadline && <Alert severity="warning" variant="outlined" sx={{ mt: 1 }}>{rsvpDeadline}</Alert>}
                {rsvpStatus.error && <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>{rsvpStatus.error}</Alert>}
                {rsvpStatus.loading ? <CircularProgress size={28} /> : <>{getRSVPButtonInner()}</>}
            </>
        )
    }

    function renderGuests(guests) {
        const getGuestLabel = guest => {
            let str = ''
            let name = ''
            let phone = ''
            let email = ''
            let birthday = ''
            let unnamed = false
            if (guest[0]) {
                str += guest[0]
                name = guest[0]
            }

            if (guest[1]) {
                if (str) {
                    str += '; '
                }

                str += 'Phone: ' + guest[1]
                phone = guest[1]
            }

            if (guest[2]) {
                if (str) {
                    str += '; '
                }

                str += 'Email: ' + guest[2]
                email = guest[2]
            }

            if (guest[3]) {
                if (str) {
                    str += '; '
                }

                str += 'Birthday: ' + guest[3]
                birthday = guest[3]
            }

            if (!str) {
                str = 'Unnamed'
                unnamed = true
            }

            try {
                return (
                    <Grid container className="chipChild" direction="row" wrap={'nowrap'} style={{ overflowX: 'auto', overflowY: 'hidden' }} key={guest['id']}>
                        <Box className={classes.chips}>
                            {unnamed && (
                                <Chip className={classes.chipOutlined} icon={<FaceIcon style={{ color: 'unset' }} />} label={'Unnamed'} variant="outlined" />
                            )}
                            {name && <Chip className={classes.chipOutlined} icon={<FaceIcon style={{ color: 'unset' }} />} label={name} variant="outlined" />}
                            {email && (
                                <Chip className={classes.chipOutlined} icon={<EmailIcon style={{ color: 'unset' }} />} label={email} variant="outlined" />
                            )}
                            {phone && (
                                <Chip className={classes.chipOutlined} icon={<PhoneIcon style={{ color: 'unset' }} />} label={phone} variant="outlined" />
                            )}
                            {birthday && (
                                <Chip
                                    className={classes.chipOutlined}
                                    icon={<BirthdayIcon style={{ color: 'unset' }} />}
                                    label={format(parseISO(birthday), 'MMMM d, yyyy')}
                                    variant="outlined"
                                />
                            )}
                        </Box>
                    </Grid>
                )
            } catch (e) {
                return <></>
            }
        }

        const guestsStr = `You are bringing ${guests.length} ${guests.length === 1 ? 'guest' : 'guests'}`

        if (guests.length > 0) {
            if (guests[0][0] === undefined && guests[0][1] === undefined && guests[0][2] === undefined) {
                return <Typography>{guestsStr}</Typography>
            }

            return (
                <>
                    <Typography>{guestsStr}</Typography>
                    <Box className={classes.chipContainer}>{guests.map(guest => getGuestLabel(guest))}</Box>
                </>
            )
        }

        return <></>
    }

    function getExcusesResults() {
        if (!hasPassedExcuseDeadline()) {
            return { value: true, reason: 'Open', description: 'Submissions are open' }
        }

        return { value: false, reason: 'Ended', description: 'Submissions have been closed because the excuse deadline has passed' }
    }

    function getSignInResults() {
        if (hasSignInEnded()) {
            if (curEvent.signIn.closed) {
                return { value: false, reason: 'Closed', description: 'Submissions have been closed by an admin' }
            }
            return { value: false, reason: 'Ended', description: 'Submissions have been closed because the event has ended' }
        }

        if (hasSignInStarted(curEvent)) {
            if (curEvent.signIn.type === 3) {
                return { value: true, reason: 'Admin Open', description: 'Submissions are open for admins to sign members in' }
            }

            return { value: true, reason: 'Open', description: 'Submissions are open' }
        }

        return { value: false, reason: 'Not Opened', description: 'Submissions have not yet opened for sign in' }
    }

    function getRSVPResults() {
        let numAttendees = Object.keys(convertListToObject(curEvent.rsvp.attendees)).length
        let maxAttendees = curEvent.rsvp.maxAttendees

        if (curEvent.rsvp.closed) {
            return { value: false, reason: 'Closed', description: 'Submissions have been closed by an admin' }
        }

        if (hasRSVPDeadlinePassed()) {
            return { value: false, reason: 'Closed', description: 'Submissions have been closed because the rsvp deadline has passed' }
        }

        if (maxAttendees !== null && maxAttendees !== undefined) {
            if (maxAttendees <= numAttendees) {
                return { value: false, reason: 'Full', description: 'Submissions have been closed because the event is full' }
            }

            return { value: true, reason: 'Open', description: `${maxAttendees - numAttendees}/${maxAttendees} spots left` }
        } else {
            return { value: true, reason: 'Open', description: `Submissions are open` }
        }
    }

    function getRSVPSection() {
        if (curEvent.rsvp.enabled) {
            let attendees = convertObjectToList(curEvent.rsvp.attendees)
            let maxAttendees = curEvent.rsvp.maxAttendees
            let attendeesLeft = maxAttendees - attendees.length

            let isAttending = false

            let guests = []

            let attendee = null

            for (let i = 0; i < attendees.length; i++) {
                if (attendees[i].id === currentUser.uid || (attendees[i].id === undefined && attendees[i].roll === currentUser.rollNumber)) {
                    isAttending = true
                    attendee = attendees[i]
                    guests = attendees[i].guests ? attendees[i].guests : []
                }
            }

            const renderAttendeeGroup = attendee => {
                let groups = curEvent.rsvp.groups.groups

                for (let i = 0; i < groups.length; i++) {
                    let group = groups[i]
                    console.log()
                    if (group.id === attendee.groupId) {
                        return <Typography>You are in group {group.name}</Typography>
                    }
                }

                return <></>
            }

            const getRsvpWaitlistButton = () => {
                const waitlistMembers = curEvent.rsvp.waitlist.members
                const numSpotsAvailable = curEvent.rsvp.maxAttendees - attendees.length
                let waitlistSpotsRemaining = curEvent.rsvp.waitlist.max - waitlistMembers.length

                const waitlistMemberIndex = waitlistMembers.map(mem => mem.memberId).indexOf(currentUser.uid)
                if (waitlistMemberIndex !== -1) {
                    if (numSpotsAvailable > waitlistMemberIndex) {
                        return (
                            <>
                                <Alert severity="warning" variant="outlined" sx={{ mt: 1 }}>You are off the waitlist, but you need to RSVP!</Alert>
                                {rsvpStatus.error && <ErrorTypography text={rsvpStatus.error} />}
                                <Box style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{
                                            margin: 4,
                                            width: isMobileDevice() ? '100%' : 'auto',
                                        }}
                                        disabled={rsvpStatus.loading}
                                        onClick={() => submitInitialRSVPSubmission()}
                                    >
                                        {curEvent && curEvent.rsvp && curEvent.rsvp.guests && curEvent.rsvp.guests.enabled ? 'RSVP' : 'Submit RSVP'}
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{
                                            margin: 4,
                                            width: isMobileDevice() ? '100%' : 'auto',
                                        }}
                                        disabled={rsvpStatus.loading}
                                        onClick={removeSelfWaitlist}
                                    >
                                        Remove from Waitlist
                                    </Button>
                                </Box>
                            </>
                        )
                    }

                    return (
                        <>
                            <WarningTypography text={`You are spot #${waitlistMemberIndex + 1} on the waitlist`} />
                            {rsvpStatus.error && <ErrorTypography text={rsvpStatus.error} />}
                            <Box style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{
                                        margin: 4,
                                        width: isMobileDevice() ? '100%' : 'auto',
                                    }}
                                    disabled={rsvpStatus.loading}
                                    onClick={removeSelfWaitlist}
                                >
                                    Remove from Waitlist
                                </Button>
                            </Box>
                        </>
                    )
                }

                if (waitlistSpotsRemaining <= 0) {
                    return <WarningTypography text={'Waitlist is full'} />
                }
                return (
                    <>
                        <Alert severity="warning" variant="outlined" sx={{ mt: 1 }}>{waitlistSpotsRemaining + ' or less spots remaining on waitlist'}</Alert>
                        {rsvpStatus.error && <ErrorTypography text={rsvpStatus.error} />}
                        <Button
                            color="primary"
                            variant="contained"
                            style={{
                                margin: 4,
                                width: isMobileDevice() ? '100%' : 'auto',
                            }}
                            disabled={rsvpStatus.loading}
                            onClick={addSelfWaitlist}
                        >
                            Join Waitlist
                        </Button>
                    </>
                )
            }

            if (!hasRSVPDeadlinePassed()) {
                let numSpotsAvailable = null

                if (curEvent.rsvp.maxAttendees !== undefined && curEvent.rsvp.maxAttendees !== null) {
                    numSpotsAvailable = curEvent.rsvp.maxAttendees - attendees.length

                    if (curEvent.rsvp.waitlist && curEvent.rsvp.waitlist.enabled) {
                        const waitlistMembersLength = curEvent.rsvp.waitlist.members.length
                        numSpotsAvailable -= waitlistMembersLength
                    }

                    if (numSpotsAvailable < 0) {
                        numSpotsAvailable = 0
                    }
                }

                return (
                    <Grid item xs={12} className={classes.borderTop}>
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: 500,
                            }}
                        >
                            RSVP
                        </Typography>

                        {isAttending ? (
                            <>
                                <Alert severity="success" variant="outlined" sx={{ mt: 1 }}>You have successfully RSVP'd!</Alert>
                                {curEvent.rsvp.groups && curEvent.rsvp.groups.enabled === true && attendee && renderAttendeeGroup(attendee)}
                                {curEvent.rsvp.guests && curEvent.rsvp.guests.enabled ? renderGuests(guests) : ''}
                                {getAskAdminToChange()}
                            </>
                        ) : numSpotsAvailable !== null && numSpotsAvailable < 6 && numSpotsAvailable > 0 ? (
                            <>
                                <Alert severity="warning" variant="outlined" sx={{ mt: 1 }}>{attendeesLeft + ' or less spots remaining'}</Alert>
                                {getRSVPButton()}
                            </>
                        ) : numSpotsAvailable !== null && numSpotsAvailable <= 0 ? (
                            <>
                                <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>This event is full</Alert>
                                {curEvent.rsvp.waitlist && curEvent.rsvp.waitlist.enabled && getRsvpWaitlistButton()}
                            </>
                        ) : curEvent.rsvp.closed ? (
                            <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>Submissions have been closed</Alert>
                        ) : (
                            getRSVPButton()
                        )}
                    </Grid>
                )
            } else {
                // Event started, just show if they rsvp'd or not
                if (isAttending) {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                RSVP
                            </Typography>
                            <Alert severity="success" variant="outline">You RSVP'd for this event!</Alert>

                            {curEvent.rsvp.groups && curEvent.rsvp.groups.enabled && attendee && renderAttendeeGroup(attendee)}
                            {curEvent.rsvp.guests && curEvent.rsvp.guests.enabled ? renderGuests(guests) : ''}
                        </Grid>
                    )
                } else {
                    return (
                        <Grid item xs={12} className={classes.borderTop}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                RSVP
                            </Typography>
                            <Alert severity="warning" variant="outline">You didn't RSVP for this event</Alert>
                        </Grid>
                    )
                }
            }
        }

        return <></>
    }

    function getExcusedColumns() {
        let cols = []

        if (chapter && chapter.settings && chapter.settings.roll) {
            cols.push({
                title: 'Roll',
                value: 'roll',
                type: 'NUMBER',
                size: 'small',
                disabled: true,
                sortable: true,
            })
        }

        if (curEvent && curEvent.excuses.enabled) {
            cols.push({
                title: 'Excused',
                value: 'excused',
                type: 'BOOLEAN',
                size: 'small',
                sortable: true,
            })
        }

        cols.push({
            title: 'Name',
            value: 'name',
            required: true,
            sortable: true,
        })

        return cols
    }

    function getSignInPassword() {
        setSignInPassword({ ...signInPassword, loading: true })

        db.collection('chapters')
            .doc(user.getChapter())
            .collection('events')
            .doc(eventID)
            .collection('data')
            .doc('meta')
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()
                    setSignInPassword({ ...signInPassword, loading: false, password: data.password, qrCode: data.qrCode, pin: data.pin })
                } else {
                    setSignInPassword({ ...signInPassword, loading: false })
                }
            })
            .catch(function(error) {
                console.log('got an error', error.message)
            })
    }

    function getRSVPHeaderRows() {
        let rows = []

        if (curEvent.rsvp.maxAttendees) {
            let max = curEvent.rsvp.maxAttendees
            let num = Object.keys(convertListToObject(curEvent.rsvp.attendees)).length
            let val = num > 0 ? (num / max) * 100 : 0

            if (val > 100) {
                val = 100
            }

            rows.push(<ProgressHeader percentage={val} displayValue={num + ' / ' + max + ' (' + Number(val.toFixed(2)) + '%)'} />)
        }

        rows.push(<StatusHeader status={getRSVPResults()} />)

        let messagesEnabled = !chapter || !chapter.enabledFeatures || chapter.enabledFeatures.messages !== false

        if (messagesEnabled && isUserMessagesCreateAdmin()) {
            rows.push(
                <LinkHeader
                    title="Message RSVP'd Members"
                    icon={<MessagesIcon />}
                    onClick={() => {
                        props.history.push(`/app/messages/new/${eventID}/3`)
                    }}
                />,
            )
        }

        return rows
    }

    function getExcusesHeaderRows() {
        return [<StatusHeader status={getExcusesResults()} />]
    }

    function getAttendanceHeaderRows() {
        let allMems = getAllMembers()
        let numTotal = allMems.length
        let numPresent = allMems.filter(member => member.present === true).length
        let val = numTotal > 0 ? (numPresent / numTotal) * 100 : 100

        let pollsEnabled = !chapter || !chapter.enabledFeatures || chapter.enabledFeatures.polls !== false
        let messagesEnabled = !chapter || !chapter.enabledFeatures || chapter.enabledFeatures.messages !== false

        const headerRows = [
            <ProgressHeader percentage={val} displayValue={numPresent + ' / ' + numTotal + ' (' + Number(val.toFixed(2)) + '%)'} />,
            <StatusHeader status={getSignInResults()} />,
        ]
        const rows = []
        if (pollsEnabled) {
            rows.push(
                <LinkHeader
                    title="Poll Present Members"
                    icon={<PollsIcon />}
                    onClick={() => {
                        props.history.push(
                            `/app/applications/polls/edit/new/?eid=${eventID}&ename=${encodeURIComponent(
                                `${curEvent.name ? curEvent.name : 'Event'} (${moment(getFirebaseDate(curEvent)).format('M/D')})`,
                            )}`,
                        )
                    }}
                />,
            )
        }

        if (messagesEnabled && isUserMessagesCreateAdmin()) {
            rows.push(
                <LinkHeader
                    title="Message Present Members"
                    icon={<MessagesIcon />}
                    onClick={() => {
                        props.history.push(`/app/messages/new/${eventID}/2`)
                    }}
                />,
            )
        }

        if (curEvent.signIn.enabled) {
            if (curEvent.signIn.type === 1) {
                if (signInPassword.password) {
                    rows.push(<ClipboardHeader title="Sign In Password" displayText={signInPassword.password} copyText={signInPassword.password} />)
                } else {
                    rows.push(
                        <LinkHeader
                            title="View Sign In Password"
                            icon={<PasswordIcon />}
                            onClick={() => {
                                getSignInPassword()
                            }}
                            loading={signInPassword.loading}
                        />,
                    )
                }
            } else if (curEvent.signIn.type === 4) {
                if (signInPassword.pin) {
                    rows.push(<ClipboardHeader title="Sign In PIN" displayText={signInPassword.pin} copyText={signInPassword.pin} />)
                } else {
                    rows.push(
                        <LinkHeader
                            title="View Sign In PIN"
                            icon={<PasswordIcon />}
                            onClick={() => {
                                getSignInPassword()
                            }}
                            loading={signInPassword.loading}
                        />,
                    )
                }
            }
        }

        if (curEvent.signIn.enabled && curEvent.signIn.type === 2) {
            if (signInPassword.qrCode) {
                rows.push(
                    <LinkHeader
                        title="View QR Code"
                        icon={<Icon className="fas fa-qrcode" />}
                        onClick={() => {
                            setViewQRCodeDialog({ ...viewQRCodeDialog, open: true })
                        }}
                    />,
                )
            } else {
                rows.push(
                    <LinkHeader
                        title="Load QR Code"
                        icon={<Icon className="fas fa-qrcode" />}
                        onClick={() => {
                            getSignInPassword()
                        }}
                        loading={signInPassword.loading}
                    />,
                )
            }
        }

        if (curEvent.signIn.enabled && curEvent.signIn.type === 3) {
            rows.push(
                <LinkHeader
                    title="Scan QR Codes"
                    icon={<Icon className="fas fa-qrcode" />}
                    onClick={() => {
                        setScanQRCodesDialog({ open: true, error: null, scan: '', facingMode: 'front' })
                    }}
                />,
            )
        }

        return { headerRows, rows }
    }

    function getRSVPActions() {
        let rows = []

        if (!hasEventEnded() && !hasEventStarted()) {
            if (curEvent.rsvp.closed) {
                rows.push({
                    title: 'Open RSVP Submissions',
                    icon: <PresentIcon />,
                    onClick: () => {
                        db.collection('chapters')
                            .doc(user.getChapter())
                            .collection('events')
                            .doc(eventID)
                            .update({ 'rsvp.closed': false })
                    },
                })
            } else {
                rows.push({
                    title: 'Close RSVP Submissions',
                    icon: <CloseCircleIcon />,
                    onClick: () => {
                        db.collection('chapters')
                            .doc(user.getChapter())
                            .collection('events')
                            .doc(eventID)
                            .update({ 'rsvp.closed': true })
                    },
                })
            }
        }

        /*rows.push({
            title: 'Upload RSVPs',
            icon: <UploadIcon />,
            onClick: () => {
                setUploadRSVPListDialog({ ...uploadRSVPListDialog, open: true })
            },
        })*/

        rows.push({
            title: 'Download RSVPs',
            icon: <DownloadIcon />,
            onClick: exportRSVPList,
        })

        return rows
    }

    function getAttendanceActions() {
        let rows = []

        /*if (curEvent.signIn.enabled && curEvent.signIn.password) {
            rows.push({
                title: 'View QR Code',
                icon: <Icon className="fas fa-qrcode" />,
                onClick: () => {
                    setViewQRCodeDialog({ open: true })
                },
            })
        }*/
        if (!hasEventEnded() && hasSignInStarted(curEvent)) {
            if (curEvent.signIn.closed) {
                rows.push({
                    title: 'Open Sign In',
                    icon: <PresentIcon />,
                    onClick: () => {
                        db.collection('chapters')
                            .doc(user.getChapter())
                            .collection('events')
                            .doc(eventID)
                            .update({ 'signIn.closed': false })
                    },
                })
            } else {
                rows.push({
                    title: 'Close Sign In',
                    icon: <CloseCircleIcon />,
                    onClick: () => {
                        db.collection('chapters')
                            .doc(user.getChapter())
                            .collection('events')
                            .doc(eventID)
                            .update({ 'signIn.closed': true })
                    },
                })
            }
        }

        rows.push({
            title: 'Download Attendance',
            icon: <DownloadIcon />,
            onClick: () => {
                setDownloadAttendanceDialog({ ...downloadAttendanceDialog, open: true })
            },
        })

        return rows
    }

    function getMemAttendance() {
        if (curEvent.signIn.enabled) {
            //Grab all the people that aren't signed in and if they are of status 1, write them in. If they have an excuse, note that...
            let excused = []
            let nonExcused = []
            let absent = []

            let present = members.filter(member => member.present === true).map(member => member.roll)

            let mems = getCombinedAbsent()
            for (let i = 0; i < mems.length; i++) {
                let mem = mems[i]
                let orgMem = chapter.members[mem.id]

                if (orgMem.status === 1) {
                    if (!curEvent.excuses.enabled) {
                        absent.push(mem.roll)
                    } else {
                        if (mem.excused) {
                            excused.push(mem.roll)
                        } else {
                            nonExcused.push(mem.roll)
                        }
                    }
                }
            }

            let str = ''

            if (present.length > 0) {
                str += chapter.letters + ' '

                for (let i = 0; i < present.length; i++) {
                    str += present[i]
                    if (i === present.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === present.length - 1) {
                        if (i === 0) {
                            str += ' was present.\n'
                        } else {
                            str += ' were present.\n'
                        }
                    } else {
                        str += ', '
                    }
                }
            }

            if (absent.length > 0) {
                str += chapter.letters + ' '

                for (let i = 0; i < absent.length; i++) {
                    str += absent[i]
                    if (i === absent.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === absent.length - 1) {
                        if (i === 0) {
                            str += ' was absent.'
                        } else {
                            str += ' were absent.'
                        }
                    } else {
                        str += ', '
                    }
                }
            }

            if (nonExcused.length > 0) {
                str += chapter.letters + ' '

                for (let i = 0; i < nonExcused.length; i++) {
                    str += nonExcused[i]
                    if (i === nonExcused.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === nonExcused.length - 1) {
                        if (i === 0) {
                            str += ' was absent without excuse.'
                        } else {
                            str += ' were absent without excuse.'
                        }
                    } else {
                        str += ', '
                    }
                }
            }

            if (excused.length > 0) {
                if (str.length > 0) {
                    str += ' '
                }
                str += chapter.letters + ' '

                for (let i = 0; i < excused.length; i++) {
                    str += excused[i]
                    if (i === excused.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === excused.length - 1) {
                        if (i === 0) {
                            str += ' was absent but excused.'
                        } else {
                            str += ' were absent but excused.'
                        }
                    } else {
                        str += ', '
                    }
                }
            }

            if (str === '') {
                return 'All active ' + chapter.letters + 's were present.'
            }

            return str
        }

        return false
    }

    function canViewRSVP() {
        if (curEvent) {
            if (curEvent.rsvp.enabled) {
                if (hasCalendarAdmin) {
                    return true
                }

                if (curEvent.rsvp.vis !== undefined) {
                    // Everyone can view
                    if (curEvent.rsvp.vis === 2) {
                        return true
                    }

                    //Rsvp'd members can view
                    if (curEvent.rsvp.vis === 1) {
                        let attendees = convertObjectToList(curEvent.rsvp.attendees)

                        for (let i = 0; i < attendees.length; i++) {
                            if (attendees[i].id === currentUser.uid || (attendees[i].id === undefined && attendees[i].roll === currentUser.rollNumber)) {
                                return true
                            }
                        }
                    }
                }
            }
        }

        return false
    }

    function getAlsoPresentAttendance() {
        if (curEvent.signIn.enabled) {
            //Grab all the people that aren't signed in and if they are of status 1, write them in. If they have an excuse, note that...
            let alsoPresent = []

            let mems = getAllMembers()
            for (let i = 0; i < mems.length; i++) {
                let mem = mems[i]
                let orgMem = chapter.members[mem.id]

                if (orgMem.status === 2) {
                    if (mem.present) {
                        alsoPresent.push(mem.roll)
                    }
                }
            }

            let str = ''

            if (alsoPresent.length > 0) {
                str += 'Also present: ' + chapter.letters + ' '

                for (let i = 0; i < alsoPresent.length; i++) {
                    str += alsoPresent[i]
                    if (i === alsoPresent.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === alsoPresent.length - 1) {
                        str += '.'
                    } else {
                        str += ', '
                    }
                }
            }

            if (str.length === 0) {
                return false
            }

            return str
        }

        return false
    }

    const duplicateEvent = () => {
        // IMPORATANT: current event send in data to new event page
        if (curEvent) props.history.push({ pathname: '/app/calendar/event/new', data: curEvent })
    }

    function addToCalendar() {
        const cal = ical()

        const e = curEvent

        const timezone = chapter && chapter.settings && chapter.settings.timezone ? chapter.settings.timezone : 'America/New_York'

        let eStart = getEventStart(e, timezone)
        let eEnd = getEventEnd(e, timezone)

        if (e.date.sd === undefined && e.length !== undefined && e.length === 0) {
            eStart.setHours(eStart.getHours() - 24)
            eEnd.setHours(eEnd.getHours() - 24)
        }

        let calE = cal.createEvent({
            uid: eventID,
            start: eStart,
            end: eEnd,
            timezone: timezone,
            allDay: e.date.ad ? e.date.ad : e.length === 0,
            summary: e.name,
            location: e.address && e.address.length > 0 ? e.address : e.location && e.location.length > 0 ? e.location : undefined,
        })

        for (let i = 0; i < e.categories.length; i++) {
            calE.createCategory({
                name: e.categories[i].name,
            })
        }

        let eventName = curEvent.name.replace(/\W/g, '')

        const element = document.createElement('a')

        element.href = URL.createObjectURL(cal.toBlob())
        element.download = 'event_' + eventName + '.ics'
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
    }

    function getNewMemAttendance() {
        if (curEvent.signIn.enabled) {
            //Grab all the people that aren't signed in and if they are of status 1, write them in. If they have an excuse, note that...
            let excused = []
            let nonExcused = []
            let absent = []
            let present = []

            let mems = getAllMembers()
            for (let i = 0; i < mems.length; i++) {
                let mem = mems[i]
                let orgMem = chapter.members[mem.id]

                if (orgMem.status === 0) {
                    if (mem.present) {
                        present.push(mem.roll)
                    } else {
                        if (!curEvent.excuses.enabled) {
                            absent.push(mem.roll)
                        } else {
                            if (mem.excused) {
                                excused.push(mem.roll)
                            } else {
                                nonExcused.push(mem.roll)
                            }
                        }
                    }
                }
            }

            let str = ''

            if (absent.length > 0) {
                str += chapter.newMemberShortname + ' '

                for (let i = 0; i < absent.length; i++) {
                    str += absent[i]
                    if (i === absent.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === absent.length - 1) {
                        if (i === 0) {
                            str += ' was absent.'
                        } else {
                            str += ' were absent.'
                        }
                    } else {
                        str += ', '
                    }
                }
            }

            if (nonExcused.length > 0) {
                str += chapter.newMemberShortname + ' '

                for (let i = 0; i < nonExcused.length; i++) {
                    str += nonExcused[i]
                    if (i === nonExcused.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === nonExcused.length - 1) {
                        if (i === 0) {
                            str += ' was absent without excuse.'
                        } else {
                            str += ' were absent without excuse.'
                        }
                    } else {
                        str += ', '
                    }
                }
            }

            if (excused.length > 0) {
                str += chapter.newMemberShortname + ' '

                for (let i = 0; i < excused.length; i++) {
                    str += excused[i]
                    if (i === excused.length - 2) {
                        if (i > 1) {
                            str += ', and '
                        } else {
                            str += ' and '
                        }
                    } else if (i === excused.length - 1) {
                        if (i === 0) {
                            str += ' was absent but excused.'
                        } else {
                            str += ' were absent but excused.'
                        }
                    } else {
                        str += ', '
                    }
                }
            }

            if (excused.length === 0 && nonExcused.length === 0 && absent.length === 0) {
                if (present.length === 0) {
                    return false
                }

                return 'All ' + chapter.newMemberShortname + 's were present.'
            }

            return str
        }

        return false
    }

    let fgColor = viewQRCodeDialog.fgColor
    let bgColor = viewQRCodeDialog.bgColor

    if (fgColor) {
        fgColor.replace('#', '')
    }

    if (bgColor) {
        bgColor.replace('#', '')
    }

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test(userAgent)
    }

    function getAddressURL(address, googleMapsPlace) {
        if (isIos()) {
            return 'https://maps.apple.com/?daddr=' + encodeURIComponent(address)
        }
        // web/android
        if(googleMapsPlace && googleMapsPlace?.value?.place_id) {
            return `https://www.google.com/maps/place?q=place_id:${googleMapsPlace?.value?.place_id}`
        }
        return 'https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(address)
    }

    function compileRSVPMembers() {
        if (!(curEvent.rsvp.guests && curEvent.rsvp.guests.enabled)) {
            return rsvpMembers
        }

        let newMems = [...rsvpMembers]

        for (let i = 0; i < newMems.length; i++) {
            let newMem = { ...newMems[i] }

            let guestCount = 0
            let guestInfo = null

            if (newMem.guests) {
                if (Array.isArray(newMem.guests)) {
                    guestCount = newMem.guests.length
                    guestInfo = ''
                }
            }

            newMem.guest_count = guestCount
            newMem.guest_info = guestInfo

            newMems[i] = newMem
        }

        return newMems
    }

    function exportToPDF() {
        let date = moment(getFirebaseDate(curEvent))

        let memAttendance = curEvent.signIn.enabled && (curEvent.visibility === undefined || curEvent.visibility.includes(1)) ? getMemAttendance() : false
        let newMemberAttendance =
            curEvent.signIn.enabled && (curEvent.visibility === undefined || curEvent.visibility.includes(0)) ? getNewMemAttendance() : false
        let alsoPresentAttendance =
            curEvent.signIn.enabled && (curEvent.visibility === undefined || curEvent.visibility.includes(2)) ? getAlsoPresentAttendance() : false

        const MyDoc = (
            <Document title={curEvent.name} author={user.getFullName()} creator="Greek Connect">
                <Page footer>
                    <Text variant="title">{curEvent.name}</Text>
                    <Text variant="h1">
                        {fraternity && fraternity.fullName && chapter.fullName
                            ? fraternity.fullName + ' Fraternity, ' + chapter.fullName + ' Chapter'
                            : fraternity && fraternity.fullName
                            ? fraternity.fullName + ' Fraternity'
                            : chapter && chapter.fullName
                            ? chapter.fullName + ' Chapter'
                            : 'Greek Connect'}
                    </Text>
                    <Text variant="subtitle">{getEventTiming(curEvent, { includeLength: true, notRelative: true, longText: true })}</Text>
                    {curEvent.location ? (
                        <>
                            <Text>{'Location: ' + curEvent.location}</Text>
                        </>
                    ) : (
                        <></>
                    )}
                    { curEvent?.googleMapsPlaceLabel || curEvent?.address || curEvent?.location ? (
                        <>
                            <Text>{`Address:  ${curEvent?.googleMapsPlaceLabel || curEvent?.address || curEvent?.location || ''}`}</Text>
                        </>
                    ) : (
                        <></>
                    )}
                    {curEvent.cost ? (
                        <>
                            <Text>{'Cost: $' + curEvent.cost}</Text>
                        </>
                    ) : (
                        <></>
                    )}
                    {curEvent.description ? (
                        <>
                            <Text>&nbsp;</Text>
                            <Text variant="h2">Description</Text>
                            <Text>{curEvent.description}</Text>
                        </>
                    ) : (
                        <></>
                    )}
                    {curEvent.signIn.enabled && (
                        <>
                            <Text>&nbsp;</Text>
                            <Typography 
                    variant="h5" 
                    fontWeight={500}
                    style={{
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        marginBottom: '0.5rem',
                    }}
                >
                    Attendance
                </Typography>
                            {memAttendance && <Text>{memAttendance}</Text>}
                            {newMemberAttendance && <Text>{newMemberAttendance}</Text>}
                            {alsoPresentAttendance && <Text>{alsoPresentAttendance}</Text>}
                        </>
                    )}
                </Page>
            </Document>
        )

        pdf(MyDoc)
            .toBlob()
            .then(function(blob) {
                let eventName = curEvent.name.replace(/\W/g, '')

                exportFile(blob, 'event_' + eventName + '_' + date.format('YYYY-MM-DD') + '.pdf', 'application/pdf')
            })
    }

    function compileRSVPGuests() {
        if (!(curEvent.rsvp.guests && curEvent.rsvp.guests.enabled)) {
            return []
        }

        let guests = []

        for (let i = 0; i < rsvpMembers.length; i++) {
            let mem = rsvpMembers[i]

            if (Array.isArray(mem.guests)) {
                for (let j = 0; j < mem.guests.length; j++) {
                    let guest = { ...mem.guests[j] }
                    guest.memberName = mem.id
                    guests.push(guest)
                }
            }
        }

        return guests
    }

    function compileRSVPWaitlist() {
        if (!(curEvent.rsvp.waitlist && curEvent.rsvp.waitlist.enabled)) {
            return []
        }

        let waitlist = []

        const waitlistMembers = curEvent.rsvp.waitlist.members

        for (let i = 0; i < waitlistMembers.length; i++) {
            let member = waitlistMembers[i]
            let memId = member.memberId

            if (memId in chapter.members) {
                let mem = chapter.members[memId]
                let waitlistMember = {
                    id: memId,
                    spot: i + 1,
                    name: mem.first + ' ' + mem.last,
                }
                if (member.expiration) {
                    waitlistMember.expiration = { seconds: member.expiration / 1000 }
                }
                waitlist.push(waitlistMember)
            }
        }

        return waitlist
    }

    function getExcusesColumns() {
        let nameOptions = { '': { title: '', default: true } }

        let visMembers = convertObjectToList(chapter.members).filter(mem => curEvent.visibility.includes(mem.status))

        for (let i = 0; i < visMembers.length; i++) {
            let mem = visMembers[i]

            const id = mem.id

            delete mem.id

            nameOptions[id] = { title: `${mem.first} ${mem.last}`, default: false }
        }

        let cols = []

        if (chapter && chapter.settings && chapter.settings.roll) {
            cols.push({
                title: 'Roll',
                value: 'roll',
                size: 'small',
                type: 'NUMBER',
                disabled: true,
                sortable: true,
            })
        }

        cols = [
            ...cols,
            {
                title: 'Name',
                value: 'name',
                type: 'SELECT',
                required: true,
                options: nameOptions,
            },
            {
                title: 'Excused',
                value: 'excused',
                type: 'BOOLEAN',
                size: 'small',
                showFalse: data => {
                    return data.excuseVoted !== undefined && data.excuseVoted === true
                },
            },
            {
                title: 'Reason',
                value: 'reason',
                size: 'large',
                required: false,
                tooltipOnHover: true,
            },
        ]

        return cols
    }

    function getRSVPNameOptions() {
        let nameOptions = { '': { title: '', default: true } }

        let visMembers = convertObjectToList(chapter.members).filter(mem => curEvent.visibility.includes(mem.status))

        for (let i = 0; i < visMembers.length; i++) {
            let mem = visMembers[i]

            const id = mem.id

            delete mem.id

            nameOptions[id] = { title: `${mem.first} ${mem.last}`, default: false }
        }

        return nameOptions
    }

    function getRSVPTableColumns() {
        let nameOptions = getRSVPNameOptions()

        let cols = []

        if (chapter && chapter.settings && chapter.settings.roll) {
            cols.push({
                title: 'Roll',
                value: 'roll',
                type: 'NUMBER',
                size: 'small',
                disabled: true,
                sortable: true,
            })
        }

        cols.push({
            title: 'Name',
            value: 'name',
            type: 'SELECT',
            required: true,
            editable: false,
            sortable: true,
            options: nameOptions,
        })

        if (curEvent.rsvp.guests && curEvent.rsvp.guests.enabled) {
            let guestField = {
                title: 'Guests',
                value: 'guest_count',
                size: 'small',
                type: 'NUMBER',
                disabled: true,
            }

            if (hasCalendarAdmin) {
                guestField.tooltip_value = 'guest_info'
            }

            cols.push(guestField)
        }

        if (curEvent.rsvp.groups && curEvent.rsvp.groups.enabled) {
            let groupOptions = { '': { title: '', default: true } }

            const groups = curEvent.rsvp.groups.groups
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i]
                groupOptions[group.id] = { title: group.name, default: false }
            }

            let groupField = {
                title: 'Group',
                value: 'groupId',
                size: 'small',
                type: 'SELECT',
                required: true,
                sortable: true,
                options: groupOptions,
            }

            cols.push(groupField)
        }

        return cols
    }

    function getRSVPGuestTableColumns() {
        let cols = []

        let nameOptions = { '': { title: '', default: true } }

        let visMembers = convertObjectToList(chapter.members).filter(mem => curEvent.visibility.includes(mem.status))

        for (let i = 0; i < visMembers.length; i++) {
            let mem = visMembers[i]

            const id = mem.id

            delete mem.id

            nameOptions[id] = { title: `${mem.first} ${mem.last}`, default: false }
        }

        cols.push({
            title: 'Member Name',
            value: 'memberName',
            size: 'large',
            sortable: true,
            type: 'SELECT',
            required: true,
            options: nameOptions,
        })

        if (curEvent.rsvp.guests.fields.includes(0)) {
            cols.push({
                title: 'Name',
                value: '0',
                required: true,
            })
        }

        if (curEvent.rsvp.guests.fields.includes(1)) {
            cols.push({
                title: 'Phone',
                value: '1',
                required: true,
            })
        }

        if (curEvent.rsvp.guests.fields.includes(2)) {
            cols.push({
                title: 'Email',
                value: '2',
                size: 'large',
                required: true,
            })
        }

        if (curEvent.rsvp.guests.fields.includes(3)) {
            cols.push({
                title: 'Birthday',
                value: '3',
                type: 'DATE',
                specialty: 'DATE_SIMPLIFIED',
                format: 'l',
                required: true,
            })
        }

        return cols
    }

    const getEventDays = () => {
        if (!curEvent) {
            return []
        }

        let days = []

        let curDate = moment(getDateFromYMD(curEvent.date.sd)).startOf('day')

        let endDate = moment(getDateFromYMD(curEvent.date.ed)).endOf('day')

        do {
            days.push({
                str: curDate.format('dddd (MMMM Do)'),
                val: curDate.format('YYYY-MM-DD'),
            })
            curDate = moment(curDate).add(1, 'day')
        } while (curDate.isBefore(endDate))

        return days
    }

    const renderExcuses = () => (
        <ParentTable title="Excuses" rows={getExcusesHeaderRows()}>
            <Table
                title="Submissions"
                addRowText="Add Submission"
                onAdd={member => {
                    addExcuse(member)
                }}
                rowActions={[
                    {
                        title: 'Remove Submission',
                        icon: <AbsentIcon />,
                        onClick: rowData => {
                            removeExcuse(rowData)
                        },
                    },
                    {
                        title: 'Approve Excuse',
                        icon: <ApprovedIcon />,
                        onClick: rowData => {
                            approveExcuse(rowData)
                        },
                    },
                    {
                        title: 'Deny Excuse',
                        icon: <DeniedIcon />,
                        onClick: rowData => {
                            denyExcuse(rowData)
                        },
                    },
                ]}
                columns={getExcusesColumns()}
                showOnEmpty={true}
                emptyText="No Excuses"
                data={addNames(members.filter(member => member.reason !== null && member.reason !== undefined))}
            />
        </ParentTable>
    )

    function getRsvpWaitlistOptions() {
        if (curEvent && curEvent.rsvp && curEvent.rsvp.waitlist && curEvent.visibility && chapter.members) {
            if (Array.isArray(curEvent.rsvp.attendees)) {
                return getRSVPNameOptions()
            }
            // Add check for curEvent.rsvp.attendees before using it
            if (curEvent.rsvp.attendees) {
                return convertListToObject(convertObjectToList(getRSVPNameOptions()).filter(mem => !(mem.id in curEvent.rsvp.attendees)))
            }
            // If attendees is undefined, just return all options
            return getRSVPNameOptions()
        }
        return {}
    }

    const renderRSVP = () => {
        let numSections = 1
        if (curEvent.rsvp.guests && curEvent.rsvp.guests.enabled) numSections++
        if (curEvent.rsvp.waitlist && curEvent.rsvp.waitlist.enabled) numSections++

        const hasGroups = curEvent && curEvent.rsvp && curEvent.rsvp.groups && curEvent.rsvp.groups.enabled

        return (
            <ParentTable title="RSVP" actions={hasCalendarAdmin ? getRSVPActions() : []} rows={hasCalendarAdmin ? getRSVPHeaderRows() : null}>
                <Grid container>
                    <Grid item xs={12} md={numSections >= 2 ? 6 : 12}>
                        <Table
                            title="Submissions"
                            addRowText="Add Member RSVP"
                            onAdd={
                                hasCalendarAdmin
                                    ? member => {
                                          addRSVP(member)
                                      }
                                    : null
                            }
                            rowActions={
                                hasCalendarAdmin
                                    ? /*hasGroups
                                        ? [
                                              {
                                                  title: 'Edit Member RSVP',
                                                  icon: <EditOutline />,
                                                  toggleEditing: true,
                                              },
                                              {
                                                  title: 'Remove Member RSVP',
                                                  icon: <AbsentIcon />,
                                                  onClick: rowData => {
                                                      removeRSVP(rowData)
                                                  },
                                              },
                                          ]
                                        :*/ [
                                          {
                                              title: 'Remove Member RSVP',
                                              icon: <AbsentIcon />,
                                              onClick: rowData => {
                                                  removeRSVP(rowData)
                                              },
                                          },
                                      ]
                                    : []
                            }
                            onUpdateRow={
                                hasGroups
                                    ? rowData => {
                                          console.log('todo', rowData)
                                      }
                                    : undefined
                            }
                            defaultSortable={{
                                value: 'name',
                                dir: 'desc',
                            }}
                            columns={getRSVPTableColumns()}
                            showOnEmpty={true}
                            emptyText="No RSVP's"
                            data={compileRSVPMembers()}
                            numRows={10}
                        />
                    </Grid>
                    {curEvent.rsvp.guests && curEvent.rsvp.guests.enabled && (
                        <Grid item xs={12} md={6}>
                            <Table
                                title="Guests"
                                addRowText="Add Guest"
                                onAdd={
                                    hasCalendarAdmin
                                        ? member => {
                                              addGuestRSVP(member)
                                          }
                                        : null
                                }
                                rowActions={
                                    hasCalendarAdmin
                                        ? [
                                              {
                                                  title: 'Remove Guest',
                                                  icon: <AbsentIcon />,
                                                  onClick: rowData => {
                                                      removeGuestRSVP(rowData)
                                                  },
                                              },
                                          ]
                                        : []
                                }
                                columns={getRSVPGuestTableColumns()}
                                defaultSortable={{
                                    value: 'memberName',
                                    dir: 'desc',
                                }}
                                showOnEmpty={true}
                                emptyText="No Guests"
                                data={compileRSVPGuests()}
                                numRows={10}
                            />
                        </Grid>
                    )}
                    {curEvent.rsvp.waitlist && curEvent.rsvp.waitlist.enabled && (
                        <Grid item xs={12} md={numSections === 2 ? 6 : 12}>
                            <Table
                                title="Waitlist"
                                addRowText="Add Member to Waitlist"
                                onAdd={
                                    hasCalendarAdmin
                                        ? member => {
                                              addMemberWaitlist(member)
                                          }
                                        : null
                                }
                                rowActions={
                                    hasCalendarAdmin
                                        ? [
                                              {
                                                  title: 'Remove Member from Waitlist',
                                                  icon: <AbsentIcon />,
                                                  onClick: rowData => {
                                                      removeMemberWaitlist(rowData)
                                                  },
                                              },
                                          ]
                                        : []
                                }
                                sortable
                                columns={[
                                    {
                                        title: 'Spot',
                                        value: 'spot',
                                        type: 'NUMBER',
                                        size: 'small',
                                        disabled: true,
                                        sortable: true,
                                    },
                                    {
                                        title: 'Name',
                                        value: 'name',
                                        type: 'SELECT',
                                        required: true,
                                        options: getRsvpWaitlistOptions(),
                                        sortable: true,
                                    },
                                    {
                                        title: 'Expiration',
                                        value: 'expiration',
                                        type: 'TIME',
                                        size: 'small',
                                        format: 'hh:mm a',
                                        disabled: true,
                                    },
                                ]}
                                defaultSortable={{
                                    value: 'spot',
                                    dir: 'asc',
                                }}
                                showOnEmpty={true}
                                emptyText="No Members on Waitlist"
                                data={compileRSVPWaitlist()}
                                numRows={10}
                            />
                        </Grid>
                    )}
                </Grid>
            </ParentTable>
        )
    }

    const getAttendanceColumns = () => {
        let cols = []

        if (chapter && chapter.settings && chapter.settings.roll) {
            cols.push({
                title: 'Roll',
                value: 'roll',
                size: 'small',
                type: 'NUMBER',
                disabled: true,
                sortable: true,
            })
        }

        return [
            ...cols,
            {
                title: 'Name',
                value: 'name',
                required: true,
                sortable: true,
            },
            {
                title: 'Time',
                value: 'signInTime',
                type: 'TIME',
                format: 'hh:mm a',
                disabled: true,
                sortable: true,
            },
        ]
    }

    const renderAttendance = () => (
        <ParentTable title="Attendance" actions={getAttendanceActions()} rows={getAttendanceHeaderRows().headerRows}>
            <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={1}
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100%', mb: 2 }}
            >
                {getAttendanceHeaderRows().rows.map((row, i) => (
                    <Box key={i}>
                        {row}
                    </Box>
                ))}
            </Stack>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Table
                        title="Present"
                        rowActions={[
                            {
                                title: 'Mark Absent',
                                icon: <AbsentIcon />,
                                onClick: rowData => {
                                    markAbsent(rowData)
                                },
                            },
                        ]}
                        sortable={true}
                        defaultSortable={{
                            value: 'name',
                            dir: 'asc',
                        }}
                        columns={getAttendanceColumns()}
                        showOnEmpty={true}
                        emptyText="None Present"
                        data={addNames(members.filter(member => member.present === true))}
                        numRows={10}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Table
                        title="Absent"
                        rowActions={[
                            {
                                title: 'Mark Present',
                                icon: <PresentIcon />,
                                onClick: rowData => {
                                    markPresent(rowData)
                                },
                            },
                        ]}
                        columns={getExcusedColumns()}
                        showOnEmpty={true}
                        emptyText="None Absent"
                        data={addNames(getCombinedAbsent())}
                        numRows={10}
                        sortable={true}
                        defaultSortable={{
                            value: 'name',
                            dir: 'asc',
                        }}
                    />
                </Grid>
            </Grid>
        </ParentTable>
    )

    const renderEvent = () => (
        <Box display="flex" flexDirection="column">
            <Stack spacing={2}>
                {/* Event Title */}
                <Typography 
                    variant="h5" 
                    fontWeight={500}
                    style={{
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        marginBottom: '0',
                    }}
                >
                    {curEvent.name}
                </Typography>

                {/* Categories */}
                <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 0 }}>
                    {curEvent.categories.map(category => (
                        <CustomChip 
                            color={category.color}
                            label={category.name}
                            key={category.id}
                        />
                    ))}
                </Stack>

                {/* Action Chips */}
                <Stack 
                    direction="row" 
                    spacing={1} 
                    justifyContent="center" 
                    flexWrap="wrap"  
                    sx={{ gap: 1 }}
                >
                    {Capacitor.getPlatform() !== 'ios' && (
                        <Chip
                            color="primary"
                            size="small"
                            icon={<CalendarAddIcon />}
                            onClick={addToCalendar}
                            label="Add to Calendar"
                        />
                    )}
                    <CopyToClipboard>
                        {({ copy }) => (
                            <Chip
                                color="primary"
                                size="small"
                                icon={<LinkIcon />}
                                onClick={() => {
                                    setSnackbar('Copied to Clipboard')
                                    copy(`https://greekconnect.app/app/calendar/event/${eventID}`)
                                }}
                                label="Copy Link"
                            />
                        )}
                    </CopyToClipboard>
                    {hasCalendarAdmin && (
                        <Chip
                            color="primary"
                            size="small"
                            icon={<DuplicateIcon />}
                            onClick={duplicateEvent}
                            label="Duplicate"
                        />
                    )}
                    <Chip
                        variant="outlined"
                        size="small"
                        icon={curEvent?.sync === false ? <SyncOffIcon /> : <SyncIcon />}
                        label={curEvent?.sync === false ? "Syncing off" : "Syncing on"}
                    />
                </Stack>

                {/* Event Details */}
                <Stack spacing={1.5}>
                    {/* Time */}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <TimeIcon />
                        <Typography>
                            {getEventTiming(curEvent, { includeLength: true, includeTZ: isDifferentTimezone })}
                        </Typography>
                    </Stack>

                    {/* Location */}
                    {(curEvent?.location || curEvent?.address || curEvent?.googleMapsPlaceLabel) && (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <LocationIcon />
                            {curEvent?.googleMapsPlaceLabel || curEvent?.address || curEvent?.location || curEvent?.googleMapsPlace ? (
                                <Link target="_blank" rel="noreferrer" href={getAddressURL(curEvent.address, curEvent?.googleMapsPlace)}>
                                    <Typography>
                                        {curEvent?.googleMapsPlaceLabel || curEvent?.location || curEvent?.address}
                                    </Typography>
                                </Link>
                            ) : (
                                <Typography>{curEvent.location}</Typography>
                            )}
                        </Stack>
                    )}

                    {/* Visibility */}
                    {curEvent.visibility && (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <VisibilityIcon />
                            <Typography>{renderVisibility(curEvent.visibility)}</Typography>
                        </Stack>
                    )}

                    {/* Links */}
                    {curEvent.links?.map(link => (
                        <Stack key={link.url} direction="row" spacing={1} alignItems="center">
                            <LinkIcon />
                            <Link 
                                target="_blank"
                                rel="noreferrer"
                                href={link.url}
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                <Typography noWrap>
                                    {link.name || link.url}
                                </Typography>
                            </Link>
                        </Stack>
                    ))}

                    {/* Author */}
                    {curEvent.author && chapter && chapter.members && curEvent.author in chapter.members && (
                        <Stack direction="row" spacing={1} alignItems="center">
                        <AuthorIcon sx={{ height: 24 }} />
                        
                        {editingAuthor.editing ? (
                            <Select
                                value={editingAuthor.authorId}
                                fullWidth
                                inputProps={{
                                    style: {
                                        paddingTop: 5,
                                        paddingBottom: 6,
                                        textAlign: `center`,
                                        textAlignLast: `center`,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                    },
                                }}
                                disabled={editingAuthor.loading}
                                native={true}
                                onChange={e => {
                                    setEditingAuthor({ ...editingAuthor, authorId: e.target.value })
                                }}
                                input={<BootstrapInput />}
                            >
                                {getPossibleAuthors
                                    .sort((b, a) => b.full.localeCompare(a.full))
                                    .map(u => (
                                        <option key={u.id} value={u.id}>
                                            {u.full}
                                        </option>
                                    ))}
                            </Select>
                        ) : (
                            <Typography>
                                {chapter.members[curEvent.author].first + ' ' + chapter.members[curEvent.author].last}
                            </Typography>
                        )}
                        
                        {hasFullCalendarAdmin() && (
                            editingAuthor.editing ? (
                                <IconButton
                                    size="small"
                                    sx={{ p: 0.25 }}
                                    disabled={editingAuthor.loading}
                                    onClick={() => {
                                        if (editingAuthor.authorId === curEvent.author) {
                                            // Cancel
                                            setEditingAuthor({ ...editingAuthor, editing: false })
                                        } else {
                                            // Confirm
                                            attemptUpdateEvent(editingAuthor.authorId)
                                        }
                                    }}
                                >
                                    {editingAuthor.authorId === curEvent.author ? 
                                        <CloseIcon fontSize="small" /> : 
                                        <OpenIcon fontSize="small" />
                                    }
                                </IconButton>
                            ) : (
                                <IconButton
                                    size="small"
                                    sx={{ p: 0.25 }}
                                    onClick={() => setEditingAuthor({ editing: true, authorId: curEvent.author })}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )
                        )}
                        
                        {editingAuthor.editing && editingAuthor.error && (
                            <Typography color="error">
                                {editingAuthor.error}
                            </Typography>
                        )}
                    </Stack>
                    )}
                    {/* Description */}
                    {curEvent.description && (
                        <Stack direction="row" spacing={1} alignItems="flex-start">
                            <DescriptionIcon />
                            <MuiLinkify>
                                <Typography sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
                                    {curEvent.description}
                                </Typography>
                            </MuiLinkify>
                        </Stack>
                    )}
                </Stack>

                {/* Offline Warning */}
                {!hasCalendarAdmin && eventStatus.cachedResponse && (
                    <MuiAlert severity="warning">
                        Your internet connection appears to be offline, so the event may be out of date
                    </MuiAlert>
                )}

                
                {/* Additional Sections */}
                <Stack spacing={2}>
                    {getPointsSection()}
                    {getRSVPSection()}
                    {getSignInSection()}
                    {getExcuseSection()}
                </Stack>
                {/* Itinerary */}
                {curEvent?.itinerary && Array.isArray(curEvent.itinerary) && curEvent.itinerary.length > 0 && (
                    <>
                        <Divider sx={{ mx: -3, mt: 1 }} />
                        <Stack spacing={1}>
                            <Typography 
                                align="center"
                                variant="h5"
                                fontWeight="bold"
                                sx={{ mt: 1 }}
                            >
                                Itinerary
                            </Typography>
                            <Timeline
                                orientation="vertical"
                                togglable
                                timeline={curEvent.itinerary}
                                showDays={getEventDays().length > 1}
                            />
                        </Stack>
                    </>
                )}

            </Stack>
        </Box>
    )

    const closeShareDialog = () => {
        setShareDialog(shareDialog => ({ ...shareDialog, open: false }))
    }

    const openShareDialog = async () => {
        if (curEvent) {
            let canShare = false

            if (isMobileDevice()) {
                let val = await Share.canShare()
                canShare = val.value
            }

            if (canShare) {
                const timing = getEventTiming(curEvent, { includeLength: true, notRelative: true, longText: true })
                Share.share({
                    title: curEvent.name,
                    text: `${curEvent.name}\n${timing}`,
                    url: `https://greekconnect.app/app/calendar/event/${eventID}`,
                    dialogTitle: 'Share Event',
                })
            } else {
                setShareDialog(shareDialog => ({
                    ...shareDialog,
                    open: true,
                }))
            }
        }
    }

    const deleteMutation = useDeleteEvent(async() => {
        requestChapterUpdateWithDelay()
        props.history.push('/app/calendar/')
    })

    const deleteEvent = async (deleteEntireSeries) => {
        try {
            if (deleteEntireSeries && curEvent?.recurringEventId) {
                // Delete the entire series by only passing the recurringEventId
                await deleteMutation.mutateAsync({
                    chapterId: user.getChapter(),
                    recurringEventId: curEvent.recurringEventId
                })
            } else {
                // Delete just this single event by only passing the eventId
                await deleteMutation.mutateAsync({
                    chapterId: user.getChapter(),
                    eventId: eventID
                })
            }
            
            props.history.push('/app/calendar')
        } catch (error) {
            // Error handling is managed by the mutation
            console.error('Failed to delete event:', error)
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    grid
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Calendar',
                            link: '/app/calendar',
                            iconMobileOnly: true,
                            icon: <CalendarIcon />,
                        },
                        {
                            name: 'Event',
                        },
                    ]}
                    rightButtons={
                        curEvent && hasCalendarAdmin
                            ? [
                                  {
                                      name: 'Share',
                                      tooltip: 'Share',
                                      type: 'icon',
                                      innerIcon: <ShareIosIcon />,
                                      onClick: () => openShareDialog(),
                                  },
                                  {
                                      name: 'Edit',
                                      tooltip: 'Edit',
                                      type: 'icon',
                                      innerIcon: <EditIcon />,
                                      onClick: () => {
                                          props.history.push('/app/calendar/event/' + eventID + '/edit')
                                      },
                                  },
                                  {
                                    name: 'Delete',
                                    type: 'icon',
                                    innerIcon: <DeleteIcon />,
                                    onClick: () => {
                                        setDeleteDialogOpen(true)
                                    },
                                },
                              ]
                            : []
                    }
                    key={curEvent && hasCalendarAdmin ? 'admin' : 'none'}
                />
                {curEvent ? (
                    <>
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                renderEvent()
                            ) : (
                                <Widget
                                    disableWidgetMenu
                                    noBodyPadding
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginLeft: 24,
                                            marginRight: 24,
                                            marginTop: 8,
                                            marginBottom: 6,
                                        }}
                                    >
                                        {renderEvent()}
                                    </Box>
                                </Widget>
                            )}
                        </Grid>
                        {hasCalendarAdmin && curEvent.signIn.enabled && (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    <Box style={{ marginLeft: -16, marginRight: -16 }}>
                                        <Divider />
                                        {renderAttendance()}
                                    </Box>
                                ) : (
                                    <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                        {renderAttendance()}
                                    </Widget>
                                )}
                            </Grid>
                        )}
                        {canViewRSVP() && (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    <Box style={{ marginLeft: -16, marginRight: -16 }}>
                                        <Divider />
                                        {renderRSVP()}
                                    </Box>
                                ) : (
                                    <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                        {renderRSVP()}
                                    </Widget>
                                )}
                            </Grid>
                        )}
                        {hasCalendarAdmin && curEvent.excuses.enabled && (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    <Box style={{ marginLeft: -16, marginRight: -16 }}>
                                        <Divider />
                                        {renderExcuses()}
                                    </Box>
                                ) : (
                                    <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                        {renderExcuses()}
                                    </Widget>
                                )}
                            </Grid>
                        )}
                    </>
                ) : (
                    eventStatus.grabbed && (
                        <Grid item xs={12}>
                            <Widget
                                disableWidgetMenu
                                noBodyPadding
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{
                                        marginLeft: 24,
                                        marginRight: 24,
                                        marginTop: 8,
                                        marginBottom: 6,
                                    }}
                                >
                                    {eventStatus.cachedResponse && <MuiAlert severity="warning">Your internet connection appears to be offline</MuiAlert>}
                                    {!eventStatus.exists && !eventStatus.cachedResponse && <MuiAlert severity="error">The event does not exist</MuiAlert>}
                                </Box>
                            </Widget>
                        </Grid>
                    )
                )}
            </Grid>
            <Snackbar open={memberAlreadyAdded} autoHideDuration={2000} onClose={() => setMemberAlreadyAdded(false)}>
                <Alert onClose={() => setMemberAlreadyAdded(false)} severity="error">
                    Member already added
                </Alert>
            </Snackbar>
            <Snackbar open={memberNotFound} autoHideDuration={2000} onClose={() => setMemberNotFound(false)}>
                <Alert onClose={() => setMemberNotFound(false)} severity="error">
                    Member not on roster
                </Alert>
            </Snackbar>
            <DialogMobile
                maxWidth="md"
                fullWidth
                open={submitExcuseDialog.open}
                onClose={() => setSubmitExcuseDialog({ open: false, reason: '' })}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitleMobile id="form-dialog-title">Submit Excuse</DialogTitleMobile>
                <DialogContentMobile dividers>
                    <DialogContentText>Please explain your reasoning for missing the event.</DialogContentText>
                    <TextField
                        variant="outlined"
                        autoFocus
                        margin="dense"
                        id="reason"
                        type="text"
                        label={submitExcuseDialog.reason ? '' : 'Reason'}
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            multiline: true,
                            rows: 3,
                        }}
                        inputProps={{
                            maxLength: 256,
                        }}
                        fullWidth
                        value={submitExcuseDialog.reason}
                        onChange={e => {
                            setSubmitExcuseDialog({
                                open: true,
                                reason: e.target.value,
                            })
                        }}
                    />
                </DialogContentMobile>
                <DialogActions>
                    <Button onClick={() => setSubmitExcuseDialog({ open: false, reason: '' })} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            submitExcuse(submitExcuseDialog.reason)
                            setSubmitExcuseDialog({
                                open: false,
                                reason: '',
                            })
                        }}
                        disabled={submitExcuseDialog.reason.length === 0}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </DialogMobile>
            <DialogMobile
                maxWidth="md"
                fullWidth
                open={submitQRCodeDialog.open}
                onClose={() => setSubmitQRCodeDialog({ open: false })}
                aria-labelledby="scan-qrcode-dialog-title"
            >
                <DialogTitleMobile id="scan-qrcode-dialog-title">Scan QR Code</DialogTitleMobile>
                <DialogContentMobile style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', overflow: 'hidden' }}>
                    <Box style={{ flexGrow: 1, overflow: 'hidden' }}>
                        <QrReader
                            ref={qrScannerRef}
                            facingMode={submitQRCodeDialog.facingMode}
                            style={{ width: `100%`, height: `100%` }}
                            legacyMode={submitQRCodeDialog.useLegacyMode}
                            onError={e => {
                                setSubmitQRCodeDialog({ ...submitQRCodeDialog, useLegacyMode: true })
                            }}
                            onScan={scan => {
                                if (scan) {
                                    if (curEvent && curEvent.signIn && curEvent.signIn.type === 2) {
                                        let sc = scan.split('/')
                                        if (sc.length > 0) {
                                            submitSignIn(sc[sc.length - 1])
                                            setSubmitQRCodeDialog({ open: false })
                                        } else {
                                            setSubmitQRCodeDialog({ ...submitQRCodeDialog, error: "QR Code doesn't appear to match" })
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                    {submitQRCodeDialog.error && (
                        <Box style={{ marginTop: 4 }}>
                            <ErrorTypography text={submitQRCodeDialog.error} isCentered />
                        </Box>
                    )}
                </DialogContentMobile>
                <DialogActions>
                    {submitQRCodeDialog.useLegacyMode ? (
                        <Button
                            onClick={() => {
                                qrScannerRef.current.openImageDialog()
                            }}
                            color="primary"
                        >
                            Select Photo
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                setSubmitQRCodeDialog({ ...submitQRCodeDialog, facingMode: submitQRCodeDialog === 'rear' ? 'front' : 'rear' })
                            }}
                            color="primary"
                        >
                            Flip Camera
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            setSubmitQRCodeDialog({ open: false })
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </DialogMobile>
            <DialogMobile
                maxWidth="md"
                fullWidth
                open={scanQRCodesDialog.open}
                onClose={() => setScanQRCodesDialog({ open: false })}
                aria-labelledby="scan-qrcodes-dialog-title"
            >
                <DialogTitleMobile id="scan-qrcodes-dialog-title">Scan QR Codes</DialogTitleMobile>
                <DialogContentMobile style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', overflow: 'hidden' }}>
                    <Box style={{ flexGrow: 1, overflow: 'hidden' }}>
                        <QrReader
                            ref={qrScannerRef}
                            facingMode={scanQRCodesDialog.facingMode}
                            onError={e => {
                                setScanQRCodesDialog({ ...scanQRCodesDialog, error: 'Unable to access camera' })
                            }}
                            style={{ width: `100%`, height: `100%` }}
                            onScan={scan => {
                                if (scan) {
                                    let mems = getAllMembers()
                                    let found = false
                                    for (let i = 0; i < mems.length; i++) {
                                        let mem = mems[i]
                                        if (mem.id === scan) {
                                            found = true
                                            let memName = mem.first + ' ' + mem.last
                                            if (mem.present) {
                                                setScanQRCodesDialog({ ...scanQRCodesDialog, check: `${memName} already signed in` })
                                            } else {
                                                markPresent(mem)
                                                setScanQRCodesDialog({ ...scanQRCodesDialog, check: `${memName} signed in` })
                                            }
                                            i = mems.length
                                        }
                                    }

                                    if (!found) {
                                        setScanQRCodesDialog({ ...scanQRCodesDialog, error: "QR Code doesn't appear to belong to a valid event attendee" })
                                    }
                                }
                            }}
                        />
                    </Box>
                    {scanQRCodesDialog.check && (
                        <Box style={{ marginTop: 4 }}>
                            <CheckedTypography text={scanQRCodesDialog.check} isCentered />
                        </Box>
                    )}
                    {scanQRCodesDialog.error && (
                        <Box style={{ marginTop: 4 }}>
                            <ErrorTypography text={scanQRCodesDialog.error} isCentered />
                        </Box>
                    )}
                </DialogContentMobile>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setScanQRCodesDialog({ ...scanQRCodesDialog, facingMode: scanQRCodesDialog === 'rear' ? 'front' : 'rear' })
                        }}
                        color="primary"
                    >
                        Flip Camera
                    </Button>
                    <Button
                        onClick={() => {
                            setScanQRCodesDialog({ open: false })
                        }}
                        color="primary"
                    >
                        Done
                    </Button>
                </DialogActions>
            </DialogMobile>
            <DialogMobile
                maxWidth="md"
                fullWidth
                className={classes.qrCodeDialog}
                open={viewQRCodeDialog.open}
                onClose={() => setViewQRCodeDialog({ ...viewQRCodeDialog, open: false })}
                aria-labelledby="qrcode-dialog-title"
            >
                <DialogTitleMobile id="qrcode-dialog-title">Sign In QR Code</DialogTitleMobile>
                <DialogContentMobile dividers>
                    <DialogContentText>Members can sign in by viewing the event, clicking sign in, and then clicking scan code.</DialogContentText>
                    <Box style={{ display: `flex`, justifyContent: `center` }}>
                        {hasQRSignIn && (
                            <QRCode
                                id="sign-in-qr-code"
                                renderAs="canvas"
                                size={512}
                                bgColor={bgColor}
                                fgColor={fgColor}
                                includeMargin={true}
                                style={{ width: `100%`, height: `100%`, maxWidth: 256, borderRadius: 6 }}
                                value={'greekconnect.app/app/calendar/signIn/' + eventID + '/' + signInPassword.qrCode}
                            />
                        )}
                    </Box>
                </DialogContentMobile>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setViewQRCodeDialog({ ...viewQRCodeDialog, open: false })
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            const canvas = document.getElementById('sign-in-qr-code')
                            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
                            let downloadLink = document.createElement('a')
                            downloadLink.href = pngUrl
                            downloadLink.download = 'qrCodeSignIn_' + eventID + '.png'
                            document.body.appendChild(downloadLink)
                            downloadLink.click()
                            document.body.removeChild(downloadLink)
                        }}
                        color="primary"
                    >
                        Export
                    </Button>
                </DialogActions>
            </DialogMobile>
            {submitPasswordDialog.open && (
                <DialogMobile
                    fullWidth
                    maxWidth="md"
                    open={submitPasswordDialog.open}
                    onClose={() => setSubmitPasswordDialog({ open: false, password: '' })}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitleMobile id="form-dialog-title">Sign In</DialogTitleMobile>
                    <DialogContentMobile dividers>
                        <DialogContentText>Please type in the {curEvent.signIn.type === 4 ? 'pin' : 'password'} for the event.</DialogContentText>
                        {curEvent.signIn.type === 4 ? (
                            <TextField
                                variant="outlined"
                                autoFocus
                                margin="dense"
                                id="password"
                                label={submitPasswordDialog.password ? '' : 'PIN'}
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                    maxLength: 6,
                                    pattern: '[0-9]*',
                                    inputMode: 'numeric',
                                }}
                                type="number"
                                fullWidth
                                value={submitPasswordDialog.password}
                                onChange={e => {
                                    setSubmitPasswordDialog({
                                        open: true,
                                        password: e.target.value,
                                    })
                                }}
                            />
                        ) : (
                            <TextField
                                variant="outlined"
                                autoFocus
                                margin="dense"
                                id="password"
                                label={submitPasswordDialog.password ? '' : 'Password'}
                                InputLabelProps={{ shrink: false }}
                                type="text"
                                fullWidth
                                value={submitPasswordDialog.password}
                                onChange={e => {
                                    setSubmitPasswordDialog({
                                        open: true,
                                        password: e.target.value,
                                    })
                                }}
                            />
                        )}
                        {submitPasswordDialog.error && <DialogContentText style={{ color: '#E66868' }}>{submitPasswordDialog.error}</DialogContentText>}
                    </DialogContentMobile>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                setSubmitPasswordDialog({
                                    open: false,
                                    password: '',
                                })
                            }
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                submitSignIn(submitPasswordDialog.password)
                                setSubmitPasswordDialog({
                                    open: false,
                                    password: '',
                                })
                            }}
                            disabled={submitPasswordDialog.password.length === 0}
                            color="primary"
                        >
                            Sign In
                        </Button>
                    </DialogActions>
                </DialogMobile>
            )}
            <DialogMobile
                open={uploadRSVPListDialog.open}
                onClose={() => setUploadRSVPListDialog({ ...uploadRSVPListDialog, open: false })}
                aria-labelledby="upload-rsvp-list-title"
                fullWidth
            >
                <DialogTitleMobile id="upload-rsvp-list-title">Upload RSVP Submissions</DialogTitleMobile>
                <DialogContentMobile dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DocumentUpload
                                onUpdateFile={file => {
                                    setUploadRSVPListDialog({ ...uploadRSVPListDialog, file: file })
                                    //parseFile(file)
                                }}
                                size="small"
                                multiple={false}
                                fileType="text/csv"
                                fileTypeText=".csv"
                            />
                        </Grid>
                    </Grid>
                </DialogContentMobile>
                <DialogActions>
                    <Button onClick={() => setUploadRSVPListDialog({ ...uploadRSVPListDialog, open: false })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {}} disabled={uploadRSVPListDialog.error || !uploadRSVPListDialog.file} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </DialogMobile>
            <DialogMobile
                open={downloadAttendanceDialog.open}
                onClose={() => setDownloadAttendanceDialog({ ...downloadAttendanceDialog, open: false })}
                aria-labelledby="download-attendance-title"
                fullWidth
            >
                <DialogTitleMobile id="download-attendance-title">Download Attendance</DialogTitleMobile>
                <DialogContentMobile dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={downloadAttendanceDialog.onlyPresent}
                                        onChange={downloadAttendanceUpdate}
                                        name="onlyPresent"
                                        color="primary"
                                    />
                                }
                                label="Include only present members"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required error={showAttendanceError} component="fieldset">
                                <FormLabel component="legend">Included Columns</FormLabel>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={downloadAttendanceDialog.first} onChange={downloadAttendanceUpdate} name="first" color="primary" />
                                    }
                                    label="First"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={downloadAttendanceDialog.last} onChange={downloadAttendanceUpdate} name="last" color="primary" />
                                    }
                                    label="Last"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={downloadAttendanceDialog.present}
                                            onChange={downloadAttendanceUpdate}
                                            name="present"
                                            color="primary"
                                        />
                                    }
                                    label="Present"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={downloadAttendanceDialog.email} onChange={downloadAttendanceUpdate} name="email" color="primary" />
                                    }
                                    label="Email"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={downloadAttendanceDialog.role} onChange={downloadAttendanceUpdate} name="role" color="primary" />
                                    }
                                    label="Role"
                                />
                                <FormHelperText>You must select at least 1 column</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContentMobile>
                <DialogActions>
                    <Button onClick={() => setDownloadAttendanceDialog({ ...downloadAttendanceDialog, open: false })} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            exportAttendanceList()
                            setDownloadAttendanceDialog({ ...downloadAttendanceDialog, open: false })
                        }}
                        color="primary"
                        disabled={showAttendanceError}
                    >
                        Download
                    </Button>
                </DialogActions>
            </DialogMobile>
            {shareDialog.open && (
                <DialogMobile maxWidth="md" fullWidth aria-labelledby="share-dialog-title" open={shareDialog.open} onClose={closeShareDialog}>
                    <DialogTitleMobile id="share-dialog-title" onClose={closeShareDialog}>
                        Share
                    </DialogTitleMobile>
                    <DialogContentMobile dividers>
                        <Tabs
                            tabs={
                                user && user.getChapter() === 'bt1d00lDdZT0rzsD2p7f'
                                    ? [
                                          { id: 'link', name: 'Link', width: 50 },
                                          { id: 'pdf', name: 'PDF', width: 50 },
                                      ]
                                    : [{ id: 'link', name: 'Link', width: 50 }]
                            }
                            selected={shareDialog.tab}
                            onChange={val => {
                                setShareDialog(shareDialog => ({ ...shareDialog, tab: val }))
                            }}
                            style={{ marginTop: -8 }}
                        />
                        <Divider style={{ marginLeft: -16, marginRight: -16, marginBottom: 16 }} />
                        {shareDialog.tab && shareDialog.tab === 'pdf' && (
                            <>
                                <Typography align="center">Export your event, including description, attendance, date, location, and other details.</Typography>
                            </>
                        )}
                        {shareDialog.tab && shareDialog.tab === 'link' && (
                            <>
                                <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                                    Link Sharing
                                </Typography>
                                <Typography gutterBottom align="center">
                                    Useful for sharing via almost any medium
                                </Typography>
                                <Typography gutterBottom align="center">
                                    Only those who can view the event will be able to utilize the link
                                </Typography>
                                <Typography gutterBottom align="center" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    https://greekconnect.app/app/calendar/event/{eventID}
                                </Typography>
                            </>
                        )}
                    </DialogContentMobile>
                    <DialogActionsMobile>
                        <Button onClick={closeShareDialog} color="primary">
                            Cancel
                        </Button>
                        {shareDialog.tab && shareDialog.tab === 'pdf' && (
                            <Button
                                onClick={() => {
                                    closeShareDialog()
                                    exportToPDF()
                                    setSnackbar('Downloaded as PDF')
                                }}
                                color="primary"
                            >
                                Download
                            </Button>
                        )}
                        {shareDialog.tab && shareDialog.tab === 'link' && (
                            <CopyToClipboard>
                                {({ copy }) => (
                                    <Button
                                        onClick={() => {
                                            closeShareDialog()
                                            setSnackbar('Copied to Clipboard')
                                            copy(`https://greekconnect.app/app/calendar/event/${eventID}`)
                                        }}
                                        color="primary"
                                    >
                                        Copy
                                    </Button>
                                )}
                            </CopyToClipboard>
                        )}
                    </DialogActionsMobile>
                </DialogMobile>
            )}
            <Snackbar open={snackbar !== null} autoHideDuration={2000} onClose={() => setSnackbar(null)}>
                <Alert onClose={() => setSnackbar(null)} severity="success">
                    {snackbar}
                </Alert>
            </Snackbar>
            <DeleteEventDialog
                open={isDeleteDialogOpen}
                isLoading={deleteMutation.isPending}
                errorText={deleteMutation.error?.message || null} 
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={deleteEvent}
                recurringEventId={curEvent?.recurringEventId}
            />
        </>
    )
}
