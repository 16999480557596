import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  devModeCard: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  mockChip: {
    marginRight: theme.spacing(1),
    backgroundColor: '#f0f8ff',
    color: '#0077cc',
    fontWeight: 'bold',
    fontSize: '0.7rem',
  },
  statsCard: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%',
  },
  statValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  statLabel: {
    color: theme.palette.text.secondary,
  },
  searchField: {
    marginBottom: theme.spacing(2),
  },
  noChapters: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  chapterCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chapterCardContent: {
    flexGrow: 1,
  },
  chapterStatus: {
    marginLeft: theme.spacing(1),
  },
  chapterSchool: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  chapterLocation: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(3),
    fontSize: '0.875rem',
  },
  chapterEmail: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  chapterMetrics: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  metricIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(0.5),
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  metricValue: {
    fontSize: '0.875rem',
  },
  chapterFounded: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  dialogContent: {
    minHeight: 200,
  },
  inviteCodeBox: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
  },
  inviteCode: {
    fontFamily: 'monospace',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    letterSpacing: 1,
    color: theme.palette.primary.main,
  },
  tableContainer: {
    marginTop: theme.spacing(1),
    boxShadow: 'none',
  },
  viewToggle: {
    marginLeft: theme.spacing(2),
  },
  // Chapter Detail Page Styles
  container: {
    padding: theme.spacing(3),
  },
  headerCard: {
    marginBottom: theme.spacing(3),
  },
  memberAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  memberInfoIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
})); 