import React from 'react';
import { Box, Grid, Typography, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, DialogActions, Button } from '@material-ui/core';
import { CloudDownloadOutlined as DownloadIcon, PersonAdd as CustomPointIcon } from '@material-ui/icons';
import moment from 'moment';
import Papa from 'papaparse';
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

import ParentTable from 'components/Tables/ParentTable';
import { PointRecord } from 'objects/Points/Record';
import { getFirebaseDate } from 'code/TimeAgo';

import { 
    ResponsiveContainer, PieChart, Pie as ReChartsPie, Cell, XAxis, YAxis, CartesianGrid, 
    Tooltip as RechartsTooltip, BarChart, Bar, LineChart, Line
} from 'recharts';

const EventAnalyticsTab = ({ 
    points, 
    chapter,
    theme
}) => {
    // Add useMediaQuery hook
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    
    // Prepare data for analytics
    const prepareEventAnalytics = () => {
        // If no points or no records, return empty data
        if (!points || !points.records) {
            return {
                topEvents: [],
                eventAttendance: [],
                monthlyActivity: [],
                memberParticipation: [],
                error: true
            };
        }
        
        const eventCounts = {};
        const eventPoints = {};
        const memberActivity = {};
        const monthlyData = {};
        const eventDates = {}; // Track the most recent date for each event
        const eventDetails = {}; // Store additional event details
        const eventParticipation = {}; // Track which members participated in each event
        const allMembers = chapter.members ? Object.keys(chapter.members) : [];
        const customPointRequests = {}; // Track custom point requests per event
        
        // Process all records from all members
        Object.keys(points.records || {}).forEach(userId => {
            const records = points.getRecords(userId) || [];
            
            // Track member activity
            memberActivity[userId] = {
                approved: 0,
                denied: 0,
                awaiting: 0,
                totalPoints: 0,
                userId: userId,
                name: chapter.members[userId] ? 
                    `${chapter.members[userId].first} ${chapter.members[userId].last}` : 
                    "Unknown Member"
            };
            
            records.forEach(record => {
                if (!record.name || !record.dt) return;
                
                const eventName = record.name;
                const eventId = record.event || 'unknown'; // Use event ID if available
                const eventKey = `${eventId}|${eventName}`; // Create a unique key for each event
                const status = record.status;
                const pointValue = record.points || 0;
                const isCustomRequest = !record.event || record.trigger === 'manual'; // Identify custom/manual requests
                
                // Store event details for display
                if (!eventDetails[eventKey]) {
                    eventDetails[eventKey] = {
                        id: eventId,
                        name: eventName,
                        displayName: eventName // Will be updated with date later
                    };
                }
                
                // Initialize member participation tracking for this event
                if (!eventParticipation[eventKey]) {
                    eventParticipation[eventKey] = {
                        participated: [],
                        didNotParticipate: [],
                        hasCustomRequests: false,
                        customRequestCount: 0
                    };
                }
                
                // Track custom point requests
                if (isCustomRequest && status === PointRecord.STATUS_APPROVED) {
                    if (!customPointRequests[eventKey]) {
                        customPointRequests[eventKey] = 0;
                    }
                    customPointRequests[eventKey]++;
                    eventParticipation[eventKey].hasCustomRequests = true;
                    eventParticipation[eventKey].customRequestCount++;
                }
                
                // Track member participation - only count non-custom requests as actual member participation
                if (status === PointRecord.STATUS_APPROVED && !isCustomRequest && !eventParticipation[eventKey].participated.includes(userId)) {
                    eventParticipation[eventKey].participated.push(userId);
                    // Remove from didNotParticipate if they were there
                    const index = eventParticipation[eventKey].didNotParticipate.indexOf(userId);
                    if (index > -1) {
                        eventParticipation[eventKey].didNotParticipate.splice(index, 1);
                    }
                }
                
                // Monthly activity tracking
                let date;
                try {
                    date = getFirebaseDate({ date: record.dt });
                    const monthYear = moment(date).format('MMM YYYY');
                    
                    // Track the most recent date for each event
                    if (!eventDates[eventKey] || moment(date).isAfter(moment(eventDates[eventKey]))) {
                        eventDates[eventKey] = date;
                    }
                    
                    if (!monthlyData[monthYear]) {
                        monthlyData[monthYear] = { 
                            month: monthYear, 
                            count: 0, 
                            points: 0,
                            approved: 0,
                            denied: 0,
                            awaiting: 0
                        };
                    }
                    
                    monthlyData[monthYear].count++;
                    
                    if (status === PointRecord.STATUS_APPROVED) {
                        monthlyData[monthYear].approved++;
                        monthlyData[monthYear].points += pointValue;
                    } else if (status === PointRecord.STATUS_DENIED) {
                        monthlyData[monthYear].denied++;
                    } else if (status === PointRecord.STATUS_AWAITING) {
                        monthlyData[monthYear].awaiting++;
                    }
                } catch (e) {
                    console.error("Date processing error:", e);
                }
                
                // Event popularity tracking
                if (!eventCounts[eventKey]) {
                    eventCounts[eventKey] = { 
                        id: eventId,
                        name: eventName,
                        count: 0, 
                        approved: 0, 
                        denied: 0, 
                        awaiting: 0,
                        totalPoints: 0
                    };
                }
                
                eventCounts[eventKey].count++;
                
                // Update status counts
                if (status === PointRecord.STATUS_APPROVED) {
                    eventCounts[eventKey].approved++;
                    memberActivity[userId].approved++;
                    memberActivity[userId].totalPoints += pointValue;
                    
                    // Track points per event type
                    if (!eventPoints[eventKey]) {
                        eventPoints[eventKey] = 0;
                    }
                    eventPoints[eventKey] += pointValue;
                } else if (status === PointRecord.STATUS_DENIED) {
                    eventCounts[eventKey].denied++;
                    memberActivity[userId].denied++;
                } else if (status === PointRecord.STATUS_AWAITING) {
                    eventCounts[eventKey].awaiting++;
                    memberActivity[userId].awaiting++;
                }
            });
        });
        
        // Update display names with dates
        Object.keys(eventDates).forEach(eventKey => {
            if (eventDetails[eventKey]) {
                const date = eventDates[eventKey];
                if (date) {
                    const formattedDate = moment(date).format('MMM D, YYYY');
                    eventDetails[eventKey].displayName = `${eventDetails[eventKey].name} (${formattedDate})`;
                }
            }
        });
        
        // Fill in members who didn't participate for each event
        Object.keys(eventParticipation).forEach(eventKey => {
            const participated = eventParticipation[eventKey].participated;
            eventParticipation[eventKey].didNotParticipate = allMembers.filter(
                memberId => !participated.includes(memberId) && chapter.members[memberId]
            );
        });
        
        // Convert to arrays and sort
        const topEvents = Object.values(eventCounts)
            .sort((a, b) => b.count - a.count)
            .slice(0, 30) // Show more events since we're now separating by date
            .map(event => {
                const eventKey = `${event.id}|${event.name}`;
                return {
                    ...event,
                    displayName: eventDetails[eventKey]?.displayName || event.name,
                    lastDate: eventDates[eventKey], // Add the date information to each event
                    participation: eventParticipation[eventKey] || { participated: [], didNotParticipate: [], hasCustomRequests: false, customRequestCount: 0 },
                    customRequests: customPointRequests[eventKey] || 0
                };
            });
            
        const eventAttendance = Object.keys(eventPoints)
            .map(eventKey => {
                const [eventId, eventName] = eventKey.split('|');
                return { 
                    id: eventId,
                    name: eventDetails[eventKey]?.displayName || eventName, 
                    points: eventPoints[eventKey]
                };
            })
            .sort((a, b) => b.points - a.points)
            .slice(0, 10);
            
        // Convert monthly data to sorted array
        const monthlyActivity = Object.values(monthlyData)
            .sort((a, b) => {
                return moment(a.month, 'MMM YYYY').diff(moment(b.month, 'MMM YYYY'));
            });
            
        // Get member participation data
        const memberParticipation = Object.values(memberActivity)
            .filter(m => m.approved > 0 || m.denied > 0 || m.awaiting > 0)
            .sort((a, b) => b.totalPoints - a.totalPoints);
            
        return {
            topEvents,
            eventAttendance,
            monthlyActivity,
            memberParticipation,
            error: false
        };
    };
    
    const analytics = prepareEventAnalytics();
    
    // Get table actions based on device type
    const getTableActions = () => {
        // Only show download button on non-mobile devices
        if (isMobile) return [];
        
        return [{
            title: 'Download Analytics',
            icon: <DownloadIcon />,
            onClick: event => {
                // Prepare analytics for CSV export
                const csvData = [
                    ...analytics.topEvents.map(event => ({
                        "Event Name": event.name,
                        "Total Submissions": event.count,
                        "Approved": event.approved,
                        "Denied": event.denied,
                        "Awaiting": event.awaiting
                    }))
                ];
                
                const element = document.createElement('a');
                const file = new Blob([Papa.unparse(csvData)], {
                    type: 'text/csv',
                });
                
                let pointsName = points.name.replace(/\W/g, '');
                
                element.href = URL.createObjectURL(file);
                element.download = `${pointsName}_analytics.csv`;
                document.body.appendChild(element);
                element.click();
            },
        }];
    };
    
    // Handle event row click to redirect to calendar event
    const handleEventRowClick = (params) => {
        const participation = params.row.participation || { hasCustomRequests: false, customRequestCount: 0 };
        
        // If this event has custom requests, show the participation dialog instead of navigating
        if (participation.hasCustomRequests) {
            handleOpenParticipationDialog(params.row, true);
            return;
        }
        
        const eventId = params.row.eventId;
        
        // If we have a valid event ID, navigate directly to it
        if (eventId && eventId !== 'unknown') {
            // Navigate to the event page
            history.push('/app/calendar/event/' + eventId);
            return;
        }
        
        // Otherwise use the name as fallback
        const eventName = params.row.originalName || params.row.name;
        
        // Find the event in chapter's calendar events if available
        if (chapter && chapter.events) {
            const eventIds = Object.keys(chapter.events);
            for (let i = 0; i < eventIds.length; i++) {
                const event = chapter.events[eventIds[i]];
                if (event.name === eventName) {
                    // Navigate to the event page
                    history.push('/app/calendar/event/' + eventIds[i]);
                    return;
                }
            }
        }
        
        // If event not found, search by name in calendar
        history.push({
            pathname: '/app/calendar',
            search: `?q=${encodeURIComponent(eventName)}`
        });
    };

    // Helper function to format member names for display
    const formatMemberList = (memberIds, limit = 3, hasCustomRequests = false, customRequestCount = 0) => {
        if (!memberIds || memberIds.length === 0) {
            if (hasCustomRequests) {
                return `${customRequestCount} custom request${customRequestCount !== 1 ? 's' : ''}`;
            }
            return "None";
        }
        
        const formattedNames = memberIds.slice(0, limit).map(id => {
            const member = chapter.members[id];
            if (!member) return "Unknown";
            return `${member.first} ${member.last}`;
        });
        
        let result = formattedNames.join(", ");
        
        if (memberIds.length > limit) {
            result = `${result} +${memberIds.length - limit} more`;
        }
        
        if (hasCustomRequests) {
            result = `${result} + ${customRequestCount} custom request${customRequestCount !== 1 ? 's' : ''}`;
        }
        
        return result;
    };
    
    // Opens dialog to show all members who participated or didn't
    const [participationDialog, setParticipationDialog] = React.useState({
        open: false,
        event: null,
        showParticipated: true
    });
    
    const handleOpenParticipationDialog = (event, showParticipated) => {
        setParticipationDialog({
            open: true,
            event,
            showParticipated
        });
    };
    
    const handleCloseParticipationDialog = () => {
        setParticipationDialog({
            ...participationDialog,
            open: false
        });
    };
    
    return (
        <ParentTable
            title="Event Analytics"
            actions={getTableActions()}
        >
            <Grid container>
                {/* No Data Message */}
                {analytics.error && (
                    <Grid item xs={12} style={{ padding: '16px 24px' }}>
                        <Box p={3} textAlign="center" border={1} borderColor="grey.300" borderRadius={4}>
                            <Typography variant="h6" color="textSecondary">
                                No analytics data available
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                There isn't enough point data to generate analytics.
                            </Typography>
                        </Box>
                    </Grid>
                )}
                
                {!analytics.error && (
                    <>
                    {/* Overall Stats */}
                    <Grid item xs={12} style={{ padding: '16px 24px' }}>
                            <Box p={2} border={1} borderColor="grey.300" borderRadius={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Attendance Statistics
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                        <Box p={2} textAlign="center" bgcolor="rgba(63, 81, 181, 0.1)" borderRadius={2}>
                                            <Typography variant="h4" color="primary">
                                                {analytics.topEvents.reduce((sum, e) => sum + e.count, 0)}
                                            </Typography>
                                            <Typography variant="body2">
                                                Submissions
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Box p={2} textAlign="center" bgcolor="rgba(76, 175, 80, 0.1)" borderRadius={2}>
                                            <Typography variant="h4" style={{ color: "#4caf50" }}>
                                                {analytics.topEvents.reduce((sum, e) => sum + e.approved, 0)}
                                            </Typography>
                                            <Typography variant="body2">
                                                Approved
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Box p={2} textAlign="center" bgcolor="rgba(255, 152, 0, 0.1)" borderRadius={2}>
                                            <Typography variant="h4" style={{ color: "#ff9800" }}>
                                                {analytics.topEvents.reduce((sum, e) => sum + e.awaiting, 0)}
                                            </Typography>
                                            <Typography variant="body2">
                                                Pending
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Box p={2} textAlign="center" bgcolor="rgba(244, 67, 54, 0.1)" borderRadius={2}>
                                            <Typography variant="h4" style={{ color: "#f44336" }}>
                                                {analytics.topEvents.reduce((sum, e) => sum + e.denied, 0)}
                                            </Typography>
                                            <Typography variant="body2">
                                                Denied
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {/* Monthly Point Activity */}
                        <Grid item xs={12} md={6} style={{ padding: '16px 24px' }}>
                            <Box p={2} border={1} borderColor="grey.300" borderRadius={4} height="100%">
                                <Typography variant="subtitle1" gutterBottom>
                                    Monthly Point Activity
                                </Typography>
                                {analytics.monthlyActivity.length > 0 ? (
                                    <Box height={300}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                data={analytics.monthlyActivity}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 40,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis 
                                                    dataKey="month"
                                                    tick={{ fontSize: 12, angle: -45, textAnchor: 'end' }}
                                                    height={60}
                                                />
                                                <YAxis 
                                                    label={{ 
                                                        value: 'Activity', 
                                                        angle: -90, 
                                                        position: 'insideLeft',
                                                        style: { textAnchor: 'middle' }
                                                    }}
                                                />
                                                <RechartsTooltip />
                                                <Line
                                                    type="monotone"
                                                    dataKey="points"
                                                    name="Points"
                                                    stroke="#3f51b5"
                                                    strokeWidth={2}
                                                    activeDot={{ r: 6 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Box>
                                ) : (
                                    <Box p={2} textAlign="center">
                                        <Typography variant="body2" color="textSecondary">
                                            No monthly activity data available
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        
                        {/* Top Events */}
                        <Grid item xs={12} md={6} style={{ padding: '16px 24px' }}>
                            <Box p={2} border={1} borderColor="grey.300" borderRadius={4} height="100%">
                                <Typography variant="subtitle1" gutterBottom>
                                    Top Events by Attendance
                                </Typography>
                                {analytics.topEvents.length > 0 ? (
                                    <Box height={300}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                layout="vertical"
                                                data={analytics.topEvents.slice(0, 5)}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis type="number" />
                                                <YAxis 
                                                    dataKey="name" 
                                                    type="category" 
                                                    width={150}
                                                    tick={{ fontSize: 12 }}
                                                />
                                                <RechartsTooltip />
                                                <Bar dataKey="approved" name="Approved" stackId="a" fill="#4caf50" />
                                                <Bar dataKey="awaiting" name="Pending" stackId="a" fill="#ff9800" />
                                                <Bar dataKey="denied" name="Denied" stackId="a" fill="#f44336" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Box>
                                ) : (
                                    <Box p={2} textAlign="center">
                                        <Typography variant="body2" color="textSecondary">
                                            No event participation data available
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        
                        {/* Member Participation */}
                        <Grid item xs={12} md={12} style={{ padding: '16px 24px' }}>
                            <Box p={2} border={1} borderColor="grey.300" borderRadius={4} height="100%">
                                <Typography variant="subtitle1" gutterBottom>
                                    Top Member Participation
                                </Typography>
                                {analytics.memberParticipation.length > 0 ? (
                                    <Box height={300}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                data={analytics.memberParticipation.slice(0, 5)}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis 
                                                    dataKey="name" 
                                                    tick={{ fontSize: 12, angle: -45, textAnchor: 'end' }}
                                                    height={60}
                                                    tickFormatter={(value) => {
                                                        if (!value || value === "Unknown Member") return value;
                                                        const nameParts = value.split(' ');
                                                        if (nameParts.length < 2) return value;
                                                        const firstName = nameParts[0];
                                                        const lastName = nameParts[nameParts.length - 1];
                                                        return `${firstName.charAt(0)}. ${lastName}`;
                                                    }}
                                                />
                                                <YAxis label={{ 
                                                    value: 'Points', 
                                                    angle: -90, 
                                                    position: 'insideLeft',
                                                    style: { textAnchor: 'middle' }
                                                }} />
                                                <RechartsTooltip />
                                                <Bar dataKey="totalPoints" name="Total Points" fill="#3f51b5" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Box>
                                ) : (
                                    <Box p={2} textAlign="center">
                                        <Typography variant="body2" color="textSecondary">
                                            No member participation data available
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        
                        {/* Event Statistics Table */}
                        <Grid item xs={12} style={{ padding: '16px 24px' }}>
                            <Box p={2} border={1} borderColor="grey.300" borderRadius={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Event Statistics
                                </Typography>
                                {analytics.topEvents.length > 0 ? (
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            sx={{
                                                border: 'none',
                                                '& .MuiDataGrid-cell:focus': { outline: 'none' },
                                                '& .MuiDataGrid-columnHeaders': {
                                                    backgroundColor: theme.palette.background.default,
                                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                                    padding: '0 8px',
                                                },
                                                '& .MuiDataGrid-cell': {
                                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                                    padding: '0 8px',
                                                },
                                                '& .MuiDataGrid-footerContainer': {
                                                    borderTop: 'none',
                                                    minHeight: '40px',
                                                },
                                                '& .MuiDataGrid-row': {
                                                    cursor: 'pointer',
                                                }
                                            }}
                                            rows={analytics.topEvents.map((event, index) => ({
                                                id: index,
                                                name: event.displayName || event.name,
                                                eventId: event.id,
                                                originalName: event.name,
                                                count: event.count,
                                                approved: event.approved,
                                                awaiting: event.awaiting,
                                                denied: event.denied,
                                                lastDate: event.lastDate,
                                                participation: event.participation
                                            }))}
                                            columns={isMobile ? [
                                                { 
                                                    field: 'name', 
                                                    headerName: 'Event Name', 
                                                    flex: 1.5,
                                                    maxWidth: 300
                                                },
                                                {
                                                    field: 'participation',
                                                    headerName: 'Participation',
                                                    flex: 1,
                                                    minWidth: 150,
                                                    sortable: false,
                                                    valueGetter: (params) => params.row.participation,
                                                    renderCell: (params) => {
                                                        const participation = params.value || { participated: [], didNotParticipate: [], hasCustomRequests: false, customRequestCount: 0 };
                                                        return (
                                                            <Box display="flex" flexDirection="column" width="100%">
                                                                <Typography 
                                                                    variant="body2" 
                                                                    style={{ 
                                                                        fontSize: '0.8rem', 
                                                                        whiteSpace: 'nowrap',
                                                                        color: '#4caf50',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleOpenParticipationDialog(params.row, true);
                                                                    }}
                                                                >
                                                                    <Box component="span" width={8} height={8} bgcolor="#4caf50" borderRadius="50%" mr={0.5} display="inline-block" />
                                                                    {`${participation.participated.length} Attended`}
                                                                    {participation.hasCustomRequests && (
                                                                        <CustomPointIcon style={{ fontSize: 14, marginLeft: 4, color: '#3f51b5' }} />
                                                                    )}
                                                                </Typography>
                                                                <Typography 
                                                                    variant="body2" 
                                                                    style={{ 
                                                                        fontSize: '0.8rem', 
                                                                        whiteSpace: 'nowrap',
                                                                        color: '#f44336',
                                                                        cursor: 'pointer' 
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleOpenParticipationDialog(params.row, false);
                                                                    }}
                                                                >
                                                                    <Box component="span" width={8} height={8} bgcolor="#f44336" borderRadius="50%" mr={0.5} display="inline-block" />
                                                                    {`${participation.didNotParticipate.length} Absent`}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    }
                                                }
                                            ] : [
                                                { 
                                                    field: 'name', 
                                                    headerName: 'Event Name', 
                                                    flex: 2,
                                                    minWidth: 200
                                                },
                                                {
                                                    field: 'lastDate',
                                                    headerName: 'Date',
                                                    flex: 1,
                                                    minWidth: 120,
                                                    valueFormatter: (params) => {
                                                        if (!params.value) return '-';
                                                        return moment(params.value).format('MMM D, YYYY');
                                                    }
                                                },
                                                { 
                                                    field: 'participated', 
                                                    headerName: 'Participated', 
                                                    flex: 1.5,
                                                    minWidth: 150,
                                                    renderCell: (params) => {
                                                        const participation = params.row.participation || { participated: [], didNotParticipate: [], hasCustomRequests: false, customRequestCount: 0 };
                                                        return (
                                                            <Box 
                                                                display="flex" 
                                                                alignItems="center"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleOpenParticipationDialog(params.row, true);
                                                                }}
                                                            >
                                                                <Box component="span" width={12} height={12} bgcolor="#4caf50" borderRadius="50%" mr={1} />
                                                                <Typography>
                                                                    {formatMemberList(
                                                                        participation.participated, 
                                                                        3, 
                                                                        participation.hasCustomRequests, 
                                                                        participation.customRequestCount
                                                                    )}
                                                                </Typography>
                                                                {participation.hasCustomRequests && (
                                                                    <CustomPointIcon style={{ fontSize: 16, marginLeft: 4, color: '#3f51b5' }} />
                                                                )}
                                                            </Box>
                                                        );
                                                    },
                                                    renderHeader: () => (
                                                        <Box display="flex" alignItems="center">
                                                            <Box width={12} height={12} bgcolor="#4caf50" borderRadius="50%" mr={1} />
                                                            Participated
                                                        </Box>
                                                    )
                                                },
                                                { 
                                                    field: 'absent', 
                                                    headerName: 'Absent', 
                                                    flex: 1.5,
                                                    minWidth: 150,
                                                    renderCell: (params) => {
                                                        const participation = params.row.participation || { participated: [], didNotParticipate: [], hasCustomRequests: false, customRequestCount: 0 };
                                                        return (
                                                            <Box 
                                                                display="flex" 
                                                                alignItems="center"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleOpenParticipationDialog(params.row, false);
                                                                }}
                                                            >
                                                                <Box component="span" width={12} height={12} bgcolor="#f44336" borderRadius="50%" mr={1} />
                                                                <Typography>
                                                                    {formatMemberList(
                                                                        participation.didNotParticipate, 
                                                                        3, 
                                                                        participation.hasCustomRequests, 
                                                                        participation.customRequestCount
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    },
                                                    renderHeader: () => (
                                                        <Box display="flex" alignItems="center">
                                                            <Box width={12} height={12} bgcolor="#f44336" borderRadius="50%" mr={1} />
                                                            Absent
                                                        </Box>
                                                    )
                                                },
                                                { 
                                                    field: 'count', 
                                                    headerName: 'Total', 
                                                    type: 'number',
                                                    flex: 0.5,
                                                    minWidth: 75
                                                }
                                            ]}
                                            density="compact"
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { pageSize: 10 },
                                                },
                                                sorting: {
                                                    sortModel: [{ field: 'lastDate', sort: 'desc' }],
                                                },
                                            }}
                                            pageSizeOptions={[5, 10, 25]}
                                            disableRowSelectionOnClick
                                            onRowClick={handleEventRowClick}
                                        />
                                    </div>
                                ) : (
                                    <Box p={2} textAlign="center">
                                        <Typography variant="body2" color="textSecondary">
                                            No event data available
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
            
            {/* Participation Details Dialog */}
            <Dialog
                open={participationDialog.open}
                onClose={handleCloseParticipationDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {participationDialog.showParticipated ? "Members Who Participated" : "Members Who Were Absent"}
                    {participationDialog.event && (
                        <Typography variant="subtitle1" color="textSecondary">
                            {participationDialog.event.name}
                        </Typography>
                    )}
                </DialogTitle>
                <DialogContent>
                    {participationDialog.event && (
                        <List>
                            {participationDialog.showParticipated && participationDialog.event.participation?.hasCustomRequests && (
                                <ListItem style={{ backgroundColor: 'rgba(63, 81, 181, 0.08)' }}>
                                    <CustomPointIcon style={{ marginRight: 16, color: '#3f51b5' }} />
                                    <ListItemText 
                                        primary={`Custom Point Requests (${participationDialog.event.participation.customRequestCount})`}
                                        secondary="Manual points added by admins"
                                    />
                                </ListItem>
                            )}
                            
                            {participationDialog.showParticipated 
                                ? (participationDialog.event.participation?.participated || []).map((memberId) => {
                                    const member = chapter.members[memberId];
                                    if (!member) return null;
                                    return (
                                        <ListItem key={memberId}>
                                            <ListItemText 
                                                primary={`${member.first} ${member.last}`}
                                                secondary={member.roll ? `Roll: ${member.roll}` : ''}
                                            />
                                        </ListItem>
                                    );
                                })
                                : (participationDialog.event.participation?.didNotParticipate || []).map((memberId) => {
                                    const member = chapter.members[memberId];
                                    if (!member) return null;
                                    return (
                                        <ListItem key={memberId}>
                                            <ListItemText 
                                                primary={`${member.first} ${member.last}`}
                                                secondary={member.roll ? `Roll: ${member.roll}` : ''}
                                            />
                                        </ListItem>
                                    );
                                })
                            }
                            {((participationDialog.showParticipated 
                                ? participationDialog.event.participation?.participated 
                                : participationDialog.event.participation?.didNotParticipate) || []).length === 0 && 
                                !participationDialog.event.participation?.hasCustomRequests && (
                                <ListItem>
                                    <ListItemText primary="No members in this category" />
                                </ListItem>
                            )}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseParticipationDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </ParentTable>
    );
};

export default EventAnalyticsTab; 