import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { CloudDownloadOutlined as DownloadIcon, CheckCircleOutlineOutlined as ApproveIcon, CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import Papa from 'papaparse';

import ParentTable from 'components/Tables/ParentTable';

const ChapterStatsTab = ({ 
    compiledMembers, 
    points, 
    chapter, 
    hasPointsAdmin, 
    theme,
    paginationModel,
    setPaginationModel,
    setViewingUser,
    viewingUser,
    setClearPointsDialog,
    setApproveOpenRequestsDialog
}) => {
    // Add useMediaQuery hook at component level
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    
    // Get the number of open requests
    const getNumOpenRequests = () => {
        return points ? points.getNumOpenRequests() : 0;
    };
    
    // Generate columns for DataGrid based on requirements
    const getDataGridColumns = () => {
        let columns = [];

        if (chapter && chapter.settings && chapter.settings.roll) {
            columns.push({
                field: 'roll',
                headerName: 'Roll',
                type: 'number',
                align: 'center',
                headerAlign: 'center',
                minWidth: 70,
                flex: 0.5,
                renderCell: ({ value }) => (
                    <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {value}
                    </Typography>
                ),
                renderHeader: () => (
                    <Typography variant="subtitle2" color="textSecondary">
                        Roll
                    </Typography>
                )
            });
        }

        // Add standard columns
        if (!isMobile) {
            columns.push(
                {
                    field: 'first',
                    headerName: 'First',
                    minWidth: 100,
                    flex: 1,
                    renderCell: ({ value }) => (
                        <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {value}
                        </Typography>
                    ),
                    renderHeader: () => (
                        <Typography variant="subtitle2" color="textSecondary">
                            First
                        </Typography>
                    )
                },
                {
                    field: 'last',
                    headerName: 'Last',
                    minWidth: 100,
                    flex: 1,
                    renderCell: ({ value }) => (
                        <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {value}
                        </Typography>
                    ),
                    renderHeader: () => (
                        <Typography variant="subtitle2" color="textSecondary">
                            Last
                        </Typography>
                    )
                }
            );
        } else {
            // Mobile view combines first and last name
            columns.push({
                field: 'fullName',
                headerName: 'Name',
                minWidth: 120,
                flex: 1.5,
                renderCell: ({ row }) => (
                    <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {`${row.last}, ${row.first.charAt(0)}.`}
                    </Typography>
                ),
                valueGetter: (params) => `${params.row.last}, ${params.row.first.charAt(0)}.`,
                renderHeader: () => (
                    <Typography variant="subtitle2" color="textSecondary">
                        Name
                    </Typography>
                )
            });
        }

        // Points column for both mobile and desktop
        columns.push({
            field: 'points',
            headerName: 'Points',
            type: 'number',
            valueType: 'numeric',
            sortComparator: (v1, v2) => Number(v1) - Number(v2),
            align: 'center',
            headerAlign: 'center',
            minWidth: 70,
            flex: 0.7,
            renderCell: ({ value }) => (
                <Typography 
                    variant="body2" 
                    sx={{ 
                        textOverflow: 'ellipsis', 
                        overflow: 'hidden', 
                        whiteSpace: 'nowrap',
                        fontWeight: 'medium',
                        color: value < 0 ? theme.palette.error.main : 'inherit'
                    }}
                >
                    {value}
                </Typography>
            ),
            renderHeader: () => (
                <Typography variant="subtitle2" color="textSecondary">
                    Points
                </Typography>
            )
        });

        // Add Open Requests column if admin
        if (hasPointsAdmin && points.allowRequests) {
            columns.push({
                field: 'or',
                headerName: isMobile ? 'Reqs' : 'Open Requests',
                type: 'number',
                valueType: 'numeric',
                align: 'center',
                headerAlign: 'center',
                minWidth: isMobile ? 60 : 100,
                flex: isMobile ? 0.5 : 0.8,
                renderCell: ({ value }) => (
                    <Box display="flex" alignItems="center" justifyContent="center">
                        {value > 0 ? (
                            <Box 
                                sx={{ 
                                    backgroundColor: theme.palette.info.light,
                                    color: theme.palette.info.contrastText,
                                    borderRadius: '10px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '24px',
                                    height: '20px',
                                    padding: '0 6px',
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                {value}
                            </Box>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                {value}
                            </Typography>
                        )}
                    </Box>
                ),
                renderHeader: () => (
                    <Typography variant="subtitle2" color="textSecondary">
                        {isMobile ? 'Reqs' : 'Open Requests'}
                    </Typography>
                )
            });
        }

        return columns;
    };

    // Default sorting
    const getDefaultSortModel = () => {
        if (hasPointsAdmin && points.allowRequests) {
            return [{ field: 'or', sort: 'desc' }];
        }
        return [{ field: 'points', sort: 'desc' }];
    };

    // Get the table actions based on user role and device type
    const getTableActions = () => {
        if (!hasPointsAdmin) return [];
        
        const actions = [];
        
        // Only add download button on non-mobile devices
        if (!isMobile) {
            actions.push({
                title: 'Download Standings',
                icon: <DownloadIcon />,
                onClick: event => {
                    let finalized = [];

                    for (let i = 0; i < compiledMembers.length; i++) {
                        let mem = compiledMembers[i];

                        let data = {
                            First: mem.first,
                            Last: mem.last,
                            Points: mem.points,
                        };

                        if (mem.roll) {
                            data.Roll = mem.roll;
                        }

                        finalized.push(data);
                    }

                    const element = document.createElement('a');
                    const file = new Blob([Papa.unparse(finalized)], {
                        type: 'text/csv',
                    });

                    let pointsName = points.name.replace(/\W/g, '');

                    element.href = URL.createObjectURL(file);
                    element.download = `${pointsName}_records.csv`;
                    document.body.appendChild(element);
                    element.click();
                },
            });
        }
        
        // Add approve requests button if there are open requests
        if (points.allowRequests && getNumOpenRequests() > 0) {
            actions.push({
                title: 'Approve Requests',
                icon: <ApproveIcon />,
                onClick: () => {
                    setApproveOpenRequestsDialog({ open: true });
                },
            });
        }
        
        // Always add clear points button
        actions.push({
            title: 'Clear Points',
            icon: <CancelOutlinedIcon />,
            onClick: () => {
                setClearPointsDialog({ open: true });
            },
        });
        
        return actions;
    };

    // Get the number of open requests for the badge
    const numOpenRequests = getNumOpenRequests();
    
    return (
        <Box style={{ padding: 24 }}>
            <ParentTable
                title="Chapter Statistics"
                actions={getTableActions()}
                rows={[]}
            >
                <Grid container>
                    <Grid item xs={12} key={`table_standings_${compiledMembers.length}`}>
                        <DataGrid
                            rows={compiledMembers}
                            columns={getDataGridColumns()}
                            loading={false}
                            rowHeight={32}
                            columnHeaderHeight={36}
                            hideFooterSelectedRowCount
                            disableColumnMenu
                            density="compact"
                            onRowClick={
                                hasPointsAdmin
                                    ? (params) => {
                                          setViewingUser(params.row.id);
                                      }
                                    : undefined
                            }
                            components={{
                                NoRowsOverlay: () => (
                                    <Box style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body2" color="textSecondary">No members</Typography>
                                    </Box>
                                )
                            }}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            initialState={{
                                sorting: {
                                    sortModel: getDefaultSortModel(),
                                },
                                pagination: {
                                    paginationModel: { pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25, 50]}
                            disableRowSelectionOnClick
                            autoHeight
                            getRowId={row => row.id}
                            getRowClassName={(params) => 
                                params.id === viewingUser ? 'selected-row' : ''
                            }
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-cell:focus': { outline: 'none' },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: theme.palette.background.default,
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                    padding: '0 8px',
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                    padding: '0 8px',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    borderTop: 'none',
                                    minHeight: '40px',
                                },
                                '& .selected-row': {
                                    backgroundColor: theme.palette.action.selected,
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover,
                                    },
                                    '& .MuiDataGrid-cell': {
                                        fontWeight: 'medium',
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </ParentTable>
        </Box>
    );
};

export default ChapterStatsTab; 