import { useMutation } from '@tanstack/react-query';
import { db } from 'config/firebase';
import { queryClient } from 'lib/queryClient';
import { queryKeys } from 'lib/queryKeys';

/**
 * Links an existing chapter to a fraternity
 * @param {Object} data - Chapter data
 * @param {string} data.fraternityId - Fraternity ID
 * @param {string} data.chapterId - Chapter ID to link
 * @returns {Promise<Object>} - The updated chapter reference
 */
const linkChapter = async ({ fraternityId, chapterId }) => {
  // Get the chapter reference
  const chapterRef = db.collection('chapters').doc(chapterId);
  const chapterDoc = await chapterRef.get();
  
  if (!chapterDoc.exists) {
    throw new Error('Chapter not found');
  }
  
  // Update the chapter with the fraternity ID
  await chapterRef.update({
    fraternity: fraternityId,
    updatedAt: new Date()
  });
  
  // Get the updated chapter
  const updatedChapterDoc = await chapterRef.get();
  
  return {
    id: updatedChapterDoc.id,
    ...updatedChapterDoc.data()
  };
};

/**
 * Hook to link an existing chapter to a fraternity
 * @param {string} fraternityId - The ID of the fraternity
 * @returns {Object} Mutation object for linking a chapter
 */
export const useLinkChapter = (fraternityId) => {
  return useMutation({
    mutationFn: linkChapter,
    onSuccess: () => {
      // Invalidate the chapters query to refresh the list
      queryClient.invalidateQueries({
        queryKey: queryKeys.fraternity.chapters(fraternityId)
      });
    }
  });
}; 