/**
 * Analyzes a user's point contribution and participation statistics
 * @param {Object} data - The chapter participation data object
 * @param {string} userId - The user ID to analyze
 * @returns {Object} An object containing the user's participation statistics
 */
export function getUserStats(data, userId) {
    // Check if the user exists in the records
    if (!data.records[userId]) {
      return {
        error: "User not found",
        userId
      };
    }
  
    // Get all records for the user
    const userRecords = data.records[userId];
    
    // Initialize stats object
    const stats = {
      userId,
      totalPoints: 0,
      approvedPoints: 0,
      pendingPoints: 0,
      eventsAttended: 0,
      eventsAbsent: 0,
      positiveRecords: 0,
      negativeRecords: 0,
      progressTowardGoal: 0,
      records: {
        approved: [],
        pending: [],
        other: []
      },
      eventBreakdown: {},
      recommendedActions: []
    };
  
    // Process each record
    userRecords.forEach(record => {
      // Group by status
      if (record.status === 0) { // Awaiting approval
        stats.pendingPoints += record.points;
        stats.records.pending.push(record);
      } else if (record.status === 1) { // Approved
        stats.approvedPoints += record.points;
        stats.records.approved.push(record);
        
        // Count events attended vs absent
        if (record.points > 0) {
          stats.positiveRecords++;
          stats.eventsAttended++;
        } else if (record.points < 0) {
          stats.negativeRecords++;
          stats.eventsAbsent++;
        }
        
        // Track event types for breakdown
        if (!stats.eventBreakdown[record.name]) {
          stats.eventBreakdown[record.name] = {
            count: 0,
            points: 0
          };
        }
        stats.eventBreakdown[record.name].count++;
        stats.eventBreakdown[record.name].points += record.points;
      } else {
        // Denied, expired, or incomplete
        stats.records.other.push(record);
      }
    });
  
    // Calculate total points and progress
    stats.totalPoints = stats.approvedPoints + stats.pendingPoints;
    stats.progressTowardGoal = data.goal > 0 ? 
      Math.min(100, Math.round((stats.approvedPoints / data.goal) * 100)) : 0;
    
    // Generate recommended actions
    if (stats.progressTowardGoal < 50) {
      stats.recommendedActions.push("Attend more events to increase points");
    }
    if (stats.eventsAbsent > stats.eventsAttended) {
      stats.recommendedActions.push("Improve event attendance rate");
    }
    if (stats.pendingPoints > 0) {
      stats.recommendedActions.push("Follow up on pending point approvals");
    }
  
    // Add attendance rate
    const totalEvents = stats.eventsAttended + stats.eventsAbsent;
    stats.attendanceRate = totalEvents > 0 ? 
      Math.round((stats.eventsAttended / totalEvents) * 100) : 0;
      
    return stats;
  }