import React from 'react'

import { useTheme } from '@material-ui/core/styles'

import { LocationContext } from 'context/LocationContext'

export const MapContext = React.createContext()

const Map = ({ style, center, children, listeners, span, ...props }) => {
    const theme = useTheme()

    const { mapkit } = React.useContext(LocationContext)

    const [map, setMap] = React.useState(null)
    let mapRef = React.useRef()

    React.useEffect(() => {
        if (map && Array.isArray(listeners)) {
            listeners.forEach(listener => map && map.addEventListener(listener.type, listener.listener))

            return () => {
                listeners.forEach(listener => map && map.removeEventListener(listener.type, listener.listener))
            }
        }
    }, [map, listeners])

    React.useEffect(() => {
        return () => {
            if (map) {
                map.destroy()
            }
        }
    }, [])

    React.useEffect(() => {
        if (mapkit && mapRef.current) {
            const backupSpan = new mapkit.CoordinateSpan(0.01)
            const region = new mapkit.CoordinateRegion(center, span ?? backupSpan)
            
            const newMap = new mapkit.Map(mapRef.current, {
                center,
                colorScheme: theme.palette.type === 'dark' ? mapkit.Map.ColorSchemes.Dark : mapkit.Map.ColorSchemes.Light,
                region: region,
            })

            setMap(newMap)
        }
    }, [mapRef, mapkit])

    return (
        <MapContext.Provider
            value={{
                map,
            }}
        >
            <div ref={mapRef} style={{ height: 128, width: '100%', ...style }} {...props}>
                {children}
            </div>
        </MapContext.Provider>
    )
}

export default Map
