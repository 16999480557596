import React from 'react'
import { Box, Divider, IconButton, Typography } from '@material-ui/core'
import {
    AttachFile as AttachIcon,
    Send as SendIcon,
    /* Format Icons */
    TextFormat as FormatIcon,
    FormatBold as BoldIcon,
    FormatItalic as ItalicIcon,
    FormatUnderlined as UnderlineIcon,
} from '@material-ui/icons'

import { Editor, Transforms, createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'

import AttachmentsBox from 'components/Messages/AttachmentsBox'
import AttachmentsDialog from 'components/Messages/AttachmentsDialog'
import Widget from 'components/Widget'

import isHotkey from 'is-hotkey'
import cn from 'classnames'

import { AuthContext } from 'context/AuthContext'
import { MessagesContext } from 'context/MessagesContext'

import desktopUseStyles from 'pages/messages/styles'

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const withGC = editor => {
    const { isVoid } = editor

    editor.isVoid = element => {
        return element.type === 'events' || element.type === 'polls' ? true : isVoid(element)
    }

    return editor
}

const ChatComposerBar = props => {
    return <ChatDefaultComposerBar {...props} />
}

const ChatDefaultComposerBar = props => {
    const editor = withGC(withReact(createEditor()))

    return <ChatMobileComposerBar {...props} editor={editor} />
}

const ChatMobileComposerBar = ({ editor, conversationId, history, version, onUpdateNumAttachments }) => {
    const desktopClasses = desktopUseStyles()

    const {
        addMessageToConversation,
        compileMessage,
        isMarkActive,
        toggleMark,
        clearAllMarks,
        getBlankMessage,
        renderElement,
        renderLeaf,
        canSendMessage,
    } = React.useContext(MessagesContext)

    const { user } = React.useContext(AuthContext)

    let canSendMessages = canSendMessage(conversationId, user.getId())

    const [attachmentDialog, setAttachmentDialog] = React.useState(false)
    const [attachments, setAttachments] = React.useState([])
    const [editorValue, setEditorValue] = React.useState(getBlankMessage())

    const [isEditing, setIsEditing] = React.useState(false)

    const [showFormatting, setShowFormatting] = React.useState(true)

    const [keyboardShowing, setKeyboardShowing] = React.useState(false)

    React.useEffect(() => {
        if (Capacitor.isPluginAvailable('Keyboard')) {
            Keyboard.addListener('keyboardDidShow', info => {
                setKeyboardShowing(true)
            })

            Keyboard.addListener('keyboardDidHide', () => {
                setKeyboardShowing(false)
            })

            return () => {
                Keyboard.removeAllListeners()
            }
        }
    }, [])

    const clearEditor = () => {
        setAttachments([])

        _onUpdateNumAttachments(0)

        clearAllMarks(editor)

        Transforms.delete(editor, {
            at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, []),
            },
        })
    }

    const removeAttachment = index => {
        setAttachments(attachments => {
            let arr = [...attachments]
            arr.splice(index, 1)
            return arr
        })

        _onUpdateNumAttachments(attachments.length - 1)
    }

    const renderSlate = () => (
        <Editable
            renderLeaf={renderLeaf}
            renderElement={props => renderElement({ ...props, history: history })}
            onKeyDown={e => {
                for (const hotkey in HOTKEYS) {
                    if (isHotkey(hotkey, e)) {
                        e.preventDefault()
                        const mark = HOTKEYS[hotkey]
                        toggleMark(editor, mark)
                    }
                }
            }}
            placeholder={!isEditing ? 'Type a message' : undefined}
            onBlur={() => setIsEditing(false)}
            onFocus={() => setIsEditing(true)}
            aria-label="Type a message"
        />
    )

    const toggleFormatting = () => {
        setShowFormatting(formatting => !formatting)
    }

    const openAttachmentDialog = () => setAttachmentDialog(true)

    const onSend = () => {
        const compiledMessage = compileMessage(editor, editor.children, attachments)

        if (compiledMessage !== null) {
            addMessageToConversation(conversationId, compiledMessage)
        }
        clearEditor()
    }

    const getSendButton = () => {
        return (
            <IconButton
                size="small"
                aria-label="send"
                className={desktopClasses.navButtonIcon}
                onClick={onSend}
                disabled={
                    (!attachments || attachments.length === 0) &&
                    (!editor.children ||
                        (editor.children.length === 1 &&
                            editor.children[0].type === 'paragraph' &&
                            editor.children[0].children.length === 1 &&
                            editor.children[0].children[0].text === ''))
                }
            >
                <SendIcon />
            </IconButton>
        )
    }

    const _onUpdateNumAttachments = num => {
        if (onUpdateNumAttachments) {
            onUpdateNumAttachments(num)
        }
    }

    const MarkButton = ({ format, icon }) => {
        const isActive = isMarkActive(editor, format)

        return (
            <IconButton
                size="small"
                aria-label={format}
                onClick={e => {
                    console.log('on click', isActive, editor, format)
                    //toggleMark(editor, format)
                    //e.preventDefault()
                }}
                className={cn(desktopClasses.navButtonIcon, isActive ? desktopClasses.buttonIconActive : desktopClasses.buttonIconInactive)}
            >
                {icon}
            </IconButton>
        )
    }

    if (version === 'mobile') {
        if (!canSendMessages) {
            return (
                <Box style={{ flexGrow: 0 }}>
                    <Box
                        className={cn({
                            [desktopClasses.navPaddingButtonsMobile]: !keyboardShowing,
                            [desktopClasses.navPaddingButtonsMobileEditing]: keyboardShowing,
                        })}
                    >
                        <Typography style={{ opacity: `${1 / 3}` }} align="center">
                            You don't have permission to send messages
                        </Typography>
                    </Box>
                </Box>
            )
        }

        return (
            <>
                <Box style={{ flexGrow: 0 }}>
                    <Box
                        className={cn({
                            [desktopClasses.navPaddingButtonsMobile]: !keyboardShowing,
                            [desktopClasses.navPaddingButtonsMobileEditing]: keyboardShowing,
                        })}
                    >
                        {attachments && attachments.length > 0 && (
                            <>
                                <Box className={desktopClasses.attachmentsBoxParent}>
                                    <AttachmentsBox
                                        attachments={attachments}
                                        onRemoveAttachment={removeAttachment}
                                        style={{ width: `calc(100% + 24px)` }}
                                        history={history}
                                    />
                                </Box>
                                <Divider style={{ marginTop: 12, marginBottom: 4, marginLeft: -4, marginRight: -4 }} />
                            </>
                        )}
                        <Slate
                            editor={editor}
                            value={editorValue}
                            onChange={val => {
                                setEditorValue(val)
                            }}
                        >
                            <Box style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, gap: 8 }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                    <IconButton size="small" aria-label="attach" className={desktopClasses.navButtonIcon} onClick={openAttachmentDialog}>
                                        <AttachIcon />
                                    </IconButton>
                                </Box>
                                <Box
                                    style={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        style={{
                                            maxHeight: 20 * 5,
                                            overflow: 'scroll',
                                        }}
                                    >
                                        {renderSlate()}
                                    </Box>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>{getSendButton()}</Box>
                            </Box>
                        </Slate>
                    </Box>
                </Box>
                {attachmentDialog && (
                    <AttachmentsDialog
                        conversationId={conversationId}
                        onClose={() => setAttachmentDialog(false)}
                        onAddAttachment={attachment => {
                            setAttachments(attachments => [...attachments, attachment])
                            setAttachmentDialog(false)

                            _onUpdateNumAttachments(attachments.length + 1)
                        }}
                    />
                )}
            </>
        )
    }

    if (!canSendMessages) {
        return (
            <Box style={{ flexGrow: 0 }}>
                <Widget disableWidgetMenu inheritHeight bodyClass={desktopClasses.navPaddingButtons}>
                    <Typography style={{ opacity: `${1 / 3}`, margin: '0 12px' }} align="center">
                        You don't have permission to send messages
                    </Typography>
                </Widget>
            </Box>
        )
    }

    return (
        <>
            <Box style={{ flexGrow: 0 }}>
                <Box className={cn(desktopClasses.navPaddingButtons, { [desktopClasses.navEditing]: isEditing })}>
                    {attachments && attachments.length > 0 && (
                        <>
                            <Box className={desktopClasses.attachmentsBoxParent}>
                                <AttachmentsBox
                                    attachments={attachments}
                                    onRemoveAttachment={removeAttachment}
                                    style={{ width: `calc(100% + 24px)` }}
                                    history={history}
                                />
                            </Box>
                            <Divider style={{ marginTop: 12, marginBottom: 4, marginLeft: -4, marginRight: -4 }} />
                        </>
                    )}
                    <Slate
                        editor={editor}
                        value={editorValue}
                        onChange={val => {
                            setEditorValue(val)
                        }}
                    >
                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                            {showFormatting ? (
                                <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                    <Box style={{ margin: 4, marginTop: 2, marginBottom: 6 }}>
                                        <Box style={{ maxHeight: 20 * 10, overflow: 'scroll' }}>{renderSlate()}</Box>
                                    </Box>
                                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton size="small" aria-label="attach" className={desktopClasses.navButtonIcon} onClick={openAttachmentDialog}>
                                            <AttachIcon />
                                        </IconButton>
                                        <Box display="flex" alignItems="center">
                                            <MarkButton format="bold" icon={<BoldIcon />} />
                                            <MarkButton format="italic" icon={<ItalicIcon />} />
                                            <MarkButton format="underline" icon={<UnderlineIcon />} />
                                        </Box>
                                        <Box style={{ flexGrow: 1 }}></Box>
                                        {false && (
                                            <Box className={desktopClasses.navButtonNewLine}>
                                                <Typography variant="caption">⌥ + ↩ to add a new line</Typography>
                                            </Box>
                                        )}
                                        <Box display="flex" alignItems="center">
                                            <IconButton size="small" aria-label="format" className={desktopClasses.navButtonIcon} onClick={toggleFormatting}>
                                                <FormatIcon />
                                            </IconButton>

                                            {getSendButton()}
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, gap: 8 }}>
                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                        <IconButton size="small" aria-label="attach" className={desktopClasses.navButtonIcon} onClick={openAttachmentDialog}>
                                            <AttachIcon />
                                        </IconButton>
                                    </Box>
                                    <Box
                                        style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Box
                                            style={{
                                                maxHeight: 20 * 5,
                                                overflow: 'scroll',
                                            }}
                                        >
                                            {renderSlate()}
                                        </Box>
                                    </Box>
                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                        <IconButton size="small" aria-label="format" className={desktopClasses.navButtonIcon} onClick={toggleFormatting}>
                                            <FormatIcon />
                                        </IconButton>
                                    </Box>
                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>{getSendButton()}</Box>
                                </Box>
                            )}
                        </Box>
                    </Slate>
                </Box>
            </Box>
            {attachmentDialog && (
                <AttachmentsDialog
                    conversationId={conversationId}
                    onClose={() => setAttachmentDialog(false)}
                    onAddAttachment={attachment => {
                        setAttachments(attachments => [...attachments, attachment])
                        setAttachmentDialog(false)

                        _onUpdateNumAttachments(attachments.length + 1)
                    }}
                />
            )}
        </>
    )
}

export default React.memo(ChatComposerBar)
