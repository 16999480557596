import { Box, Chip, Typography } from '@material-ui/core'
import {
    DeleteOutline as DeleteIcon,
    FavoriteBorderOutlined as LoveIcon,
    ThumbDownOutlined as ThumbsDownIcon,
    ThumbUpOutlined as ThumbsUpIcon,
} from '@material-ui/icons'
import React from 'react'

import axios from 'axios'

import { apiPrefix } from 'code/Helper'

import { IconButton, IconChip } from 'components/Wrappers'

import SelectEmojiDialog from 'components/Messages/SelectEmojiDialog'
import ViewPollDialog from 'components/Messages/ViewPollDialog'

import moment from 'moment'

import CopyToClipboard from 'components/copy-to-clipboard/CopyToClipboard'

import FullScreenImage from 'components/FullScreenImage'

import { ClipboardIcon, EmojiAddOutlineIcon as InsertEmojiIcon } from 'components/Icons'

import ConversationAvatar from 'components/Messages/Conversation/ConversationAvatar'

import MessageLink from 'components/Messages/Message/MessageLink'

import MessageDetailDrawer from './MessageDetailDrawer'
import ReportMessageDrawer from './MessageReportDrawer'
import ReactionDrawer from './ReactionDrawer'

import { useTheme } from '@material-ui/styles'

import { Messages } from 'objects/Messages'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { MessagesContext } from 'context/MessagesContext'

import useLongPress from 'code/LongPress'

import cn from 'classnames'

import { createEditor, Node } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'

import { convertObjectToList, isMobileDevice } from 'code/Helper'

// styles
import useStyles from '../styles'

export default function Message(props) {
    const classes = useStyles()
    const theme = useTheme()

    let { reactions, conversationId, history, deleted, conversation, includeBlocked, onIncrement } = props

    const [hover, setHover] = React.useState(false)
    const [reportDrawer, setReportDrawer] = React.useState({ open: false })
    const [showingReactions, setShowingReactions] = React.useState(false)
    const ref = React.useRef(null)

    React.useEffect(() => {
        if (isMobileDevice()) {
            function handleClick(event) {
                if (ref.current) {
                    if (
                        ref.current.contains(event.target) &&
                        (event.target.tagName === 'DIV' || (event.target.tagName === 'SPAN' && event.target.getAttribute('data-slate-string') === 'true'))
                    ) {
                        setHover(true)
                    } else {
                        setHover(false)
                    }
                }
            }

            document.addEventListener('click', handleClick)

            return () => {
                document.removeEventListener('click', handleClick)
            }
        }
    }, [ref])

    const { renderElement, renderLeaf, addReactionToMessage, removeReactionToMessage, removeMessageFromConversation } = React.useContext(MessagesContext)
    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [addReaction, setAddReaction] = React.useState(false)
    const [viewPoll, setViewPoll] = React.useState({ open: false, data: null })
    const [viewImage, setViewImage] = React.useState(false)

    const editor = withReact(createEditor())

    const reactionsEnabled = !conversation.settings || conversation.settings.reactionsPerms !== Messages.REACTION_PERMISSIONS_DISABLED
    const containsReactions = !deleted && Object.keys(reactions).length > 0

    const getBlocked = () => {
        let blockedItems = localStorage.getItem('cache_messagesBlocked')

        if (blockedItems) {
            return JSON.parse(blockedItems)
        }

        return { users: [], messages: [] }
    }

    const isBlocked = () => {
        if (!includeBlocked || !props.blockedItems) {
            return false
        }

        if (props.blockedItems.users.includes(props.sender.id)) {
            return 'user'
        } else if (props.blockedItems.messages.includes(props.id)) {
            return 'message'
        }

        return false
    }

    const submitBlock = ({ id, type }) => {
        if (type === 'user') {
            let blocked = getBlocked()
            blocked.users.push(id)
            localStorage.setItem('cache_messagesBlocked', JSON.stringify(blocked))
        } else {
            let blocked = getBlocked()
            blocked.messages.push(id)
            localStorage.setItem('cache_messagesBlocked', JSON.stringify(blocked))
        }
        onIncrement()
    }

    const unblockMessage = () => {
        let blocked = getBlocked()

        if (blocked.users.includes(props.sender.id)) {
            blocked.users = blocked.users.filter(id => id !== props.sender.id)
        }

        if (blocked.messages.includes(props.id)) {
            blocked.messages = blocked.users.filter(id => id !== props.id)
        }

        localStorage.setItem('cache_messagesBlocked', JSON.stringify(blocked))
        onIncrement()
    }

    const submitReport = async ({ type }) => {
        const sender = props.sender

        const body = {
            user: {
                first: sender.first,
                last: sender.last,
                id: sender.id,
            },
            message: props.message.map(n => Node.string(n)).join('\n'),
            report: type,
        }

        try {
            await axios.post(`${apiPrefix}/app/messages/report`, body)
            window.alert(
                `Thank you for your report, please consider blocking the ${type} while we look over their content to see if it breaks our objectionable content policy.`,
            )
        } catch (e) {
            window.alert(`Ran into error while trying to report ${type}, please try again.`)
        }
    }

    function getTimeOfDay(cd) {
        if (cd) {
            let relative = {
                sameDay: 'h:mm a',
                lastDay: 'h:mm a',
                lastWeek: 'h:mm a',
                sameElse: 'h:mm a',
            }

            return getTime(cd).calendar(null, relative)
        }

        return ''
    }

    function getRelativeTime(cd) {
        if (cd) {
            let relative = {
                sameDay: '[Today]',
                lastDay: '[Yesterday]',
                lastWeek: 'dddd',
                sameElse: 'MMMM D',
            }

            return getTime(cd).calendar(null, relative)
        }

        return ''
    }

    const getTime = cd => {
        if (cd.seconds || cd._seconds) {
            let date = new Date(0)
            date.setUTCSeconds(cd._seconds ? cd._seconds : cd.seconds)

            return moment(date)
        }

        return moment(cd)
    }

    const getCurrentReaction = () => {
        if (reactions) {
            let users = Object.keys(reactions)

            let arr = {}

            for (let i = 0; i < users.length; i++) {
                let user = users[i]
                let reaction = reactions[user]

                if (reaction in arr) {
                    arr[reaction].users.push(user)
                } else {
                    arr[reaction] = { users: [user] }
                }
            }

            arr = convertObjectToList(arr)

            arr.sort((a, b) => b.users.length - a.users.length)

            for (let i = 0; i < arr.length; i++) {
                let reaction = arr[i]

                let userReacted = reaction.users.includes(user.getId())

                if (userReacted) {
                    return reaction.id
                }
            }

            return ''
        }

        return ''
    }

    const curReaction = getCurrentReaction()

    const ReactionChip = ({ onClick, onLongPress, ...props }) => {
        const longPressEvent = useLongPress(onLongPress, onClick, {
            shouldPreventDefault: true,
            delay: 600,
        })

        return <Chip {...longPressEvent} {...props} />
    }

    const getReactions = () => {
        if (reactions && chapter) {
            let users = Object.keys(reactions)

            let obj = {}

            for (let i = 0; i < users.length; i++) {
                let user = users[i]
                let reaction = reactions[user]

                if (user in chapter.members) {
                    user = { ...chapter.members[user], id: user }

                    if (reaction in obj) {
                        obj[reaction].users.push(user)
                    } else {
                        obj[reaction] = { users: [user] }
                    }

                    // Code below helps test multiple users
                    /*let userKeys = Object.keys(chapter.members)
                    for (let j = 0; j < userKeys.length; j++) {
                        let userId = userKeys[j]
                        const newUser = { ...chapter.members[userId], id: userId }

                        if (reaction in obj) {
                            obj[reaction].users.push(newUser)
                            obj[reaction].users.push(newUser)
                            obj[reaction].users.push(newUser)
                            obj[reaction].users.push(newUser)
                        } else {
                            obj[reaction] = { users: [newUser, newUser, newUser, newUser] }
                        }
                    }*/
                }
            }

            return obj
        }

        return null
    }

    const formattedReactions = getReactions()

    const renderReactions = () => {
        //React.useMemo(() => {
        if (reactions) {
            const arr = convertObjectToList(formattedReactions)

            arr.sort((a, b) => b.users.length - a.users.length)

            let compiled = []

            for (let i = 0; i < arr.length; i++) {
                let reaction = arr[i]

                let userReacted = reaction.users.map(user => user.id).includes(user.getId())

                compiled.push(
                    <ReactionChip
                        onClick={userReacted ? () => onSetReaction('') : () => onSetReaction(reaction.id)}
                        onLongPress={() => setShowingReactions(reaction.id)}
                        key={`${props.id}.${reaction.id}`}
                        label={`${reaction.id} ${reaction.users.length}`}
                        variant={userReacted ? 'default' : 'outlined'}
                        size="small"
                        className={classes.reactionChip}
                        clickable
                        component="button"
                    />,
                )

                /*compiled.push(
                    <Chip
                        {...longPressEvent}
                        key={`${props.id}.${reaction.id}`}
                        label={`${reaction.id} ${reaction.users.length}`}
                        variant={userReacted ? 'default' : 'outlined'}
                        size="small"
                        className={classes.reactionChip}
                    />,
                )*/
            }

            return compiled
        }

        return <></>
    } //, [reactions])

    const onSetReaction = emoji => {
        if (emoji === '') {
            removeReactionToMessage(conversationId, props.id)
        } else {
            addReactionToMessage(conversationId, props.id, emoji)
        }
    }

    const attachmentsData = props.attachments !== undefined ? props.attachments : null

    let blockedVal = isBlocked()

    if (blockedVal) {
        return (
            <Box style={{ paddingTop: props.showAuthor ? 8 : 0 }} className={cn(classes.messageOuter)} ref={ref}>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box style={{ margin: -4, marginRight: 2 }}>
                        <Box style={{ width: 44, height: 20 }}></Box>
                    </Box>
                    <Box className={classes.messageInner}>
                        <Typography style={{ fontStyle: 'italic', opacity: 0.7 }}>
                            This {blockedVal} has been blocked (
                            <span
                                style={{ color: theme.palette.primary.main, fontWeight: 'semibold' }}
                                onClick={() => {
                                    unblockMessage()
                                }}
                            >
                                Unblock
                            </span>
                            )
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            {props.showTimestamp && (
                <Box style={{ paddingTop: 12, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box className={classes.timestampBar} />
                    <Typography align="center" className={classes.timestamp}>
                        {getRelativeTime(props.cd)}
                    </Typography>
                    <Box className={classes.timestampBar} />
                </Box>
            )}
            <Box
                style={{ paddingTop: props.showAuthor ? 8 : 0 }}
                className={cn(classes.messageOuter, { [classes.messageOuterHover]: hover })}
                onMouseEnter={!isMobileDevice() ? () => setHover(true) : null}
                onMouseLeave={!isMobileDevice() ? () => setHover(false) : null}
                ref={ref}
            >
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    {props.showAuthor ? (
                        <Box style={{ margin: -4, marginRight: 2 }}>
                            <ConversationAvatar onClick={_ => props.history.push(`/app/profile/${props.sender.id}`)} src={props.sender.photo} size={32} />
                        </Box>
                    ) : (
                        <Box style={{ margin: -4, marginRight: 2 }}>
                            <Box style={{ width: 44, height: 20 }}></Box>
                        </Box>
                    )}
                    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, maxWidth: '100%', overflow: 'hidden' }}>
                        {props.showAuthor && (
                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography style={{ fontWeight: 'bold', lineHeight: 1, flexGrow: 1 }}>
                                    {`${props.sender.first} ${props.sender.last}`} <span style={{ fontWeight: 400 }}>{getTime(props.cd).format('h:mm a')}</span>
                                </Typography>
                            </Box>
                        )}
                        <Box className={classes.messageInner}>
                            {deleted ? (
                                <Typography style={{ fontStyle: 'italic', opacity: 0.7 }}>This message has been deleted</Typography>
                            ) : (
                                <>
                                    {props.links && props.links.map(link => <MessageLink {...link} key={link.url} />)}
                                    <Slate editor={editor} value={props.message}>
                                        <Editable
                                            renderLeaf={renderLeaf}
                                            renderElement={props =>
                                                renderElement({
                                                    ...props,
                                                    history: history,
                                                    attachmentsData: attachmentsData,
                                                    onClickPoll: data => setViewPoll({ open: true, data: data }),
                                                    onClickImage: url => setViewImage(url),
                                                })
                                            }
                                            readOnly
                                        />
                                    </Slate>
                                </>
                            )}
                        </Box>
                        {reactionsEnabled && containsReactions && (
                            <Box style={{ display: 'flex', flexDirection: 'row', paddingBottom: 4 }}>
                                {renderReactions()}
                                <IconChip icon={<InsertEmojiIcon />} variant="outlined" size="small" onClick={e => setAddReaction(true)} />
                            </Box>
                        )}
                        {addReaction && (
                            <SelectEmojiDialog
                                id="add-reaction-dialog"
                                title="Add Reaction"
                                onSelect={emoji => {
                                    onSetReaction(emoji.native)
                                    setAddReaction(false)
                                }}
                                onClose={() => setAddReaction(false)}
                            />
                        )}
                        {viewImage !== false && <FullScreenImage open onClose={() => setViewImage(false)} src={viewImage} />}
                        {viewPoll.open && (
                            <ViewPollDialog
                                conversationId={conversationId}
                                messageId={props.id}
                                data={viewPoll.data}
                                id="view-poll-dialog"
                                onClose={() => {
                                    setViewPoll(viewPoll => ({ ...viewPoll, open: false }))
                                }}
                                onUpdate={val => {
                                    setViewPoll(viewPoll => ({ ...viewPoll, open: false }))
                                }}
                                history={props.history}
                            />
                        )}
                    </Box>
                </Box>
                {!deleted && (
                    <Box style={{ position: 'absolute', top: '-19px', right: 0, display: hover ? 'block' : 'none' }}>
                        {hover && !isMobileDevice() && (
                            <Box className={classes.messageReactionBox}>
                                <Typography className={classes.messageReactionBoxTOD}>{getTimeOfDay(props.cd)}</Typography>
                                <Box className={classes.messageReactionBoxBorder} />
                                {reactionsEnabled && (
                                    <>
                                        <IconButton size="small" aria-label="Thumbs Up" onClick={() => onSetReaction('👍')}>
                                            <ThumbsUpIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton size="small" aria-label="Thumbs Down" onClick={() => onSetReaction('👎')}>
                                            <ThumbsDownIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton size="small" aria-label="Love" onClick={() => onSetReaction('❤️')}>
                                            <LoveIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton size="small" aria-label="Custom Reaction" onClick={() => setAddReaction(true)}>
                                            <InsertEmojiIcon fontSize="small" />
                                        </IconButton>
                                        <Box className={classes.messageReactionBoxBorder} />
                                    </>
                                )}
                                <CopyToClipboard>
                                    {({ copy }) => (
                                        <IconButton size="small" aria-label="Copy" onClick={() => copy(props.message.map(n => Node.string(n)).join('\n'))}>
                                            <ClipboardIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </CopyToClipboard>
                                {(user.getId() === props.sender.id ||
                                    (conversation &&
                                        (conversation.owner === user.getId() ||
                                            (Array.isArray(conversation.admins) && conversation.admins.includes(user.getId()))))) && (
                                    <IconButton
                                        size="small"
                                        color="red"
                                        aria-label="Delete"
                                        onClick={() => removeMessageFromConversation(conversationId, props.id)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            {isMobileDevice() && (
                <MessageDetailDrawer
                    message={props.message}
                    open={hover}
                    onClose={() => setHover(false)}
                    onSetReaction={val => onSetReaction(val)}
                    onDelete={
                        user.getId() === props.sender.id ||
                        (conversation &&
                            (conversation.owner === user.getId() || (Array.isArray(conversation.admins) && conversation.admins.includes(user.getId()))))
                            ? () => removeMessageFromConversation(conversationId, props.id)
                            : null
                    }
                    onReport={
                        user.getId() !== props.sender.id
                            ? () => {
                                  setHover(false)
                                  setReportDrawer(drawer => ({ ...drawer, open: true }))
                              }
                            : null
                    }
                    reactionsEnabled={reactionsEnabled}
                    curReaction={curReaction}
                    setAddReaction={setAddReaction}
                    time={getTime(props.cd).format('h:mm a')}
                />
            )}
            {isMobileDevice() && (
                <ReportMessageDrawer
                    message={props.message}
                    sender={props.sender}
                    open={reportDrawer.open}
                    onClose={() => setReportDrawer(drawer => ({ ...drawer, open: false }))}
                    onBlock={type => {
                        console.log('blocking', type)
                        if (type === 'message') {
                            submitBlock({ id: props.id, type: 'messages' })
                        } else if (type === 'user') {
                            submitBlock({ id: props.sender.id, type: 'user' })
                        }
                        setReportDrawer(drawer => ({ ...drawer, open: false }))
                    }}
                    onReport={type => {
                        submitReport({ type: type })
                        setReportDrawer(drawer => ({ ...drawer, open: false }))
                    }}
                />
            )}
            <ReactionDrawer
                open={showingReactions}
                reaction={showingReactions && formattedReactions ? { ...formattedReactions[showingReactions], id: showingReactions } : null}
                onClose={() => setShowingReactions(false)}
                history={history}
            />
        </>
    )
}
